import { IClaimManagerService } from './claim-manager-service';
import { IEmailModalService } from './emails/email-modal';
import { IDocumentHttpService } from '../../core/http';
import {
  Guid,
  TemplateRequirementTypes,
  ITemplateRequirementValue,
  RecordTypes
} from '../../core/models';
import { IAlertService, IMessagingService, ITrackingService } from '../../core/services';
import { IClaimsWindow } from '../../core/utils';
import { IDocumentService } from '../documents/document.service';
import { StateService } from 'angular-ui-router';
import { DocumentCreatedMessage } from '../../core/messages';
import { ISmsService } from '../sms/sms.service';
import ng from 'angular';

/* @ngInject */
export default function ClaimToolbarController(
  $scope: any,
  $http: ng.IHttpService,
  $window: IClaimsWindow,
  $rootScope: any,
  $stateParams: any,
  $state: StateService,
  AlertService: IAlertService,
  ClaimManagerService: IClaimManagerService,
  EmailModalService: IEmailModalService,
  DocumentService: IDocumentService,
  MessagingService: IMessagingService,
  DocumentHttpService: IDocumentHttpService,
  SmsService: ISmsService,
  TrackingService: ITrackingService
): void {
  const claimId = $stateParams.claimId;

  const vm = this;
  vm.claim = ClaimManagerService.claim();
  vm.emailTemplates = ClaimManagerService.emailTemplates();
  vm.sendEmail = sendEmail;
  vm.selectDocument = selectDocument;
  vm.sendSms = sendSms;

  $rootScope.$on('claim.updated', function(e, claim) {
    vm.claim = claim;
  });

  function sendSms() {
    SmsService.open({
      recordId: claimId,
      recordType: RecordTypes.Claim,
      to: ''
    })
      .then(() => {
        $state.go('app.claim.activity', { claimId: claimId }, { reload: true });
      })
      .catch(() => {});
  }

  function sendEmail(template) {
    if (template) {
      template.claimId = claimId;
    }

    EmailModalService.sendEmail(claimId, template, email => {
      email.claimId = claimId;

      $http
        .post('/api/v1/emails/send', JSON.stringify(email))
        .then(function() {
          logEmailSent(template);
          AlertService.success('Email queued for sending.');
          $rootScope.$broadcast('emailCreated', {
            email: email,
            claimId: claimId
          });
        })
        .catch(function() {
          AlertService.error('Failed to send email.');
        });
    });
  }

  function logEmailSent(template) {
    try {
      TrackingService.increment('Emails Sent');
      TrackingService.track('Email Sent', {
        'Claim ID': claimId,
        'Is Template': !!template
      });
    } catch {}
  }

  function selectDocument(): void {
    const requirements: ITemplateRequirementValue[] = [
      {
        type: TemplateRequirementTypes.Claim,
        field: '',
        recordId: new Guid(claimId)
      }
    ];
    DocumentService.select(requirements)
      .then(selection => {
        if (!selection.document) {
          return;
        }
        DocumentHttpService.createDocument(
          new Guid(vm.claim.folderId),
          selection.document.id,
          selection.values || []
        )
          .safeApply($scope, file => {
            if (!file) {
              return;
            }
            AlertService.success('Document created.');
            $state.go('app.claim.files', { claimId: claimId }, { reload: true });
            MessagingService.publish(new DocumentCreatedMessage(file));
          })
          .subscribe();
      })
      .catch(() => {});
  }
}
