import { SmsModalController } from './smsModal.controller';
import { RecordTypes, Guid } from '../../core/models';

export interface ISmsTarget {
  recordId: Guid;
  recordType: RecordTypes;
  to: string;
}

export interface ISmsService {
  open(target: ISmsTarget): ng.IPromise<void>;
}

export class SmsService implements ISmsService {
  static $inject = ['$uibModal'];

  constructor(private uibModal: ng.ui.bootstrap.IModalService) {}

  open(target: ISmsTarget): ng.IPromise<void> {
    return this.uibModal.open({
      openedClass: 'smsModalOpened',
      template: require('./smsModal.html'),
      controllerAs: '$ctrl',
      controller: SmsModalController,
      size: 'md',
      resolve: {
        target() {
          return target;
        }
      },
      backdrop: 'static',
      keyboard: false
    }).result;
  }
}
