import { DateTime } from 'luxon';

export const DEFAULT_DATE_FORMAT = 'dd/MM/yyyy';
export const DEFAULT_TIME_FORMAT = 'hh:mm a';

const empty = () => undefined;

type DateTimeFactory = () => DateTime | undefined;

export interface IClock {
  factory: DateTimeFactory;
  freeze(): void;
  reset(): void;
  local(value?: DateTime | Date): IDateMapFactory;
  utc(value?: DateTime | Date): IDateMapFactory;
}

export const Clock = {
  factory: empty,
  freeze(): void {
    const date = this.utc().now();
    this.factory = () => date;
  },
  reset(): void {
    this.factory = empty;
  },
  local(value?: DateTime): IDateMapFactory {
    if (value) {
      return dateMapFactory(() => value.toLocal());
    }
    const factoryValue = this.factory();
    return dateMapFactory(() =>
      factoryValue !== undefined ? factoryValue.toLocal() : DateTime.local()
    );
  },
  utc(value?: DateTime): IDateMapFactory {
    if (value) {
      return dateMapFactory(() => value.toUTC());
    }
    const factoryValue = this.factory();
    return dateMapFactory(() =>
      factoryValue !== undefined ? factoryValue.toUTC() : DateTime.utc()
    );
  }
} as IClock;

export interface IDateMapFactory {
  valueOf(): number;
  now(): DateTime;
  asString(): string;
  startOfToday(): DateTime;
  endOfToday(): DateTime;
  startOfYesterday(): DateTime;
  endOfYesterday(): DateTime;
  startOfTomorrow(): DateTime;
  endOfTomorrow(): DateTime;
  startOfThisWeek(): DateTime;
  endOfThisWeek(): DateTime;
  startOfLastWeek(): DateTime;
  endOfLastWeek(): DateTime;
  startOfNextWeek(): DateTime;
  endOfNextWeek(): DateTime;
  startOfThisMonth(): DateTime;
  endOfThisMonth(): DateTime;
  startOfNextMonth(): DateTime;
  endOfNextMonth(): DateTime;
  startOfLastMonth(): DateTime;
  endOfLastMonth(): DateTime;
}

export function dateMapFactory(factory: () => DateTime): IDateMapFactory {
  const getNow = () => factory();
  return {
    valueOf(): number {
      return getNow().valueOf();
    },
    now(): DateTime {
      return getNow();
    },
    asString(): string {
      return getNow().toISO();
    },
    startOfToday(): DateTime {
      return getNow().startOf('day');
    },
    endOfToday(): DateTime {
      return getNow().endOf('day');
    },
    startOfYesterday(): DateTime {
      return this.startOfToday().minus({ days: 1 });
    },
    endOfYesterday(): DateTime {
      return this.endOfToday().minus({ days: 1 });
    },
    startOfTomorrow(): DateTime {
      return this.startOfToday().plus({ days: 1 });
    },
    endOfTomorrow(): DateTime {
      return this.endOfToday().plus({ days: 1 });
    },
    startOfThisWeek(): DateTime {
      return getNow().startOf('week');
    },
    endOfThisWeek(): DateTime {
      return getNow().endOf('week');
    },
    startOfLastWeek(): DateTime {
      return this.startOfThisWeek().minus({ weeks: 1 });
    },
    endOfLastWeek(): DateTime {
      return this.endOfThisWeek().minus({ weeks: 1 });
    },
    startOfNextWeek(): DateTime {
      return this.startOfThisWeek().plus({ weeks: 1 });
    },
    endOfNextWeek(): DateTime {
      return this.endOfThisWeek().plus({ weeks: 1 });
    },
    startOfThisMonth(): DateTime {
      return getNow().startOf('month');
    },
    endOfThisMonth(): DateTime {
      return getNow().endOf('month');
    },
    startOfNextMonth(): DateTime {
      return this.startOfThisMonth().plus({ months: 1 });
    },
    endOfNextMonth(): DateTime {
      return getNow()
        .plus({ months: 1 })
        .endOf('month');
    },
    startOfLastMonth(): DateTime {
      return this.startOfThisMonth()
        .minus({ months: 1 })
        .startOf('month');
    },
    endOfLastMonth(): DateTime {
      return getNow()
        .minus({ months: 1 })
        .endOf('month');
    }
  };
}
