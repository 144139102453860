import { ITemplateItem } from './documentRequirement.interfaces';
import { EntityTemplate, Guid, TemplateRequirement } from '../../../core/models';
import { ICacheService } from '../../services/cache';
import * as ng from 'angular';

export class DocumentRequirementEditModalController {
  static $inject = ['requirement', 'Cache', '$uibModalInstance'];

  selectedTemplate: string;
  updated: TemplateRequirement;
  templates: ITemplateItem[] = [];
  isBusy: boolean = false;

  constructor(
    private requirement: TemplateRequirement,
    private cacheService: ICacheService,
    private modalInstanceService: ng.ui.bootstrap.IModalInstanceService
  ) {
    this.updated = TemplateRequirement.clone(this.requirement);
    if (this.requirement.templateId) {
      this.selectedTemplate = this.requirement.templateId.toString();
    }
  }

  get isValid(): boolean {
    return !!this.selectedTemplate;
  }

  $onInit() {
    this.loadTemplates();
  }

  save() {
    if (!this.selectedTemplate) {
      return;
    }
    this.isBusy = true;
    this.updated.templateId = new Guid(this.selectedTemplate);
    this.modalInstanceService.close(this.updated);
    this.isBusy = false;
  }

  cancel() {
    this.modalInstanceService.dismiss('cancel');
  }

  private loadTemplates() {
    this.cacheService
      .entityTemplates()
      .then((entityTemplates: EntityTemplate[]) => {
        this.templates = entityTemplates.map(t => {
          return { id: t.id, name: t.name };
        });
      })
      .catch(err => console.log(err));
  }
}
