export class PageHelper {
  private static hasQueryString(url) {
    return url.indexOf('?') > -1;
  }

  static query(url: string, take: number = 50, skip: number = 0): string {
    if (take < 1) {
      throw new Error(`Cannot take less than one '${take}'`);
    }
    if (skip < 0) {
      throw new Error(`Cannot skip less than zero '${skip}'`);
    }
    return PageHelper.hasQueryString(url)
      ? `${url}&take=${take}${skip ? '&skip=' + skip : ''}`
      : `${url}?take=${take}${skip ? '&skip=' + skip : ''}`;
  }
}
