import { StateProvider } from 'angular-ui-router';
import { EntityResource } from '../../data/resources/entity';
import { hideLoader } from '../utils/loader';
import { FieldDefinitionScopes } from 'app/core/models';

/* @ngInject */
export function StateConfig($stateProvider: StateProvider): void {
  const claimResolution = {
    claimsPrepService: /* @ngInject */ function($stateParams, ClaimManagerService) {
      return ClaimManagerService.activate($stateParams.claimId);
    },
    sysTemplatePrepService: /* @ngInject */ function(SystemTemplateField) {
      return SystemTemplateField.activate();
    }
  };

  $stateProvider

    // ------------------------
    // Auth
    // ------------------------

    .state('auth', {
      abstract: true,
      template: '<ui-view></ui-view>',
      data: {
        authenticate: false
      }
    })

    .state('auth.login', <any>{
      title: 'Login',
      url: '/login',
      template: '<cio-login></cio-login>',
      resolve: {
        ready: function() {
          hideLoader();
          return true;
        }
      }
    })

    .state('auth.logout', <any>{
      title: 'Logout',
      url: '/logout',
      template: '<cio-logout></cio-logout>'
    })

    // ------------------------
    // App
    // ------------------------

    .state('app', {
      abstract: true,
      template: '<cio-application></cio-application>',
      resolve: {
        ready: /* @ngInject */ function(LoadingService) {
          return LoadingService.load().then(hideLoader);
        }
      },
      data: {
        authenticate: true
      }
    })

    // ------------------------
    // Dashboard
    // ------------------------

    .state('app.dashboard', <any>{
      title: 'Dashboard',
      url: '/dashboard',
      controller: 'DashboardController as vm',
      template: require('../dashboard/dashboard.html'),
      resolve: {
        userConfig: /* @ngInject */ function(DashboardResource) {
          return DashboardResource.get().$promise;
        }
      }
    })

    // ------------------------
    // Search
    // ------------------------

    .state('app.search', <any>{
      title: 'Search',
      url: '/search',
      template: '<cio-search></cio-search>',
      reloadOnSearch: false
    })

    // ------------------------
    // Settings
    // ------------------------

    .state('app.settings', <any>{
      title: 'Settings',
      url: '/settings',
      template: require('../settings/settings.html')
    })

    // ------------------------
    // User Delegations
    // ------------------------

    .state('app.delegations', {
      url: '/settings/delegations',
      controller: 'DelegationListController',
      template: require('../settings/delegation/delegations.html')
    })
    .state('app.delegation', {
      abstract: true,
      url: '/settings/delegation',
      template: '<ui-view/>'
    })
    .state('app.delegation.new', {
      url: '/new',
      controller: 'DelegationNewController',
      template: require('../settings/delegation/delegation-new.html')
    })
    .state('app.delegation.edit', {
      url: '/{id}',
      template: '<cio-delegation-edit></cio-delegation-edit>'
    })

    // ------------------------
    // Saved Searches
    // ------------------------

    .state('app.saved-searches', {
      url: '/settings/saved-searches',
      controller: 'SavedSearchListController',
      template: require('../settings/search/searches.html')
    })
    .state('app.savedSearch', {
      abstract: true,
      url: '/settings/saved-search',
      template: '<ui-view/>'
    })
    .state('app.savedSearch.new', {
      url: '/new',
      controller: 'SavedSearchNewController',
      template: require('../settings/search/search-new.html'),
      resolve: {
        fields: /* @ngInject */ function(FieldStore) {
          return FieldStore.allFieldsPromise();
        }
      }
    })
    .state('app.savedSearch.edit', {
      url: '/{id}',
      controller: 'SavedSearchEditController',
      template: require('../settings/search/search-edit.html'),
      resolve: {
        fields: /* @ngInject */ function(FieldStore) {
          return FieldStore.allFieldsPromise();
        }
      }
    })

    // ------------------------
    // Roles
    // ------------------------

    .state('app.roles', <any>{
      title: 'Roles',
      url: '/settings/roles',
      controller: 'RoleListController',
      template: require('../settings/roles/roles.html')
    })
    .state('app.role', {
      abstract: true,
      url: '/settings/role',
      template: '<ui-view/>'
    })
    .state('app.role.new', <any>{
      title: 'New Role',
      url: '/new',
      controller: 'RoleNewController',
      template: require('../settings/roles/role-new.html')
    })
    .state('app.role.edit', <any>{
      title: 'Edit Role',
      url: '/{id}',
      controller: 'RoleEditController',
      template: require('../settings/roles/role-edit.html')
    })

    // ------------------------
    // Groups
    // ------------------------

    .state('app.groups', <any>{
      title: 'Groups',
      url: '/settings/groups',
      controller: 'GroupListController',
      template: require('../settings/groups/groups.html')
    })
    .state('app.group', {
      abstract: true,
      url: '/settings/group',
      template: '<ui-view/>'
    })
    .state('app.group.new', <any>{
      title: 'New Group',
      url: '/new',
      controller: 'GroupNewController',
      template: require('../settings/groups/group-new.html')
    })
    .state('app.group.edit', <any>{
      title: 'Edit Group',
      url: '/{id}',
      controller: 'GroupEditController',
      template: require('../settings/groups/group-edit.html')
    })

    // ------------------------
    // Users
    // ------------------------

    .state('app.users', <any>{
      title: 'Users',
      url: '/settings/users',
      controller: 'UserListController',
      template: require('../settings/users/users.html')
    })
    .state('app.user', {
      abstract: true,
      url: '/settings/user',
      template: '<ui-view/>'
    })
    .state('app.user.new', <any>{
      title: 'New User',
      url: '/new',
      controller: 'UserNewController',
      template: require('../settings/users/user-new.html')
    })
    .state('app.user.edit', <any>{
      title: 'Edit User',
      url: '/{id}',
      controller: 'UserEditController',
      template: require('../settings/users/user-edit.html')
    })

    // ------------------------
    // Entity Fields
    // ------------------------

    .state('app.entityFields', <any>{
      title: 'Entity Fields',
      url: '/settings/entity-fields',
      controller: 'FieldListController',
      template: require('../settings/fields/fields.html'),
      data: {
        newState: 'app.entityField.new',
        editState: 'app.entityField.edit',
        fieldScope: FieldDefinitionScopes.Entity
      }
    })
    .state('app.entityField', {
      abstract: true,
      url: '/settings/entity-field',
      template: '<ui-view/>'
    })
    .state('app.entityField.new', <any>{
      title: 'New Entity Field',
      url: '/new',
      controller: 'FieldNewController',
      template: require('../settings/fields/field-new.html'),
      data: {
        listState: 'app.entityFields',
        editState: 'app.entityField.edit',
        fieldScope: FieldDefinitionScopes.Entity
      }
    })
    .state('app.entityField.edit', <any>{
      title: 'Edit Field',
      url: '/{id}',
      controller: 'FieldEditController as vm',
      template: require('../settings/fields/field-edit.html'),
      data: {
        newState: 'app.entityFields.new',
        editState: 'app.entityFields.edit',
        listState: 'app.entityFields'
      }
    })

    // ------------------------
    // Claim Fields
    // ------------------------

    .state('app.claimFields', <any>{
      title: 'Claim Fields',
      url: '/settings/claim-fields',
      controller: 'FieldListController',
      template: require('../settings/fields/fields.html'),
      data: {
        newState: 'app.claimField.new',
        editState: 'app.claimField.edit',
        fieldScope: FieldDefinitionScopes.Claim
      }
    })
    .state('app.claimField', {
      abstract: true,
      url: '/settings/claim-field',
      template: '<ui-view/>'
    })
    .state('app.claimField.new', <any>{
      title: 'New Claim Field',
      url: '/new',
      controller: 'FieldNewController',
      template: require('../settings/fields/field-new.html'),
      data: {
        listState: 'app.claimFields',
        editState: 'app.claimField.edit',
        fieldScope: FieldDefinitionScopes.Claim
      }
    })
    .state('app.claimField.edit', <any>{
      title: 'Edit Field',
      url: '/{id}',
      controller: 'FieldEditController as vm',
      template: require('../settings/fields/field-edit.html'),
      data: {
        newState: 'app.claimField.new',
        editState: 'app.claimField.edit',
        listState: 'app.claimFields'
      }
    })

    // ------------------------
    // Claim templates
    // ------------------------

    .state('app.claimTypes', <any>{
      title: 'Claim templates',
      url: '/settings/claim-types',
      controller: 'ClaimTypesListController',
      template: require('../settings/claimTypes/claimTypes.html')
    })
    .state('app.claimType', {
      abstract: true,
      url: '/settings/claim-type',
      template: '<ui-view/>'
    })
    .state('app.claimType.new', <any>{
      title: 'New claim template',
      url: '/new',
      controller: 'ClaimTypeNewController',
      template: require('../settings/claimTypes/claimType-new.html')
    })
    .state('app.claimType.edit', <any>{
      title: 'Edit claim template',
      url: '/{id}',
      controller: 'ClaimTypeEditController as vm',
      template: require('../settings/claimTypes/claimType-edit.html'),
      resolve: {
        claimsTypePrepService: /* @ngInject */ function(
          $stateParams,
          ClaimTemplateManagerService
        ) {
          return ClaimTemplateManagerService.activate($stateParams.id);
        }
      }
    })

    // ------------------------
    // Entity templates
    // ------------------------

    .state('app.entityTypes', <any>{
      title: 'Entity templates',
      url: '/settings/entity-types',
      controller: 'EntityTypesListController',
      template: require('../settings/entityTypes/entityTypes.html')
    })
    .state('app.entityType', {
      abstract: true,
      url: '/settings/entity-type',
      template: '<ui-view/>'
    })
    .state('app.entityType.new', <any>{
      title: 'New entity template',
      url: '/new',
      controller: 'EntityTypeNewController',
      template: require('../settings/entityTypes/entityType-new.html')
    })
    .state('app.entityType.edit', <any>{
      title: 'Edit entity template',
      url: '/{id}',
      controller: 'EntityTypeEditController as vm',
      template: require('../settings/entityTypes/entityType-edit.html'),
      resolve: {
        entityTypePrepService: /* @ngInject */ function(
          $stateParams,
          EntityTemplateManagerService
        ) {
          return EntityTemplateManagerService.activate($stateParams.id);
        }
      }
    })

    // ------------------------
    // Email Templates
    // ------------------------

    .state('app.emailTemplates', <any>{
      title: 'Email Templates',
      url: '/settings/email-templates',
      controller: 'EmailTemplateListController',
      template: require('../settings/emails/emails.html')
    })
    .state('app.emailTemplate', {
      abstract: true,
      url: '/settings/email-template',
      template: '<ui-view/>'
    })
    .state('app.emailTemplate.new', <any>{
      title: 'New Email Template',
      url: '/new',
      controller: 'EmailTemplateNewController',
      template: require('../settings/emails/email-new.html')
    })
    .state('app.emailTemplate.edit', <any>{
      title: 'Edit Email Template',
      url: '/{id}',
      controller: 'EmailTemplateEditController',
      template: require('../settings/emails/email-edit.html')
    })

    // ------------------------
    // Cost Categories
    // ------------------------

    .state('app.costCategories', <any>{
      title: 'Cost Categories',
      url: '/settings/cost-categories',
      template: require('../settings/cost/categories.html')
    })
    .state('app.costCategory', {
      abstract: true,
      url: '/settings/cost-category',
      template: '<ui-view/>'
    })
    .state('app.costCategory.new', <any>{
      title: 'New cost category',
      url: '/new',
      template: require('../settings/cost/category-new.html')
    })
    .state('app.costCategory.edit', <any>{
      title: 'Edit Cost Category',
      url: '/{id}',
      template: require('../settings/cost/category-edit.html')
    })

    // ------------------------
    // Sum Insured Categories
    // ------------------------

    .state('app.suminsuredCategories', <any>{
      title: 'Sum Insured Categories',
      url: '/settings/sum-insured-categories',
      template: require('../settings/suminsured/categories.html')
    })
    .state('app.suminsuredCategory', {
      abstract: true,
      url: '/settings/sum-insured-category',
      template: '<ui-view/>'
    })
    .state('app.suminsuredCategory.new', <any>{
      title: 'New Sum Insured category',
      url: '/new',
      controller: 'SumInsuredCategoryNewController as vm',
      template: require('../settings/suminsured/category-new.html')
    })
    .state('app.suminsuredCategory.edit', <any>{
      title: 'Edit Sum Insured Category',
      url: '/{id}',
      template: require('../settings/suminsured/category-edit.html')
    })

    // ------------------------
    // Tax Codes
    // ------------------------

    .state('app.taxcodes', <any>{
      title: 'Tax Codes',
      url: '/settings/tax-codes',
      controller: 'TaxCodeListController',
      template: require('../settings/taxcodes/taxcodes.html')
    })
    .state('app.taxcode', {
      abstract: true,
      url: '/settings/tax-code',
      template: '<ui-view/>'
    })
    .state('app.taxcode.new', <any>{
      title: 'New tax code',
      url: '/new',
      controller: 'TaxCodeNewController',
      template: require('../settings/taxcodes/taxcode-new.html')
    })
    .state('app.taxcode.edit', <any>{
      title: 'Edit Tax Code',
      url: '/{id}',
      controller: 'TaxCodeEditController',
      template: require('../settings/taxcodes/taxcode-edit.html')
    })

    // ------------------------
    // Claims
    // ------------------------

    .state('app.claim', {
      abstract: true,
      url: '/claim',
      template: '<ui-view/>'
    })
    .state('app.claim.new', <any>{
      title: 'New Claim',
      url: '/new/{templateId}',
      controller: 'NewClaimController as vm',
      template: require('../claims/claim-new.html')
    })
    .state('app.claim.overview', <any>{
      title: 'Claim Overview',
      url: '/{claimId}',
      controller: 'EditClaimHomeController as vm',
      template: require('../claims/claim-edit.html'),
      partialName: 'claimOverview',
      resolve: claimResolution
    })
    .state('app.claim.edit', <any>{
      title: 'Claim Details',
      url: '/{claimId}/details',
      controller: 'EditClaimHomeController as vm',
      template: require('../claims/claim-edit.html'),
      partialName: 'claimForm',
      resolve: claimResolution
    })
    .state('app.claim.activity', <any>{
      title: 'Claim Activities',
      url: '/{claimId}/activity',
      controller: 'EditClaimHomeController as vm',
      template: require('../claims/claim-edit.html'),
      partialName: 'activities',
      resolve: claimResolution
    })
    .state('app.claim.files', <any>{
      title: 'Claim Files',
      url: '/{claimId}/files?preview',
      controller: 'EditClaimHomeController as vm',
      template: require('../claims/claim-edit.html'),
      params: {
        preview: {
          value: '',
          squash: true
        }
      },
      reloadOnSearch: false,
      resolve: claimResolution
    })
    .state('app.claim.tasks', <any>{
      title: 'Claim Tasks',
      url: '/{claimId}/tasks?view&complete&due&status',
      params: {
        view: {
          value: '',
          squash: true
        },
        complete: {
          value: undefined,
          squash: true
        },
        due: {
          value: '',
          squash: true
        },
        status: {
          value: '',
          squash: true
        }
      },
      reloadOnSearch: false,
      controller: 'EditClaimHomeController as vm',
      template: require('../claims/claim-edit.html'),
      partialName: 'tasks',
      resolve: claimResolution
    })
    .state('app.claim.timesheet', <any>{
      title: 'Claim Timesheet',
      url: '/{claimId}/timesheet',
      controller: 'EditClaimHomeController as vm',
      template: require('../claims/claim-edit.html'),
      partialName: 'timesheet',
      resolve: claimResolution
    })

    .state('app.claim.invoices', {
      abstract: true,
      url: '/{claimId}/invoices',
      template: '<ui-view/>'
    })
    .state('app.claim.invoices.list', <any>{
      title: 'Claim Invoices',
      url: '/list',
      controller: 'EditClaimHomeController as vm',
      template: require('../claims/claim-edit.html'),
      partialName: 'invoices',
      resolve: claimResolution
    })
    .state('app.claim.invoices.new', <any>{
      title: 'Claim Invoices - New Invoice',
      url: '/new',
      controller: 'EditClaimHomeController as vm',
      template: require('../claims/claim-edit.html'),
      partialName: 'invoiceNew',
      resolve: claimResolution
    })
    .state('app.claim.invoices.edit', <any>{
      title: 'Claim Invoices - Edit Invoice',
      url: '/{invoiceId}',
      controller: 'EditClaimHomeController as vm',
      template: require('../claims/claim-edit.html'),
      partialName: 'invoiceNew',
      resolve: claimResolution
    })

    .state('app.claim.payments', <any>{
      abstract: true,
      url: '/{claimId}/payments',
      template: '<ui-view/>'
    })
    .state('app.claim.payments.list', <any>{
      title: 'Claim Payments',
      url: '/list',
      controller: 'EditClaimHomeController as vm',
      template: require('../claims/claim-edit.html'),
      partialName: 'payments',
      resolve: claimResolution
    })
    .state('app.claim.payments.new', <any>{
      title: 'Claim Payments - New Payment',
      url: '/new',
      controller: 'EditClaimHomeController as vm',
      template: require('../claims/claim-edit.html'),
      partialName: 'paymentNew',
      resolve: claimResolution
    })
    .state('app.claim.payments.edit', <any>{
      title: 'Claim Payments - Edit Payment',
      url: '/{paymentId}',
      controller: 'EditClaimHomeController as vm',
      template: require('../claims/claim-edit.html'),
      partialName: 'paymentNew',
      resolve: claimResolution
    })

    .state('app.claim.sumsinsured', {
      abstract: true,
      url: '/{claimId}/sums-insured',
      template: '<ui-view/>'
    })
    .state('app.claim.sumsinsured.list', <any>{
      title: 'Claim Sums Insured',
      url: '/list',
      controller: 'EditClaimHomeController as vm',
      template: require('../claims/claim-edit.html'),
      partialName: 'sumsInsured',
      resolve: claimResolution
    })
    .state('app.claim.sumsinsured.new', <any>{
      title: 'Claim Sums Insured - New Sum',
      url: '/new',
      controller: 'EditClaimHomeController as vm',
      template: require('../claims/claim-edit.html'),
      partialName: 'sumsInsuredNew',
      resolve: claimResolution
    })
    .state('app.claim.sumsinsured.edit', <any>{
      title: 'Claim Sums Insured - Edit Sum',
      url: '/{sumInsuredId}',
      controller: 'EditClaimHomeController as vm',
      template: require('../claims/claim-edit.html'),
      partialName: 'sumsInsuredNew',
      resolve: claimResolution
    })

    // ------------------------
    // Entities
    // ------------------------

    .state('app.entity', {
      abstract: true,
      url: '/entity',
      template: '<ui-view/>'
    })
    .state('app.entity.new', <any>{
      title: 'New Entity',
      url: '/new/{templateId}',
      controller: 'EntityNewController',
      template: require('../entities/entity-new.html')
    })
    .state('app.entity.edit', <any>{
      title: 'Edit Entity',
      url: '/{entityId}',
      controller: 'EntityEditController',
      template: require('../entities/entity-edit.html'),
      resolve: {
        /* tslint:disable:no-shadowed-variable */
        entity: /* @ngInject */ function($stateParams, EntityResource: EntityResource) {
          return EntityResource.get({ id: $stateParams.entityId }).$promise;
        }
        /* tslint:enable:no-shadowed-variable */
      }
    })
    .state('app.entity.contacts', <any>{
      title: 'Entity Contacts',
      url: '/{entityId}/contacts',
      controller: 'entity-contacts',
      template: require('../entities/entity-contacts.html')
    })
    .state('app.entity-types', <any>{
      title: 'Entities',
      url: '/entities',
      controller: 'EntityTypesController as vm',
      template: require('../entities/entity-types.html')
    })
    .state('app.entities', <any>{
      title: 'Entities',
      url: '/entities/{templateId}',
      controller: 'EntityListController',
      template: require('../entities/entities.html')
    });
}
