import { Guid } from '../../../core/models';

export class ClaimLinkController {
  static $inject = [];

  private _id: Guid;

  get id(): Guid {
    return this._id;
  }

  set id(value: Guid) {
    this._id = new Guid(value);
  }
}
