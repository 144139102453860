import { StateProvider } from 'angular-ui-router';

/* @ngInject */
export function WorkflowRoutes($stateProvider: StateProvider) {
  $stateProvider
    .state('app.workflows', <any>{
      title: 'Workflows',
      url: '/settings/workflows',
      template: '<cio-workflow-list></cio-workflow-list>'
    })
    .state('app.workflow', {
      abstract: true,
      url: '/settings/workflow',
      template: '<ui-view/>'
    })
    .state('app.workflow.new', <any>{
      title: 'New Workflow',
      url: '/new',
      template: '<cio-workflow-new></cio-workflow-new>'
    })
    .state('app.workflow.edit', <any>{
      title: 'Edit Workflow',
      url: '/{id}',
      template: '<cio-workflow-edit></cio-workflow-edit>'
    });
}
