import { TimesheetResource } from '../../../data/resources/timesheet';
import { CostCategoryResource } from '../../../data/resources/costCategory';
import {
  ISecurityService,
  IAlertService,
  ITrackingService
} from '../../../core/services';
import { IClaimsWindow } from '../../../core/utils/claimsWindow';
import { Guid, Timesheet } from '../../../core/models';

/* @ngInject */
export function TimesheetModalController(
  $window: IClaimsWindow,
  $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
  claimId: Guid,
  // tslint:disable-next-line:no-shadowed-variable
  CostCategoryResource: CostCategoryResource,
  // tslint:disable-next-line:no-shadowed-variable
  TimesheetResource: TimesheetResource,
  SecurityService: ISecurityService,
  AlertService: IAlertService,
  TrackingService: ITrackingService
): void {
  const vm = this;
  vm.categories = [];
  vm.model = { duration: 0 };
  vm.cancel = cancel;
  vm.save = save;
  vm.saving = false;

  init();

  function init() {
    CostCategoryResource.query().$promise.then(function(data: any) {
      vm.categories = data.items;
    });
  }

  function cancel() {
    $uibModalInstance.dismiss('cancel');
  }

  function save() {
    if (vm.saving) {
      return;
    }
    vm.saving = true;

    vm.model.claimId = claimId.toString();
    vm.model.user = SecurityService.getCurrentUser().id;
    vm.model.date = new Date().toISOString();
    vm.model.billable = true;

    TimesheetResource.save(vm.model)
      .$promise.then(function(saved: Timesheet) {
        logTimesheetCreated(saved);
        AlertService.success('Timesheet successfully saved.');
        $uibModalInstance.close(saved);
        vm.saving = false;
      })
      .catch(function(error) {
        AlertService.error('Could not save timesheet, please try again.');
        vm.saving = false;
      });
  }

  function logTimesheetCreated(saved) {
    try {
      TrackingService.increment('Timesheets Created');
      TrackingService.track('Timesheet Created', {
        'Claim ID': claimId.toString(),
        Billable: saved.billable
      });
    } catch {}
  }
}
