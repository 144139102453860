import { DocumentRequirementEditModalController } from './documentRequirementEditModal.controller';
import { DocumentRequirementNewModalController } from './documentRequirementNewModal.controller';
import { TemplateRequirement } from '../../../core/models';
import * as ng from 'angular';

export interface IDocumentRequirementService {
  /**
   * Creates a new document requirement.
   * @param {DocumentRequirement[]} requirements
   */
  create(): ng.IPromise<TemplateRequirement>;

  /**
   * Edits an existing document requirement.
   * @param {DocumentRequirement[]} requirements
   */
  edit(requirement: TemplateRequirement): ng.IPromise<TemplateRequirement>;
}

export class DocumentRequirementService implements IDocumentRequirementService {
  static $inject = ['$uibModal'];

  constructor(private modalService: ng.ui.bootstrap.IModalService) {}

  create(): ng.IPromise<TemplateRequirement> {
    const modalInstance = this.modalService.open({
      template: require('./documentRequirementNewModal.html'),
      controller: DocumentRequirementNewModalController,
      controllerAs: '$ctrl'
    });
    return modalInstance.result;
  }

  edit(requirement: TemplateRequirement): ng.IPromise<TemplateRequirement> {
    const modalInstance = this.modalService.open({
      template: require('./documentRequirementEditModal.html'),
      controller: DocumentRequirementEditModalController,
      controllerAs: '$ctrl',
      resolve: {
        requirement() {
          return requirement;
        }
      }
    });
    return modalInstance.result;
  }
}
