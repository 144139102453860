import { TaskListController } from './taskList.controller';
import { ComponentBase } from '../../core/utils/componentBase';

export class TaskListComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      record: '<', // { type, id }
      showAssociations: '<',
      showCreate: '<'
    };
    this.controller = TaskListController;
    this.template = require('./taskList.html');
  }
}
