import { Observable } from 'rxjs/Observable';
import { Subject } from 'rxjs/Subject';
import 'rxjs/add/operator/filter';

interface Message {
  channel: string;
  data: any;
}

export interface IMessagingService {
  publish<T>(message: T): void;
  of<T>(messageType: { new (...args: any[]): T }): Observable<T>;
}

export class MessagingService implements IMessagingService {
  private message$: Subject<Message>;

  constructor() {
    this.message$ = new Subject<Message>();
  }

  publish<T>(message: T): void {
    const channel = (<any>message.constructor).name;
    this.message$.next({ channel: channel, data: message });
  }

  of<T>(messageType: { new (...args: any[]): T }): Observable<T> {
    const channel = (<any>messageType).name;
    return this.message$.filter(m => m.channel === channel).map(m => m.data);
  }
}

const MessagingSingleton = new MessagingService();
export const MessagingFactory = () => MessagingSingleton;
