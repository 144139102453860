import { TaskModalController } from './taskModal.controller';
import { TaskRepeatModalController } from './taskRepeatModal.controller';
import { RecordAssociation, Task, Tag } from '../../core/models';
import * as angular from 'angular';

export interface ITaskService {
  createTask(
    ...associations: RecordAssociation[]
  ): angular.ui.bootstrap.IModalServiceInstance;

  openTask(task: Task, complete?: boolean): angular.ui.bootstrap.IModalServiceInstance;
}

export class TaskService implements ITaskService {
  static $inject = ['$uibModal'];

  constructor(private modalService: angular.ui.bootstrap.IModalService) {}

  createTask(
    ...associations: RecordAssociation[]
  ): angular.ui.bootstrap.IModalServiceInstance {
    return this.showTaskModal(undefined, associations, undefined);
  }

  openTask(task: Task, complete?: boolean): angular.ui.bootstrap.IModalServiceInstance {
    return this.showTaskModal(task, [], complete);
  }

  confirmRepeat(previousTask: Task): angular.ui.bootstrap.IModalServiceInstance {
    return this.showRepeatModal(previousTask);
  }

  private showRepeatModal(
    previousTask: Task
  ): angular.ui.bootstrap.IModalServiceInstance {
    return this.modalService.open({
      template: require('./taskRepeatModal.html'),
      controllerAs: '$ctrl',
      controller: TaskRepeatModalController,
      resolve: {
        previousTask: () => previousTask
      }
    });
  }

  private showTaskModal(
    task?: Task,
    associations?: RecordAssociation[],
    complete?: boolean
  ): angular.ui.bootstrap.IModalServiceInstance {
    return this.modalService.open({
      template: require('./taskModal.html'),
      controllerAs: '$ctrl',
      controller: TaskModalController,
      resolve: {
        associations: () => associations || [],
        task: () => task,
        complete: () => complete
      }
    });
  }
}
