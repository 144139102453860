export class LoadingButtonController {
  isLoading = false;
  disabled = false;
  type = 'primary';
  text = 'OK';

  click() {
    if (this.disabled || this.isLoading) {
      return;
    }

    this.isLoading = true;

    try {
      const result = (this as any).onClick();
      if (result && typeof result.then === 'function') {
        result.then(() => (this.isLoading = false)).catch(() => (this.isLoading = false));
      } else {
        this.isLoading = false;
      }
    } catch (err) {
      console.error(err);
      this.isLoading = false;
    }
  }
}
