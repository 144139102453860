export function FileChangeDirective() {
  return {
    restrict: 'A',
    link: function(scope, element: JQLite, attrs) {
      const onFileChange = scope.$eval(attrs.fileChange);
      element.on('change', onFileChange);
      scope.$on('$destroy', () => element.off(onFileChange));
    }
  };
}
