// import * as ng from 'angular';

export function FileAddButtonDirective() {
  return {
    restrict: 'E',
    template: [
      '<span class="cio-fileAddButton">',
      '  <button class="btn btn-sm btn-primary cio-fileAddButton-btn">{{ title || "Add File" }}</button>',
      '  <input type="file" multiple style="display: none">',
      '</span>'
    ].join(''),
    replace: true,
    scope: {
      onFiles: '&',
      title: '@'
    },
    link: (scope, element: JQLite, attrs) => {
      const fileInput = element.find('input');
      fileInput.on('change', onChange);

      const btn = element.find('button');
      btn.on('click', onClick);

      scope.$on('$destroy', () => {
        btn.off('click', onClick);
        fileInput.off('change', onChange);
      });

      function onClick() {
        fileInput[0].click();
      }

      function onChange(event: Event) {
        if (!scope.onFiles) {
          return;
        }

        const files = [];
        for (let i = 0; i < (event.target as any).files.length; i++) {
          files.push((event.target as any).files[i]);
        }

        scope.onFiles({ files });
      }
    }
  };
}
