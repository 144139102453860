import { ClaimLinkController } from './claimLink.controller';
import { ComponentBase } from '../../../core/utils/componentBase';

export class ClaimLinkComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      id: '<',
      text: '<'
    };
    this.controller = ClaimLinkController;
    this.template = require('./claimLink.html');
  }
}
