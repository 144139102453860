import '../../../core/services/alert';
import '../../../data/resources/costCategory';
import '../../../data/resources/tax-code';

/* @ngInject */
export default function CostCategoryListController(
  CostCategoryResource,
  TaxCodeResource,
  AlertService
): void {
  const vm = this;
  vm.models = [];
  vm.taxCodes = [];

  vm.getTaxCode = getTaxCode;

  init();

  function init() {
    TaxCodeResource.query()
      .$promise.then(function(result) {
        vm.taxCodes = result.items;
      })
      .catch(function(error) {
        AlertService.error('Could not load tax codes, please try again.');
      });

    CostCategoryResource.query()
      .$promise.then(function(result) {
        vm.models = result.items;
      })
      .catch(function(error) {
        AlertService.error('Could not load categories, please try again.');
      });
  }

  function getTaxCode(id) {
    const taxCode = vm.taxCodes.find(x => x.id === id);
    if (!taxCode) {
      return 'N/A';
    }

    return taxCode.code + ' (' + taxCode.percentage + '%)';
  }
}
