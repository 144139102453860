export const FIELD_DEFAULT_COLOR: string = '#777';

export enum FieldDefinitionScopes {
  All = 'All',
  Claim = 'Claim',
  Entity = 'Entity'
}

export enum FieldDefinitionTypes {
  Text = 'Text',
  Email = 'Email',
  Number = 'Number',
  Date = 'Date',
  DateTime = 'DateTime',
  Dropdown = 'Dropdown',
  Radio = 'Radio',
  Check = 'Check',
  Country = 'Country',
  Address = 'Address',
  Entity = 'Entity',
  EntityContact = 'EntityContact',
  MultipleEntity = 'MultipleEntity',
  StatusSet = 'StatusSet',
  User = 'User',
  ClaimType = 'ClaimType'
}

export interface FieldDefinition {
  id: string;
  name: string;
  label: string;
  scope: FieldDefinitionScopes;
  type: FieldDefinitionTypes;
  settings: any;
  description: string;
  isReadOnly: boolean;
  isRequired: boolean;
  isBuiltin: boolean;
  canRename: boolean;
  canDelete: boolean;
  created: Date;
  createdBy: string;
  lastModified: Date;
  lastModifiedBy: string;
}

export interface EntityFieldDefinition extends FieldDefinition {
  settings: EntityFieldDefinitionSettings;
}

export interface EntityFieldDefinitionSettings {
  entityTypeId: string;
}

export interface EntityContactFieldDefinition extends FieldDefinition {
  settings: EntityContactFieldDefinitionSettings;
}

export interface EntityContactFieldDefinitionSettings {
  entityFieldId: string;
}

export interface DropdownFieldDefinition extends FieldDefinition {
  settings: DropdownFieldDefinitionSettings;
  emptyText: string;
}

export interface DropdownFieldDefinitionSettings {
  options: string;
}

export interface DropdownFieldDefinitionOption {
  value: string;
  label: string;
}

export interface StatusSetFieldDefinition extends FieldDefinition {
  settings: StatusSetFieldDefinitionSettings;
}

export interface StatusSetFieldDefinitionSettings {
  status: StatusSetFieldDefinitionOption[];
}

export interface StatusSetFieldDefinitionOption {
  color: string;
  name: string;
  description: string;
}
