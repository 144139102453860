import { IFileUploadService } from './fileUpload.service';
import { IStorageFolderHttpService, IStorageFileHttpService } from '../../core/http';
import { UploadCompleteMessage } from '../../core/messages';
import {
  Guid,
  StorageFile,
  StorageFolder,
  SettingKeys,
  KnownSettingValues
} from '../../core/models';
import { IAlertService, IMessagingService, ISecurityService } from '../../core/services';
import { IFileService } from '../files/file.service';
import { IFileRenameService } from '../files/fileRename.service';
import { Subscription } from 'rxjs/Subscription';
import { DocumentCreatedMessage } from '../../core/messages';
import { FolderNavigator } from './folder.navigator';
import { ISettingHttpService } from 'app/core/http';

export class FolderViewerController {
  static $inject = [
    '$rootScope',
    '$scope',
    '$state',
    'FileUploadService',
    'StorageFolderHttpService',
    'StorageFileHttpService',
    'FileService',
    'FileRenameService',
    'AlertService',
    'MessagingService',
    'SecurityService',
    'SettingHttpService'
  ];

  // bindings
  folderId: Guid;

  folder: StorageFolder;
  folders: StorageFolder[] = [];
  files: StorageFile[] = [];
  starredFiles: StorageFile[] = [];
  isGridView: boolean = false;
  activePreview?: StorageFile;
  locationChangeSubscription: () => {};
  uploadCompleteSubscription: Subscription;
  documentCreatedSubscription: Subscription;

  constructor(
    private rootScope: any,
    private scope: any,
    private state: any,
    private fileUploadService: IFileUploadService,
    private storageFolderHttpService: IStorageFolderHttpService,
    private storageFileHttpService: IStorageFileHttpService,
    private fileService: IFileService,
    private fileRenameService: IFileRenameService,
    private alertService: IAlertService,
    private messagingService: IMessagingService,
    private securityService: ISecurityService,
    private settingService: ISettingHttpService
  ) {}

  $onInit(): void {
    this.getViewPreference();
    this.previewFileFromState();
    this.subscribeToLocationChanges();
    this.subscribeToMessages();
  }

  $onChanges(changes: any): void {
    if (changes.folderId) {
      this.loadFolder();
    }
  }

  $onDestroy(): void {
    this.unsubscribeFromLocationChanges();
    this.unsubscribeFromMessages();
  }

  getViewPreference() {
    this.settingService
      .getEffectiveSetting(
        this.securityService.getCurrentUserId(),
        SettingKeys.Files.LastView
      )
      .safeApply(this.scope, setting => {
        if (!setting) {
          this.isGridView = false;
        }
        this.isGridView = setting.value === KnownSettingValues.Files.GridView;
      })
      .subscribe();
  }

  previewFileFromState(): void {
    // There will be an active window, if we have switched
    // the state from the navigator, in which case, we don't
    // need to do anything here
    if (!!this.activePreview) {
      return;
    }

    if (!this.state.params.preview) {
      return;
    }

    const fileId = new Guid(this.state.params.preview);
    if (this.activePreview && this.activePreview.id.equals(fileId)) {
      return;
    }

    this.storageFileHttpService
      .getFile(fileId)
      .subscribe(file => this.previewFile(file, false));
  }

  previewFileFromEvent(file: StorageFile, showRevisions: boolean = false): void {
    if (this.isAlreadyShowing(file)) {
      return;
    }
    this.setStateForPreview(file.id.toString());
  }

  loadFolder(): void {
    this.storageFolderHttpService
      .getFolder(this.folderId)
      .safeApply(this.scope, folder => {
        this.folder = folder;
        this.refresh();
      })
      .subscribe();
  }

  refresh(): void {
    // TODO: handle paging
    this.storageFolderHttpService
      .getFiles(this.folder.id, 500)
      .safeApply(this.scope, page => {
        // We do not replace the array, its referenced in the navigator
        this.files.length = 0;
        this.files.push(...page.items);
      })
      .subscribe();

    this.storageFolderHttpService
      .getStarredFiles(this.folder.id, 500)
      .safeApply(this.scope, page => {
        // We do not replace the array, its referenced in the navigator
        this.starredFiles.length = 0;
        this.starredFiles.push(...page.items);
      })
      .subscribe();
  }

  addFiles(files: File[]): void {
    files.map(f => this.fileUploadService.uploadToFolder(this.folder, f).subscribe());
  }

  showList(): void {
    this.isGridView = false;
    this.settingService
      .modifyUserSetting(
        this.securityService.getCurrentUserId(),
        SettingKeys.Files.LastView,
        KnownSettingValues.Files.ListView
      )
      .subscribe();
  }

  showGrid(): void {
    this.isGridView = true;
    this.settingService
      .modifyUserSetting(
        this.securityService.getCurrentUserId(),
        SettingKeys.Files.LastView,
        KnownSettingValues.Files.GridView
      )
      .subscribe();
  }

  renameFile(file: StorageFile): void {
    this.fileRenameService.show(file);
  }

  downloadFile(file: StorageFile): void {
    this.fileService.downloadFile(file);
  }

  deleteFile(file: StorageFile): void {
    this.storageFileHttpService
      .deleteFile(file.id)
      .safeApply(this.scope, _ => this.afterDeleteFile(file))
      .subscribe();
  }

  addStar(file: StorageFile): void {
    file.isStarred = true;
    this.storageFileHttpService
      .addStar(file.id)
      .safeApply(this.scope, _ => this.refresh())
      .subscribe();
  }

  removeStar(file: StorageFile): void {
    file.isStarred = false;
    this.storageFileHttpService
      .removeStar(file.id)
      .safeApply(this.scope, _ => this.refresh())
      .subscribe();
  }

  refreshFiles() {}

  private isAlreadyShowing(file: StorageFile) {
    return this.activePreview && this.activePreview.id.equals(file.id);
  }

  private afterDeleteFile(file: StorageFile) {
    this.alertService.success('File deleted');
    this.refresh();
  }

  private previewFile(file: StorageFile, showRevisions: boolean = false): void {
    // TODO: Check the file actually belongs in the folder (or its parent)

    if (this.isAlreadyShowing(file)) {
      return;
    }

    const combinedFiles = [...this.starredFiles, ...this.files];

    const navigator = new FolderNavigator(combinedFiles, file, current => {
      this.setStateForPreview(current.id.toString());
    });

    this.activePreview = file;
    this.fileService
      .showFileRevision(file, file.headRevision, showRevisions, navigator)
      .result.then(() => this.clearPreview())
      .catch(() => this.clearPreview());
  }

  private setStateForPreview(fileId: string): void {
    this.state.go('.', { preview: fileId }, { notify: false });
  }

  private clearPreview(): void {
    this.activePreview = undefined;
    this.setStateForPreview('');
  }

  private subscribeToMessages(): void {
    this.uploadCompleteSubscription = this.messagingService
      .of(UploadCompleteMessage)
      .subscribe(message => this.onUploadComplete(message));
    this.documentCreatedSubscription = this.messagingService
      .of(DocumentCreatedMessage)
      .subscribe(message => this.onDocumentCreated(message));
  }

  private unsubscribeFromMessages(): void {
    if (this.uploadCompleteSubscription) {
      this.uploadCompleteSubscription.unsubscribe();
    }
    if (this.documentCreatedSubscription) {
      this.documentCreatedSubscription.unsubscribe();
    }
  }

  private subscribeToLocationChanges(): void {
    this.locationChangeSubscription = this.rootScope.$on('$locationChangeSuccess', () => {
      this.previewFileFromState();
    });
  }

  private unsubscribeFromLocationChanges(): void {
    if (this.locationChangeSubscription) {
      this.locationChangeSubscription();
    }
  }

  private onUploadComplete(message: UploadCompleteMessage) {
    if (!this.folder.id.equals(message.folder.id)) {
      return;
    }
    this.refresh();
  }

  private onDocumentCreated(message: DocumentCreatedMessage) {
    if (!this.folder.id.equals(message.file.folder)) {
      return;
    }
    this.refresh();
  }
}
