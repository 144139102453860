import { DelegationEditController } from './delegation-edit-controller';

export default class DelegationEditComponent implements ng.IComponentOptions {
  public controller: any;
  public template: string;
  public bindings: any;

  constructor() {
    this.bindings = {};
    this.controller = DelegationEditController;
    this.template = require('./delegation-edit.html');
  }
}
