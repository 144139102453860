import { PageHelper } from '../../../core/http/pageHelper';
import { Notification } from '../../../core/models';

const DEFAULT_TAKE = 10;

export class NotificationsWidgetController {
  static $inject = ['$http'];

  notifications: Notification[] = [];

  private config: any = { take: DEFAULT_TAKE };

  constructor(private http: ng.IHttpService) {}

  $onInit() {
    this.config.take = this.config.take || DEFAULT_TAKE;
    this.load();
  }

  load() {
    const url = PageHelper.query(
      '/api/v1/notifications?status=unread',
      this.config.take,
      0
    );
    this.http.get<Notification[]>(url).then(response => {
      this.notifications = response.data || [];
    });
  }
}
