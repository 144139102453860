import { AddressFieldController } from './addressField.controller';

export class AddressFieldComponent implements ng.IComponentOptions {
  public controller: any;
  public template: string;
  public bindings: any;

  constructor() {
    this.bindings = {
      definition: '<',
      field: '=',
      templateDefinition: '<',
      templateFields: '<',
      formDisabled: '<',
      sections: '<'
    };
    this.controller = AddressFieldController;
    this.template = require('./addressField.html');
  }
}
