import { Guid } from '../../core/models';
import { DateTimeHelper, QueryParams } from '../../core/utils';
import { DateTime } from 'luxon';

export class WorkflowSearch {
  skip: number = 0;
  take: number = 100;
  sort: string = 'created';
  sortDescending: boolean = true;

  toParams(): string {
    const params = new QueryParams();
    if ((this.skip || 0) > 0) {
      params.set('skip', Math.max(0, this.skip || 0).toString());
    }
    params.set('take', Math.max(1, this.take || 1).toString());
    params.set('sort', this.sort || 'desc');
    params.set('sortDescending', !!this.sortDescending ? 'true' : 'false');
    return params.toString();
  }
}

export enum WorkflowFilterTypes {
  Equals = 'Equals',
  NotEquals = 'NotEquals',
  Contains = 'Contains',
  NotContains = 'NotContains',
  StartsWith = 'StartsWith',
  MatchRegex = 'MatchRegex',
  IsBefore = 'IsBefore',
  IsAfter = 'IsAfter',
  IsBetween = 'IsBetween',
  GreaterThan = 'GreaterThan',
  GreaterThanOrEqualTo = 'GreaterThanOrEqualTo',
  LessThan = 'LessThan',
  LessThanOrEqualTo = 'LessThanOrEqualTo',
  IsEmpty = 'IsEmpty',
  IsNotEmpty = 'IsNotEmpty',
  Added = 'Added',
  Modified = 'Modified',
  Removed = 'Removed'
}

export interface IWorkflowFilter {
  fieldId: string;
  fieldName: string;
  filterType: WorkflowFilterTypes;
  value: any;
}

export interface IWorkflowActionType {
  id: string;
  name: string;
  description: string;
}

export enum WorkflowActions {
  SendEmail = 'send-email',
  CreateTask = 'create-task',
  UpdateTask = 'update-task',
  AddComment = 'add-comment',
  CreateDocument = 'create-document'
}

export const WORKFLOW_ACTIONS: IWorkflowActionType[] = [
  { id: 'send-email', name: 'Send Email', description: 'Sends an email' },
  { id: 'create-task', name: 'Create Task', description: 'Creates a new task' },
  { id: 'update-task', name: 'Update Task', description: 'Updates a task' },
  { id: 'add-comment', name: 'Add Comment', description: 'Creates a new comment.' },
  {
    id: 'update-fields',
    name: 'Update claim fields',
    description: 'Updates fields of a claim'
  },
  {
    id: 'create-document',
    name: 'Create document from template',
    description: 'Creates a new file from a document template'
  }
];

export interface IWorkflowTriggerType {
  id: string;
  name: string;
  description: string;
}

export enum WorkflowTriggers {
  ClaimCreated = 'claim-created',
  ClaimModified = 'claim-modified',
  ClaimStatusChanged = 'claim-status-changed'
}

export const WORKFLOW_TRIGGERS: IWorkflowTriggerType[] = [
  {
    id: 'claim-created',
    name: 'Claim Created',
    description: 'When a new claim is created in the system.'
  },
  {
    id: 'claim-modified',
    name: 'Claim Updated',
    description: 'When a claim is modified in the system.'
  },
  {
    id: 'claim-status-changed',
    name: 'Claim Status Changed',
    description: 'When the status of claim changes.'
  }
];

export enum WorkflowFilterGroupMatches {
  /**
   * All filters must match.
   */
  All = 'All',
  /**
   * One or more filters must match.
   */
  Any = 'Any',
  /**
   * None of the filters must match (a negated any).
   */
  None = 'None'
}

/**
 * A group of filters that must obey the specified match condition.
 */
export class WorkflowFilterGroup {
  match: WorkflowFilterGroupMatches = WorkflowFilterGroupMatches.All;
  filters: IWorkflowFilter[] = [];

  static fromJson(json: any): WorkflowFilterGroup {
    const group = Object.assign(new WorkflowFilterGroup(), json) as WorkflowFilterGroup;
    group.filters = group.filters || [];
    return group;
  }

  static create(filters: number = 0): WorkflowFilterGroup {
    const group = new WorkflowFilterGroup();
    for (let i = 0; i < filters; i++) {
      group.filters.push({} as IWorkflowFilter);
    }
    return group;
  }
}

export class Workflow {
  id: Guid;
  name: string;
  trigger: WorkflowTriggers;
  filterGroups: WorkflowFilterGroup[] = [];
  action: WorkflowActions;
  data: any = {};
  created: DateTime;
  createdBy: Guid;
  lastModified: DateTime;
  lastModifiedBy: Guid;

  static fromJson(json: any): Workflow {
    const workflow = Object.assign(new Workflow(), json) as Workflow;
    workflow.id = new Guid(json.id);
    workflow.data = workflow.data || {};
    workflow.filterGroups = (workflow.filterGroups || []).map(g =>
      WorkflowFilterGroup.fromJson(g)
    );
    workflow.created = DateTimeHelper.parseUtcDateTime(json.created);
    workflow.createdBy = new Guid(json.createdBy);
    workflow.lastModified = DateTimeHelper.parseUtcDateTime(json.lastModified);
    workflow.lastModifiedBy = new Guid(json.lastModifiedBy);
    return workflow;
  }

  static create(): Workflow {
    const workflow = new Workflow();
    workflow.trigger = WorkflowTriggers.ClaimCreated;
    workflow.action = WorkflowActions.CreateTask;
    workflow.data = {};
    workflow.filterGroups = [];
    return workflow;
  }
}
