import { IAlertService, ISecurityService } from '../../../core/services';
import { PermissionResource } from '../../../data/resources/permission';
import { IRoleStore } from '../../../data/stores/role';
import { StateService } from 'angular-ui-router';
import groupBy from 'lodash/groupBy';
import forEach from 'lodash/forEach';
import { Role, Permission } from 'app/core/models';

/* @ngInject */
export default function RoleEditController(
  $scope: any,
  $q: ng.IQService,
  $state: StateService,
  $stateParams: any,
  RoleStore: IRoleStore,
  // tslint:disable-next-line:no-shadowed-variable
  PermissionResource: PermissionResource,
  SecurityService: ISecurityService,
  AlertService: IAlertService
) {
  $scope.role = {};
  $scope.roleForm = {};

  // TODO: check user access permissions

  $scope.load = function() {
    $scope.roleForm.active = true;

    const rolePromise = RoleStore.get($stateParams.id),
      permissionsPromise = PermissionResource.query().$promise;

    $q.all([rolePromise, permissionsPromise])
      .then(function(values) {
        const role = values[0];
        $scope.role = role;
        $scope.roleForm.active = false;

        // first level grouped by category
        const permissions = {};
        const categories = groupBy(values[1], 'category');
        forEach(categories, function(cat, prop) {
          // second level grouped by type
          permissions[prop] = groupBy(cat, 'type');
        });

        $scope.permissions = permissions;
      })
      .catch(function(error) {
        AlertService.error('Could not load role, please try again.');
        $state.go('app.roles');
      });
  };

  $scope.load();

  $scope.cannotChange = function(role: Role, permission: Permission) {
    return !role.canChangePermissions || permission.name === 'Administrator';
  };

  $scope.updateRole = function(role) {
    $scope.roleForm.active = true;

    role
      .update()
      .then(function(updated) {
        $scope.group = updated;
        AlertService.success('Role ' + updated.name + ' has been updated.');
        $scope.roleForm.active = false;
        $scope.roleForm.$setPristine();
        SecurityService.loadPermissions(SecurityService.getCurrentUserId());
      })
      .catch(function(error) {
        AlertService.error('Could not update role, please try again.');
        $scope.roleForm.active = false;
      });
  };

  $scope.deleteRole = function(role, form) {
    RoleStore.delete(role)
      .then(function(deleted) {
        AlertService.success('Role ' + role.name + ' has been deleted.');
        form.$setPristine();
        SecurityService.loadPermissions(SecurityService.getCurrentUserId());
        $state.go('app.roles');
      })
      .catch(function(error) {
        AlertService.error('Could not delete role, please try again.');
      });
  };
}
