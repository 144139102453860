/* @ngInject */
export default function StatusDetailsController($uibModalInstance, status): void {
  const vm = this;
  vm.model = status;
  vm.ok = ok;
  vm.cancel = cancel;

  function ok() {
    $uibModalInstance.close(vm.model);
  }

  function cancel() {
    $uibModalInstance.dismiss('cancel');
  }
}
