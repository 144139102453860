import '../../../core/services/alert';
import '../../../data/resources/sum-insured-category';

/* @ngInject */
export default function SumInsuredCategoryListController(
  SumInsuredCategoryResource,
  AlertService
): void {
  const vm = this;
  vm.models = [];

  init();

  function init() {
    SumInsuredCategoryResource.query()
      .$promise.then(function(result) {
        vm.models = result.items;
      })
      .catch(function(error) {
        AlertService.error('Could not load categories, please try again.');
      });
  }
}
