import '../../../core/services/alert';
import '../../../data/resources/entityTemplate';

/* @ngInject */
export default function EntityTypesListController(
  $scope,
  $state,
  EntityTemplateResource,
  AlertService
) {
  $scope.models = [];

  EntityTemplateResource.query()
    .$promise.then(function(result) {
      $scope.models = result.items;
    })
    .catch(function(error) {
      AlertService.error('Could not load types, please try again.');
    });

  $scope.new = function() {
    $state.go('app.entityType.new');
  };

  $scope.view = function(model) {
    $state.go('app.entityType.edit', { id: model.id });
  };
}
