import { TagListController } from './tagList.controller';
import { ComponentBase } from '../../../core/utils/componentBase';

export class TagListComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      ids: '<'
    };
    this.controller = TagListController;
    this.template = require('./tagList.html');
  }
}
