import { TypeHelper } from '../utils';

/**
 * Filter for displaying files sizes in a human readable format.
 *
 *  <!-- input: 1024; output: 1 MB -->
 *  <div>{{ file.size | bytes }}</div>
 *
 */
export function bytesFilter() {
  return function(bytes: string | number, precision: number) {
    const bytesNumber: number = TypeHelper.isString(bytes)
      ? parseFloat(<string>bytes)
      : <number>bytes;

    if (bytesNumber === 0) {
      return '0 bytes';
    }

    if (isNaN(bytesNumber) || !isFinite(bytesNumber)) {
      return '-';
    }

    if (typeof precision === 'undefined') {
      precision = 1;
    }

    const units = ['B', 'KB', 'MB', 'GB', 'TB', 'PB'];
    const num = Math.floor(Math.log(bytesNumber) / Math.log(1024));

    return (
      (bytesNumber / Math.pow(1024, Math.floor(num))).toFixed(precision) +
      ' ' +
      units[num]
    );
  };
}
