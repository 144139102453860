import { IWorkflowFilter, Tag } from '../../../../core/models';
import { ITagHttpService } from '../../../../core/http';

export class WorkflowFilterTagsController {
  static $inject = ['$scope', 'TagHttpService'];

  filter: IWorkflowFilter;
  tags: Tag[] = [];

  constructor(private scope: any, private tagHttpService: ITagHttpService) {}

  $onInit() {
    this.tagHttpService
      .getAllTags()
      .safeApply(this.scope, tags => {
        this.tags = tags;
      })
      .subscribe();
  }
}
