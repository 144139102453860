/* @ngInject */
export function FileChangeDirective(): any {
  return {
    scope: {
      cioFileChange: '&'
    },
    link: link,
    restrict: 'A'
  };

  function link(scope, element, attrs) {
    element.on('change', change);
    function change(event) {
      const file = event.target.files[0];
      if (scope.cioFileChange) {
        scope.$apply(() => {
          scope.cioFileChange({ file: file ? file : undefined });
        });
      }
    }
  }
}
