import { TagController } from './tag.controller';
import { ComponentBase } from '../../../core/utils/componentBase';

export class TagComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      id: '<'
    };
    this.controller = TagController;
    this.template = require('./tag.html');
  }
}
