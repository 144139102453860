import { HttpService } from './http';
import { Guid, Notification, Page } from '../models';
import { logError } from '../utils';
import { Observable } from 'rxjs/Observable';
import { QueryParams } from '../utils';

export interface INotificationHttpService {
  getNotification(NotificationId: Guid): Observable<Notification>;
  findNotifications(search: NotificationSearch): Observable<Page<Notification>>;
  markAsUnread(notification: Notification): Observable<Notification>;
  markAsRead(notification: Notification): Observable<Notification>;
}

export enum NotificationSearchStatues {
  All = 'all',
  Read = 'read',
  Unread = 'unread'
}

export class NotificationSearch {
  take: number = 50;
  skip: number = 0;
  status: NotificationSearchStatues = NotificationSearchStatues.Unread;

  toParams(): string {
    const params = new QueryParams();
    if (this.skip > 0 && !isNaN(this.skip)) {
      params.set('skip', Math.max(0, this.skip || 0).toString());
    }
    params.set('take', Math.max(1, isNaN(this.take) ? 1 : this.take).toString());
    params.set('status', this.status.toString());
    return params.toString();
  }
}

export class NotificationHttpService implements INotificationHttpService {
  static $inject = ['HttpService'];

  private readonly _url: string = '/api/v1/notifications';

  constructor(private httpService: HttpService) {}

  getNotification(notificationId: Guid): Observable<Notification> {
    const url = `${this._url}/${notificationId.toString()}`;
    return this.httpService
      .get(url)
      .map(res => Notification.fromJson(res.json()))
      .catch(logError);
  }

  findNotifications(search: NotificationSearch): Observable<Page<Notification>> {
    const url = `${this._url}?skip=${search.skip}&take=${search.take}`;
    return this.httpService
      .get(url)
      .map(res => Page.fromJson(res.json(), item => Notification.fromJson(item)))
      .catch(logError);
  }

  markAsUnread(notification: Notification): Observable<Notification> {
    notification.isRead = false;
    const url = `${this._url}/${notification.id.toString()}/unread`;
    return this.httpService
      .put(url, notification)
      .map(res => Notification.fromJson(res.json()))
      .catch(logError);
  }

  markAsRead(notification: Notification): Observable<Notification> {
    notification.isRead = true;
    const url = `${this._url}/${notification.id.toString()}/read`;
    return this.httpService
      .put(url, notification)
      .map(res => Notification.fromJson(res.json()))
      .catch(logError);
  }
}
