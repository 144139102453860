import { WidgetController } from './widget.controller';

export default class WidgetComponent implements ng.IComponentOptions {
  public controller: any;
  public template: string;
  public bindings: any;

  constructor() {
    this.bindings = {
      widget: '<',
      editable: '<',
      onRemove: '&'
    };
    this.controller = WidgetController;
    this.template = require('./widget.html');
  }
}
