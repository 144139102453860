import { CurrencyEditController } from './currencyEdit.controller';
import { ComponentBase } from '../../../core/utils/componentBase';

export class CurrencyEditComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      currency: '<',
      onChange: '&'
    };
    this.controller = CurrencyEditController;
    this.template = require('./currencyEdit.html');
  }
}
