import '../../../data/stores/userDelegation';

/* @ngInject */
export default function DelegationListController(
  $scope,
  $state,
  UserDelegationStore
): void {
  $scope.delegations = [];

  init();

  function init() {
    UserDelegationStore.query().then(function(data) {
      $scope.delegations = data.items;
    });
  }

  $scope.viewDelegation = function(delegation) {
    $state.go('app.delegation.edit', { id: delegation.id });
  };

  $scope.newDelegation = function() {
    $state.go('app.delegation.new');
  };
}
