import { ReportFieldCondition } from '../../core/models';
import * as ng from 'angular';

/* tslint:disable-next-line:no-empty-interface */
export interface ReportFieldConditionResource
  extends ng.resource.IResourceClass<ng.resource.IResource<ReportFieldCondition>> {}

/* @ngInject */
export default function ReportFieldResource(
  $resource: ng.resource.IResourceService
): ReportFieldConditionResource {
  return <ReportFieldConditionResource>(
    $resource<ng.resource.IResource<ReportFieldCondition>>(
      '/api/v1/reports/conditions',
      null,
      {
        query: { method: 'POST' }
      }
    )
  );
}
