import {
  DropdownFieldDefinition,
  DropdownFieldDefinitionOption,
  IWorkflowFilter
} from '../../../../core/models';
import { FieldDefinitionHelper } from '../../../utils/fieldDefinitionHelper';

export class WorkflowFilterDropdownController {
  static $inject = [];

  filter: IWorkflowFilter;
  options: DropdownFieldDefinitionOption[] = [];
  private _field: DropdownFieldDefinition;

  get field(): DropdownFieldDefinition {
    return this._field;
  }

  set field(value: DropdownFieldDefinition) {
    this._field = value;
    if (!this._field || !this._field.settings || !this._field.settings.options) {
      this.options = [];
    } else {
      this.options = FieldDefinitionHelper.parseDropdownOptions(value);
    }
  }
}
