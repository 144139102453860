import { FieldDefinitionTypes } from 'app/core/models';

export interface IFieldTypeSummary {
  type: FieldDefinitionTypes;
  name: string;
}

export const fieldTypeList: IFieldTypeSummary[] = [
  { type: FieldDefinitionTypes.Text, name: 'Text' },
  { type: FieldDefinitionTypes.Email, name: 'Email' },
  { type: FieldDefinitionTypes.Date, name: 'Date' },
  { type: FieldDefinitionTypes.Dropdown, name: 'Dropdown' },
  { type: FieldDefinitionTypes.Country, name: 'Country' },
  { type: FieldDefinitionTypes.Address, name: 'Address' },
  { type: FieldDefinitionTypes.Entity, name: 'Entity' },
  { type: FieldDefinitionTypes.StatusSet, name: 'StatusSet' },
  { type: FieldDefinitionTypes.User, name: 'User' },
  { type: FieldDefinitionTypes.EntityContact, name: 'Entity Contact' },
  { type: FieldDefinitionTypes.DateTime, name: 'Date/Time' }
];
