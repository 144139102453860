import { StateService } from 'angular-ui-router';
import { IAlertService } from '../../../core/services/alert';
import { ILogService } from '../../../core/services/log';
import '../../../data/resources/sum-insured-category';

/* @ngInject */
export default function SumInsuredCategoryNewController(
  SumInsuredCategoryResource,
  Cache,
  AlertService: IAlertService,
  LogService: ILogService,
  $state: StateService
): void {
  const vm = this;
  vm.model = {};
  vm.newForm = {};
  vm.newForm.active = false;
  vm.create = create;

  init();

  function init() {
    Cache.claimTemplates().then(function(result) {
      vm.claimTypes = result;
    });
  }

  function create(model) {
    vm.newForm.active = true;
    SumInsuredCategoryResource.save(model)
      .$promise.then(createSuccess)
      .catch(createdFailure);
  }

  function createSuccess(updated) {
    AlertService.success('New category ' + updated.name + ' has been created.');

    vm.newForm.active = false;
    vm.newForm.$setPristine();

    $state.go('app.suminsuredCategory.edit', { id: updated.id });
  }

  function createdFailure(error) {
    AlertService.error('Could not create category, please try again.');
    LogService.error('Could not create category. Error: ' + JSON.stringify(error));

    vm.newForm.active = false;
  }
}
