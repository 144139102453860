import { FileService } from './file.service';
import { FileModalService } from './fileModal.service';
import { FilePreviewerComponent } from './filePreviewer.component';
import { FileRenameComponent } from './fileRename.component';
import { FileRenameService } from './fileRename.service';
import { FileRevisionListComponent } from './fileRevisionList.component';
import { PdfViewerComponent } from './pdfViewer.component';
import { CoreModule } from '../../core/module';
import { DataModule } from '../../data/module';
import { UIModule } from '../../ui/module';
import * as angular from 'angular';

export const FilesModule = angular
  .module('app.instance.files', [DataModule.name, CoreModule.name, UIModule.name])

  // Components
  .component('filePreviewerComponent', new FilePreviewerComponent())
  .component('fileRevisionListComponent', new FileRevisionListComponent())
  .component('fileRenameComponent', new FileRenameComponent())
  .component('pdfViewerComponent', new PdfViewerComponent())

  // Services
  .service('FileModalService', FileModalService)
  .service('FileRenameService', FileRenameService)
  .service('FileService', FileService);
