import { ConfirmationModalConfig } from './confirmation-modal-config';
import ConfirmationModalController from './confirmation-modal-controller';

export interface IConfirmationModalService {
  /**
   * Confirms a question with the user.
   * @param  {ConfirmationModalConfig} config The confirmation configuration.
   * @param  {(result)=>void} resultFunction Called after the user has confirmed.
   */
  confirm(config: ConfirmationModalConfig, resultFunction: (result) => void): void;
}

/* @ngInject */
export default function ConfirmationModalService(
  $uibModal: ng.ui.bootstrap.IModalService
): IConfirmationModalService {
  return {
    confirm
  };

  function confirm(config: ConfirmationModalConfig, resultFunction: (result) => void) {
    openModal(config, resultFunction);
  }

  function openModal(config: ConfirmationModalConfig, resultFunction) {
    const modalInstance = $uibModal.open({
      template: require('./confirmation-modal.html'),
      controller: ConfirmationModalController,
      resolve: {
        config: function() {
          return config;
        }
      }
    });

    modalInstance.result.then(function(ok) {
      if (ok) {
        resultFunction();
      }
    });
  }
}
