import { Guid } from './guid';
import { DateTimeHelper } from '../utils';
import { DateTime } from 'luxon';

export class Notification {
  id: Guid;
  userId: Guid;
  subject: string;
  body: string;
  type: string;
  isRead: boolean;
  isDeleted: boolean;
  created: DateTime;
  createdBy: Guid;

  static fromJson(json: any): Notification {
    const notification = Object.assign(new Notification(), json) as Notification;
    notification.id = new Guid(json.id);
    notification.userId = new Guid(json.userId);
    notification.created = DateTimeHelper.parseUtcDateTime(json.created);
    notification.createdBy = new Guid(json.createdBy);
    return notification;
  }
}
