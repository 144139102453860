import { Field } from '../../../core/models';
import { DateTimeHelper } from '../../../core/utils';
import { DateTime } from 'luxon';

export class DateFieldController {
  static $inject = [];

  field: Field;
  dateValue: DateTime;
  isValid: boolean;

  $onInit() {
    this.dateValue = this.field.value
      ? DateTimeHelper.parseUtcDate(this.field.value)
      : undefined;
  }

  $onChanges(changes) {
    // TODO: Does this update?
  }

  dateUpdated(date: DateTime) {
    this.field.value = date ? date.toJSDate() : undefined;
  }
}
