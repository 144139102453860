import { LoadingButtonController } from './loadingButton.controller';
import { ComponentBase } from '../../../core/utils/componentBase';

export class LoadingButtonComponent extends ComponentBase {
  constructor() {
    super();
    (this.bindings = {
      onClick: '<',
      type: '@',
      text: '@',
      disabled: '<'
    }),
      (this.controller = LoadingButtonController);
    this.template = require('./loadingButton.html');
  }
}
