import EntityContactModalController from './entity-contact-modal-controller';
import { IAlertService } from '../../../core/services';
import { IEntityContactStore } from '../../../data/stores/entityContact';
import ng from 'angular';

export class EntityContactFieldController {
  static $inject = ['$uibModal', 'EntityContactStore', 'AlertService'];

  public definition: any;
  public field: any;
  public templateDefinition: any;
  public templateFields: any[];
  public formDisabled: boolean;
  public sections: any[];

  constructor(
    private modalService: ng.ui.bootstrap.IModalService,
    private entityContactStore: IEntityContactStore,
    private alertService: IAlertService
  ) {}

  getField(entityFieldId: string): any {
    return this.templateFields.find(x => x.id === entityFieldId);
  }

  getFieldDefinition(entityFieldId: string): any {
    const fields = [];
    (this.sections || []).forEach(s => (s.fields || []).forEach(f => fields.push(f)));
    return fields.find(x => x.id === entityFieldId);
  }

  openEntityContactsModal(entityFieldId: string): void {
    const field = this.getField(entityFieldId);
    if (!field || !field.value) {
      // If we have not set the entity field, then its not possible to
      // select an entity for it - we should inform the user, then do
      // nothing

      const definition = this.getFieldDefinition(entityFieldId);
      if (definition) {
        this.alertService.warn(`'${definition.label}' not set`);
      } else {
        this.alertService.warn('Related entity not set');
      }

      return;
    }

    this.entityContactStore
      .query({}, { EntityId: field.value.id, Sort: 'Name' })
      .then(data => {
        const modalInstance = this.modalService.open({
          template: require('./entity-contact-modal.html'),
          controller: EntityContactModalController,
          controllerAs: '$ctrl',
          resolve: {
            contacts: function() {
              return data.items;
            }
          }
        });

        modalInstance.result
          .then(selectedItem => {
            this.field.value = {
              id: selectedItem.id,
              name: selectedItem.name
            };
          })
          .catch(() => {});
      });
  }
}
