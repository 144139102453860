/* @ngInject */
export default function WidgetsService(): any {
  const widgets = [
    /**
     * Tasks
     */
    {
      title: 'Outstanding Tasks',
      description: 'Loads all of your tasks that are already due',
      type: 'OutstandingTasksWidget'
    },

    /**
     * Recent Claims
     */
    {
      title: 'Recent Claims',
      description: 'Retrieves your recent claims',
      type: 'RecentClaimsWidget'
    },

    /**
     * Claims Stats
     */
    {
      title: 'Field Stats',
      description: 'Displays claims field statistics',
      type: 'ClaimsStatsWidget'
    },

    /**
     * Claims Histogram
     */
    {
      title: 'Lodgement Rate',
      description: 'Displays the rate of lodgement',
      type: 'ClaimsHistogramWidget'
    }
  ];

  return {
    widgets: widgets
  };
}
