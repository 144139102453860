import { DateTime } from 'luxon';
import { Guid } from '../../../../core/models';

interface IWorkflowActionCreateTask {
  description: string;
  dueDate: DateTime;
  tagIds: Guid[];
}

export class WorkflowActionCreateTaskController {
  static $inject = [];

  private _data: IWorkflowActionCreateTask;

  get data(): IWorkflowActionCreateTask {
    return this._data;
  }

  set data(item: IWorkflowActionCreateTask) {
    this._data = item;
    this._data.tagIds = this._data.tagIds || [];
  }
}
