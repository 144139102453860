import { Address } from './address';
import { Guid } from './guid';
import { DateTimeHelper } from '../utils';
import { DateTime } from 'luxon';

export class Contact {
  id: Guid;
  entityId: Guid;
  entityTypeId: Guid;
  name: string;
  email: string;
  address: Address;
  created: DateTime;
  createdBy: Guid;
  lastModified: DateTime;
  lastModifiedBy: Guid;

  static fromJson(json: any): Contact {
    const contact = Object.assign(new Contact(), json) as Contact;
    contact.id = new Guid(json.id);
    contact.entityId = json.entityId && new Guid(json.entityId);
    contact.entityTypeId = json.entityTypeId && new Guid(json.entityTypeId);
    contact.address = contact.address || ({} as Address);
    contact.created = DateTimeHelper.parseUtcDateTime(json.created);
    contact.createdBy = new Guid(json.createdBy);
    contact.lastModified = DateTimeHelper.parseUtcDateTime(json.lastModified);
    contact.lastModifiedBy = json.lastModifiedBy && new Guid(json.lastModifiedBy);
    return contact;
  }
}
