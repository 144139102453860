import * as angular from 'angular';

/* @ngInject */
export function RouteConfig($urlRouterProvider): void {
  // apply consistent trailing slashes and casing

  $urlRouterProvider.rule(customRule);
  $urlRouterProvider.otherwise('/');

  /* @ngInject */
  function customRule($injector, $location) {
    const path = $location.path();
    const search = $location.search();
    let newPath, params;

    if (path === '/') {
      newPath = '/';
    }

    if (path[path.length - 1] === '/') {
      newPath = path.substring(0, path.length - 1);
    } else {
      newPath = path;
    }

    if (Object.keys(search).length > 0) {
      params = [];
      angular.forEach(search, function(v, k) {
        params.push(k + '=' + v);
      });

      newPath += '?' + params.join('&');
    }

    newPath = newPath.toLowerCase();

    if ($location.url() === newPath) {
      return;
    }

    $location.replace().path(newPath);
  }
}
