import { ICacheService } from './cache';
import { IFieldStore } from '../../data/stores/field';
import ng from 'angular';
import includes from 'lodash/includes';
import reject from 'lodash/reject';
import forEach from 'lodash/forEach';
import uniqBy from 'lodash/uniqBy';
import { DateTime } from 'luxon';
import {
  FieldDefinition,
  Guid,
  User,
  FieldDefinitionTypes,
  FieldDefinitionScopes,
  Tag
} from '../../core/models';
import { IDateService, ICountryService } from '../../core/services';

export const RESERVED_CLAIMS_FIELDS = [
  'type',
  'reference',
  'title',
  'tags',
  'assignees',
  'status',
  'currency',
  'isClosed',
  'closed',
  'closed_by'
];

export interface IFilterBuilder {
  allClaimFilters(): ng.IPromise<IFilter[]>;
  build(filters: IFilter[]): IFilter;
  hideFilter(theFilter: IFilter);
  getFilterDisplay(theFilter: IFilter);
}

export interface IFilter {
  name?: string;
  description?: string;
  prop?: string;
  field?: string;
  fieldId?: string;
  visible?: boolean;
  claimFilter?: boolean;
  claimSearch?: string;
  type?: string;
  originalType?: string;
  settings?: any;
  options?: any;
}

/* @ngInject */
export function FilterBuilder(
  $q: ng.IQService,
  FieldStore: IFieldStore,
  Cache: ICacheService,
  CountryService: ICountryService,
  DateService: IDateService
): any {
  const builder: any = {};
  let first = true;
  let filter: IFilter = { claimSearch: '' } as IFilter;
  let users: User[] = [];
  Cache.users().then(u => (users = u));

  const service = {
    allClaimFilters: allClaimFilters,
    build: build,
    getFilterDisplay: getFilterDisplay,
    hideFilter: hideFilter
  };

  function allClaimFilters(): ng.IPromise<IFilter> {
    const deferred = $q.defer();

    let allFields: any[] = [];
    const allStatuses: any[] = [];
    let allTemplates: any[] = [];

    Cache.fields()
      .then(function(data) {
        allFields = data;
      })
      .then(function() {
        Cache.claimTemplates().then(function(templates) {
          allTemplates = templates;
          templates.forEach(tpl => {
            const statusField = tpl.systemFields.find(x => x.name === 'status');
            if (!statusField) {
              return null;
            }
            const field = allFields.find(x => x.id === statusField.fieldId);
            if (field) {
              field.settings.status.forEach(sts => allStatuses.push(sts));
            }
          });
        });
      })
      .then(function() {
        FieldStore.allFieldsPromise().then(data => {
          const claimFilters: IFilter[] = [];

          reject<FieldDefinition>(data, function(f) {
            return (
              f.scope === FieldDefinitionScopes.Entity ||
              (f.type === FieldDefinitionTypes.StatusSet && f.name !== 'status') ||
              (!f.isBuiltin && includes(RESERVED_CLAIMS_FIELDS, f.name))
            );
          }).forEach(function(f) {
            const options = [];
            if (f.type === FieldDefinitionTypes.Dropdown && f.settings.options) {
              const fieldOptions = f.settings.options.split('$$');
              fieldOptions.forEach(opt => {
                const value = opt.split('|');
                options.push({ text: value[1] });
              });
            }

            // handles claim status -> we need to show all available
            // status combined
            if (f.type === FieldDefinitionTypes.StatusSet && f.name === 'status') {
              const uniqStatus = uniqBy(allStatuses, 'name');
              f.settings = { status: uniqStatus };
            }

            // handles claim template -> show all types
            if (f.isBuiltin && f.name === 'type') {
              f.type = FieldDefinitionTypes.Dropdown;
              allTemplates.forEach(tpl => {
                options.push({ text: tpl.name });
              });
            }

            if (f.type === FieldDefinitionTypes.Address) {
              f.settings = f.settings || {};
              f.settings.countries = CountryService.getCountries();
            }

            claimFilters.push({
              name: f.label,
              description: f.description,
              field: f.name,
              fieldId: f.id,
              visible: false,
              claimFilter: true,
              type: f.type === 'Check' ? 'Bool' : f.type,
              originalType: f.type,
              settings: f.settings,
              options: options
            } as IFilter);

            deferred.resolve(claimFilters);
          });
        });
      });

    return deferred.promise as ng.IPromise<IFilter>;
  }

  function build(filters: IFilter[]): IFilter {
    filter = { claimSearch: '' } as IFilter;
    first = true;

    filters
      .filter(x => !!x.visible)
      .forEach(function(f) {
        if (f.claimFilter) {
          builder.buildClaimFilter(f);
        } else {
          f.prop = f.field || f.name;
          builder['build' + f.type](f);
        }
      });

    return filter;
  }

  function hideFilter(theFilter) {
    theFilter.value = null;
    theFilter.displayValue = null;
    theFilter.Equal = null;
    theFilter.Notequal = null;
    theFilter.GreaterThan = null;
    theFilter.LessThan = null;
    theFilter.address = {};

    if (theFilter.options) {
      theFilter.options.forEach(function(opt) {
        opt.checked = false;
      });
    }

    if (theFilter.selectedEntities) {
      theFilter.selectedEntities.forEach(function(opt) {
        opt.checked = false;
      });
    }

    if (theFilter.selectedUsers) {
      theFilter.selectedUsers.length = 0;
    }

    if (theFilter.selectedTags) {
      theFilter.selectedTags.length = 0;
    }

    if (theFilter.unassigned) {
      theFilter.unassigned = false;
    }

    if (theFilter.settings && theFilter.settings.status) {
      theFilter.settings.status.forEach(function(opt) {
        opt.checked = false;
      });
    }

    theFilter.visible = false;
  }

  function getFilterDisplay(theFilter) {
    let values;
    let value = null;
    switch (theFilter.type) {
      case 'Dropdown':
        {
          theFilter.options = theFilter.options || [];
          values = theFilter.options.filter(f => !!f.checked).map(f => f.text);
          if (!values.length || values.length === theFilter.options.length) {
            value = 'All';
            break;
          }

          value = values.join(', ');
        }
        break;

      case 'StatusSet':
        {
          values = theFilter.settings.status.filter(f => !!f.checked).map(f => f.name);
          if (!values.length || values.length === theFilter.options.length) {
            value = 'All';
            break;
          }

          value = values.join(', ');
        }
        break;

      case 'Entity':
        {
          theFilter.selectedEntities = theFilter.selectedEntities || [];
          values = theFilter.selectedEntities.filter(f => !!f.checked).map(f => f.name);
          if (!values.length || values.length === theFilter.selectedEntities) {
            value = 'All';
            break;
          }

          value = values.join(', ');
        }
        break;

      case 'Tags':
        {
          theFilter.selectedTags = theFilter.selectedTags || [];
          values = theFilter.selectedTags.filter(f => !!f.selected).map(f => f.tag.name);
          if (!values.length || values.length === theFilter.selectedTags) {
            value = 'All';
            break;
          }

          value = values.join(', ');
        }
        break;

      case 'User':
        {
          theFilter.selectedUsers = theFilter.selectedUsers || [];
          values = (theFilter.selectedUsers as Guid[]).map(x => {
            const user = users.find(u => x.equals(u.id));
            return user ? user.name : 'Unknown';
          });
          if (!values.length && theFilter.unassigned) {
            value = 'Unassigned';
            break;
          }
          if (values.length === users.length && !theFilter.unassigned) {
            value = 'All assigned';
            break;
          }
          if (
            !values.length ||
            (values.length === users.length && theFilter.unassigned)
          ) {
            value = 'All';
            break;
          }
          value = values.join(', ');
        }
        break;

      case 'Number':
        {
          const typeTranslator = {
            Equal: '=',
            Notequal: '!=',
            GreaterThan: '>',
            LessThan: '<'
          };

          const operator = typeTranslator[theFilter.filterType];
          const opValue = theFilter[theFilter.filterType];
          if (operator && opValue) {
            value = operator + ' ' + opValue;
          }
        }
        break;

      // CHECK: Date filter works correctly
      case 'Date':
        {
          value = '';
          if (theFilter.dateFrom) {
            value += DateService.formatUtcDate(DateTime.fromISO(theFilter.dateFrom));
          }

          if (theFilter.dateTo) {
            value += theFilter.dateFrom ? ' - ' : '';
            value += DateService.formatUtcDate(DateTime.fromISO(theFilter.dateTo));
          }
        }
        break;

      case 'DateTime':
        {
          value = '';
          if (theFilter.dateFrom) {
            value += DateService.formatLocalDateTime(
              DateTime.fromISO(theFilter.dateFrom)
            );
          }

          if (theFilter.dateTo) {
            value += theFilter.dateFrom ? ' - ' : '';
            value += DateService.formatLocalDateTime(DateTime.fromISO(theFilter.dateTo));
          }
        }
        break;

      case 'Bool':
        {
          value =
            theFilter.value === 'true'
              ? 'Yes'
              : theFilter.value === 'false'
              ? 'No'
              : 'Not Set';
        }
        break;

      case 'Address':
        {
          if (theFilter.address) {
            value = AppendAddressComponent(value, theFilter.address.street);
            value = AppendAddressComponent(value, theFilter.address.city);
            value = AppendAddressComponent(value, theFilter.address.state);
            value = AppendAddressComponent(value, theFilter.address.postcode);
            value = AppendAddressComponent(value, theFilter.address.country);
          }
        }
        break;

      default: {
        value = theFilter.value;
        break;
      }
    }

    theFilter.displayValue = value;
    return value;
  }

  function AppendAddressComponent(str, value) {
    if (!value) {
      return str;
    }

    if (!str) {
      str = '';
    }

    if (str.length) {
      str += ', ';
    }

    str += value;

    return str;
  }

  builder.buildClaimFilter = function(f) {
    if (first) {
      first = false;
    } else {
      filter.claimSearch += ' AND ';
    }

    let value = null;
    let hasMissing = false;
    let ignoreSet = false;
    switch (f.originalType) {
      case 'Entity':
        {
          const checkeds = (f.selectedEntities || []).filter(e => !!e.checked);
          let values = checkeds.map(x => x.name);
          values = values.map((v: string) => {
            let theValue = v;

            if ((theValue || '').toLowerCase() === 'unassigned') {
              theValue = '';
            }

            return '"' + theValue + '"';
          });
          value = values.join(' OR ');

          if (!value.length) {
            value = null;
          }

          if (checkeds.find(item => Guid.isEmpty(item.id))) {
            hasMissing = true;
          }
        }
        break;

      case 'Tags':
        {
          const selected = (f.selectedTags || [])
            .filter(e => !!e.selected)
            .map(x => x.tag);

          if (!selected.length) {
            value = null;
          } else {
            value = selected.map((t: Tag) => '"' + t.id.toString() + '"').join(' OR ');
          }
        }
        break;

      case 'User':
        {
          const values: string[] = [];
          f.selectedUsers = f.selectedUsers || [];
          for (const selectedUser of f.selectedUsers as Guid[]) {
            const user = users.find(u => selectedUser.equals(u.id));
            if (user) {
              values.push(`"${user.name}"`);
            }
          }

          hasMissing = f.unassigned;
          if (f.unassigned) {
            values.push('""');
          }

          value = values.join(' OR ');

          if (!value.length) {
            value = null;
          }
        }
        break;

      case 'Dropdown':
        {
          let ddValues = f.options.filter(o => !!o.checked).map(o => o.text);
          ddValues = ddValues.map(v => {
            return '"' + v + '"';
          });
          value = ddValues.join(' OR ');
        }
        break;

      case 'StatusSet':
        {
          let values = f.settings.status.filter(s => !!s.checked).map(s => s.name);
          values = values.map(v => {
            return '"' + v + '"';
          });
          value = values.join(' OR ');
        }
        break;

      case 'Date':
        {
          const from = f.dateFrom;
          const to = f.dateTo;
          ignoreSet = true;
          let clause = '';

          if (from && to) {
            // if has both values
            clause =
              '{"' +
              DateTime.fromISO(from).toISODate() +
              '" TO "' +
              DateTime.fromISO(to).toISODate() +
              '"}';
          } else if (from) {
            // just from
            clause = '{"' + DateTime.fromISO(from).toISODate() + '" TO *}';
          } else if (to) {
            // just to
            clause = '{* TO "' + DateTime.fromISO(to).toISODate() + '"}';
          }

          if (clause.length) {
            filter.claimSearch += f.field + ':' + clause;
          }
        }
        break;

      case 'DateTime':
        {
          const from = f.dateFrom;
          const to = f.dateTo;
          ignoreSet = true;
          let clause = '';

          if (from && to) {
            // if has both values
            clause =
              '{"' +
              DateTime.fromISO(from)
                .toUTC()
                .toSQL() +
              '" TO "' +
              DateTime.fromISO(to)
                .toUTC()
                .toSQL() +
              '" }';
          } else if (from) {
            // just from
            clause =
              '{"' +
              DateTime.fromISO(from)
                .toUTC()
                .toSQL() +
              '" TO *}';
          } else if (to) {
            // just to
            clause =
              '{* TO "' +
              DateTime.fromISO(to)
                .toUTC()
                .toSQL() +
              '"}';
          }

          if (clause.length) {
            filter.claimSearch += f.field + ':' + clause;
          }
        }
        break;

      case 'Address':
        {
          ignoreSet = true;
          let clause = '';
          forEach(<any[]>f.address, function(val, key) {
            if (val && val.length) {
              if (clause.length) {
                clause += ' AND ';
              }

              clause += f.field + '.' + key + ':(' + val + ')';
            }
          });

          filter.claimSearch += clause;
        }
        break;

      case 'Text':
        {
          if (f.value) {
            value = f.value + '*';
          }
        }
        break;

      case 'Bool':
        {
          if (f.value === 'null') {
            value = undefined;
            hasMissing = true;
          } else {
            value = f.value;
          }
        }
        break;

      default:
        value = f.value;
        break;
    }

    if (!ignoreSet) {
      if (value) {
        filter.claimSearch += f.field + ':(' + value + ')';
      } else if (value || hasMissing) {
        filter.claimSearch += f.field + ':(' + value + ' OR _missing_:' + f.field + ')';
      } else if (hasMissing) {
        filter.claimSearch += f.field + ':(_missing_:' + f.field + ')';
      }
    }
  };

  builder.buildBool = function(f) {
    filter[f.prop] = f.value;
  };

  builder.buildClaim = function(f) {
    if (Array.isArray(f.value)) {
      filter[f.prop] = f.value.map(x => x.id);
      return;
    }

    filter[f.prop] = f.value;
  };

  builder.buildDate = function(f) {
    filter[f.prop + 'From'] = f.dateFrom;
    filter[f.prop + 'To'] = f.dateTo;
  };

  builder.buildDropdown = function(f) {
    filter[f.prop] = f.options.filter(o => !!o.checked).map(o => o.id);
  };

  builder.buildInvoice = function(f) {
    filter[f.prop] = f.value;
  };

  builder.buildEntity = function(f) {
    filter[f.prop] = f.selectedEntities.filter(e => !!e.checked).map(e => e.id);
  };

  builder.buildNumber = function(f) {
    filter[f.prop] = {
      Type: f.filterType,
      Value: f[f.filterType]
    };
  };

  builder.buildText = function(f) {
    filter[f.prop] = f.value;
  };

  builder.buildUser = function(f) {
    filter[f.prop] = f.selectedUsers.filter(u => !!u.checked).map(u => u.id);
  };

  return service;
}
