import { PageHelper } from '../../../core/http/pageHelper';
import { Claim } from '../../../core/models';

const DEFAULT_TAKE = 10;

export class RecentClaimsWidgetController {
  static $inject = ['$http'];

  claims: Claim[] = [];
  config: any = { take: DEFAULT_TAKE };

  constructor(private http: ng.IHttpService) {}

  $onInit() {
    this.config.take = this.config.take || DEFAULT_TAKE;
    this.load();
  }

  load() {
    const url = PageHelper.query('/api/v1/claims/recent', this.config.take, 0);
    this.http.get<Claim[]>(url).then(response => {
      this.claims = response.data || [];
    });
  }
}
