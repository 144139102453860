import { IGravatarService } from '../../../core/services/gravatar';

/* @ngInject */
export default function CioAvatarTopbar(): any {
  return {
    scope: {},
    bindToController: {
      user: '='
    },
    controller: /* @ngInject */ function(GravatarService: IGravatarService) {
      const vm = this;

      vm.avatar = function() {
        return GravatarService.forEmail(vm.user.email, 48);
      };
    },
    controllerAs: 'avatar',
    restrict: 'E',
    template: require('./cio-avatar-topbar.html')
  };
}
