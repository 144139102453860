import { IStorageFileHttpService } from '../../core/http/storageFile';
import { StorageFile } from '../../core/models';
import { IAlertService } from '../../core/services/alert';
import { FileHelper } from '../../core/utils/fileHelper';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';

export class FileRenameController {
  static $inject = [
    'file',
    'AlertService',
    'StorageFileHttpService',
    '$uibModalInstance'
  ];

  name: string;
  extension: string;

  constructor(
    private file: StorageFile,
    private alertService: IAlertService,
    private storageFileHttpService: IStorageFileHttpService,
    private modalInstance: ng.ui.bootstrap.IModalServiceInstance
  ) {}

  $onInit(): void {
    this.name = FileHelper.getNameWithoutExtension(this.file.name);
    this.extension = FileHelper.getExtension(this.file.name);
  }

  ok(): void {
    if (!this.isValidName()) {
      return;
    }

    const clone = Object.assign(new StorageFile(), this.file, {
      name: this.getNewName()
    });
    this.storageFileHttpService
      .modifyFile(clone)
      .do(updated => (this.file = updated))
      .do(() => this.alertService.success('File renamed'))
      .do(() => this.close(true))
      .catch(this.handleFailure)
      .subscribe();
  }

  getNewName() {
    return `${this.name}${this.extension}`;
  }

  isValidName(): boolean {
    return FileHelper.sanitizeFileName(this.name).length > 0;
  }

  close(success: boolean): void {
    this.modalInstance.close({ success, file: this.file });
  }

  handleFailure(error: any): Observable<any> {
    this.close(false);
    return Observable.throw(error);
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }
}
