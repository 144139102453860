import { Guid } from '../../core/models';

/* @ngInject */
export default function CioEntityDisplay(EntityResource): any {
  return {
    restrict: 'AE',
    link: link,
    scope: {
      entityId: '='
    },
    template: '<span>{{text}}</span>'
  };

  function link(scope, element, attr) {
    if (!scope.entityId || Guid.isEmpty(scope.entityId)) {
      scope.text = '';
      return;
    }

    EntityResource.simple({ id: scope.entityId }).$promise.then(function(entity) {
      scope.text = entity.name;
    });
  }
}
