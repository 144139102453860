import { DateTimeViewController } from './dateTimeView.controller';
import { ComponentBase } from '../../../core/utils/componentBase';

export class DateTimeViewComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      dateTime: '<',
      mode: '<'
    };
    this.controller = DateTimeViewController;
    this.template = require('./dateTimeView.html');
  }
}
