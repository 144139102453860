import { IHashService } from './hash';

const DEFAULT_SIZE: number = 24;

/**
 * Provides functions for calculating Gravatar URL's.
 */
export interface IGravatarService {
  forUnknown(size: number): string;
  forEmail(email: string, size: number): string;
}

/* @ngInject */
export function GravatarService(HashService: IHashService): void {
  /**
   * Returns the Gravatar URL for an unknown user.
   * @param {number} size The size of the icon.
   */
  this.forUnknown = function(size?: number): string {
    return `https://www.gravatar.com/avatar/?d=identicon&s=${size || DEFAULT_SIZE}`;
  };

  /**
   * Returns the Gravatar URL for the specified email.
   * @param {string} email The email address to return the gravatar for.
   * @param {number} size The size of the icon.
   */
  this.forEmail = function(email: string, size?: number): string {
    return `https://www.gravatar.com/avatar/${HashService.MD5(
      email
    )}.jpg?d=identicon&r=pg&s=${size || DEFAULT_SIZE}`;
  };
}
