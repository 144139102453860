import { StateProvider } from 'angular-ui-router';

/* @ngInject */
export function UserRoutes($stateProvider: StateProvider): void {
  $stateProvider
    .state('app.profile', {
      url: '/settings/profile',
      controller: 'ProfileController',
      template: '<ui-view/>',
      data: {
        title: 'Profile'
      }
    })

    .state('app.user-claims', {
      url: '/my-claims',
      controller: 'UserClaimsController as vm',
      template: require('./user-claims.html'),
      data: {
        title: 'My Claims'
      }
    });
}
