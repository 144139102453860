import { IClaimManagerService } from './claim-manager-service';
import { Organization } from '../../core/models';
import { IAlertService, ILogService } from '../../core/services';
import { IOrganizationHttpService, IClaimHttpService } from '../../core/http';
import '../services/system-template-field';
import { IUserSelectService } from '../settings/users/user-select';

/* @ngInject */
export default function ClaimOverviewController(
  $scope: any,
  $rootScope: any,
  OrganizationHttpService: IOrganizationHttpService,
  ClaimManagerService: IClaimManagerService,
  SystemTemplateField,
  ClaimHttpService: IClaimHttpService,
  LogService: ILogService,
  AlertService: IAlertService,
  UserSelectService: IUserSelectService
): void {
  const template = ClaimManagerService.template();
  const templateStatusField = template.systemFields.find(f => f.name === 'status');

  const vm = this;
  vm.claim = ClaimManagerService.claim();
  vm.template = template;
  vm.showUploader = false;
  vm.templateStatus = SystemTemplateField.getField(templateStatusField);
  vm.currentStatus = {};
  vm.tasksTotal = tasksTotal;
  vm.changeStatus = changeStatus;
  vm.subscribe = subscribe;
  vm.unsubscribe = unsubscribe;
  vm.tagChange = tagChange;
  vm.assigneeChanged = assigneeChanged;
  vm.assigneeRemoved = assigneeRemoved;
  vm.addAssignee = addAssignee;
  vm.changedCurrency = changedCurrency;
  vm.organization = null;
  vm.referenceEditable = false;
  vm.referenceChanged = referenceChanged;
  vm.currencyChanged = currencyChanged;
  vm.isWatching = ClaimManagerService.isWatching();

  activate();

  function activate() {
    if (vm.claim.status) {
      vm.currentStatus = vm.templateStatus.settings.status.find(
        s => s.name === vm.claim.status
      );
    }

    OrganizationHttpService.getOrganization()
      .safeApply(
        $scope,
        organization => setOrganization(organization),
        err => LogService.error('Failed to load organization', err)
      )
      .subscribe();
  }

  function setOrganization(organization: Organization) {
    vm.organization = organization;
    vm.referenceEditable =
      !vm.claim.closed &&
      (vm.organization.referenceStrategy || '').toLowerCase() === 'manual';
  }

  function referenceChanged(reference: string) {
    vm.claim.reference = reference;
    updateClaim();
  }

  $rootScope.$on('claim.updated', function(e, claim) {
    vm.claim = claim;
  });

  function tasksTotal() {
    return vm.tasks.overdue + vm.tasks.open;
  }

  function changedCurrency() {
    updateClaim();
  }

  function changeStatus(status) {
    vm.currentStatus = status;
    vm.claim.status = status.name;
    updateClaim();
  }

  function subscribe() {
    ClaimHttpService.subscribe(vm.claim.id)
      .safeApply(
        $scope,
        () => {
          vm.isWatching = true;
          AlertService.success('Watching claim');
        },
        () => AlertService.error('Could not watch claim')
      )
      .subscribe();
  }

  function unsubscribe() {
    ClaimHttpService.unsubscribe(vm.claim.id)
      .safeApply(
        $scope,
        () => {
          vm.isWatching = false;
          AlertService.success('Stopped watching claim');
        },
        () => AlertService.error('Could not stop watching claim')
      )
      .subscribe();
  }

  function tagChange() {
    updateClaim();
  }

  function assigneeChanged(user) {
    if (!vm.claim.assignees) {
      vm.claim.assignees = [];
    }

    // Check if user already exists
    const index = vm.claim.assignees.indexOf(user.id);
    if (index !== -1) {
      return;
    }

    vm.claim.assignees.push(user.id);
    updateClaim();
  }

  function addAssignee() {
    UserSelectService.open({
      group: template.assigneeGroupId,
      title: 'Select Assignee'
    })
      .then(user => {
        if (!user) {
          return;
        }

        assigneeChanged(user);
      })
      .catch(() => {});
  }

  function currencyChanged(currency: string) {
    vm.claim.currency = currency;
    updateClaim();
  }

  function assigneeRemoved(idx) {
    vm.claim.assignees.splice(idx, 1);
    updateClaim();
  }

  function updateClaim() {
    ClaimManagerService.updateClaim()
      .then(function() {
        AlertService.success('Claim updated successfully.');
      })
      .catch(function(err) {
        AlertService.error('Could not update claim, please try again.');
      });
  }
}
