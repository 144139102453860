import { IAlertService } from '../../core/services/alert';
import { EntityResource } from '../../data/resources/entity';
import ng from 'angular';

/* tslint:disable:no-shadowed-variable */
/* @ngInject */
export function EntityEditModalController(
  $scope: any,
  $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
  entity: any,
  EntityResource: EntityResource,
  AlertService: IAlertService
) {
  /* tslint:enable:no-shadowed-variable */

  $scope.model = entity;

  $scope.save = function(form) {
    if (!form.$valid) {
      return;
    }

    const request = entity.id
      ? EntityResource.update($scope.model)
      : EntityResource.save($scope.model);

    request.$promise.then(
      function(updated) {
        form.$setPristine();
        AlertService.success('The entity was successfully updated.');
        $uibModalInstance.close(updated);
      },
      function() {
        AlertService.error('Could not update entity, please try again.');
      }
    );
  };

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };
}
