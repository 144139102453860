import { StateProvider } from 'angular-ui-router';

/* @ngInject */
export default function HomeRoutes($stateProvider: StateProvider): void {
  $stateProvider.state('home', {
    url: '/',
    template: ' ',
    controller: 'HomeController',
    data: {
      authenticate: true
    }
  });
}
