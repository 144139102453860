import { CostCategory } from '../../core/models';
import * as ng from 'angular';

/* tslint:disable-next-line:no-empty-interface */
export interface CostCategoryResource
  extends ng.resource.IResourceClass<ng.resource.IResource<CostCategory>> {
  update(costCategory: CostCategory): ng.resource.IResource<CostCategory>;
}

/* @ngInject */
export default function CostCategoryResource(
  $resource: ng.resource.IResourceService
): CostCategoryResource {
  return <CostCategoryResource>$resource<ng.resource.IResource<CostCategory>>(
    '/api/v1/cost-categories/:id',
    { id: '@id' },
    {
      update: {
        method: 'PUT',
        params: {
          id: '@id'
        }
      },
      query: { method: 'GET' }
    }
  );
}
