interface IWidgetModalController {
  save(): void;
  cancel(): void;
}

export default class WidgetModalController implements IWidgetModalController {
  static $inject = ['$uibModalInstance', 'widget'];

  public rows: number[];

  constructor(
    private uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
    private widget: any
  ) {}

  $onInit(): void {
    this.widget.take = this.widget.take || 10;
    this.rows = [5, 10, 15, 20, 25, 30, 35, 40, 45, 50];
  }

  save(): void {
    this.uibModalInstance.close(this.widget);
  }

  cancel(): void {
    this.uibModalInstance.dismiss('cancel');
  }
}
