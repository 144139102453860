import { EntityTasksController } from './entityTasks.controller';
import { ComponentBase } from '../../../core/utils/componentBase';

export class EntityTasksComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      entity: '<'
    };
    this.controller = EntityTasksController;
    this.template = require('./entityTasks.html');
  }
}
