import { UserSelectController } from './userSelect.controller';
import { ComponentBase } from '../../../core/utils/componentBase';

export class UserSelectComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      userId: '='
    };
    this.controller = UserSelectController;
    this.template = require('./userSelect.html');
  }
}
