import { WorkflowEditorController } from './workflowEditor.controller';
import { ComponentBase } from '../../../core/utils/componentBase';

export class WorkflowEditorComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = { workflow: '=' };
    this.controller = WorkflowEditorController;
    this.template = require('./workflowEditor.html');
  }
}
