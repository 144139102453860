import { Entity } from '../../core/models';
import ng from 'angular';

/* tslint:disable-next-line:no-empty-interface */
export interface EntityResource
  extends ng.resource.IResourceClass<ng.resource.IResource<Entity>> {
  update(entity: Entity): ng.resource.IResource<Entity>;
  simple(params: { id: string }): ng.resource.IResource<any>;
  queryByTemplate(params: { id: string }): any;
}

/* @ngInject */
export default function EntityResource(
  $resource: ng.resource.IResourceService
): EntityResource {
  return <EntityResource>$resource<ng.resource.IResource<Entity>>(
    '/api/v1/entities/:id',
    { id: '@id' },
    {
      update: {
        method: 'PUT',
        params: {
          id: '@id'
        }
      },
      query: { method: 'GET' },
      simple: {
        method: 'GET',
        url: '/api/v1/entities/simple/:id',
        params: { id: '@id' }
      },
      queryByTemplate: {
        method: 'GET',
        url: '/api/v1/entities/find/:id',
        params: { id: '@id' }
      }
    }
  );
}
