import { INotificationSettingsHttpService, ITaskHttpService } from '../../../core/http';
import { Guid, User } from '../../../core/models';
import {
  IAlertService,
  IGravatarService,
  ISecurityService
} from '../../../core/services';
import {
  Clock,
  DEFAULT_DATE_FORMAT,
  DEFAULT_TIMEZONE,
  IANA_TIMEZONES,
  TimeFactory,
  TimeFrequencies
} from '../../../core/utils';
import { IUserStore } from '../../../data/stores/user';
import '../groups/group-select';
import { StateService } from 'angular-ui-router';
import ng from 'angular';
import debounce from 'lodash/debounce';
import isUndefined from 'lodash/isUndefined';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';

const DEFAULT_TASK_HOUR = 9;

/* @ngInject */
export default function UserEditController(
  $scope: any,
  $state: StateService,
  $stateParams: any,
  UserStore: IUserStore,
  GroupSelectService,
  SecurityService: ISecurityService,
  NotificationSettingsHttpService: INotificationSettingsHttpService,
  GravatarService: IGravatarService,
  AlertService: IAlertService,
  TaskHttpService: ITaskHttpService
): void {
  // TODO: check user access permissions

  $scope.passwordForm = {};
  $scope.userForm = {};
  $scope.user = {};
  $scope.notificationSettings = {};
  $scope.groups = [];
  $scope.sysGroups = [];
  $scope.timeZones = IANA_TIMEZONES;
  $scope.hours = [];

  $scope.dateFormats = [
    { name: 'DD/MM/YYYY', format: 'dd/MM/yyyy' },
    { name: 'MM/DD/YYYY', format: 'MM/dd/yyyy' }
  ];

  $scope.timePreferences = [
    { name: '24h format', format: 'HH:mm' },
    { name: '12h format', format: 'hh:mm a' }
  ];

  buildDateFormatsDisplay();

  function buildDateFormatsDisplay() {
    const now = Clock.local().now();
    $scope.dateFormats.forEach(
      dateFormat => (dateFormat.name += ' (' + now.toFormat(dateFormat.format) + ')')
    );
    $scope.timePreferences.forEach(
      timeFormat => (timeFormat.name += ' (' + now.toFormat(timeFormat.format) + ')')
    );
  }

  // Watch for changes to the timezone, so we can show an updated example date
  $scope.$watch('user.timezone', function(value) {
    // Default if nothing is selected
    if (!value) {
      value = DEFAULT_TIMEZONE;
    }
  });

  // Watch for changes to the email, so we can update the users avatar; we debounce
  // to avoid hitting gravatar too much
  $scope.$watch(
    'user.email',
    debounce(function(email) {
      $scope.$apply(function() {
        // Don't change if empty, wrong is ok as the gravatar fall back will handle this
        if (isUndefined(email) || isEmpty(email)) {
          return;
        }

        $scope.userProfilePicture = getUserPicture($scope.user);
      });
    }, 300)
  );

  $scope.load = function() {
    $scope.hours = TimeFactory.getTimes(TimeFrequencies.Hour);
    $scope.userForm.active = true;

    UserStore.get($stateParams.id)
      .then(function(user) {
        $scope.user = user;
        $scope.userForm.active = false;
        $scope.userProfilePicture = getUserPicture(user);

        setUserDefaults(user);

        UserStore.groups(user.id)
          .then(function(groups) {
            $scope.groups = groups.items;
          })
          .catch(function() {
            AlertService.error('Could not load user groups.');
          });
      })
      .catch(function() {
        AlertService.error('Could not update the user, please try again.');
        $state.go('app.users');
      });

    NotificationSettingsHttpService.getByUserId(new Guid($stateParams.id))
      .safeApply($scope, settings => {
        $scope.notificationSettings = settings || {};
        if (
          $scope.notificationSettings.tasks &&
          $scope.notificationSettings.tasks.emailSummary &&
          !$scope.notificationSettings.tasks.scheduleNotification
        ) {
          $scope.notificationSettings.tasks.scheduleNotification = DEFAULT_TASK_HOUR;
        }
      })
      .subscribe();
  };

  $scope.load();

  $scope.updateUser = function(user, form) {
    $scope.userForm.active = true;

    user
      .update()
      .then(function(updated: User) {
        $scope.user = updated;
        AlertService.success('User updated');
        $scope.userForm.active = false;
        form.$setPristine();

        setUserDefaults(updated);

        // updates security user info
        if (updated.id === SecurityService.getCurrentUser().id) {
          const theUser = ng.copy(updated);
          SecurityService.initialize(theUser);
        }
      })
      .catch(function(error) {
        AlertService.error('Could not update the user, please try again.');
        $scope.userForm.active = false;
      });
  };

  $scope.updatePassword = function(password: string) {
    $scope.passwordForm.active = true;

    UserStore.modifyPassword($scope.user.id, password)
      .then(function() {
        AlertService.success('Password updated');
        $scope.passwordForm.active = false;

        // TODO: reset the password form - below does nothing..
        $scope.passwordForm.password = '';
        $scope.passwordForm.passwordRepeat = '';
      })
      .catch(function(error) {
        AlertService.error('Could not reset the password, please try again.');
        $scope.passwordForm.active = false;
      });
  };

  $scope.savePreferences = function() {
    updateTaskSettings();
    NotificationSettingsHttpService.modify(
      new Guid($stateParams.id),
      $scope.notificationSettings
    )
      .safeApply($scope, settings => {
        $scope.notificationSettings = settings;
        AlertService.success('Settings updated');
      })
      .subscribe();
  };

  $scope.viewGroup = function(group) {
    $state.go('app.group.edit', group.id);
  };

  $scope.addGroups = function() {
    GroupSelectService.open('Select groups', $scope.groups).then(function(groups) {
      const groupIds = map<any, string>(groups, 'id');

      UserStore.modifyGroups($scope.user.id, groupIds).then(function(data) {
        AlertService.success('User groups updated');
        $scope.groups = data.items;
      });
    });
  };

  $scope.sendDigest = function() {
    TaskHttpService.sendDigest(new Guid($scope.user.id))
      .safeApply($scope, () => {
        AlertService.success('Your digest email will be send shortly');
      })
      .subscribe();
  };

  function updateTaskSettings() {
    if (!$scope.notificationSettings.tasks.emailSummary) {
      delete $scope.notificationSettings.tasks.scheduleNotification;
    }

    if (
      $scope.notificationSettings.tasks.emailSummary &&
      typeof $scope.notificationSettings.tasks.scheduleNotification !== 'number'
    ) {
      $scope.notificationSettings.tasks.scheduleNotification = DEFAULT_TASK_HOUR;
    }
  }

  function setUserDefaults(user: User) {
    if (user.dateFormat) {
      const indexOfTime = user.dateFormat.toLowerCase().indexOf(' hh');
      if (indexOfTime > -1) {
        user.dateFormat = user.dateFormat.substr(0, indexOfTime + 1);
      }
    } else {
      user.dateFormat = DEFAULT_DATE_FORMAT;
    }

    if (!user.timezone) {
      user.timezone = DEFAULT_TIMEZONE;
    }
  }

  function getUserPicture(user) {
    return GravatarService.forEmail(user.email, 64);
  }
}
