import { HttpService } from './http';
import { Currency } from '../models';
import { logError } from '../utils/rx';
import { Observable } from 'rxjs/Observable';

export interface ICurrencyHttpService {
  getAll(): Observable<Currency[]>;
}

export class CurrencyHttpService implements ICurrencyHttpService {
  static $inject = ['HttpService'];

  private readonly _url: string = '/api/v1/currencies';

  constructor(private httpService: HttpService) {}

  getAll(): Observable<Currency[]> {
    return this.httpService
      .get(this._url)
      .map(res => res.json<Currency[]>())
      .catch(logError);
  }
}
