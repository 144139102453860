import 'trix';

interface ITrixEditor {
  undo: () => void;
  redo: () => void;
  activateAttribute: (attr: string, value?: string) => void;
  increaseIndentationLevel: () => void;
  decreaseIndentationLevel: () => void;
  insertString: (text: string) => void;
  recordUndoEntry: (entry: string) => void;
  setSelectedRange: (range: number | number[]) => void;
  deleteInDirection: (direction: 'forward' | 'backward') => void;
  insertLineBreak: () => void;
  insertFile: (file: File) => void;
  insertHTML: (html: string) => void;
  expandSelectionInDirection: (direction: 'forward' | 'backward') => void;
  moveCursorInDirection: (direction: 'forward' | 'backward') => void;
  getSelectedRange: () => number[];
  getDocument: () => { toString: () => string };
}

export class HtmlEditorController {
  static $inject = [];

  public content: string = '';
  public required: boolean = false;
}
