import ReportFiltersModalController from './report-filters-modal.controller';
import { Report, ReportFilter } from '../../../core/models';

/**
 * A service for selecting and configuring a report's fields.
 */
export interface IReportFiltersService {
  /**
   * Selects the fields and their configuration.
   *
   * @param {Report} report The report to select the fields for.
   * @returns {Promise<ReportField[]>} The selected fields (the original report is not modified).
   */
  selectFilters(report: Report): ng.IPromise<ReportFilter[]>;
}

export default class ReportFiltersService implements IReportFiltersService {
  static $inject = ['$uibModal'];

  constructor(private $uibModal: ng.ui.bootstrap.IModalService) {}

  selectFilters(report: Report): ng.IPromise<ReportFilter[]> {
    const modalInstance = this.$uibModal.open({
      template: require('./report-filters-modal.html'),
      controller: ReportFiltersModalController,
      controllerAs: '$ctrl',
      size: 'lg',
      resolve: {
        report: function() {
          return report;
        }
      }
    });

    return <ng.IPromise<ReportFilter[]>>modalInstance.result;
  }
}
