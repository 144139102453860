import { Notification, Guid } from '../../core/models';
import { INotificationHttpService } from '../../core/http';
import { StateService } from 'angular-ui-router';

export class NotificationViewController {
  static $inject = ['$scope', '$state', 'NotificationHttpService'];

  notification: Notification;

  constructor(
    private scope: any,
    private stateService: StateService,
    private notificationHttpService: INotificationHttpService
  ) {}

  $onInit() {
    const id = new Guid(this.stateService.params.id);
    this.notificationHttpService
      .getNotification(id)
      .safeApply(this.scope, notification => (this.notification = notification))
      .subscribe();
  }

  markAsRead() {
    this.notificationHttpService.markAsRead(this.notification);
  }

  markAsUnread() {
    this.notificationHttpService.markAsUnread(this.notification);
  }

  goToNotifications() {
    this.stateService.go('app.notifications');
  }
}
