import { ICountryService } from '../../../../core/services';
import { IWorkflowFilter } from '../../../../core/models';

export class WorkflowFilterAddressController {
  static $inject = ['CountryService'];

  filter: IWorkflowFilter;
  countries: string[] = [];

  constructor(countryService: ICountryService) {
    this.countries = countryService.getCountries();
  }

  $onInit() {
    this.filter.value = this.filter.value || {};
  }
}
