/**
 * Filter for truncating long strings and adding an ending
 * @param {string} The text to truncate
 * @param {number} The maximum length, before the string will be truncated. The default is 10.
 * @param {string} The truncation indicator, the default is '...'.
 * @return The truncated string.
 *
 *  <!-- input: hello; output: hell... -->
 *  <div>{{ item.name | truncate:4 }}</div>
 *
 *  <!-- input: hello; output: hel. -->
 *  <div>{{ item.name | truncate:3:'.' }}</div>
 */
export function truncateFilter() {
  return function(text: string, length: number, end: string) {
    length = length || 10;
    end = end || '...';

    if (text.length <= length || text.length - end.length <= length) {
      return text;
    }

    return String(text).substring(0, length - end.length) + end;
  };
}
