import * as angular from 'angular';

export function ResizeProvider(): ng.IServiceProvider {
  return {
    $get
  };

  // service object
  function $get($rootScope, $window, $interval) {
    const resize = this;
    let bound = 0;
    let timer = 0;
    let resized = 0;
    const $w = angular.element($window);

    resize.throttle = 32;
    resize.initBind = 1;

    // api to set throttle amount
    function setThrottle(time) {
      resize.throttle = time;
    }

    // api to get current throttle amount
    function getThrottle() {
      return resize.throttle;
    }

    // trigger a resize event on provided $scope or $rootScope
    function trigger($scope) {
      $scope = $scope || $rootScope;
      $scope.$broadcast('resize', {
        width: $window.innerWidth,
        height: $window.innerHeight
      });
    }

    // bind to window resize event, will only ever be bound
    // one time for entire app
    function bind() {
      if (!bound) {
        $w.on('resize', function(event) {
          if (!resized) {
            timer = $interval(function() {
              if (resized) {
                resized = 0;
                $interval.cancel(timer);
                resize.trigger();
              }
            }, resize.throttle);
          }
          resized = 1;
        });
        bound = 1;
        $w.triggerHandler('resize');
      }
    }

    // unbind window resize event
    function unbind() {
      if (bound) {
        $w.off('resize');
        bound = 0;
      }
    }

    this.getThrottle = getThrottle;
    this.setThrottle = setThrottle;
    this.trigger = trigger;
    this.bind = bind;
    this.unbind = unbind;

    // bind to window resize event when service created
    if (resize.initBind) {
      resize.bind();
    }
  }
}
