import { ClaimLinkComponent } from './claimLink/claimLink.component';
import { ColorShowComponent } from './colorShow/colorShow.component';
import { CurrencyEditComponent } from './currencyEdit/currencyEdit.component';
import { CurrencyViewComponent } from './currencyView/currencyView.component';
import { DateEditComponent } from './dateEdit/dateEdit.component';
import { DateTimeEditComponent } from './dateTimeEdit/dateTimeEdit.component';
import { DateTimeViewComponent } from './dateTimeView/dateTimeView.component';
import { DateViewComponent } from './dateView/dateView.component';
import { EntityLinkComponent } from './entityLink/entityLink.component';
import { FinancialEntitySelectComponent } from './financialEntitySelect/financialEntitySelect.component';
import { ListPagerComponent } from './listPager/listPager.component';
import { LoadingButtonComponent } from './loadingButton/loadingButton.component';
import { RecordLinkComponent } from './recordLink/recordLink.component';
import { ReferenceLinkComponent } from './referenceLink/referenceLink.component';
import { ReferenceViewComponent } from './referenceView/referenceView.component';
import { SearchUserSelectComponent } from './searchUserSelect/searchUserSelect.component';
import { StatusBadgeComponent } from './statusBadge/statusBadge.component';
import { TagComponent } from './tag/tag.component';
import { TagListComponent } from './tagList/tagList.component';
import { TagEditorComponent } from './tagEditor/tagEditor.component';
import { TagSelectComponent } from './tagSelect/tagSelect.component';
import { TimeEditComponent } from './timeEdit/timeEdit.component';
import { UserAvatarComponent } from './userAvatar/userAvatar.component';
import { UserSelectComponent } from './userSelect/userSelect.component';
import { HtmlEditorComponent } from './htmlEditor/htmlEditor.component';
import { CoreModule } from '../../core/module';
import { DataModule } from '../../data/module';
import { UIModule } from '../../ui/module';
import angular from 'angular';

export const ComponentsModule = angular
  .module('app.instance.components', [DataModule.name, CoreModule.name, UIModule.name])

  // Components
  .component('cioClaimLink', new ClaimLinkComponent())
  .component('cioColorShow', new ColorShowComponent())
  .component('cioCurrencyEdit', new CurrencyEditComponent())
  .component('cioCurrencyView', new CurrencyViewComponent())
  .component('cioDateEdit', new DateEditComponent())
  .component('cioDateTimeEdit', new DateTimeEditComponent())
  .component('cioDateTimeView', new DateTimeViewComponent())
  .component('cioDateView', new DateViewComponent())
  .component('cioEntityLink', new EntityLinkComponent())
  .component('cioFinancialEntitySelect', new FinancialEntitySelectComponent())
  .component('cioListPager', new ListPagerComponent())
  .component('cioLoadingButton', new LoadingButtonComponent())
  .component('cioRecordLink', new RecordLinkComponent())
  .component('cioReferenceLink', new ReferenceLinkComponent())
  .component('cioReferenceView', new ReferenceViewComponent())
  .component('cioSearchUserSelect', new SearchUserSelectComponent())
  .component('cioStatusBadge', new StatusBadgeComponent())
  .component('cioTag', new TagComponent())
  .component('cioTagSelect', new TagSelectComponent())
  .component('cioTagEditor', new TagEditorComponent())
  .component('cioTagList', new TagListComponent())
  .component('cioTimeEdit', new TimeEditComponent())
  .component('cioUserAvatar', new UserAvatarComponent())
  .component('cioUserSelect', new UserSelectComponent())
  .component('cioHtmlEditor', new HtmlEditorComponent());
