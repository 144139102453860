import { TasksController } from './tasks.controller';
import { ComponentBase } from '../../core/utils/componentBase';

export class TasksComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {};
    this.controller = TasksController;
    this.template = require('./tasks.html');
  }
}
