import { HttpService } from './http';
import { IEntitySearchQuery, EntitySearchResults } from '../models';
import { logError } from '../utils/rx';
import { Observable } from 'rxjs/Observable';

export interface IEntityHttpService {
  search(search: IEntitySearchQuery): Observable<EntitySearchResults>;
}

export class EntityHttpService implements IEntityHttpService {
  static $inject = ['HttpService'];

  constructor(private httpService: HttpService) {}

  search(search: IEntitySearchQuery): Observable<EntitySearchResults> {
    const url = '/api/v1/entities/search';
    return this.httpService
      .post(url, search)
      .map(res => EntitySearchResults.fromJson(res.json()))
      .catch(logError);
  }
}
