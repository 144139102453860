import { IEntityHttpService } from '../../core/http';
import {
  EntitySearchResults,
  EntityTemplate,
  Guid,
  IEntitySearchQuery,
  Page,
  FieldDefinition
} from '../../core/models';
import { ISearchColumn, ISearchFilter } from '../search/search.interfaces';
import { SearchRow } from '../search/searchRow';
import { ICacheService } from '../services/cache';
import debounce from 'lodash/debounce';
import { IEntityService } from './entity.service';

const DEFAULT_PAGE_SIZE: number = 30;
const DEFAULT_SORT = 'title';

export class EntitySearchModalController {
  static $inject = [
    '$scope',
    'typeIds',
    'allowCreate',
    'Cache',
    'EntityHttpService',
    'EntityService',
    '$uibModalInstance'
  ];

  columns: ISearchColumn[] = [];
  filters: ISearchFilter[] = [];
  searchText: string;
  searchQuery: string;
  searching: boolean = false;
  sort: string = undefined;
  sortDescending: boolean = false;
  pageNumber: number = 1;
  currentPage: Page<SearchRow> = undefined;
  types: EntityTemplate[] = [];
  delayedSearch: any = debounce(this.search, 300);
  private _currentType: EntityTemplate;

  constructor(
    private scope: any,
    private typeIds: Guid[] = [],
    private allowCreate: boolean = false,
    private cacheService: ICacheService,
    private entityHttpService: IEntityHttpService,
    private entityService: IEntityService,
    private modalInstance: ng.ui.bootstrap.IModalInstanceService
  ) {}

  get currentType(): EntityTemplate {
    return this._currentType;
  }

  set currentType(value: EntityTemplate) {
    this._currentType = value;
    this.setColumns(value);
  }

  get showCreate() {
    return this.allowCreate;
  }

  $onInit() {
    this.loadTypes();
  }

  select(row: SearchRow) {
    this.modalInstance.close(row.id);
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }

  search() {
    this.loadPage(1);
  }

  filterBy(filters: ISearchFilter[], text: string): void {
    this.filters = filters;
    this.searchText = text;
    this.loadPage(1);
  }

  sortBy(column: ISearchColumn, sortDescending: boolean): void {
    this.sort = column.fieldName;
    this.sortDescending = sortDescending;
    this.loadPage(1);
  }

  pageChanged(pageNumber: number): void {
    this.loadPage(pageNumber);
  }

  createEntity() {
    if (!this.currentType) {
      return;
    }

    this.entityService
      .createEntity(new Guid(this.currentType.id))
      .then(created => {
        this.modalInstance.close(new Guid(created.id));
      })
      .catch(() => {});
  }

  private setIsBusy(): void {
    this.searching = true;
  }

  private setNotBusy(): void {
    this.searching = false;
  }

  private loadPage(pageNumber: number) {
    this.setIsBusy();
    this.searchQuery = this.createQuery(this.filters, this.searchText);
    this.entityHttpService
      .search({
        query: this.searchQuery,
        fields: this.columns.map(x => x.fieldName),
        sort: this.sort || DEFAULT_SORT,
        sortDescending: this.sortDescending,
        skip: (pageNumber - 1) * DEFAULT_PAGE_SIZE,
        take: DEFAULT_PAGE_SIZE,
        types: this.currentType ? [new Guid(this.currentType.id)] : []
      } as IEntitySearchQuery)
      .safeApply(this.scope, results => {
        this.setResults(results);
        this.setNotBusy();
      })
      .subscribe();
  }

  private setResults(results: EntitySearchResults): void {
    this.currentPage = results.page as any;
    this.currentPage.items = results.page.items.map(x => new SearchRow(x));
    this.pageNumber = results.page.page;
  }

  private setColumns(entityTemplate: EntityTemplate): void {
    this.cacheService
      .fields()
      .then(fields => {
        this.setColumnsFromFields(entityTemplate, fields);
        this.loadPage(1);
      })
      .catch(() => {});
  }

  private setColumnsFromFields(
    entityTemplate: EntityTemplate,
    fields: FieldDefinition[]
  ) {
    const columns: ISearchColumn[] = [];
    for (const section of entityTemplate.sections) {
      for (const field of section.fields) {
        if (!field.tableDisplay) {
          continue;
        }
        const f = fields.find(x => x.id === field.fieldId);
        if (!f) {
          continue;
        }
        columns.push({
          fieldId: new Guid(f.id),
          fieldLabel: f.label || f.name,
          fieldName: f.name,
          fieldType: f.type
        } as ISearchColumn);
      }
    }
    if (columns.length === 0) {
      columns.push({
        fieldId: undefined,
        fieldLabel: 'Title',
        fieldName: 'title',
        fieldType: 'reference'
      } as ISearchColumn);
    }
    for (let i = 0; i < columns.length; i++) {
      columns[i].columnNumber = i;
    }
    this.columns.length = 0;
    this.columns.push(...columns);
  }

  private loadTypes(): void {
    this.cacheService
      .entityTemplates()
      .then(types => {
        if (this.typeIds.length === 0) {
          this.types = types;
        } else {
          this.types = types.filter(x => this.typeIds.some(t => t.equals(x.id)));
        }
        this.currentType = this.types[0];
      })
      .catch(() => {});
  }

  private createQuery(filters, text): string {
    // TODO: Add filter support
    text = (text || '').trim();
    return text.length ? `${text}*` : '';
  }
}
