import { IAlertService } from '../../../core/services/alert';
import '../../../data/resources/tax-code';
import '../../../data/resources/costCategory';

/* @ngInject */
export default function CostCategoryEditController(
  CostCategoryResource,
  TaxCodeResource,
  AlertService: IAlertService,
  $state
): void {
  const vm = this;
  vm.model = {};
  vm.newForm = {};
  vm.newForm.active = false;

  vm.update = update;

  init();

  function init() {
    TaxCodeResource.query()
      .$promise.then(function(result) {
        vm.taxCodes = result.items;
      })
      .catch(function(error) {
        AlertService.error('Could not load tax codes, please try again.');
      });

    CostCategoryResource.get({ id: $state.params.id }).$promise.then(function(data) {
      vm.model = data;
    });
  }

  function update(model) {
    vm.newForm.active = true;

    CostCategoryResource.update(model)
      .$promise.then(function(updated) {
        AlertService.success('Category ' + updated.name + ' has been updated.');
        vm.newForm.active = false;
        vm.newForm.$setPristine();
        vm.model = updated;
      })
      .catch(function(error) {
        AlertService.error('Could not update category, please try again.');
        vm.newForm.active = false;
      });
  }
}
