import ClaimActivitiesController from './activities/activities';
import { CommentModalService } from './activities/commentModal.service';
import EditClaimHomeController from './claim-edit';
import ClaimFormController from './claim-form';
import ClaimInvoicesService from './claim-invoices-service';
import { ClaimManagerService } from './claim-manager-service';
import NewClaimController from './claim-new';
import ClaimOverviewController from './claim-overview';
import ClaimPaymentsService from './claim-payments-service';
import ClaimToolbarController from './claim-toolbar';
import ClaimTypeModalController from './claim-type-modal';
import ClaimTypeSelectModalController from './claim-type-select-modal';
import EmailModalService from './emails/email-modal';
import { ClaimFilesComponent } from './files/claimFiles.component';
import ClaimNewInvoiceController from './invoices/invoice-new';
import ClaimInvoicesController from './invoices/invoices';
import InvoiceNotesService from './invoices/modals/invoice-notes.service';
import InvoicePaymentService from './invoices/modals/invoice-payment.service';
import ClaimNewPaymentController from './payments/payment-new';
import ClaimPaymentsController from './payments/payments';
import ClaimNewSumInsuredController from './sumsInsured/sumInsured-new';
import ClaimSumsInsuredController from './sumsInsured/sumsInsured';
import { ClaimTasksComponent } from './tasks/claimTasks.component';
import ClaimTimesheetController from './timesheets/timesheet';
import { TimesheetModalService } from './timesheets/timesheetModal.service';
import { CoreModule } from '../../core/module';
import { DataModule } from '../../data/module';
import { UIModule } from '../../ui/module';
import { EmailsModule } from '../emails/emails.module';
import { FoldersModule } from '../folders/folders.module';
import { TasksModule } from '../tasks/tasks.module';
import ng from 'angular';

export const ClaimsModule = ng
  .module('app.instance.claims', [
    DataModule.name,
    CoreModule.name,
    UIModule.name,
    FoldersModule.name,
    EmailsModule.name,
    TasksModule.name
  ])

  // Factories
  .factory('ClaimInvoicesService', ClaimInvoicesService)
  .factory('ClaimPaymentsService', ClaimPaymentsService)

  // Services
  .service('ClaimManagerService', ClaimManagerService)
  .service('EmailModalService', EmailModalService)
  .service('CommentModalService', CommentModalService)
  .service('TimesheetModalService', TimesheetModalService)
  .service('InvoicePaymentService', InvoicePaymentService)
  .service('InvoiceNotesService', InvoiceNotesService)

  // Components
  .component('claimTasksComponent', new ClaimTasksComponent())
  .component('claimFilesComponent', new ClaimFilesComponent())

  // Controllers
  .controller('EditClaimHomeController', EditClaimHomeController)
  .controller('ClaimFormController', ClaimFormController)
  .controller('NewClaimController', NewClaimController)
  .controller('ClaimNewSumInsuredController', ClaimNewSumInsuredController)
  .controller('ClaimTimesheetController', ClaimTimesheetController)
  .controller('ClaimActivitiesController', ClaimActivitiesController)
  .controller('ClaimOverviewController', ClaimOverviewController)
  .controller('ClaimToolbarController', ClaimToolbarController)
  .controller('ClaimTypeModalController', ClaimTypeModalController)
  .controller('ClaimTypeSelectModalController', ClaimTypeSelectModalController)
  .controller('ClaimNewPaymentController', ClaimNewPaymentController)
  .controller('ClaimPaymentsController', ClaimPaymentsController)
  .controller('ClaimSumsInsuredController', ClaimSumsInsuredController)
  .controller('ClaimSelectController', ClaimSumsInsuredController)
  .controller('ClaimNewInvoiceController', ClaimNewInvoiceController)
  .controller('ClaimInvoicesController', ClaimInvoicesController);
