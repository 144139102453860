import '../../../core/services/alert';
import '../../../data/resources/tax-code';

/* @ngInject */
export default function TaxCodeListController($scope, TaxCodeResource, AlertService) {
  $scope.models = [];

  TaxCodeResource.query()
    .$promise.then(function(result) {
      $scope.models = result.items;
    })
    .catch(function(error) {
      AlertService.error('Could not load tax codes, please try again.');
    });
}
