import { User } from '../models';
import { IClaimsWindow } from '../../core/utils/claimsWindow';

export interface ITrackingService {
  /**
   * Set the current user to track.
   * @param {User} user The user to start tracking.
   */
  setUser(user: User): void;

  /**
   * Resets the user that is being tracked (used on logout).
   */
  resetUser(): void;

  /**
   * Track the specified event.
   * @param {String} event The counter to decrement.
   * @param {Any} params The count to decrement by.
   */
  track(event: string, params: any): void;

  /**
   * Increment the specified counter.
   * @param {string} counter The counter to decrement.
   * @param {string} count The count to decrement by.
   */
  increment(counter: string, count?: number): void;

  /**
   * Decrements the specified counter.
   * @param {string} counter The counter to decrement.
   * @param {string} count The count to decrement by.
   */
  decrement(counter: string, count?: number): void;
}

export class TrackingService implements ITrackingService {
  static $inject = ['$window'];

  constructor(private window: IClaimsWindow) {}

  hasGA() {
    return !!this.window.ga;
  }

  hasMixpanel() {
    return !!this.window.mixpanel;
  }

  hasAppInsights() {
    return !!window.appInsights;
  }

  setUser(user: User): void {
    try {
      const userTrackingId = user.id + '|' + this.window.location.hostname;

      if (this.hasGA()) {
        this.window.ga('set', 'userId', userTrackingId);
      }

      if (this.hasAppInsights()) {
        window.appInsights.setAuthenticatedUserContext(userTrackingId, undefined, true);
      }

      if (!this.hasMixpanel()) {
        return;
      }

      this.window.mixpanel.identify(userTrackingId);
      this.window.mixpanel.register({
        Instance: this.window.location.hostname,
        Email: user.email
      });
      this.window.mixpanel.people.set({
        $name: user.name,
        $email: user.email
      });
    } catch (e) {
      console.error('Failed to set user: ', e);
    }
  }

  resetUser(): void {
    try {
      if (!this.hasMixpanel()) {
        return;
      }
      this.window.mixpanel.people.reset();
    } catch (e) {
      console.error(`Failed to reset user: `, e);
    }
  }

  track(event: string, params: any) {
    try {
      if (!this.hasMixpanel()) {
        return;
      }
      this.window.mixpanel.track(event, params);
    } catch (e) {
      console.error(`Failed to track event ${event}: `, e);
    }
  }

  increment(counter: string, count: number = 1) {
    try {
      if (!this.hasMixpanel()) {
        return;
      }
      this.window.mixpanel.people.increment(counter, count || 1);
    } catch (e) {
      console.error(`Failed to set increment ${counter}: `, e);
    }
  }

  decrement(counter: string, count: number = 1) {
    try {
      if (!this.hasMixpanel()) {
        return;
      }
      this.window.mixpanel.people.increment(counter, (count || 1) * -1);
    } catch (e) {
      console.error(`Failed to set decrement ${counter}: `, e);
    }
  }
}
