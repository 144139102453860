import * as angular from 'angular';

/**
 * Originally taken from ng-currency and adapted
 *
 * https://github.com/aguirrel/ng-currency/blob/master/src/ng-currency.js
 */

/* @ngInject*/
export function CurrencyDirective(
  $filter: ng.IFilterService,
  $locale: ng.ILocaleService
) {
  return {
    require: 'ngModel',
    scope: {},
    link: function(scope, element, attrs, ngModel) {
      attrs.$observe('min', function(v) {
        scope.min = v;
      });
      attrs.$observe('max', function(v) {
        scope.max = v;
      });
      attrs.$observe('currencySymbol', function(v) {
        scope.currencySymbol = v;
      });
      attrs.$observe('ngRequired', function(v) {
        scope.ngRequired = v;
      });
      attrs.$observe('fraction', function(v) {
        scope.fraction = v;
      });

      scope.fraction = typeof scope.fraction !== 'undefined' ? scope.fraction : 2;

      function decimalRex(dChar) {
        return RegExp('\\d|\\-|\\' + dChar, 'g');
      }

      function clearRex(dChar) {
        return RegExp(
          '\\-{0,1}((\\' +
            dChar +
            ')|([0-9]{1,}\\' +
            dChar +
            '?))&?[0-9]{0,' +
            scope.fraction +
            '}',
          'g'
        );
      }

      function clearValue(value) {
        value = String(value);
        let dSeparator = $locale.NUMBER_FORMATS.DECIMAL_SEP;
        let cleared = null;

        if (
          value.indexOf($locale.NUMBER_FORMATS.DECIMAL_SEP) === -1 &&
          value.indexOf('.') !== -1 &&
          scope.fraction > 0
        ) {
          dSeparator = '.';
        }

        // Replace negative pattern to minus sign (-)
        const negDummy = $filter('currency')(-1, getCurrencySymbol(), scope.fraction);
        const negRegexp = RegExp(
          '[0-9.' +
            $locale.NUMBER_FORMATS.DECIMAL_SEP +
            $locale.NUMBER_FORMATS.GROUP_SEP +
            ']+'
        );
        const negDummyTxt = negDummy.replace(negRegexp.exec(negDummy).toString(), '');
        const valueDummyTxt = value.replace(negRegexp.exec(value), '');

        // If is negative
        if (negDummyTxt === valueDummyTxt) {
          value = '-' + negRegexp.exec(value);
        }

        if (RegExp('^-[\\s]*$', 'g').test(value)) {
          value = '-0';
        }

        if (decimalRex(dSeparator).test(value)) {
          cleared = value
            .match(decimalRex(dSeparator))
            .join('')
            .match(clearRex(dSeparator));
          cleared = cleared ? cleared[0].replace(dSeparator, '.') : null;
        }

        return cleared;
      }

      function getCurrencySymbol() {
        if (angular.isDefined(scope.currencySymbol)) {
          return scope.currencySymbol;
        } else {
          return '';
        }
      }

      function reformatViewValue() {
        const formatters = ngModel.$formatters;
        let idx = formatters.length;

        let viewValue = ngModel.$$rawModelValue;
        while (idx--) {
          viewValue = formatters[idx](viewValue);
        }

        ngModel.$setViewValue(viewValue);
        ngModel.$render();
      }

      ngModel.$parsers.push(function(viewValue) {
        let cVal = clearValue(viewValue);
        // Check for fast digitation (-. or .)
        if (cVal === '.' || cVal === '-.') {
          cVal = '.0';
        }
        return parseFloat(cVal);
      });

      element.on('blur', function() {
        ngModel.$commitViewValue();
        reformatViewValue();
      });

      ngModel.$formatters.unshift(function(value) {
        return $filter('currency')(value, getCurrencySymbol(), scope.fraction);
      });

      ngModel.$validators.min = function(cVal) {
        if (!scope.ngRequired && isNaN(cVal)) {
          return true;
        }
        if (typeof scope.min !== 'undefined') {
          return cVal >= parseFloat(scope.min);
        }
        return true;
      };

      scope.$watch('min', function(val) {
        ngModel.$validate();
      });

      ngModel.$validators.max = function(cVal) {
        if (!scope.ngRequired && isNaN(cVal)) {
          return true;
        }
        if (typeof scope.max !== 'undefined') {
          return cVal <= parseFloat(scope.max);
        }
        return true;
      };

      scope.$watch('max', function(val) {
        ngModel.$validate();
      });

      ngModel.$validators.fraction = function(cVal) {
        if (!!cVal && isNaN(cVal)) {
          return false;
        }

        return true;
      };

      scope.$on('currencyRedraw', function() {
        ngModel.$commitViewValue();
        reformatViewValue();
      });

      element.on('focus', function() {
        let viewValue = ngModel.$$rawModelValue;
        if (isNaN(viewValue) || viewValue === '' || viewValue === null) {
          viewValue = '';
        } else {
          viewValue = parseFloat(viewValue).toFixed(scope.fraction);
        }
        ngModel.$setViewValue(viewValue);
        ngModel.$render();
      });
    }
  };
}
