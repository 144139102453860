import debounce from 'lodash/debounce';
import sortBy from 'lodash/sortBy';

/* @ngInject */
export default function CioEntityListSelect($http): any {
  return {
    restrict: 'AE',
    link: link,
    scope: {
      selectedEntities: '=',
      validTypes: '='
    },
    template:
      '<div class="input-group search"> \
            <span class="input-group-addon"><i class="icon-ic-search"></i></span> \
            <input type="text" class="form-control" ng-model="searchQuery" \
                    ng-change="filterChanged()"\
                    placeholder="Search" autocomplete="off" maxlength="500"/> \
        </div> \
        <ul class="no-padding"> \
            <li><label><input type="checkbox" ng-model="unassignedChecked" ng-change="selectChanged()"/>&nbsp; Unassigned</label></li>\
            <li ng-if="entities.length > 0" class="divider"></li>\
            <li ng-if="entities.length > 0">\
              <label><a href="" ng-click="unassignAllEntities()" title="Clear selected"><i class="icon-ic-ban text-danger"></i></a></label>\
            </li>\
            <li ng-repeat="entity in entities | limitTo:20"> \
                <label> \
                    <input type="checkbox" ng-model="entity.checked" ng-change="selectChanged()"/> \
                    {{entity.title}} \
                </label> \
            </li> \
        </ul>'
  };

  function link(scope, element, attr) {
    scope.types = scope.validTypes || [];
    scope.filterChanged = debounce(filterChanged, 400);
    scope.selectChanged = selectChanged;
    scope.unassignAllEntities = unassignAllEntities;

    scope.entities = [];

    filterChanged();

    function selectChanged() {
      scope.selectedEntities = scope.entities.filter(x => !!x.checked);

      if (scope.unassignedChecked) {
        scope.selectedEntities.push({
          id: '00000000-0000-0000-0000-000000000000',
          name: 'Unassigned',
          title: 'Unassigned',
          checked: true
        });
      }
    }

    function unassignAllEntities() {
      scope.selectedEntities = [];
      (scope.entities || []).forEach(function(opt) {
        opt.checked = false;
      });

      scope.unassignedChecked = false;
    }

    function getFieldValue(
      record: { name: string; values: any[] }[],
      field: string
    ): string {
      const fieldValue = (record || []).find(x => x.name === field);
      if (!fieldValue) {
        return null;
      }

      if ((fieldValue.values || []).length === 0) {
        return null;
      }

      return fieldValue.values[0];
    }

    function filterChanged() {
      if (scope.searchQuery && scope.searchQuery.length) {
        const entityTypes = Array.isArray(scope.types)
          ? scope.types || []
          : scope.types
          ? [scope.types]
          : [];
        const currentEntities = scope.entities.filter(x => !!x.checked);

        let query = scope.searchQuery;
        if (!/\*$/.test(query)) {
          query += '*';
        }

        // TODO: Why are we getting the name and email?

        $http
          .post('/api/v1/entities/search', {
            fields: ['id', 'title', 'name', 'email'],
            types: entityTypes,
            query,
            skip: 0,
            take: 10,
            sort: 'title',
            sortDescending: false
          })
          .then(function(response) {
            const localData = [
              ...response.data.page.items.map(item => {
                return {
                  id: getFieldValue(item, 'id'),
                  name: getFieldValue(item, 'name'),
                  title: getFieldValue(item, 'title'),
                  email: getFieldValue(item, 'email')
                };
              })
            ];

            const sorted = sortBy(localData, 'title');
            // add previous selected items
            (currentEntities || []).forEach(et => {
              sorted.push({
                id: et.id,
                name: et.name,
                title: et.title,
                email: et.email,
                checked: true
              });
            });

            scope.entities.length = 0;
            scope.entities.push.apply(scope.entities, sorted);
          });
      }
    }
  }
}
