import { Guid, ISearchValue } from '../../core/models';

export class SearchRow {
  private readonly _row: ISearchValue[];
  private _templateId: Guid;
  private _id: Guid;
  private _isClosed: any;

  constructor(row: ISearchValue[]) {
    this._row = row;
    this.setRowValues();
  }

  get row(): ISearchValue[] {
    return this._row;
  }

  get templateId(): Guid {
    return this._templateId;
  }

  get id(): Guid {
    return this._id;
  }

  get isClosed(): Guid {
    return this._isClosed;
  }

  getValueByName(name: string) {
    return this._row.find(x => isNameEqual(x.name, name));
  }

  private setRowValues() {
    this.assignFirstValue('id', value => (this._id = new Guid(value)));
    this.assignFirstValue('templateid', value => (this._templateId = new Guid(value)));
    this.assignFirstValue('isclosed', value => (this._isClosed = value));
  }

  private assignFirstValue(name: string, func: (rowValue: any) => any) {
    const value = this.getValueByName(name);
    if (value && value.values.length) {
      func(value.values[0]);
    }
  }
}

function isNameEqual(valueA, valueB) {
  return (valueA || '').toLowerCase() === (valueB || '').toLowerCase();
}
