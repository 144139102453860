import ng from 'angular';

/**
 * @desc Confirms via a pop-up if the user wants to continue before propagating the click event.
 * @example <button cio-confirm="are you sure?" ng-click="go()"></button>
 */
/* @ngInject */
export default function CioConfirm($parse: ng.IParseService): any {
  return {
    priority: 1,
    terminal: true,
    restrict: 'A',
    link: link
  };

  function link(scope: any, element: any, attrs: any) {
    element.prop('ng-click', null).off('click');
    element.bind('click', function(event: any) {
      const message = attrs.cioConfirm || 'Are you sure?';
      if (window.confirm(message) && attrs.ngClick) {
        const callback = $parse(attrs.ngClick);
        callback(scope, { result: true, $event: event });
        scope.$apply(scope.ngClick);
      }
    });
  }
}
