/* @ngInject */
export function ErrorHandlerConfig($provide): void {
  $provide.decorator('$exceptionHandler', handle);
}

/* @ngInject */
function handle($delegate) {
  return function(exception, cause) {
    if (window.appInsights) {
      window.appInsights.trackException(exception, 'unhandled', { cause });
    }
    $delegate(exception, cause);
  };
}
