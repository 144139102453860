import { Tag, Guid } from '../../../core/models';
import { ITagCacheService } from '../../../core/http';

export class TagController {
  static $inject = ['$scope', 'TagCacheService'];

  _id: Guid;
  tag: Tag;

  constructor(private scope: any, private tagCacheService: ITagCacheService) {}

  get id(): Guid {
    return this._id;
  }

  set id(id: Guid) {
    this._id = new Guid(id);
  }

  $onInit() {
    this.tagCacheService
      .getTag(this.id)
      .safeApply(this.scope, tag => {
        this.tag = tag;
      })
      .subscribe();
  }

  useWhiteColor(): any {
    return Tag.useWhiteColor(this.tag.color);
  }
}
