import {
  IActivityHttpService,
  INoteHttpService,
  IStorageFileHttpService
} from '../../../core/http';
import { Activity, Guid, Note } from '../../../core/models';
import { ILogService, ISecurityService } from '../../../core/services';
import { IFileService } from '../../files/file.service';
import { IClaimManagerService } from '../claim-manager-service';
import { StateService } from 'angular-ui-router';
import ng from 'angular';

/* @ngInject */
export default function ClaimActivitiesController(
  $scope: any,
  $state: StateService,
  $stateParams: any,
  LogService: ILogService,
  NoteHttpService: INoteHttpService,
  ActivityHttpService: IActivityHttpService,
  ClaimManagerService: IClaimManagerService,
  SecurityService: ISecurityService,
  $uibModal: ng.ui.bootstrap.IModalService,
  FileService: IFileService,
  StorageFileHttpService: IStorageFileHttpService
): void {
  const vm = this;
  vm.search = '';
  vm.claim = ClaimManagerService.claim();
  vm.claimId = vm.claim.id;
  vm.activities = [];
  vm.starred = [];
  vm.currentUser = SecurityService.getCurrentUser();
  vm.commentModel = {};
  vm.createNote = createNote;
  vm.download = download;
  vm.showTask = showTask;
  vm.addStar = addStar;
  vm.removeStar = removeStar;

  refresh();

  function addStar($event: Event, activity: Activity) {
    $event.preventDefault();
    activity.isStarred = true;
    ActivityHttpService.addStar(activity.id)
      .safeApply($scope, _ => refresh())
      .subscribe();
  }

  function removeStar($event: Event, activity: Activity) {
    $event.preventDefault();
    activity.isStarred = false;
    ActivityHttpService.removeStar(activity.id)
      .safeApply($scope, _ => refresh())
      .subscribe();
  }

  function refresh(): void {
    ActivityHttpService.getStarredClaimActivities(vm.claimId, 1000)
      .safeApply($scope, page => (vm.starred = page.items))
      .subscribe();
    ActivityHttpService.getClaimActivities(vm.claimId, 1000)
      .safeApply($scope, page => (vm.activities = page.items))
      .subscribe();
  }

  function createNote() {
    if ((vm.commentModel.content || '').trim().length === 0) {
      return;
    }

    const note = Object.assign(new Note(), {
      claimId: new Guid(vm.claimId),
      content: vm.commentModel.content
    });

    NoteHttpService.createNote(note)
      .safeApply($scope, applyNoteSaved)
      .subscribe();
  }

  function applyNoteSaved() {
    refresh();
    vm.commentModel = {};
  }

  function download(activity) {
    if (
      !activity.data.fileId ||
      Guid.empty().equals(activity.data.fileId) ||
      fileHasBeenRemoved(activity)
    ) {
      return;
    }
    StorageFileHttpService.getFile(activity.data.fileId).subscribe(
      file => FileService.showFile(file),
      () => LogService.debug('Could not download file')
    );
  }

  function fileHasBeenRemoved(act: any) {
    if (act.type === 'file-added') {
      return vm.starred.find(a => {
        return a.type === 'file-removed' && a.data.fileId === act.data.fileId;
      });
      return vm.activities.find(a => {
        return a.type === 'file-removed' && a.data.fileId === act.data.fileId;
      });
    }
    return false;
  }

  function showTask(taskId) {
    $state.go('app.claim.tasks', { claimId: vm.claimId });
  }
}
