import { WorkflowActionsController } from './workflowActions.controller';
import { ComponentBase } from '../../../../core/utils/componentBase';

export class WorkflowActionsComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      action: '<',
      data: '='
    };
    this.controller = WorkflowActionsController;
    this.template = require('./workflowActions.html');
  }
}
