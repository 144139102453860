import { SumInsured } from '../../core/models';
import * as ng from 'angular';

/* tslint:disable-next-line:no-empty-interface */
export interface ClaimSumsInsuredResource
  extends ng.resource.IResourceClass<ng.resource.IResource<SumInsured>> {}

/* @ngInject */
export default function ClaimSumsInsuredResource(
  $resource: ng.resource.IResourceService
): ClaimSumsInsuredResource {
  return <ClaimSumsInsuredResource>$resource<ng.resource.IResource<SumInsured>>(
    '/api/v1/claims/:claimId/sums-insured',
    { claimId: '@claimId' },
    {
      query: { method: 'GET' }
    }
  );
}
