import { Invoice } from '../../core/models';
import { InvoiceResource } from '../../data/resources/invoice';
import { ClaimInvoiceResource } from '../../data/resources/claimInvoice';

export interface IClaimInvoicesService {
  invoices(): Invoice[];
  activate(claimId): ng.IPromise<any>;
  refresh(claimId): ng.IPromise<any>;
  getInvoice(invoiceId): ng.IPromise<Invoice>;
  update(invoice): void;
  remove(invoice): void;
  save(invoice): void;
}

/* @ngInject */
export default function ClaimInvoicesService(
  $q: ng.IQService,
  // tslint:disable-next-line:no-shadowed-variable
  ClaimInvoiceResource: ClaimInvoiceResource,
  // tslint:disable-next-line:no-shadowed-variable
  InvoiceResource: InvoiceResource
): any {
  const invoices: Invoice[] = [];

  const service = {
    invoices: function() {
      return invoices;
    },

    activate: activate,
    refresh: refresh,
    getInvoice: getInvoice,
    update: update,
    remove: remove,
    save: save
  };

  return service;

  function activate(claimId): ng.IPromise<any> {
    return refresh(claimId);
  }

  function refresh(claimId): ng.IPromise<any> {
    const defer = $q.defer();

    ClaimInvoiceResource.query({ claimId: claimId }).$promise.then(function(
      data: any /* type hack */
    ) {
      invoices.length = 0;
      invoices.push.apply(invoices, data.items);

      defer.resolve({});
    });

    return defer.promise;
  }

  function getInvoice(invoiceId): ng.IPromise<Invoice> {
    return InvoiceResource.get({ id: invoiceId }).$promise;
  }

  function update(invoice): void {
    InvoiceResource.update(invoice).$promise.then(function(saved) {
      return refresh(saved.claimId);
    });
  }

  function remove(invoice): void {
    InvoiceResource.remove({ id: invoice.id }).$promise.then(function() {
      return refresh(invoice.claimId);
    });
  }

  function save(invoice): void {
    InvoiceResource.save(invoice).$promise.then(function(saved) {
      return refresh(saved.claimId);
    });
  }
}
