export function TrixDirective() {
  return {
    restrict: 'A',
    require: 'ngModel',
    scope: {
      trixInitialize: '&',
      trixChange: '&',
      trixSelectionChange: '&',
      trixFocus: '&',
      trixBlur: '&',
      trixFileAccept: '&',
      trixAttachmentAdd: '&',
      trixAttachmentRemove: '&'
    },
    link: function(scope: any, element: any, attrs: any, ngModel: any) {
      const editorElement = element.parent().find('trix-editor');
      const editor = editorElement[0].editor;

      editorElement.on('trix-initialize', function() {
        if (ngModel.$modelValue) {
          editor.loadHTML(ngModel.$modelValue);
        }
      });

      ngModel.$render = function() {
        if (editor) {
          editor.loadHTML(ngModel.$modelValue);
        }

        editorElement.on('trix-change', function() {
          ngModel.$setViewValue(editorElement.html());
        });
      };

      const registerEvents = function(type, method) {
        editorElement[0].addEventListener(type, function(e) {
          if (type === 'trix-file-accept' && attrs.preventTrixFileAccept === 'true') {
            e.preventDefault();
          }

          scope[method]({
            e: e,
            editor: editor
          });
        });
      };

      registerEvents('trix-initialize', 'trixInitialize');
      registerEvents('trix-change', 'trixChange');
      registerEvents('trix-selection-change', 'trixSelectionChange');
      registerEvents('trix-focus', 'trixFocus');
      registerEvents('trix-blur', 'trixBlur');
      registerEvents('trix-file-accept', 'trixFileAccept');
      registerEvents('trix-attachment-add', 'trixAttachmentAdd');
      registerEvents('trix-attachment-remove', 'trixAttachmentRemove');
    }
  };
}
