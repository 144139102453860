import { IClaimManagerService } from './claim-manager-service';
import { Organization } from '../../core/models';
import { IOrganizationHttpService } from '../../core/http';
import { ILogService } from '../../core/services';

/* @ngInject */
export default function EditClaimHomeController(
  ClaimManagerService: IClaimManagerService,
  $state,
  $scope,
  $rootScope,
  ClaimTemplateResource,
  OrganizationHttpService: IOrganizationHttpService,
  LogService: ILogService
): void {
  const vm = this;
  vm.model = ClaimManagerService.claim();
  vm.template = ClaimManagerService.template();
  vm.partialName = $state.current.partialName;
  vm.stateName = $state.current.name;
  vm.claimTypeSettings = {};
  vm.enableSms = false;

  OrganizationHttpService.getOrganization()
    .safeApply(
      $scope,
      organization => setOrganization(organization),
      err => LogService.error('Failed to load organization', err)
    )
    .subscribe();

  ClaimTemplateResource.query({
    id: this.model.templateId
  }).$promise.then((data) => {
    vm.claimTypeSettings = data;
  });

  $rootScope.$on('claim.updated', function(e, claim) {
    vm.model = claim;
  });

  function setOrganization(organization: Organization) {
    if (!organization) {
      return;
    }
    vm.enableSms = (organization.smsSendingNumber || '').length > 0;
  }
}
