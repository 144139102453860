import { ClaimSumsInsuredResource } from '../../../data/resources/claimSumsInsured';
import { PaymentResource } from '../../../data/resources/payment';
import { IClaimManagerService } from '../claim-manager-service';
import reduce from 'lodash/reduce';
import { IOrganizationHttpService } from '../../../core/http';
import { Organization } from '../../../core/models';
import { ILogService } from '../../../core/services';

/* @ngInject */
export default function ClaimSumsInsuredController(
  $scope: any,
  $stateParams: any,
  OrganizationHttpService: IOrganizationHttpService,
  LogService: ILogService,
  ClaimManagerService: IClaimManagerService,
  // tslint:disable-next-line:no-shadowed-variable
  ClaimSumsInsuredResource: ClaimSumsInsuredResource,
  // tslint:disable-next-line:no-shadowed-variable
  PaymentResource: PaymentResource
): void {
  const vm = this;
  const claimId = $stateParams.claimId;

  vm.sums = [];
  vm.payments = [];
  vm.organization = null;
  vm.showPayments = false;
  vm.claim = ClaimManagerService.claim();
  vm.totals = {
    reserve: 0,
    payments: 0,
    outstanding: function() {
      return this.reserve - this.payments;
    }
  };

  vm.getPaymentValue = getPaymentValue;
  vm.getOutstandingReserve = getOutstandingReserve;

  activate();

  function activate() {
    if (claimId) {
      ClaimSumsInsuredResource.query({ claimId: claimId }).$promise.then(function(
        data: any
      ) {
        vm.sums = data.items;
        vm.totals.reserve = reduce(vm.sums.map(x => x.reserve), function(total, reserve) {
          return total + reserve;
        });
      });
    }

    OrganizationHttpService.getOrganization()
      .safeApply(
        $scope,
        organization => setOrganization(organization),
        err => LogService.error('Failed to load organization', err)
      )
      .subscribe();
  }

  function setOrganization(organization: Organization) {
    vm.organization = organization;
    if (!organization.disableSumInsuredPaymentTracking) {
      vm.showPayments = true;
      PaymentResource.query({ claimId: claimId }).$promise.then(function(data: any) {
        vm.payments = data.items;

        (vm.payments || []).forEach(function(p) {
          const items = p.items.filter(x => !!x.sumInsuredId);

          if (items.length) {
            const itemsTotal = reduce(items.map(x => x.total), function(total, payTotal) {
              return total + payTotal;
            });

            vm.totals.payments += itemsTotal;
          }
        });
      });
    }
  }

  function getPaymentValue(sumInsured) {
    let grandTotal = 0;

    (vm.payments || []).forEach(function(p) {
      const items = p.items.filter(i => i.sumInsuredId === sumInsured.id);
      if (items.length) {
        const itemsTotal = reduce(items.map(x => x.total), function(
          total: any,
          payTotal: any
        ) {
          return total + payTotal;
        });

        grandTotal += itemsTotal;
      }
    });

    return grandTotal;
  }

  function getOutstandingReserve(sumInsured) {
    const paymentValue = getPaymentValue(sumInsured);

    return sumInsured.reserve - paymentValue;
  }
}
