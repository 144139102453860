import { IAlertService } from '../../../core/services/alert';
import '../../../data/resources/tax-code';
import '../../../data/resources/costCategory';

/* @ngInject */
export default function CostCategoryNewController(
  CostCategoryResource,
  TaxCodeResource,
  AlertService: IAlertService,
  $state
): void {
  const vm = this;
  vm.model = {};
  vm.newForm = {};
  vm.newForm.active = false;
  vm.create = create;

  init();

  function init() {
    TaxCodeResource.query()
      .$promise.then(function(result) {
        vm.taxCodes = result.items;
      })
      .catch(function(error) {
        AlertService.error('Could not load tax codes, please try again.');
      });
  }

  function create(model) {
    vm.newForm.active = true;

    CostCategoryResource.save(model)
      .$promise.then(function(updated) {
        AlertService.success('New category ' + updated.name + ' has been created.');
        vm.newForm.active = false;
        vm.newForm.$setPristine();

        $state.go('app.costCategory.edit', { id: updated.id });
      })
      .catch(function(error) {
        AlertService.error('Could not create category, please try again.');
        vm.newForm.active = false;
      });
  }
}
