import { IResourceStore, IResourceStoreFactory } from './store';
import { UserSettings } from '../../core/models';

/* tslint:disable-next-line:no-empty-interface */
export interface IUserSettingsStore extends IResourceStore<UserSettings> {
  byUserId(userId: string): UserSettings;
}

/* @ngInject */
export default function UserSettingsStore(
  StoreFactory: IResourceStoreFactory<UserSettings>
): IUserSettingsStore {
  const store = StoreFactory('/api/v1/user-settings');

  store.withStoreExtension('byUserId', function(userId: string) {
    return StoreFactory('/api/v1/user-settings').get(userId);
  });

  return <IUserSettingsStore>store;
}
