import { Guid } from '../../../core/models';
import { IGravatarService } from '../../../core/services';
import { ICacheService } from '../../services/cache';
// import '../../settings/users/users';

interface ICioUserController {
  user: any;
  updateDetails(id: string | Guid): void;
  showUser(user: any): void;
  selectUser(scope: any): void;
  showUnassigned(): void;
}

class LocalUser {
  name: string;
  avatar: string;
}

export default class CioUserController implements ICioUserController {
  static $inject = ['$scope', 'GravatarService', 'UserSelectService', 'Cache'];

  public user: LocalUser;
  public id: string;
  public type: string;
  public mode: string;
  public group: string;
  public emptyText: string;
  public displayRemove: boolean;
  public show: boolean;
  public selectChange: any;
  public removeClick: any;
  private watcher: any;

  constructor(
    private scope: any,
    private gravatarService: IGravatarService,
    private userSelectService: any,
    private cache: ICacheService
  ) {
    this.user = new LocalUser();
  }

  $onInit(): void {
    this.updateDetails(this.id);
    const that = this;
    this.watcher = this.scope.$watch(
      () => that.id,
      id => {
        that.updateDetails(id);
      }
    );
  }

  $onDestroy() {
    if (this.watcher) {
      this.watcher();
    }
  }

  updateDetails(id: string | Guid): void {
    if (!Guid.isValid(id)) {
      this.showUnassigned();
      return;
    }
    const guid = new Guid(id);
    if (Guid.empty().equals(guid)) {
      this.showUnassigned();
      return;
    }
    this.cache
      .user(guid.toString())
      .then(user => {
        this.showUser(user);
      })
      .catch(error => {
        console.error(error);
        this.showUnassigned();
      });
  }

  showUser(user: any): void {
    this.user.name = user.name;
    this.user.avatar = this.gravatarService.forEmail(user.email, 24);
  }

  showUnassigned(): void {
    this.user.name = this.emptyText || 'Unassigned';
    this.user.avatar = this.gravatarService.forUnknown(24);
  }

  removeUser(): void {
    if (this.removeClick) {
      this.removeClick({ id: this.id });
    }
  }

  selectUser(): void {
    this.userSelectService
      .open({
        title: 'Select a user',
        group: this.group
      })
      .then(user => {
        this.id = user.id;

        if (this.show) {
          this.showUser(user);
        }

        if (this.selectChange) {
          this.selectChange({ user: user });
        }
      })
      .catch(() => {});
  }
}
