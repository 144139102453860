import { IDateService } from '../../../core/services';
import { DateTimeHelper } from '../../../core/utils';
import { DateTime } from 'luxon';
import ng from 'angular';

const DEFAULT_MODE = 'datetime';

export class DateTimeViewController {
  static $inject = ['$timeout', 'DateService'];

  title: string;
  text: string;
  private _mode: string;
  private _dateTime: DateTime;
  private _timer: ng.IPromise<void>;

  constructor(
    private timeoutService: ng.ITimeoutService,
    private dateService: IDateService
  ) {}

  $onChanges(changes) {
    this.tick();
  }

  $destroy() {
    this.cancelTimer();
  }

  get dateTime(): DateTime | undefined {
    return this._dateTime;
  }

  set dateTime(value: DateTime | undefined) {
    this._dateTime = DateTimeHelper.parseUtcDateTime(value);
  }

  get mode(): string {
    return this._mode || DEFAULT_MODE;
  }

  set mode(value: string) {
    this._mode = (value || '').toLowerCase();
  }

  tick() {
    this.cancelTimer();
    this.setText();
    this.startTimer();
  }

  cancelTimer() {
    if (this._timer) {
      this.timeoutService.cancel(this._timer);
    }
  }

  startTimer() {
    if (!this._dateTime || this.mode !== 'friendly') {
      return;
    }
    const minutesAgo = this._dateTime.diffNow('minutes').minutes;
    let secondsUntilUpdate = 3600;
    if (minutesAgo < 1) {
      secondsUntilUpdate = 10;
    } else if (minutesAgo < 60) {
      secondsUntilUpdate = 60;
    } else if (minutesAgo < 180) {
      secondsUntilUpdate = 300;
    }
    this._timer = this.timeoutService(() => this.tick(), secondsUntilUpdate * 1000);
  }

  setText() {
    if (!this._dateTime) {
      this.text = '';
      this.title = '';
      return;
    }
    this.title = this._dateTime.toLocal().toFormat('DDDD t ZZZZ');
    switch (this.mode) {
      case 'friendly':
        this.text = this.dateService.relative(this._dateTime);
        break;
      case 'date':
        this.text = this.dateService.formatLocalDate(this._dateTime);
        break;
      case 'time':
        this.text = this.dateService.formatLocalTime(this._dateTime);
        break;
      default:
        this.text = this.dateService.formatLocalDateTime(this._dateTime);
        break;
    }
  }
}
