import { SearchChangeModalController } from './searchChangeModal.controller';
import { SavedSearch } from '../../core/models';
import * as angular from 'angular';

export interface ISearchChangeModalService {
  select(cb: (search: SavedSearch) => any);
}

export class SearchChangeModalService implements ISearchChangeModalService {
  static $inject = ['$uibModal'];

  constructor(private modalService: ng.ui.bootstrap.IModalService) {}

  select(cb: (search: SavedSearch) => any) {
    return this.modalService
      .open({
        controller: SearchChangeModalController,
        controllerAs: '$ctrl',
        template: require('./searchChangeModal.html')
      })
      .result.then(selected => {
        cb(selected);
      });
  }
}
