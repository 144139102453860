import * as ng from 'angular';

/**
 * Public information about an instance.
 */
export type InstanceInfo = {
  /**
   * The name of the instance.
   *
   * @type {string}
   */
  name: string;

  /**
   * The path of the instance logo, if any.
   *
   * @type {string}
   */
  logoPath: string;
};

export interface IInstanceService {
  /**
   * Returns the intance information for the given domain. If no
   * domain is specified, then the current hostname will be used
   * to determine the instance information.
   *
   * @param {string} domain
   * @returns {ng.IPromise<InstanceInfo>}
   *
   * @memberOf IInstanceService
   */
  getInfo(domain?: string): ng.IPromise<InstanceInfo>;
}

/* @ngInject */
export default function InstanceService($q: ng.IQService, $http: ng.IHttpService): any {
  return {
    getInfo: getInfo
  };

  function getInfo(domain: string) {
    domain = domain || window.location.hostname;

    const deferred = $q.defer();

    $http({
      method: 'GET',
      url: `/api/v1/tenants/${domain}/info`
    })
      .then(onSuccess)
      .catch(onFailure);

    return deferred.promise;

    /**
     * Handles a successful login.
     */
    function onSuccess(response) {
      deferred.resolve(response.data);
    }

    /**
     * Handles a failed login.
     */
    function onFailure(err) {
      deferred.reject(err);
    }
  }
}
