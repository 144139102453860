import { Guid } from './guid';

export enum SettingScopes {
  Organization = 'Organization',
  User = 'User'
}

export class UserSetting {
  name: string = '';
  value: string = '';
  userId: Guid;
  readonly scope: SettingScopes = SettingScopes.User;

  constructor(props: any = {}) {
    Object.assign(this, props);
    this.userId = props.userId ? new Guid(props.userId) : Guid.empty();
  }
}

export class OrganizationSetting {
  name: string = '';
  value: string = '';
  readonly scope: SettingScopes = SettingScopes.Organization;

  constructor(props: any = {}) {
    Object.assign(this, props);
  }
}

export class EffectiveSetting {
  name: string = '';
  value: string = '';
  isUserSetting: boolean = false;

  constructor(props: any = {}) {
    Object.assign(this, props);
  }
}

export const SettingKeys = Object.freeze({
  Files: Object.freeze({
    LastView: 'Files:LastView'
  })
});

export const KnownSettingValues = Object.freeze({
  Files: Object.freeze({
    ListView: 'List',
    GridView: 'Grid'
  })
});
