import { IDocumentTemplateSelection } from './document.interfaces';
import { IDocumentHttpService } from '../../core/http';
import {
  DocumentTemplate,
  Guid,
  ITemplateRequirementValue,
  Page,
  TemplateRequirementTypes
} from '../../core/models';
import { IEntityService } from '../entities/entity.service';
import ng from 'angular';

const PAGE_SIZE: number = 100;

export class DocumentSelectModalController {
  static $inject = [
    '$scope',
    'values',
    '$uibModalInstance',
    'DocumentHttpService',
    'EntityService'
  ];

  searching: boolean = false;
  pageNumber: number = 1;
  currentPage: Page<DocumentTemplate> = new Page<DocumentTemplate>();

  constructor(
    private scope: any,
    private values: ITemplateRequirementValue[] = [],
    private modalInstance: ng.ui.bootstrap.IModalServiceInstance,
    private documentHttpService: IDocumentHttpService,
    private entityService: IEntityService
  ) {}

  $onInit() {
    this.refresh();
  }

  async select(documentTemplate: DocumentTemplate): Promise<void> {
    if (documentTemplate.properties.requirements.length <= 0) {
      this.modalInstance.close({
        document: documentTemplate,
        values: this.values
      } as IDocumentTemplateSelection);
      return;
    }
    const values = this.buildValues(documentTemplate);
    this.fulfillRequirements(documentTemplate, values)
      .then(result => {
        if (!result.success) {
          return;
        }
        this.modalInstance.close({
          document: documentTemplate,
          values: result.values
        } as IDocumentTemplateSelection);
      })
      .catch(() => {});
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }

  private buildValues(documentTemplate: DocumentTemplate): ITemplateRequirementValue[] {
    const values = documentTemplate.properties.requirements.map(x => x.toValue());
    for (const value of this.values) {
      if (!value.recordId) {
        continue;
      }
      const found = values.find(x => isValueMatch(x, value));
      if (found) {
        found.recordId = value.recordId;
      } else {
        values.push(value);
      }
    }
    return values;
  }

  private async fulfillRequirements(
    documentTemplate: DocumentTemplate,
    values: ITemplateRequirementValue[]
  ): Promise<{ success: boolean; values: ITemplateRequirementValue[] }> {
    for (const value of values) {
      if (value.type !== TemplateRequirementTypes.Entity) {
        continue;
      }
      const requirement = documentTemplate.properties.requirements.find(
        x => x.field === value.field
      );
      if (!requirement || !requirement.templateId) {
        continue;
      }
      const entityId = await this.entityService.selectEntity(
        [requirement.templateId],
        true
      );
      if (!entityId && !Guid.isEmpty(entityId)) {
        return { success: false, values: [] };
      }
      value.recordId = entityId;
    }
    return { success: true, values };
  }

  private setIsBusy(): void {
    this.searching = true;
  }

  private setNotBusy(): void {
    this.searching = false;
  }

  private refresh() {
    this.loadPage(1);
  }

  private loadPage(pageNumber: number) {
    const skip = (pageNumber - 1) * PAGE_SIZE;
    const take = PAGE_SIZE;
    this.setIsBusy();
    this.documentHttpService
      .getTemplateFiles(take, skip)
      .safeApply(this.scope, page => {
        this.pageNumber = pageNumber;
        this.currentPage = page;
        this.setNotBusy();
      })
      .subscribe();
  }
}

function isValueMatch(
  valueA: ITemplateRequirementValue,
  valueB: ITemplateRequirementValue
) {
  return (
    (valueA.field || '').toLowerCase() === (valueB.field || '').toLowerCase() &&
    valueA.type === valueB.type
  );
}
