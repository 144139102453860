export class UserListReportFieldController {
  static $inject = [];

  private _users: string[];

  get users(): string[] {
    return this._users || [];
  }

  set users(value: string[]) {
    this._users = value;
  }
}
