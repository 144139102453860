import { TemplateLoaderDirective } from './templateLoader.directive';

export function TemplateLoaderDirectiveFactory(): ng.IDirectiveFactory {
  const directive = ($compile: any) => {
    return new TemplateLoaderDirective($compile);
  };

  directive.$inject = ['$compile'];
  return directive;
}
