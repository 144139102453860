import { TaskDueDateEditController } from './taskDueDateEdit.controller';
import { ComponentBase } from '../../core/utils/componentBase';

export class TaskDueDateEditComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      task: '<',
      onChange: '&'
    };
    this.controller = TaskDueDateEditController;
    this.template = require('./taskDueDateEdit.html');
  }
}
