import {
  INotificationHttpService,
  NotificationSearch,
  NotificationSearchStatues
} from '../../core/http';
import { Notification, Page } from '../../core/models';

const DEFAULT_PAGE_SIZE: number = 25;

export class NotificationTableController {
  static $inject = ['$scope', 'NotificationHttpService'];

  searching: boolean = false;
  pageNumber: number = 1;
  status: NotificationSearchStatues = NotificationSearchStatues.Unread;
  search: NotificationSearch = new NotificationSearch();
  currentPage: Page<Notification> = Page.empty();
  onSelect: ({ notification: Notification }) => any;
  private _pageSize: number;

  constructor(
    private scope: any,
    private notificationHttpService: INotificationHttpService
  ) {}

  get pageSize(): number {
    return this._pageSize || DEFAULT_PAGE_SIZE;
  }

  set pageSize(value: number) {
    this._pageSize = value;
  }

  $onInit() {
    this.refresh();
  }

  $onChanges(changes: any) {
    if (
      changes.status &&
      changes.status.currentValue &&
      changes.status.currentValue !== this.status
    ) {
      this.refresh();
    }
  }

  select(notification: Notification) {
    this.onSelect({ notification });
  }

  pageChanged(pageNumber) {
    this.loadPage(pageNumber);
  }

  private refresh() {
    this.loadPage(1);
  }

  private loadPage(pageNumber: number) {
    this.search.skip = (pageNumber - 1) * this.pageSize;
    this.search.take = this.pageSize;
    this.search.status = this.status;

    this.notificationHttpService
      .findNotifications(this.search)
      .safeApply(this.scope, page => {
        this.pageNumber = pageNumber;
        this.currentPage = page;
      })
      .subscribe();
  }
}
