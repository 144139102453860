import {
  Workflow,
  WORKFLOW_ACTIONS,
  WORKFLOW_TRIGGERS,
  WorkflowSearch,
  Page,
  WorkflowActions,
  WorkflowTriggers
} from '../../../core/models';
import { StateService } from 'angular-ui-router';
import { IWorkflowHttpService } from '../../../core/http/workflow';

const DEFAULT_SORT: string = 'created';
const DEFAULT_PAGE_SIZE: number = 50;

export class WorkflowListController {
  static $inject = ['$scope', '$state', 'WorkflowHttpService'];

  busy: boolean = false;
  sort: string = undefined;
  sortDescending: boolean = false;
  pageNumber: number = 1;
  currentPage: Page<Workflow> = Page.empty();

  constructor(
    private scope: any,
    private stateService: StateService,
    private workflowHttpService: IWorkflowHttpService
  ) {}

  $onInit() {
    this.loadPage(1);
  }

  newWorkflow() {
    this.stateService.go('app.workflow.new');
  }

  editWorkflow(workflow: Workflow) {
    this.stateService.go('app.workflow.edit', { id: workflow.id.toString() });
  }

  getActionName(actionId: WorkflowActions): string {
    const action = WORKFLOW_ACTIONS.find(x => x.id === actionId);
    if (!action) {
      return 'Not set';
    }
    return action.name;
  }

  getTriggerName(triggerId: WorkflowTriggers): string {
    const trigger = WORKFLOW_TRIGGERS.find(x => x.id === triggerId);
    if (!trigger) {
      return 'Not set';
    }
    return trigger.name;
  }

  pageChanged(pageNumber: number): void {
    this.loadPage(pageNumber);
  }

  private setIsBusy(): void {
    this.busy = true;
  }

  private setNotBusy(): void {
    this.busy = false;
  }

  private loadPage(pageNumber: number) {
    this.setIsBusy();
    this.workflowHttpService
      .findWorkflows(this.createSearch(pageNumber))
      .safeApply(this.scope, results => {
        this.setResults(results);
        this.setNotBusy();
      })
      .subscribe();
  }

  private createSearch(pageNumber: number): WorkflowSearch {
    const search = new WorkflowSearch();
    search.sort = this.sort || DEFAULT_SORT;
    search.sortDescending = this.sortDescending;
    search.skip = (pageNumber - 1) * DEFAULT_PAGE_SIZE;
    search.take = DEFAULT_PAGE_SIZE;
    return search;
  }

  private setResults(page: Page<Workflow>): void {
    this.currentPage = page;
    this.pageNumber = page.page;
  }
}
