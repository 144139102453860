import { IAlertService } from '../../../core/services';
import { SumInsuredResource } from '../../../data/resources/sum-insured';
import { SumInsuredCategoryResource } from '../../../data/resources/sum-insured-category';
import { IClaimManagerService } from '../claim-manager-service';
import { StateService } from 'angular-ui-router';
import { SumInsured } from 'app/core/models';

/* @ngInject */
export default function ClaimNewSumInsuredController(
  $stateParams: any,
  $state: StateService,
  ClaimManagerService: IClaimManagerService,
  AlertService: IAlertService,
  // tslint:disable-next-line:no-shadowed-variable
  SumInsuredResource: SumInsuredResource,
  // tslint:disable-next-line:no-shadowed-variable
  SumInsuredCategoryResource: SumInsuredCategoryResource
): void {
  const claimId = $stateParams.claimId;
  const sumInsuredId = $stateParams.sumInsuredId;

  const vm = this;
  vm.claim = ClaimManagerService.claim();
  vm.categories = [];
  vm.sum = createNewSumInsured();
  vm.removeItem = removeItem;
  vm.saveSum = saveSum;
  vm.deleteSum = deleteSum;

  activate();

  function activate() {
    if (sumInsuredId) {
      SumInsuredResource.get({ id: sumInsuredId }).$promise.then(function(data) {
        vm.sum = data;
      });
    }

    SumInsuredCategoryResource.query().$promise.then(function(result) {
      vm.categories = (result as any).items.filter(item => {
        return !item.claimTypeId || item.claimTypeId === vm.claim.templateId;
      });

      // if only one category, then default select it
      if (vm.categories.length === 1) {
        vm.sum.categoryId = vm.categories[0].id;
      }
    });
  }

  function removeItem(index) {
    vm.invoice.items.splice(index, 1);
  }

  function deleteSum() {
    SumInsuredResource.delete({ id: vm.sum.id })
      .$promise.then(function() {
        AlertService.success('Sum Insured deleted successfully.');
        vm.newForm.$setPristine();
        $state.go('app.claim.sumsinsured.list', { claimId: claimId });
      })
      .catch(function(error) {
        if (error.data && error.data.message) {
          AlertService.error(error.data.message);
        } else {
          AlertService.error('Could not delete sum insured, please try again.');
        }
      });
  }

  function saveSum() {
    vm.sum.claimId = claimId;
    if (!vm.sum.id) {
      SumInsuredResource.save(vm.sum as SumInsured)
        .$promise.then(saveSumSuccess)
        .catch(saveSumError);
    } else {
      SumInsuredResource.update(vm.sum as SumInsured)
        .$promise.then(saveSumSuccess)
        .catch(saveSumError);
    }
  }

  function saveSumSuccess() {
    AlertService.success('Sum Insured saved successfully.');
    vm.newForm.$setPristine();
    $state.go('app.claim.sumsinsured.list', { claimId: claimId });
  }

  function saveSumError(error: any) {
    if (error.data && error.data.message) {
      AlertService.error(error.data.message);
    } else {
      AlertService.error('Could not create sum insured, please try again.');
    }
  }
}

function createNewSumInsured() {
  return {
    value: 0,
    excess: 0,
    reserve: 0
  };
}
