import { ReferenceEditModalController } from './referenceEditModal.controller';
import * as ng from 'angular';

export interface IReferenceChangedEvent {
  (changes: { reference: string }): any;
}

export class ReferenceViewController {
  static $inject = ['$uibModal'];

  reference: string;
  canEdit: boolean = false;
  onChange: IReferenceChangedEvent;

  constructor(private modalService: ng.ui.bootstrap.IModalService) {}

  get referenceOrDefault(): string {
    return this.reference || 'No reference';
  }

  edit() {
    if (!this.canEdit) {
      return;
    }
    this.modalService
      .open({
        template: require('./referenceEditModal.html'),
        controller: ReferenceEditModalController,
        controllerAs: '$ctrl',
        size: 'sm',
        resolve: {
          reference: () => {
            return this.reference;
          }
        }
      })
      .result.then(updated => {
        if (!updated) {
          return;
        }
        this.updateRecord(updated);
      })
      .catch(() => {});
  }

  private updateRecord(updated) {
    this.onChange({
      reference: updated
    });
  }
}
