import ReportNewController from './report-new.controller';

export default class ReportListComponent implements ng.IComponentOptions {
  public controller: any;
  public template: string;
  public bindings: any;

  constructor() {
    this.bindings = {
      onCreate: '&'
    };
    this.controller = ReportNewController;
    this.template = require('./report-new.html');
  }
}
