/* @ngInject */
export default function CioCreateClaim(): any {
  return {
    priority: 1,
    terminal: true,
    restrict: 'A',
    link: link,
    controller: 'ClaimTypeModalController'
  };

  function link(scope, element, attrs) {
    element.bind('click', function(e) {
      e.preventDefault();
      scope.select();
    });
  }
}
