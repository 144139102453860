import { WorkflowFilterController } from './workflowFilter.controller';
import { ComponentBase } from '../../../../core/utils/componentBase';

export class WorkflowFilterComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      filter: '=',
      fields: '<',
      onDelete: '&'
    };
    this.controller = WorkflowFilterController;
    this.template = require('./workflowFilter.html');
  }
}
