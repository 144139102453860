import { ListPagerController } from './listPager.controller';
import { ComponentBase } from '../../../core/utils/componentBase';

export class ListPagerComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      pageNumber: '<',
      currentPage: '<',
      onPageChange: '&'
    };
    this.controller = ListPagerController;
    this.template = require('./listPager.html');
  }
}
