import { IHookRegistry, RejectType, StateService } from 'angular-ui-router';

/* @ngInject */
export function StateErrorHandler(
  $transitions: IHookRegistry,
  $state: StateService
): void {
  $state.defaultErrorHandler(function($error$) {
    if (!$error$) {
      return;
    }
    if (
      $error$.type === RejectType.ABORTED ||
      $error$.type === RejectType.SUPERSEDED ||
      $error$.type === RejectType.IGNORED
    ) {
      return;
    }
    if ($error$ instanceof Error && $error$.stack) {
      console.error($error$);
      console.error($error$.stack);
    } else {
      console.error($error$);
    }
  });
}
