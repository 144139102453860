export class TextFieldComponent implements ng.IComponentOptions {
  public controller: any;
  public template: string;
  public bindings: any;

  constructor() {
    this.bindings = {
      definition: '<',
      field: '=',
      templateDefinition: '<',
      templateFields: '<',
      formDisabled: '<',
      sections: '<'
    };
    this.template = require('./textField.html');
  }
}
