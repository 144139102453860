import * as ng from 'angular';

export abstract class ComponentBase implements ng.IComponentOptions {
  /**
   * Controller constructor function that should be associated with newly created scope or the name of a registered
   * controller if passed as a string. Empty function by default.
   * Use the array form to define dependencies (necessary if strictDi is enabled and you require dependency injection)
   */
  controller?: string | ng.Injectable<ng.IControllerConstructor>;
  /**
   * An identifier name for a reference to the controller. If present, the controller will be published to its scope under
   * the specified name. If not present, this will default to '$ctrl'.
   */
  controllerAs?: string;
  /**
   * html template as a string or a function that returns an html template as a string which should be used as the
   * contents of this component. Empty string by default.
   * If template is a function, then it is injected with the following locals:
   * $element - Current element
   * $attrs - Current attributes object for the element
   * Use the array form to define dependencies (necessary if strictDi is enabled and you require dependency injection)
   */
  template?: string | ng.Injectable<(...args: any[]) => string>;
  /**
   * Path or function that returns a path to an html template that should be used as the contents of this component.
   * If templateUrl is a function, then it is injected with the following locals:
   * $element - Current element
   * $attrs - Current attributes object for the element
   * Use the array form to define dependencies (necessary if strictDi is enabled and you require dependency injection)
   */
  templateUrl?: string | ng.Injectable<(...args: any[]) => string>;
  /**
   * Define DOM attribute binding to component properties. Component properties are always bound to the component
   * controller and not to the scope.
   */
  bindings?: { [boundProperty: string]: string } = {};
  /**
   * Whether transclusion is enabled. Disabled by default.
   */
  transclude?: boolean | { [slot: string]: string };
  /**
   * Requires the controllers of other directives and binds them to this component's controller.
   * The object keys specify the property names under which the required controllers (object values) will be bound.
   * Note that the required controllers will not be available during the instantiation of the controller,
   * but they are guaranteed to be available just before the $onInit method is executed!
   */
  require?: { [controller: string]: string };
}
