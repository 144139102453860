/* @ngInject */
export default function CioDropdownToggle(): any {
  return {
    require: '?^cioDropdown',
    link: link
  };

  function link(scope, element, attrs, dropdownCtrl) {
    if (!dropdownCtrl) {
      return;
    }

    dropdownCtrl.toggleElement = element;

    const toggleDropdown = function(event) {
      event.preventDefault();
      if (!element.hasClass('disabled') && !attrs.disabled) {
        scope.$apply(function() {
          dropdownCtrl.toggle();
        });
      }
    };

    element.bind('click', toggleDropdown);

    // WAI-ARIA
    element.attr({ 'aria-haspopup': true, 'aria-expanded': false });
    scope.$watch(dropdownCtrl.isOpen, function(isOpen) {
      element.attr('aria-expanded', !!isOpen);
    });

    scope.$on('$destroy', function() {
      element.unbind('click', toggleDropdown);
    });
  }
}
