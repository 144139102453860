import {
  IWorkflowFilter,
  EntityContactFieldDefinition,
  Guid,
  FieldDefinition,
  EntityFieldDefinition,
  Contact
} from '../../../../core/models';
import { IContactHttpService, ContactSearch } from '../../../../core/http';

interface IContactItem {
  value: string;
  label: string;
}

export class WorkflowFilterEntityContactController {
  static $inject = ['$scope', 'ContactHttpService'];

  filter: IWorkflowFilter;
  fields: FieldDefinition[] = [];
  contacts: IContactItem[] = [];
  private _field: EntityContactFieldDefinition;

  constructor(private scope: any, private contactHttpService: IContactHttpService) {}

  get field(): EntityContactFieldDefinition {
    return this._field;
  }

  set field(value: EntityContactFieldDefinition) {
    this._field = value;
    if (!this._field || !this._field.settings || !this._field.settings.entityFieldId) {
      this.contacts = [];
    } else {
      this.loadContacts(new Guid(this._field.settings.entityFieldId));
    }
  }

  private loadContacts(entityFieldId: Guid) {
    const entityField = this.fields.find(x =>
      entityFieldId.equals(x.id)
    ) as EntityFieldDefinition;
    if (!entityField || !entityField.settings || !entityField.settings.entityTypeId) {
      this.contacts = [];
      return;
    }
    const search = new ContactSearch();
    search.entityTypeId = new Guid(entityField.settings.entityTypeId);
    search.take = 1000;
    this.contactHttpService
      .search(search)
      .safeApply(this.scope, page => this.setContacts(page.items))
      .subscribe();
  }

  private setContacts(contacts: Contact[]) {
    this.contacts = contacts.map(
      x => ({ value: x.id.toString(), label: x.name } as IContactItem)
    );
  }
}
