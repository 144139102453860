import { IHookRegistry, Transition } from 'angular-ui-router';

/* @ngInject */
export function TitleHandler($rootScope: any, $transitions: IHookRegistry): void {
  const DEFAULT_TITLE = 'Claims';

  // register the default title
  $rootScope.pageTitle = DEFAULT_TITLE;

  /**
   * Sets the page title.
   * @param {string} pageTitle The new title for the page.
   */
  $rootScope.setPageTitle = setPageTitle;

  // watch for successful state transitions
  $transitions.onSuccess({ to: '*' }, function($transition$: Transition) {
    // The title should only be set by data.title now, this first
    // check is for legacy only

    const toState = $transition$.to();
    if (toState.data.title) {
      setPageTitle(toState.data.title);
    } else {
      setPageTitle(DEFAULT_TITLE);
    }
  });

  function setPageTitle(pageTitle: string): void {
    $rootScope.pageTitle = pageTitle;
  }
}
