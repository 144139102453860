import { ComponentBase } from '../../core/utils';
import { SearchController } from './search.controller';

export class SearchComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      entityId: '<'
    };
    this.controller = SearchController;
    this.template = require('./search.html');
  }
}
