import { IAlertService, ILogService, ISecurityService } from '../../../core/services';
import { IUserDelegationStore } from '../../../data/stores/userDelegation';
import { StateService } from 'angular-ui-router';

/* @ngInject */
export default function DelegationNewController(
  $scope: any,
  $state: StateService,
  LogService: ILogService,
  UserDelegationStore: IUserDelegationStore,
  SecurityService: ISecurityService,
  AlertService: IAlertService
) {
  $scope.delegationForm = {};
  $scope.delegationForm.active = false;
  $scope.dateFormat = SecurityService.getDateFormat();

  $scope.delegation = {
    startDate: new Date().toISOString()
  };

  $scope.saveDelegation = function() {
    $scope.delegationForm.active = true;

    UserDelegationStore.save($scope.delegation)
      .then(function(updated) {
        AlertService.success('New delegation has been created.');
        $scope.delegationForm.active = false;
        $scope.delegationForm.$setPristine();

        $state.go('app.delegation.edit', { id: updated.id });
      })
      .catch(function(error) {
        AlertService.error('Could not create delegation, please try again.');
        $scope.delegationForm.active = false;
      });
  };
}
