import { HttpService } from './http';
import { Activity, Guid, Page } from '../models';
import { logError } from '../utils';
import { ITrackingService } from '../services';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';

export interface IActivityHttpService {
  getClaimActivities(
    claimId: Guid,
    take?: number,
    skip?: number
  ): Observable<Page<Activity>>;
  getStarredClaimActivities(
    claimId: Guid,
    take?: number,
    skip?: number
  ): Observable<Page<Activity>>;
  addStar(activityId: Guid): Observable<Activity>;
  removeStar(activityId: Guid): Observable<Activity>;
}

export class ActivityHttpService implements IActivityHttpService {
  static $inject = ['$window', 'HttpService', 'TrackingService'];

  private readonly _url: string = '/api/v1/activities';

  constructor(
    private window: any,
    private httpService: HttpService,
    private trackingService: ITrackingService
  ) {}

  getClaimActivities(
    claimId: Guid,
    take: number = 50,
    skip: number = 0
  ): Observable<Page<Activity>> {
    const url = `${
      this._url
    }?claim=${claimId.toString()}&starred=false&take=${take}&skip=${skip}`;
    return this.httpService
      .get(url)
      .map(res => Page.fromJson(res.json(), item => Activity.fromJson(item)))
      .catch(logError);
  }

  getStarredClaimActivities(
    claimId: Guid,
    take: number = 50,
    skip: number = 0
  ): Observable<Page<Activity>> {
    const url = `${
      this._url
    }?claim=${claimId.toString()}&starred=true&take=${take}&skip=${skip}`;
    return this.httpService
      .get(url)
      .map(res => Page.fromJson(res.json(), item => Activity.fromJson(item)))
      .catch(logError);
  }

  addStar(activityId: Guid): Observable<Activity> {
    const url = `${this._url}/${activityId.toString()}/add-star`;
    return this.httpService
      .put(url, JSON.stringify({}))
      .map(res => Activity.fromJson(res.json()))
      .do(a => this.trackActivityStarred(a, true))
      .catch(logError);
  }

  removeStar(activityId: Guid): Observable<Activity> {
    const url = `${this._url}/${activityId.toString()}/remove-star`;
    return this.httpService
      .put(url, JSON.stringify({}))
      .map(res => Activity.fromJson(res.json()))
      .do(a => this.trackActivityStarred(a, false))
      .catch(logError);
  }

  private trackActivityStarred(activity: Activity, starred: boolean): Activity {
    try {
      const fields = Object.assign(
        { Starred: starred },
        ActivityHttpService.getTrackingFields(activity)
      );
      this.trackingService.track('Activity Starred', fields);
    } catch {}
    return activity;
  }

  private static getTrackingFields(activity: Activity): any {
    return {
      'Activity ID': activity.id.toString(),
      'Claim ID': activity.claimId,
      Type: activity.type
    };
  }
}
