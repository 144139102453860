import { FieldDefinitionTypes } from '../../../core/models';
import { IAlertService, ICountryService } from '../../../core/services';
import { EntityTemplateResource } from '../../../data/resources/entityTemplate';
import { IFieldStore } from '../../../data/stores/field';
import { IGroupStore } from '../../../data/stores/group';
import { ICacheService } from '../../services/cache';
import { IConfirmationModalService } from '../../services/confirmation-modal';
import { StateService } from 'angular-ui-router';

/* @ngInject */
export default function FieldEditController(
  $scope: any,
  $uibModal: ng.ui.bootstrap.IModalService,
  $state: StateService,
  $stateParams: any,
  $filter: ng.IFilterService,
  CountryService: ICountryService,
  Cache: ICacheService,
  FieldStore: IFieldStore,
  // tslint:disable-next-line:no-shadowed-variable
  EntityTemplateResource: EntityTemplateResource,
  ConfirmationModalService: IConfirmationModalService,
  GroupStore: IGroupStore,
  AlertService: IAlertService
): void {
  const vm = this;
  let originalName = '';

  vm.model = {};
  vm.modelSettings = {};
  vm.formActive = false;
  vm.settingsTemplate = '';
  vm.countries = [];
  vm.backToList = backToList;
  vm.nameChange = nameChange;
  vm.addStatusToStatusSet = addStatusToStatusSet;
  vm.editStatus = editStatus;
  vm.removeStatusFromStatusSet = removeStatusFromStatusSet;
  vm.removeOptFromOptions = removeOptFromOptions;
  vm.newOption = newOption;
  vm.save = save;
  vm.deleteField = deleteField;
  vm.patternChange = patternChange;
  vm.newField = newField;
  vm.updateOptions = updateOptions;

  function updateOptions(currentOption: string) {
    $scope.newForm.$invalid =
      vm.modelSettings.ddlOptions.filter(x => x.text === currentOption).length > 1;
  }

  activate();

  function newField() {
    $state.go($state.current.data.newState);
  }

  function activate() {
    FieldStore.get($stateParams.id).then(function(data: any) {
      vm.model = data;
      vm.countries = CountryService.getCountries();

      originalName = vm.model.name;

      // Loads settings page
      let templateName = '';
      switch (vm.model.type) {
        case FieldDefinitionTypes.Text:
          // TODO: move this to external service once number of patterns increase
          vm.modelSettings.patterns = [
            { name: 'Year', pattern: '^(19|20)\\d{2}$' },
            { name: 'Age', pattern: '^\\d{3}$' }
          ];
          templateName = 'fieldsText';
          break;

        case FieldDefinitionTypes.Dropdown:
          templateName = 'fieldsDropdown';
          const theOpts = [];

          // fills options properties
          if (data.settings.options) {
            const fieldOptions = data.settings.options.split('$$');
            fieldOptions.forEach(opt => {
              const value = opt.split('|');
              theOpts.push({ text: value[1] });
            });
          }

          vm.modelSettings.ddlOptions = theOpts;
          break;

        case FieldDefinitionTypes.Country:
          templateName = 'fieldsCountry';
          break;

        case FieldDefinitionTypes.Address:
          templateName = 'fieldsAddress';
          break;

        case FieldDefinitionTypes.Entity:
          templateName = 'fieldsEntity';

          EntityTemplateResource.query().$promise.then(function(d) {
            vm.modelSettings.entities = (d as any).items;
          });
          break;

        case FieldDefinitionTypes.StatusSet:
          templateName = 'fieldsStatusSet';
          break;

        case FieldDefinitionTypes.User:
          templateName = 'fieldsUser';

          GroupStore.query().then(function(d) {
            vm.modelSettings.groups = d.items;
          });

          break;

        case FieldDefinitionTypes.EntityContact:
          templateName = 'fieldsEntityContact';

          FieldStore.query().then(d => {
            const fields = d.items.filter(x => x.type === FieldDefinitionTypes.Entity);
            vm.modelSettings.entities = fields;
          });
          break;

        default:
          templateName = '';
          break;
      }

      vm.settingsTemplate = templateName;
    });
  }

  function nameChange() {
    /* ngPattern is not used on the input, because it prevents model to be updated */
    // <!--ng-pattern="/^[a-zA-Z_]+$/"-->
    if (vm.model.name) {
      // lowercase
      let str = $filter('lowercase')(vm.model.name);

      // Replaces invalid chars
      str = str.replace(/([^a-z]+)/gi, '_');

      vm.model.name = str;
    }
  }

  function backToList() {
    $state.go($state.current.data.listState);
  }

  function addStatusToStatusSet() {
    if (!vm.model.settings.status) {
      vm.model.settings.status = [];
    }

    showModal({})
      .result.then(function(newStatus) {
        vm.model.settings.status.push(newStatus);
      })
      .catch(err => {});
  }

  function editStatus(status) {
    showModal(status).result.then(function(theStatus) {
      // do something;
      status = theStatus;
    });
  }

  function removeStatusFromStatusSet(status, $index) {
    vm.model.settings.status.splice($index, 1);
  }

  function removeOptFromOptions(idx) {
    // Force apply because of confirm dialog
    vm.modelSettings.ddlOptions.splice(idx, 1);
    $scope.$apply();
  }

  function newOption() {
    vm.modelSettings.ddlOptions.push({ text: '' });
  }

  function save() {
    if (vm.model.name !== originalName) {
      ConfirmationModalService.confirm(
        {
          title: 'Are you absolutely sure?',
          text:
            'This action CANNOT be undone. This will permanently rename the ' +
            originalName +
            ' field. This can affect your email and/or document templates',
          confirmText: originalName
        },
        function() {
          modelSave();
        }
      );
    } else {
      modelSave();
    }
  }

  function modelSave() {
    vm.formActive = true;

    if (vm.modelSettings.ddlOptions) {
      const values = vm.modelSettings.ddlOptions;
      let options = '';
      values.forEach(opt => {
        if (options.length) {
          options += '$$';
        }
        options += '[0|' + opt.text + '|' + opt.text + ']';
      });
      vm.model.settings.options = options;
    }

    FieldStore.update(vm.model)
      .then(function(updated: any) {
        AlertService.success('Field ' + updated.name + ' has been updated.');
        vm.formActive = false;
        $scope.newForm.$setPristine();
        vm.model = updated;
        originalName = vm.model.name;

        // invalidates cache
        Cache.fields(true);
        Cache.claimTemplates(true);
        Cache.entityTemplates(true);
      })
      .catch(function(error) {
        AlertService.error('Could not update field, please try again.');
        vm.formActive = false;
      });
  }

  function deleteField() {
    ConfirmationModalService.confirm(
      {
        title: 'Are you absolutely sure?',
        text:
          'This action CANNOT be undone. This will permanently delete the ' +
          vm.model.name +
          ' field. This field will be removed from all associated types and objects. ' +
          'This can affect your email and/or document templates',
        confirmText: vm.model.name
      },
      function() {
        vm.formActive = true;

        FieldStore.delete(vm.model)
          .then(function(deleted) {
            AlertService.success('Field ' + vm.model.name + ' has been deleted.');
            vm.formActive = false;
            $scope.newForm.$setPristine();

            // invalidates cache
            Cache.claimTemplates(true);
            Cache.entityTemplates(true);

            $state.go($state.current.data.listState);
          })
          .catch(function(error) {
            AlertService.error('Could not delete field, please try again.');
            vm.formActive = false;
          });
      }
    );
  }

  function showModal(status) {
    return $uibModal.open({
      template: require('./status-details.html'),
      controller: 'StatusDetailsController as status',
      resolve: {
        status: function() {
          return status;
        }
      }
    });
  }

  function patternChange() {
    vm.model.settings.pattern = vm.modelSettings.pattern.pattern.toString();
  }
}
