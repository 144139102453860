import { IAlertService } from '../../../core/services';
import { IRoleStore } from '../../../data/stores/role';
import { StateService } from 'angular-ui-router';

/* @ngInject */
export default function RoleNewController(
  $scope: any,
  $state: StateService,
  RoleStore: IRoleStore,
  AlertService: IAlertService
) {
  $scope.role = {};
  $scope.roleForm = {};
  $scope.roleForm.active = false;

  $scope.saveRole = function(role) {
    $scope.roleForm.active = true;

    RoleStore.save(role)
      .then(function(updated) {
        AlertService.success('New role ' + updated.name + ' has been created.');
        $scope.roleForm.active = false;
        $scope.roleForm.$setPristine();

        $state.go('app.role.edit', { id: updated.id });
      })
      .catch(function(error) {
        AlertService.error('Could not create role, please try again.');
        $scope.roleForm.active = false;
      });
  };
}
