import '../../../core/services/alert';
import '../../../data/resources/tax-code';

/* @ngInject */
export default function TaxCodeEditController(
  $scope,
  $stateParams,
  TaxCodeResource,
  AlertService
) {
  $scope.model = {};
  $scope.newForm = {};
  $scope.newForm.active = false;

  TaxCodeResource.get({ id: $stateParams.id }).$promise.then(function(data) {
    $scope.model = data;
  });

  $scope.saveModel = function(model) {
    $scope.newForm.active = true;

    TaxCodeResource.update(model)
      .$promise.then(function(updated) {
        AlertService.success('Tax code ' + updated.name + ' has been updated.');
        $scope.newForm.active = false;
        $scope.newForm.$setPristine();
        $scope.model = updated;
      })
      .catch(function(error) {
        AlertService.error('Could not create tax code, please try again.');
        $scope.newForm.active = false;
      });
  };
}
