import { ITaskHttpService } from '../../core/http';
import { Task, TaskRepeatOptionHelper } from '../../core/models';
import { ISecurityService } from '../../core/services';
import { Clock } from '../../core/utils';
import * as ng from 'angular';
import { DateTime } from 'luxon';

export class TaskRepeatModalController {
  static $inject = [
    '$scope',
    '$uibModalInstance',
    'TaskHttpService',
    'SecurityService',
    'previousTask'
  ];

  task: Task;
  dateFormat: string;

  constructor(
    private scope: any,
    private modalInstance: ng.ui.bootstrap.IModalServiceInstance,
    private taskHttpService: ITaskHttpService,
    private securityService: ISecurityService,
    private previousTask: Task
  ) {
    this.dateFormat = securityService.getDateFormat();
  }

  $onInit() {
    this.task = this.previousTask.clone(this.securityService.getCurrentUserId());
    this.task.dueDate = this.getInitialDueDate();
  }

  ok(): void {
    this.taskHttpService
      .createTask(this.task)
      .safeApply(this.scope, t => this.onSave(t))
      .subscribe();
    this.modalInstance.close(this.task);
  }

  cancel(): void {
    this.modalInstance.dismiss('cancel');
  }

  dueDateChanged(dueDate: DateTime | undefined, hasTime: boolean) {
    this.task.dueDate = dueDate ? dueDate.toUTC() : undefined;
    this.task.hasTime = hasTime;
  }

  private getInitialDueDate(): DateTime {
    return Clock.local()
      .now()
      .plus(
        TaskRepeatOptionHelper.asDateTimePlus(
          this.previousTask.repeatEvery,
          this.previousTask.repeatOption
        )
      );
  }

  private onSave(task: Task): void {
    this.task = task;
  }
}
