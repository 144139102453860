export interface IAlertService {
  error(value: string): void;
  warn(value: string): void;
  success(value: string): void;
  info(value: string): void;
}

export class AlertService {
  static $inject = ['toastr', 'toastrConfig'];

  constructor(private toastrService: any, toastrConfig: any) {
    toastrConfig.positionClass = 'toast-bottom-right';
    toastrConfig.timeOut = 5000;
  }

  /**
   * Generates a information level alert.
   * @param {String} value The text of the alert.
   */
  success(value: string): void {
    this.toastrService.success(value);
  }

  /**
   * Generates a information level alert.
   * @param {String} value The text of the alert.
   */
  info(value: string): void {
    this.toastrService.info(value);
  }

  /**
   * Generates a warning level alert.
   * @param {String} value The text of the alert.
   */
  warn(value: string): void {
    this.toastrService.warning(value);
  }

  /**
   * Generates a error level alert.
   * @param {String} value The text of the alert.
   */
  error(value: string): void {
    this.toastrService.error(value);
  }
}
