import { Tag, Guid } from '../../../core/models';
import { ITagHttpService } from 'app/core/http';

export class TagEditorController {
  static $inject = ['$scope', 'TagHttpService'];

  tags: Tag[] = [];
  selectedTags: Tag[] = [];
  ids: Guid[] = [];

  constructor(private scope: any, private tagHttpService: ITagHttpService) {}

  $onInit() {
    this.tagHttpService
      .getAllTags()
      .safeApply(this.scope, tags => {
        this.tags = tags;
        this.selectedTags = this.tags.filter(
          t => this.ids.findIndex(id => t.id.equals(id)) >= 0
        );
      })
      .subscribe();
  }

  changed() {
    this.ids.length = 0;
    this.ids.push(...this.selectedTags.map(x => x.id));

    (this as any).onChange({ ids: this.ids, tags: this.tags });
  }
}
