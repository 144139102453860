import { WorkflowFilterNumberController } from './workflowFilterNumber.controller';
import { ComponentBase } from '../../../../core/utils/componentBase';

export class WorkflowFilterNumberComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      filter: '='
    };
    this.controller = WorkflowFilterNumberController;
    this.template = require('./workflowFilterNumber.html');
  }
}
