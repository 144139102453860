import { ILogService } from '../../../core/services/log';
import { IRedirectService } from '../../../core/services/redirect';
import { ISessionService } from '../../../core/services/session';
import { IClaimsWindow } from '../../../core/utils/claimsWindow';

/* @ngInject */
export default function CioLogoutController(
  LogService: ILogService,
  SessionService: ISessionService,
  RedirectService: IRedirectService
): void {
  LogService.debug('Auth :: Logged out');

  // Forget the current session
  SessionService.clearSession();

  if (window.appInsights) {
    window.appInsights.clearAuthenticatedUserContext();
  }

  // Reset user data
  if ((<IClaimsWindow>window).mixpanel) {
    (<IClaimsWindow>window).mixpanel.reset();
  }

  // Hard reload to login page
  RedirectService.redirectToLogin();
}
