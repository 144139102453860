import { Field } from '../../../core/models';
import { DateTimeHelper } from '../../../core/utils';
import { DateTime } from 'luxon';

export class DateTimeFieldController {
  static $inject = [];

  field: Field;
  dateValue: DateTime;
  isValid: boolean;

  $onInit() {
    this.dateValue = this.field.value
      ? DateTimeHelper.parseUtcDateTime(this.field.value)
      : undefined;
  }

  dateTimeUpdated(dateTime: DateTime) {
    this.field.value = dateTime ? dateTime.toUTC().toJSDate() : undefined;
  }
}
