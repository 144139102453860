import UserMultiSelectController from './user-select-multi-controller';
import { User } from '../../../core/models';
import { IUserStore } from '../../../data/stores/user';
import * as ng from 'angular';

export interface IUserMultiSelectService {
  open(title?: string, selectedUsers?: User[]): Promise<any>;
}

/* @ngInject */
export function UserMultiSelectService(
  $uibModal: ng.ui.bootstrap.IModalService,
  UserStore: IUserStore
): IUserMultiSelectService {
  const self = this;

  /**
   * Opens a modal and allows the user to select users.
   * @param {string} title the modal window title.
   * @param {object[]} selectedUsers the list of already selected users.
   * @returns {promise}
   */
  self.open = function(title: string, selectedUsers: User[]) {
    title = title || 'Select users';
    selectedUsers = selectedUsers || [];

    const modalInstance = $uibModal.open({
      template: require('./user-select-multi.html'),
      controller: UserMultiSelectController,
      resolve: {
        title: function() {
          return title;
        },
        selectedUsers: function() {
          return selectedUsers;
        }
      }
    });

    return modalInstance.result;
  };

  return self;
}
