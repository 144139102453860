import { ISecurityService } from '../../../core/services';
import { DateTimeHelper } from '../../../core/utils';
import { DateTime } from 'luxon';

export class DateEditController {
  static $inject = ['SecurityService'];

  isRequired: boolean = false;
  isPickerOpen: boolean = false;
  dateOptions = {
    showWeeks: false,
    formatDay: 'd',
    startingDay: 1
  };
  inputValue: Date;
  dateFormat: string;
  dateAltFormats: string[] = [];
  private _date: DateTime;

  constructor(securityService: ISecurityService) {
    this.dateFormat = securityService.getDateFormat();
    this.dateAltFormats = [
      // short dates
      this.dateFormat.replace('dd', 'd').replace('MM', 'M'),
      // hyphens
      this.dateFormat.replace(/\//g, '-'),
      this.dateFormat
        .replace(/\//g, '-')
        .replace('dd', 'd')
        .replace('MM', 'M'),
      // no year
      this.dateFormat
        .replace('dd', 'd')
        .replace('MM', 'M')
        .replace('/yyyy', ''),
      this.dateFormat
        .replace(/\//g, '-')
        .replace('dd', 'd')
        .replace('MM', 'M')
        .replace('-yyyy', '')
    ];
  }

  get placeholderText(): string {
    return this.dateFormat ? this.dateFormat.toUpperCase() : '';
  }

  set date(value: DateTime | undefined) {
    this._date = DateTimeHelper.parseUtcDate(value);
    this.setValues(this._date);
  }

  updateFromInput(): void {
    if (!this.inputValue) {
      this.resetValues();
      (this as any).onChange({ date: undefined });
      return;
    }

    const date = DateTimeHelper.extractUtcDate(this.inputValue);
    if (!date.isValid) {
      console.log(`Input invalid: ${this.inputValue}`);
      return;
    }
    this.updateDate(date);
  }

  toggleOpen() {
    this.isPickerOpen = !this.isPickerOpen;
  }

  private updateDate(date: DateTime) {
    this.setValues(date);
    (this as any).onChange({ date });
  }

  private setValues(date: DateTime) {
    this.inputValue = date ? date.toJSDate() : undefined;
  }

  private resetValues() {
    this.inputValue = undefined;
  }
}
