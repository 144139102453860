import { Claim, Guid, RecordAssociation, Tag } from '../../../core/models';
import { ITaskService } from '../../tasks/task.service';
import { StateService } from 'angular-ui-router';
import { ITagHttpService } from '../../../core/http';

export class ClaimTasksController {
  static $inject = ['$scope', '$state', 'TaskService', 'TagHttpService'];

  claim: Claim;
  record: RecordAssociation;
  tags: Tag[];

  constructor(
    private scope: any,
    private stateService: StateService,
    private taskService: ITaskService,
    private tagHttpService: ITagHttpService
  ) {}

  $onInit() {
    this.record = Object.assign(new RecordAssociation(), {
      type: 'claim',
      id: new Guid(this.claim.id),
      title: (<any>this.claim).title
    });
    this.tagHttpService
      .getAllTags()
      .safeApply(this.scope, tags => {
        this.tags = tags;
      })
      .subscribe();
  }

  get isClosed(): boolean {
    return this.claim.isClosed;
  }

  createTask() {
    this.taskService
      .createTask(this.record)
      .result.then(t => this.stateService.go('.'))
      .catch(err => {});
  }
}
