import { Page } from '../../../core/models';

export class ListPagerController {
  pageNumber: number = 1;
  currentPage: Page<any>;

  pageChanged() {
    (<any>this).onPageChange({ pageNumber: this.pageNumber });
  }
}
