import { IDocumentHttpService } from '../../../core/http';
import { Page, StorageFile } from '../../../core/models';
import { StateService } from 'angular-ui-router';

const DEFAULT_PAGE_SIZE: number = 50;

export class DocumentsController {
  static $inject = ['$scope', '$state', 'DocumentHttpService'];

  pageNumber: number = 1;
  pageSize: number = DEFAULT_PAGE_SIZE;
  currentPage: Page<StorageFile> = new Page<StorageFile>(this.pageSize);

  constructor(
    private scope: any,
    private state: StateService,
    private documentHttpService: IDocumentHttpService
  ) {}

  $onInit() {
    this.routeLoad();
    this.loadPage(this.pageNumber);
  }

  newDocument() {
    this.state.go('app.document.new');
  }

  viewDocument(storageFile: StorageFile) {
    this.state.go('app.document.edit', { id: storageFile.id });
  }

  pageChanged(pageNumber) {
    this.loadPage(pageNumber);
  }

  private loadPage(pageNumber: number) {
    this.documentHttpService
      .getTemplateFiles(this.pageSize, (pageNumber - 1) * this.pageSize)
      .safeApply(this.scope, page => {
        this.pageNumber = pageNumber;
        this.currentPage = page;
        this.routeSave(pageNumber);
      })
      .subscribe();
  }

  private routeLoad() {
    if (this.state.params.page) {
      this.pageNumber = this.state.params.page;
    }
    if (this.state.params.records) {
      this.pageSize = this.state.params.records;
    }
  }

  private routeSave(pageNumber) {
    const params = {
      page: pageNumber > 1 ? pageNumber : undefined,
      records: this.pageSize !== DEFAULT_PAGE_SIZE ? this.pageSize : undefined
    };
    this.state.go('.', params, { notify: false });
  }
}
