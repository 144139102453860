import { Group } from '../../../core/models';
import { IAlertService } from '../../../core/services';
import { IGroupStore } from '../../../data/stores/group';

export interface IGroupSelectService {
  open(title?: string, selectedGroups?: Group[]): Promise<any>;
}

/* @ngInject */
export default function GroupSelectService($uibModal): IGroupSelectService {
  const self = this;

  /**
   * Opens a modal and allows the user to select groups.
   * @param {string} [title=Select roles] - the modal title.
   * @param {object[]} [selectedGroups] - the list of already selected roles.
   * @returns {promise}
   */
  self.open = function(title?: string, selectedGroups?: Group[]) {
    title = title || 'Select groups';
    selectedGroups = selectedGroups || [];

    const modalInstance = $uibModal.open({
      template: require('./group-select.html'),
      controller: 'GroupSelectController',
      resolve: {
        title: function() {
          return title;
        },
        selectedGroups: function() {
          return selectedGroups;
        }
      }
    });

    // promise
    return modalInstance.result;
  };

  return self;
}

/* tslint:disable:no-shadowed-variable */
/* @ngInject */
export function GroupSelectController(
  $scope,
  $uibModalInstance,
  title,
  selectedGroups,
  GroupStore: IGroupStore,
  AlertService: IAlertService
): void {
  /* tslint:enable:no-shadowed-variable */

  $scope.title = title;
  $scope.groups = [];
  $scope.allSelected = false;
  $scope.query = '';

  const evaluateAllSelected = function() {
    let allSelected = true;

    for (let i = 0; i < $scope.groups.length; ++i) {
      if ($scope.groups[i].selected === false) {
        allSelected = false;
        break;
      }
    }

    $scope.allSelected = allSelected;
  };

  GroupStore.query()
    .then(function(result) {
      const list = [];
      result.items.forEach(item => {
        const isSelected = selectedGroups.some(x => x.id === item.id) ? true : false;
        list.push({ selected: isSelected, group: item });
      });

      $scope.groups = list;

      evaluateAllSelected();
    })
    .catch(function(error) {
      AlertService.error('Could not load groups.');
      $uibModalInstance.dismiss('cancel');
    });

  $scope.toggleAll = function() {
    const toggle = !$scope.allSelected;

    $scope.groups.forEach(item => {
      item.selected = toggle;
    });

    evaluateAllSelected();
  };

  $scope.groupSelect = function(item) {
    item.selected = !item.selected;
    evaluateAllSelected();
  };

  $scope.ok = function() {
    const selected = [];
    $scope.groups.forEach(item => {
      if (item.selected) {
        selected.push(item.group);
      }
    });

    $uibModalInstance.close(selected);
  };

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };
}
