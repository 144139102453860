import { Dashboard } from '../../core/models';
import * as ng from 'angular';

/* tslint:disable-next-line:no-empty-interface */
export interface DashboardResource
  extends ng.resource.IResourceClass<ng.resource.IResource<Dashboard>> {}

/* @ngInject */
export default function DashboardResource(
  $resource: ng.resource.IResourceService
): DashboardResource {
  return <DashboardResource>$resource<ng.resource.IResource<Dashboard>>(
    '/api/v1/dashboards',
    {},
    {
      update: {
        method: 'PUT'
      }
    }
  );
}
