import { IResourceStore, IResourceStoreFactory } from './store';
import { User } from '../../core/models';

/* tslint:disable-next-line:no-empty-interface */
export interface IUserStore extends IResourceStore<User> {
  groups(id: string): any;
  permissions(id: string): any;
  modifyPassword(id: string, password: string): any;
  modifyGroups(id: string, groupIds: string[]): any;
}

/* @ngInject */
export default function UserStore(StoreFactory: IResourceStoreFactory<User>): IUserStore {
  const store = StoreFactory('/api/v1/users/:id', { id: '@id' });

  store.withStoreExtension('groups', function(id: string) {
    return StoreFactory('/api/v1/groups/user/:id', { id: id }).query();
  });

  store.withStoreExtension('permissions', function(id: string) {
    return StoreFactory('/api/v1/users/:id/permissions', { id: id }).query();
  });

  store.withStoreExtension('modifyPassword', function(id: string, password: string) {
    return StoreFactory('/api/v1/users/:id/modify-password', { id: id }).update({
      newPassword: password
    });
  });

  store.withStoreExtension('modifyGroups', function(id: string, groupIds: string[]) {
    return StoreFactory('/api/v1/groups/user/:id', { id: id }).update({
      groups: groupIds
    });
  });

  return <IUserStore>store;
}
