import { IGroupEntitySelectService } from './groupEntitySelect.service';
import { IAlertService, ISecurityService } from '../../../core/services';
import { IGroupStore } from '../../../data/stores/group';
import { IRoleStore } from '../../../data/stores/role';
import { IRoleSelectService } from '../roles/role-select';
import { IUserMultiSelectService } from '../users/user-select-multi';
import { StateService } from 'angular-ui-router';
import findIndex from 'lodash/findIndex';

/* @ngInject */
export function GroupEditController(
  $scope: any,
  $state: StateService,
  $stateParams: any,
  GroupStore: IGroupStore,
  RoleStore: IRoleStore,
  UserMultiSelectService: IUserMultiSelectService,
  RoleSelectService: IRoleSelectService,
  GroupEntitySelectService: IGroupEntitySelectService,
  SecurityService: ISecurityService,
  AlertService: IAlertService
): void {
  $scope.members = [];
  $scope.group = {};
  $scope.groupForm = {};
  $scope.groups = [];

  // TODO: check user access permissions

  const updateMembers = function(group) {
    group
      .getMembers()
      .then(function(members) {
        $scope.members = members.items;
      })
      .catch(function(error) {
        AlertService.error('Could not load members data.');
      });
  };

  const updateRoles = function(group) {
    RoleStore.query()
      .then(function(result) {
        const newRoles = [];
        const allRoles = result.items;

        group.roleParticipation.forEach(item => {
          let role;
          for (let i = 0; i < allRoles.length; i++) {
            if (allRoles[i].id === item.key) {
              role = allRoles[i];
              break;
            }
          }

          newRoles.push({
            role: role,
            info: item
          });
        });

        $scope.groups = newRoles;
      })
      .catch(function(error) {
        AlertService.error('Could not load role data.');
      });
  };

  $scope.load = function() {
    $scope.groupForm.active = true;

    GroupStore.get($stateParams.id)
      .then(function(group) {
        $scope.group = group;
        $scope.groupForm.active = false;

        updateMembers(group);
        updateRoles(group);
      })
      .catch(function(error) {
        AlertService.error('Could not load group, please try again.');
        $state.go('app.groups');
      });
  };

  $scope.load();

  $scope.updateGroup = function(group, form) {
    $scope.groupForm.active = true;

    group.update().then(function(updated) {
      $scope.group = updated;
      AlertService.success('Group updated successfully.');
      $scope.groupForm.active = false;

      if (form) {
        form.$setPristine();
      }

      SecurityService.loadPermissions(SecurityService.getCurrentUserId());
      updateMembers(updated);
      updateRoles(updated);
    });
  };

  $scope.deleteGroup = function(group, form) {
    GroupStore.delete(group).then(function(deleted) {
      AlertService.success('Group ' + group.name + ' has been deleted.');
      form.$setPristine();
      SecurityService.loadPermissions(SecurityService.getCurrentUserId());
      $state.go('app.groups');
    });
  };

  $scope.addMembers = function() {
    UserMultiSelectService.open()
      .then(function(users) {
        users.forEach(item => {
          $scope.group.members.push(item.id);
        });
        $scope.updateGroup($scope.group);
      })
      .catch(() => {});
  };

  $scope.removeMember = function(user) {
    const index = findIndex($scope.group.members, function(item) {
      return item === user.id;
    });

    $scope.group.members.splice(index, 1);

    $scope.updateGroup($scope.group);
  };

  $scope.addRoles = function() {
    RoleSelectService.open().then(function(roles) {
      roles.forEach(item => {
        $scope.group.roleParticipation.push({
          key: item.id,
          value: {
            roleId: item.id,
            entities: []
          }
        });
      });

      $scope.updateGroup($scope.group);
    });
  };

  $scope.removeRole = function(role) {
    const index = findIndex(<any[]>$scope.group.roleParticipation, function(item) {
      return item.key === role.id;
    });

    $scope.group.roleParticipation.splice(index, 1);

    $scope.updateGroup($scope.group);
  };

  $scope.manageEntities = function(role) {
    const participation = $scope.group.roleParticipation.find(item => {
      return item.key === role.id;
    });

    GroupEntitySelectService.open('Select Entities', participation).then(function(data) {
      participation.value.entities = data;

      $scope.updateGroup($scope.group);
    });
  };
}
