import { FieldController } from './field.controller';

export class FieldComponent implements ng.IComponentOptions {
  public controller: any;
  public template: string;
  public bindings: any;

  constructor() {
    this.bindings = {
      definition: '<',
      field: '=',
      templateDefinition: '<',
      templateFields: '<',
      formDisabled: '<',
      sections: '<'
    };
    this.controller = FieldController;
    this.template = require('./field.html');
  }
}
