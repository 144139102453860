import { NotificationViewComponent } from './notificationView.component';
import { NotificationListComponent } from './notificationList.component';
import { NotificationTableComponent } from './notificationTable.component';
import { NotificationModalService } from './notificationModal.service';
import { NotificationRoutes } from './notification.routes';
import { CoreModule } from '../../core/module';
import { DataModule } from '../../data/module';
import { UIModule } from '../../ui/module';
import angular from 'angular';

export const NotificationModule = angular
  .module('app.instance.notifications', [DataModule.name, CoreModule.name, UIModule.name])
  // Routes
  .config(NotificationRoutes)

  // Services
  .service('NotificationModalService', NotificationModalService)

  // Components
  .component('cioNotificationList', new NotificationListComponent())
  .component('cioNotificationView', new NotificationViewComponent())
  .component('cioNotificationTable', new NotificationTableComponent());
