/**
 * Provides local stoage support with fallback if required.
 */
export interface ILocalStorageService {
  /**
   * Returns the specified item.
   * @param {string} key The key to return the value for.
   * @returns {any} The value stored for the given key.
   */
  getItem(key: string): any;

  /**
   * Stores the specified item.
   * @param {string} key The key to save the value as.
   * @param {any} value The value to save.
   */
  setItem(key: string, value: any): void;

  /**
   * Removes the specified key.
   * @param {string} key The key to remove.
   */
  removeItem(key: string): void;

  /**
   * Determines if the item is stored.
   * @param {string} key The key to check if it exists.
   * @returns {boolean} True if the item exists, else false.
   */
  hasItem(key: string): boolean;
}

/**
 * The interface for the window.localStorage service.
 *
 * @interface IStorageAdapter
 */
interface ILocalCache {
  /**
   * Returns the specified item.
   * @param {string} key The key to return the value for.
   * @returns {string} The value stored for the given key.
   */
  getItem(key: string): string;

  /**
   * Stores the specified item.
   * @param {string} key The key to save the value as.
   * @param {any} value The value to save.
   */
  setItem(key: string, value: string): void;

  /**
   * Removes the specified key.
   * @param {string} key The key to remove.
   */
  removeItem(key: string): void;

  /**
   * Determines if the item is stored.
   * @param {string} key The key to check if it exists.
   * @returns {boolean} True if the item exists, else false.
   */
  hasOwnProperty(key: string): boolean;
}

/**
 * A local version of the local cache that is used when the window.localStorage
 * is not available for use (private windows in safari, etc.).
 *
 * @class LocalCache
 */
class LocalCache {
  // implements ILocalCache {

  private _items: any = [];

  getItem(key: string): any {
    if (!isValidKey(key)) {
      return null;
    }

    return this._items[key];
  }

  setItem(key: string, value: any) {
    if (!isValidKey(key)) {
      throw new Error('Invalid storage key');
    }

    this._items[key] = value;
  }

  hasOwnProperty(key: string): boolean {
    if (!isValidKey(key)) {
      return false;
    }

    return this._items != null && this._items[key] != null;
  }

  removeItem(key: string) {
    if (!isValidKey(key)) {
      return;
    }

    const index = this._items.indexOf(key, 0);
    if (index > -1) {
      this._items.splice(index, 1);
    }
  }
}

export class LocalStorageService implements ILocalStorageService {
  static $inject = ['$window'];

  private storageCache = this._getStorage();

  constructor(public $window: ng.IWindowService) {
    this.storageCache = this._getStorage();
  }

  getItem(key: string): any {
    if (!isValidKey(key)) {
      return null;
    }

    const value = this.storageCache.getItem(key);
    if (!value) {
      return null;
    }

    return JSON.parse(value);
  }

  setItem(key: string, value: any): void {
    if (!isValidKey(key)) {
      throw new Error('Invalid storage key');
    }

    this.storageCache.setItem(key, JSON.stringify(value));
  }

  hasItem(key: string): boolean {
    if (!isValidKey(key)) {
      return false;
    }

    return this.storageCache.hasOwnProperty(key);
  }

  removeItem(key: string): void {
    if (!isValidKey(key)) {
      return;
    }

    this.storageCache.removeItem(key);
  }

  private _isLocalStorageEnabled(): boolean {
    const testKey = 'test',
      storage = window.localStorage;
    try {
      storage.setItem(testKey, '1');
      storage.removeItem(testKey);
      return true;
    } catch (error) {
      return false;
    }
  }

  private _getStorage(): ILocalCache {
    return this._isLocalStorageEnabled() ? this.$window.localStorage : new LocalCache();
  }
}

/**
 * Checks the specified key is valid.
 *
 * @param {string} key The key to check.
 * @returns True if valid, else false.
 */
function isValidKey(key: string) {
  return !!key && key.length > 0;
}
