import * as angular from 'angular';

/**
 * Taken from:
 * https://github.com/vitalets/checklist-model
 */
/* @ngInject */
export default function ChecklistModel($parse, $compile): any {
  return {
    restrict: 'A',
    priority: 1000,
    terminal: true,
    scope: true,
    compile: compile
  };

  function compile(element, attrs) {
    if (element[0].tagName !== 'INPUT' || !element.attr('type', 'checkbox')) {
      throw 'checklist-model should be applied to `input[type="checkbox"]`.';
    }

    if (!attrs.checklistValue) {
      throw 'You should provide `checklist-value`.';
    }

    element.removeAttr('checklist-model');
    element.attr('ng-model', 'checked');

    return postLinkFn;
  }

  function contains(arr, item) {
    if (angular.isArray(arr)) {
      for (let i = 0; i < arr.length; i++) {
        if (angular.equals(arr[i], item)) {
          return true;
        }
      }
    }
    return false;
  }

  function add(arr, item) {
    arr = angular.isArray(arr) ? arr : [];
    for (let i = 0; i < arr.length; i++) {
      if (angular.equals(arr[i], item)) {
        return arr;
      }
    }
    arr.push(item);
    return arr;
  }

  function remove(arr, item) {
    if (angular.isArray(arr)) {
      for (let i = 0; i < arr.length; i++) {
        if (angular.equals(arr[i], item)) {
          arr.splice(i, 1);
          break;
        }
      }
    }
    return arr;
  }

  function postLinkFn(scope, elem, attrs) {
    $compile(elem)(scope);

    const getter = $parse(attrs.checklistModel);
    const setter = getter.assign;
    const value = $parse(attrs.checklistValue)(scope.$parent);

    scope.$watch('checked', function(newValue, oldValue) {
      if (newValue === oldValue) {
        return;
      }
      const current = getter(scope.$parent);
      if (newValue === true) {
        setter(scope.$parent, add(current, value));
      } else {
        setter(scope.$parent, remove(current, value));
      }
    });

    scope.$parent.$watch(
      attrs.checklistModel,
      function(newArr, oldArr) {
        scope.checked = contains(newArr, value);
      },
      true
    );
  }
}
