import CommentModalController from './commentModal.controller';
import { Guid, Note } from '../../../core/models';
import * as ng from 'angular';

export interface ICommentModalService {
  /**
   * Opens a modal to create a new comment.
   * @returns {ng.ui.bootstrap.IModalInstanceService}
   */
  create(claimId: Guid): ng.IPromise<Note>;
}

export class CommentModalService implements ICommentModalService {
  static $inject = ['$uibModal'];

  constructor(private modalService: ng.ui.bootstrap.IModalService) {}

  create(claimId: Guid): ng.IPromise<Note> {
    const modalInstance = this.modalService.open({
      template: require('./commentModal.html'),
      controller: CommentModalController,
      controllerAs: '$ctrl',
      resolve: {
        claimId() {
          return claimId;
        }
      }
    });
    return modalInstance.result;
  }
}
