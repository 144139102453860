import { IFileModalService } from './fileModal.service';
import {
  STORAGE_FOLDER_TYPES,
  StorageFile,
  StorageFileRevision,
  StorageFolder
} from '../../core/models';
import { IDownloadService, ISessionService } from '../../core/services';
import { StateService } from 'angular-ui-router';
import { IFolderNavigator } from '../folders/folder.navigator';

export interface IFileModal {
  result: Promise<any>;
  opened: Promise<any>;
  rendered: Promise<any>;
  closed: Promise<any>;
  close(result?: any): void;
  dismiss(reason?: any): void;
}

export interface IFileService {
  showFolder(folder: StorageFolder): void;
  downloadFile(file: StorageFile, revision?: StorageFileRevision): void;
  getPreviewUrl(file: StorageFile, revision?: StorageFileRevision);
  getThumbnailUrl(file: StorageFile, width: number, height: number);
  showFile(file: StorageFile): IFileModal;
  showFileRevision(
    file: StorageFile,
    revision?: StorageFileRevision,
    showRevisions?: boolean,
    navigator?: IFolderNavigator
  ): IFileModal;
}

export class FileService implements IFileService {
  static $inject = ['$state', 'SessionService', 'DownloadService', 'FileModalService'];

  constructor(
    private state: StateService,
    private sessionService: ISessionService,
    private downloadService: IDownloadService,
    private fileModalService: IFileModalService
  ) {}

  downloadFile(file: StorageFile, revision?: StorageFileRevision): void {
    if (!this.canViewFile(file)) {
      return;
    }
    const url = !revision
      ? `${file.getDownloadUrl()}?token=${this.sessionService.getToken().token}`
      : `${file.getRevisionDownloadUrl(revision || file.headRevision)}?token=${
          this.sessionService.getToken().token
        }`;
    this.downloadService.downloadGet(url, file.name);
  }

  getPreviewUrl(file: StorageFile, revision?: StorageFileRevision) {
    if (!this.canViewFile(file)) {
      return;
    }

    return `${file.getRevisionPreviewUrl(revision || file.headRevision)}?token=${
      this.sessionService.getToken().token
    }`;
  }

  getThumbnailUrl(file: StorageFile, width: number = 100, height: number = 100) {
    return `${file.getRevisionThumbnailUrl(file.headRevision, width, height)}&token=${
      this.sessionService.getToken().token
    }`;
  }

  showFolder(folder: StorageFolder): void {
    if (!this.canViewFolder(folder)) {
      return;
    }
    switch (folder.type) {
      case STORAGE_FOLDER_TYPES.Claim:
        this.state.go('app.claim.folder', {
          claimId: folder.recordId,
          folderId: folder.id
        });
        break;
      case STORAGE_FOLDER_TYPES.Entity:
        this.state.go('app.entity.folder', {
          entityId: folder.recordId,
          folderId: folder.id
        });
        break;
      case STORAGE_FOLDER_TYPES.Public:
        this.state.go('app.organization');
        break;
      case STORAGE_FOLDER_TYPES.Template:
        this.state.go('app.documents');
        break;
      default:
        throw new Error(`Invalid folder type '${folder.type}' found.`);
    }
  }

  showFile(file: StorageFile): IFileModal {
    if (!this.canViewFile(file)) {
      return this.asClosedFileModal();
    }
    return this.asFileModal(this.fileModalService.show(file, file.headRevision, false));
  }

  showFileRevision(
    file: StorageFile,
    revision: StorageFileRevision = undefined,
    showRevisions: boolean = false,
    navigator: IFolderNavigator = undefined
  ): IFileModal {
    if (!this.canViewFile(file)) {
      return;
    }
    return this.asFileModal(
      this.fileModalService.show(
        file,
        revision || file.headRevision,
        showRevisions,
        navigator
      )
    );
  }

  private asClosedFileModal(): IFileModal {
    const done = Promise.resolve();
    return {
      result: done,
      opened: done,
      rendered: done,
      closed: done,
      close(result?: any) {},
      dismiss(reason?: any) {}
    } as IFileModal;
  }

  private asFileModal(modalInstance: ng.ui.bootstrap.IModalServiceInstance): IFileModal {
    return <any>modalInstance;
  }

  private canViewFile(file: StorageFile) {
    // TODO: Add permission check
    return true;
  }

  private canViewFolder(folder: StorageFolder) {
    // TODO: Add permission check
    return true;
  }
}
