import { WorkflowEditController } from './workflowEdit.controller';
import { ComponentBase } from '../../../core/utils/componentBase';

export class WorkflowEditComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {};
    this.controller = WorkflowEditController;
    this.template = require('./workflowEdit.html');
  }
}
