/**
 * The default MIME type that should be used if a MIME type is unknown.
 */
export const DefaultMimeType = 'application/octet-stream';

/**
 * A hash of MIME types keyed by file extension.
 */
export const MimeTypeByExtension = (types => {
  const map = new Map<string, string>();
  types.map(x => map.set(x.ext, x.mime));
  return map;
})([
  { ext: 'dot', mime: 'application/msword' },
  { ext: 'doc', mime: 'application/msword' },
  { ext: 'pdf', mime: 'application/pdf' },
  { ext: 'pgp', mime: 'application/pgp-signature' },
  { ext: 'ps', mime: 'application/postscript' },
  { ext: 'ai', mime: 'application/postscript' },
  { ext: 'eps', mime: 'application/postscript' },
  { ext: 'rtf', mime: 'application/rtf' },
  { ext: 'xls', mime: 'application/vnd.ms-excel' },
  { ext: 'xlb', mime: 'application/vnd.ms-excel' },
  { ext: 'ppt', mime: 'application/vnd.ms-powerpoint' },
  { ext: 'pps', mime: 'application/vnd.ms-powerpoint' },
  { ext: 'pot', mime: 'application/vnd.ms-powerpoint' },
  { ext: 'zip', mime: 'application/zip' },
  { ext: 'swf', mime: 'application/x-shockwave-flash' },
  { ext: 'swfl', mime: 'application/x-shockwave-flash' },
  {
    ext: 'docx',
    mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  },
  {
    ext: 'dotx',
    mime: 'application/vnd.openxmlformats-officedocument.wordprocessingml.template'
  },
  {
    ext: 'xlsx',
    mime: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
  },
  {
    ext: 'pptx',
    mime: 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  },
  {
    ext: 'potx',
    mime: 'application/vnd.openxmlformats-officedocument.presentationml.template'
  },
  {
    ext: 'ppsx',
    mime: 'application/vnd.openxmlformats-officedocument.presentationml.slideshow'
  },
  { ext: 'js', mime: 'application/x-javascript' },
  { ext: 'json', mime: 'application/json' },
  { ext: 'mp3', mime: 'audio/mpeg' },
  { ext: 'mpga', mime: 'audio/mpeg' },
  { ext: 'mpega', mime: 'audio/mpeg' },
  { ext: 'mp2', mime: 'audio/mpeg' },
  { ext: 'wav', mime: 'audio/x-wav' },
  { ext: 'm4a', mime: 'audio/x-m4a' },
  { ext: 'oga', mime: 'audio/ogg' },
  { ext: 'ogg', mime: 'audio/ogg' },
  { ext: 'aiff', mime: 'audio/aiff' },
  { ext: 'aif', mime: 'audio/aiff' },
  { ext: 'flac', mime: 'audio/flac' },
  { ext: 'aac', mime: 'audio/aac' },
  { ext: 'ac3', mime: 'audio/ac3' },
  { ext: 'wma', mime: 'audio/x-ms-wma' },
  { ext: 'bmp', mime: 'image/bmp' },
  { ext: 'gif', mime: 'image/gif' },
  { ext: 'jpg', mime: 'image/jpeg' },
  { ext: 'jpeg', mime: 'image/jpeg' },
  { ext: 'jpe', mime: 'image/jpeg' },
  { ext: 'psd', mime: 'image/photoshop' },
  { ext: 'png', mime: 'image/png' },
  { ext: 'svg', mime: 'image/svg+xml' },
  { ext: 'svgz', mime: 'image/svg+xml' },
  { ext: 'tiff', mime: 'image/tiff' },
  { ext: 'tif', mime: 'image/tiff' },
  { ext: 'asc', mime: 'text/plain' },
  { ext: 'txt', mime: 'text/plain' },
  { ext: 'text', mime: 'text/plain' },
  { ext: 'diff', mime: 'text/plain' },
  { ext: 'log', mime: 'text/plain' },
  { ext: 'htm', mime: 'text/html' },
  { ext: 'html', mime: 'text/html' },
  { ext: 'xhtml', mime: 'text/html' },
  { ext: 'css', mime: 'text/css' },
  { ext: 'csv', mime: 'text/csv' },
  { ext: 'rtf', mime: 'text/rtf' },
  { ext: 'mpeg', mime: 'video/mpeg' },
  { ext: 'mpg', mime: 'video/mpeg' },
  { ext: 'm2v', mime: 'video/mpeg' },
  { ext: 'm2v', mime: 'video/mpeg' },
  { ext: 'qt', mime: 'video/quicktime' },
  { ext: 'mov', mime: 'video/quicktime' },
  { ext: 'mp4', mime: 'video/mp4' },
  { ext: 'm4v', mime: 'video/x-m4v' },
  { ext: 'flv', mime: 'video/x-flv' },
  { ext: 'wmv', mime: 'video/x-ms-wmv' },
  { ext: 'avi', mime: 'video/avi' },
  { ext: 'webm', mime: 'video/webm' },
  { ext: '3gpp', mime: 'video/3gpp' },
  { ext: '3gp', mime: 'video/3gpp' },
  { ext: '3g2', mime: 'video/3gpp2' },
  { ext: 'rv', mime: 'video/vnd.rn-realvideo' },
  { ext: 'ogv', mime: 'video/ogg' },
  { ext: 'mkv', mime: 'video/x-matroska' },
  { ext: 'otf', mime: 'application/vnd.oasis.opendocument.formula-template' },
  { ext: 'exe', mime: 'application/octet-stream' }
]);
