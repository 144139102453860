import { SmsService } from './sms.service';
import { CoreModule } from '../../core/module';
import { DataModule } from '../../data/module';
import { UIModule } from '../../ui/module';
import * as angular from 'angular';

export const SmsModule = angular
  .module('app.instance.sms', [DataModule.name, CoreModule.name, UIModule.name])

  // Components
  .service('SmsService', SmsService);
