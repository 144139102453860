import { DocumentEditComponent } from './documentEdit.component';
import { DocumentNewComponent } from './documentNew.component';
import { DocumentRequirementService } from './documentRequirement.service';
import { DocumentsComponent } from './documents.component';
import { DocumentSettingsRoutes } from './documents.routes';
import { CoreModule } from '../../../core/module';
import { DataModule } from '../../../data/module';
import { UIModule } from '../../../ui/module';
import * as angular from 'angular';

export const DocumentSettingsModule = angular
  .module('app.instance.settings.documents', [
    DataModule.name,
    CoreModule.name,
    UIModule.name
  ])

  // Services
  .service('DocumentRequirementService', DocumentRequirementService)

  // Components
  .component('cioDocuments', new DocumentsComponent())
  .component('cioDocumentNew', new DocumentNewComponent())
  .component('cioDocumentEdit', new DocumentEditComponent())

  // Routes
  .config(DocumentSettingsRoutes);
