import { IAlertService } from '../../../core/services/alert';
import { ClaimTemplateResource } from '../../../data/resources/claimTemplate';
import { StateService } from 'angular-ui-router';

/* tslint:disable:no-shadowed-variable */
/* @ngInject */
export default function ClaimTypesListController(
  $scope: any,
  $state: StateService,
  ClaimTemplateResource: ClaimTemplateResource,
  AlertService: IAlertService
): void {
  /* tslint:enable:no-shadowed-variable */

  $scope.models = [];

  ClaimTemplateResource.query()
    .$promise.then(function(result: any) {
      $scope.models = result.items;
    })
    .catch(function(error) {
      AlertService.error('Could not load fields, please try again.');
    });

  $scope.new = function() {
    $state.go('app.claimType.new');
  };

  $scope.view = function(model) {
    $state.go('app.claimType.edit', { id: model.id });
  };
}
