import FieldAddModalController from './claimTypes/add-field';
import ClaimTemplateManagerService from './claimTypes/claim-template-service';
import ClaimTypeEditController from './claimTypes/claimType-edit';
import ClaimTypeNewController from './claimTypes/claimType-new';
import ClaimTypesListController from './claimTypes/claimTypes';
import SystemFieldController from './claimTypes/system-field';
import CostCategoryListController from './cost/categories';
import CostCategoryEditController from './cost/category-edit';
import CostCategoryNewController from './cost/category-new';
import DelegationEditComponent from './delegation/delegation-edit';
import DelegationNewController from './delegation/delegation-new';
import DelegationListController from './delegation/delegations';
import { DocumentSettingsModule } from './documents/documents.module';
import EmailTemplateEditController from './emails/email-edit';
import EmailTemplateNewController from './emails/email-new';
import EmailTemplateListController from './emails/emails';
import EntityTemplateManagerService from './entityTypes/entity-template-service';
import EntityTypeEditController from './entityTypes/entityType-edit';
import EntityTypeNewController from './entityTypes/entityType-new';
import EntityTypesListController from './entityTypes/entityTypes';
import FieldEditController from './fields/field-edit';
import FieldNewController from './fields/field-new';
import FieldListController from './fields/fields';
import StatusDetailsController from './fields/status-details';
import { GroupEditController } from './groups/group-edit';
import GroupNewController from './groups/group-new';
import { GroupSelectController } from './groups/group-select';
import GroupSelectService from './groups/group-select';
import { GroupEntitySelectService } from './groups/groupEntitySelect.service';
import GroupListController from './groups/groups';
import { OrganizationModule } from './organization/organization.module';
import RoleEditController from './roles/role-edit';
import RoleNewController from './roles/role-new';
import { RoleSelectService } from './roles/role-select';
import { RoleSelectController } from './roles/role-select';
import RoleListController from './roles/roles';
import SavedSearchEditController from './search/search-edit';
import SavedSearchNewController from './search/search-new';
import SavedSearchListController from './search/searches';
import SumInsuredCategoryListController from './suminsured/categories';
import SumInsuredCategoryEditController from './suminsured/category-edit';
import SumInsuredCategoryNewController from './suminsured/category-new';
import TaxCodeEditController from './taxcodes/taxcode-edit';
import TaxCodeNewController from './taxcodes/taxcode-new';
import TaxCodeListController from './taxcodes/taxcodes';
import UserEditController from './users/user-edit';
import UserNewController from './users/user-new';
import UserSelectService from './users/user-select';
import UserSelectController from './users/user-select-controller';
import { UserMultiSelectService } from './users/user-select-multi';
import UserMultiSelectController from './users/user-select-multi-controller';
import UserListController from './users/users';
import { WorkflowSettingsModule } from './workflows/workflows.module';
import { CoreModule } from '../../core/module';
import { DataModule } from '../../data/module';
import { UIModule } from '../../ui/module';
import * as angular from 'angular';

export const SettingsModule = angular
  .module('app.instance.settings', [
    CoreModule.name,
    DataModule.name,
    DocumentSettingsModule.name,
    OrganizationModule.name,
    UIModule.name,
    WorkflowSettingsModule.name
  ])

  // Factories
  .factory('ClaimTemplateManagerService', ClaimTemplateManagerService)
  .factory('EntityTemplateManagerService', EntityTemplateManagerService)

  // Services
  .service('GroupEntitySelectService', GroupEntitySelectService)
  .service('GroupSelectService', GroupSelectService)
  .service('RoleSelectService', RoleSelectService)
  .service('UserMultiSelectService', UserMultiSelectService)
  .service('UserSelectService', UserSelectService)

  // Controllers
  .controller('ClaimTypeEditController', ClaimTypeEditController)
  .controller('ClaimTypeNewController', ClaimTypeNewController)
  .controller('ClaimTypesListController', ClaimTypesListController)
  .controller('CostCategoryEditController', CostCategoryEditController)
  .controller('CostCategoryListController', CostCategoryListController)
  .controller('CostCategoryNewController', CostCategoryNewController)
  .controller('DelegationListController', DelegationListController)
  .controller('DelegationNewController', DelegationNewController)
  .controller('EmailTemplateEditController', EmailTemplateEditController)
  .controller('EmailTemplateListController', EmailTemplateListController)
  .controller('EmailTemplateNewController', EmailTemplateNewController)
  .controller('EntityTypeEditController', EntityTypeEditController)
  .controller('EntityTypeNewController', EntityTypeNewController)
  .controller('EntityTypesListController', EntityTypesListController)
  .controller('FieldAddModalController', FieldAddModalController)
  .controller('FieldEditController', FieldEditController)
  .controller('FieldListController', FieldListController)
  .controller('FieldNewController', FieldNewController)
  .controller('GroupEditController', GroupEditController)
  .controller('GroupListController', GroupListController)
  .controller('GroupNewController', GroupNewController)
  .controller('GroupSelectController', GroupSelectController)
  .controller('RoleEditController', RoleEditController)
  .controller('RoleListController', RoleListController)
  .controller('RoleNewController', RoleNewController)
  .controller('RoleSelectController', RoleSelectController)
  .controller('SavedSearchEditController', SavedSearchEditController)
  .controller('SavedSearchListController', SavedSearchListController)
  .controller('SavedSearchNewController', SavedSearchNewController)
  .controller('StatusDetailsController', StatusDetailsController)
  .controller('SumInsuredCategoryEditController', SumInsuredCategoryEditController)
  .controller('SumInsuredCategoryListController', SumInsuredCategoryListController)
  .controller('SumInsuredCategoryNewController', SumInsuredCategoryNewController)
  .controller('SystemFieldController', SystemFieldController)
  .controller('TaxCodeEditController', TaxCodeEditController)
  .controller('TaxCodeListController', TaxCodeListController)
  .controller('TaxCodeNewController', TaxCodeNewController)
  .controller('UserEditController', UserEditController)
  .controller('UserListController', UserListController)
  .controller('UserMultiSelectController', UserMultiSelectController)
  .controller('UserNewController', UserNewController)
  .controller('UserSelectController', UserSelectController)

  // Components
  .component('cioDelegationEdit', new DelegationEditComponent());
