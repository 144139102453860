import { TaskService } from './task.service';
import { TasksComponent } from './tasks.component';
import { TaskRoutes } from './tasks.routes';
import { CoreModule } from '../../core/module';
import { DataModule } from '../../data/module';
import { UIModule } from '../../ui/module';
import * as angular from 'angular';
import { TaskListComponent } from './taskList.component';
import { TaskDueDateEditComponent } from './taskDueDateEdit.component';

export const TasksModule = angular
  .module('app.instance.tasks', [DataModule.name, CoreModule.name, UIModule.name])
  // Routes
  .config(TaskRoutes)

  // Services
  .service('TaskService', TaskService)

  // Components
  .component('cioTasks', new TasksComponent())
  .component('cioTaskList', new TaskListComponent())
  .component('cioTaskDueDateEdit', new TaskDueDateEditComponent());
