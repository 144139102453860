import '../../core/services/security';

/**
 * @desc Hides an element if current user does not have required permission.
 * @example <element cio-permission="PermissionToCheck"></element>
 */
/* @ngInject */
export default function CioPermission($animate, SecurityService): any {
  return {
    restrict: 'A',
    multiElement: true,
    link: link
  };

  function link(scope, element, attrs) {
    if (!SecurityService.hasPermission(attrs.cioPermission)) {
      $animate.addClass(element, 'ng-hide', { tempClasses: 'ng-hide-animate' });
    }
  }
}
