import { ILogService } from '../../../core/services/log';
import { ITemplateService } from './template.service';

export class TemplateController {
  static $inject = ['LogService', 'TemplateService'];

  public data: any;
  public model: any;
  public type: string;
  public formDisabled: boolean;
  private logService: ILogService;
  private templateService: ITemplateService;

  /* @ngInject */
  constructor(logService: ILogService, templateService: ITemplateService) {
    this.logService = logService;
    this.templateService = templateService;
  }

  $onInit(): void {
    this.loadTemplate();
  }

  $onChanges(changes: any): void {
    if (changes.model) {
      this.model = changes.model.currentValue;
      this.loadTemplate();
    }
  }

  loadTemplate(): void {
    const that = this;

    this.templateService
      .createData(this.model, this.type)
      .then(function(data) {
        that.data = data;
      })
      .catch(function(error) {
        that.logService.error('Failed to load template', error);
      });
  }

  getField(id: string): any {
    return this.model.fields.find(f => {
      return f.id === id;
    });
  }

  getTemplateDefinition(sectionIndex, fieldIndex): any {
    return this.data.template.sections[sectionIndex].fields[fieldIndex];
  }
}
