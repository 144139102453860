import { FinancialEntitySelectController } from './financialEntitySelect.controller';
import { ComponentBase } from '../../../core/utils/componentBase';

export class FinancialEntitySelectComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      entityId: '<',
      validTypes: '<',
      onSelect: '&'
    };
    this.controller = FinancialEntitySelectController;
    this.template = require('./financialEntitySelect.html');
  }
}
