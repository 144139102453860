import { IReportFieldsService } from './modals/report-fields.service';
import { IReportFiltersService } from './modals/report-filters.service';
import { IReportSaveService } from './modals/report-save.service';
import { Report, ReportField, ReportFilter, ReportTypes } from '../../core/models';
import { IAlertService, IDownloadService } from '../../core/services';
import { ReportResource } from '../../data/resources/report';
import * as angular from 'angular';
import { StateService } from 'angular-ui-router';
import { Clock } from '../../core/utils';

/**
 * The route params for the component.
 */
type ReportRouteParams = {
  id?: string;
};

type ReportEvent = {
  report: Report;
};

export default class ReportController {
  static $inject = [
    '$http',
    '$state',
    '$stateParams',
    'ReportResource',
    'DownloadService',
    'ReportFieldsService',
    'ReportFiltersService',
    'ReportSaveService',
    'AlertService'
  ];

  public ready: boolean = false;
  public report: Report;

  constructor(
    private httpService: ng.IHttpService,
    private stateService: StateService,
    private stateParams: ReportRouteParams,
    private reportResource: ReportResource,
    private downloadService: IDownloadService,
    private reportFieldsService: IReportFieldsService,
    private reportFiltersService: IReportFiltersService,
    private reportSaveService: IReportSaveService,
    private alertService: IAlertService
  ) {}

  $onInit() {
    if (!this.stateParams.id) {
      this.ready = true;
      return;
    }

    this.getReport();
  }

  /**
   * Loads loads a saved report (if we are dealing with one).
   */
  getReport(): void {
    this.reportResource.get({ id: this.stateParams.id }).$promise.then((data: Report) => {
      this.report = data;
      this.ready = true;
    });
  }

  /**
   * Saves or updates the current report.
   */
  save(): void {
    this.reportSaveService.save(this.report).then((report: Report) => {
      if (!this.stateParams.id) {
        this.stateService.go('app.reports.run', { id: report.id });
      } else {
        this.report = report;
      }
    });
  }

  create($event: ReportEvent) {
    this.report = $event.report;

    if (this.report.type === ReportTypes.Claim) {
      this.editFields();
    }
  }

  update($event: ReportEvent) {
    this.report = angular.copy($event.report);
  }

  remove(report: Report) {
    this.httpService.delete<{}>(`/api/v1/reports/${report.id}`).then(response => {
      this.alertService.success(`${report.name} removed successfully.`);
      this.stateService.go('app.reports.list');
    });
  }

  /**
   * Edits the fields of the report and their configuration.
   */
  editFields() {
    this.reportFieldsService.selectFields(this.report).then((fields: ReportField[]) => {
      const report = angular.copy(this.report);
      report.fields = fields;
      this.report = report;
    });
  }

  /**
   * Edits the filters of the report and their configuration.
   */
  editFilters() {
    this.reportFiltersService
      .selectFilters(this.report)
      .then((filters: ReportFilter[]) => {
        const report = angular.copy(this.report);
        report.filters = filters;
        this.report = report;
      });
  }

  exportXlsx() {
    this.exportReport('xlsx');
  }

  exportCsv() {
    this.exportReport('csv');
  }

  exportPdf() {
    this.exportReport('pdf');
  }

  exportOds() {
    this.exportReport('ods');
  }

  exportReport(exportType: string) {
    const postData = {
      search: this.report,
      exportType: exportType
    };

    // CHECK: Report export dates is correct
    const now = Clock.local()
      .now()
      .toFormat('yyyy-MM-dd HH:mm');
    const fileName = `${this.report.name} (${now}).${exportType}`;

    this.downloadService.downloadPost('/api/v1/reports/export', postData, fileName);
  }
}
