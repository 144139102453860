import '../../../data/stores/savedSearch';

/* @ngInject */
export default function SavedSearchListController(
  $scope,
  $state,
  SavedSearchStore
): void {
  $scope.searches = [];

  init();

  function init() {
    SavedSearchStore.query().then(function(data) {
      $scope.searches = data.items;
    });
  }

  $scope.viewSearch = function(search) {
    $state.go('app.savedSearch.edit', { id: search.id });
  };

  $scope.newSearch = function() {
    $state.go('app.savedSearch.new');
  };
}
