import { StateProvider } from 'angular-ui-router';

/* @ngInject */
export function TaskRoutes($stateProvider: StateProvider) {
  $stateProvider.state('app.tasks', <any>{
    title: 'Tasks',
    url: '/tasks?view&complete&due&status',
    params: {
      due: {
        value: '',
        squash: true
      },
      status: {
        value: '',
        squash: true
      },
      view: {
        value: '',
        squash: true
      },
      complete: {
        value: undefined,
        squash: true
      }
    },
    reloadOnSearch: false,
    template: '<cio-tasks></cio-tasks>'
  });
}
