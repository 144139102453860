import { Report, ReportField } from '../../../core/models';
import ReportFieldsModalController from './report-fields-modal.controller';

/**
 * A service for selecting and configuring a report's fields.
 */
export interface IReportFieldsService {
  /**
   * Selects the fields and their configuration.
   *
   * @param {Report} report The report to select the fields for.
   * @returns {Promise<ReportField[]>} The selected fields (the original report is not modified).
   */
  selectFields(report: Report): ng.IPromise<ReportField[]>;
}

export default class ReportFieldsService implements IReportFieldsService {
  static $inject = ['$uibModal'];

  constructor(private $uibModal: ng.ui.bootstrap.IModalService) {}

  selectFields(report: Report): ng.IPromise<ReportField[]> {
    const modalInstance = this.$uibModal.open({
      template: require('./report-fields-modal.html'),
      controller: ReportFieldsModalController,
      controllerAs: '$ctrl',
      resolve: {
        report: function() {
          return report;
        }
      }
    });

    return <ng.IPromise<ReportField[]>>modalInstance.result;
  }
}
