import CioFileMultipickerComponent from './cio-file-multipicker/cio-file-multipicker.component';
import { CoreModule } from '../../core/module';
import { DataModule } from '../../data/module';
import { UIModule } from '../../ui/module';
import * as angular from 'angular';

export const EmailsModule = angular
  .module('app.instance.emails', [DataModule.name, CoreModule.name, UIModule.name])

  // Components
  .component('cioFileMultipickerComponent', new CioFileMultipickerComponent());
