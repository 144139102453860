import { ICurrencyHttpService } from '../../../core/http';
import { Currency } from '../../../core/models';

export class CurrencyViewController {
  static $inject = ['$scope', 'CurrencyHttpService'];

  title: string;
  text: string;
  private _currencies: Currency[] = [];
  private _currencyCode: string;
  private _currency: Currency;

  constructor(private scope: any, private currencyHttpService: ICurrencyHttpService) {}

  $onInit() {
    this.currencyHttpService
      .getAll()
      .safeApply(this.scope, currencies => {
        this._currencies = currencies;
        this.selectCurrency();
        this.setText();
      })
      .subscribe();
  }

  set currency(value: string) {
    this._currencyCode = value;
    this.selectCurrency();
    this.setText();
  }

  private selectCurrency() {
    if (!this._currencyCode) {
      return;
    }
    this._currency = this._currencies.find(
      x => x.code.toUpperCase() === this._currencyCode.toUpperCase()
    );
  }

  private setText() {
    if (!this._currencyCode) {
      this.text = '';
      this.title = '';
      return;
    }
    this.text = this._currencyCode;
    if (!this._currency) {
      this.title = this._currencyCode;
      return;
    }
    this.title = `${this._currency.name} (${this._currency.code})`;
  }
}
