import { HttpService } from './http';
import { Guid, Page, RecordReference, Task } from '../models';
import { ITrackingService } from '../services';
import { logError, QueryParams } from '../utils';
import { DateTime } from 'luxon';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';

export enum TaskSearchStatuses {
  All = 'all',
  Incomplete = 'incomplete',
  Complete = 'complete'
}

export class TaskSearch {
  take: number;
  skip: number = 0;
  text?: string;
  from?: DateTime;
  to?: DateTime;
  due: string;
  sortDescending: boolean = false;
  sort: string = 'dueDate';
  assignee: Guid;
  subscribers: Guid[] = [];
  associations: RecordReference[] = [];
  status: TaskSearchStatuses = TaskSearchStatuses.All;

  toParams(): string {
    const params = new QueryParams();
    if (this.skip > 0 && !isNaN(this.skip)) {
      params.set('skip', Math.max(0, this.skip || 0).toString());
    }
    params.set('take', Math.max(1, isNaN(this.take) ? 1 : this.take).toString());
    params.set('status', this.status);
    if (this.assignee) {
      params.set('assignees', this.assignee.toString());
    }
    if (this.sortDescending) {
      params.set('sortDescending', this.sortDescending ? 'true' : 'false');
    }
    if (this.sort) {
      params.set('sort', this.sort);
    }
    if (this.text) {
      params.set('text', this.text);
    }
    if (this.from) {
      params.set('from', this.from.toISO());
    }
    if (this.to) {
      params.set('to', this.to.toISO());
    }
    if (this.subscribers && this.subscribers.length > 0) {
      params.set(
        'subscribers',
        this.subscribers.map(subscriber => subscriber.toString()).join('|')
      );
    }
    if (this.associations && this.associations.length > 0) {
      params.set(
        'associations',
        this.associations.map(association => association.toString()).join('|')
      );
    }
    return params.toString();
  }
}

export interface ITaskHttpService {
  getTask(taskId: Guid): Observable<Task>;
  createTask(task: Task): Observable<Task>;
  modifyTask(task: Task, isCompleted?: boolean): Observable<Task>;
  deleteTask(taskId: Guid): Observable<boolean>;
  findTasks(search: TaskSearch): Observable<Page<Task>>;
  sendDigest(userId: Guid): Observable<void>;
}

export class TaskHttpService implements ITaskHttpService {
  static $inject = ['$window', 'HttpService', 'TrackingService'];

  private readonly _url: string = '/api/v1/tasks';

  constructor(
    private window: any,
    private httpService: HttpService,
    private trackingService: ITrackingService
  ) {}

  getTask(taskId: Guid): Observable<Task> {
    const url = `${this._url}/${taskId.toString()}`;
    return this.httpService
      .get(url)
      .map(res => Task.fromJson(res.json()))
      .catch(logError);
  }

  createTask(task: Task): Observable<Task> {
    const body = JSON.stringify(task);
    return this.httpService
      .post(this._url, body)
      .map(res => Task.fromJson(res.json()))
      .do(t => {
        this.trackTaskCreated(t);
      })
      .catch(logError);
  }

  modifyTask(task: Task, isCompleted?: boolean): Observable<Task> {
    const url = `${this._url}/${task.id.toString()}`;
    const body = JSON.stringify(task);
    return this.httpService
      .put(url, body)
      .map(res => Task.fromJson(res.json()))
      .do(t => {
        this.trackTaskModified(t, isCompleted);
      })
      .catch(logError);
  }

  deleteTask(taskId: Guid): Observable<boolean> {
    const url = `${this._url}/${taskId.toString()}`;
    return this.httpService
      .delete(url)
      .map(_ => true)
      .do(() => {
        this.trackTaskDeleted(taskId);
      })
      .catch(logError);
  }

  findTasks(search: TaskSearch): Observable<Page<Task>> {
    const url = `${this._url}/search?${search.toParams()}`;
    return this.httpService
      .get(url)
      .map(res => Page.fromJson(res.json(), item => Task.fromJson(item)))
      .catch(logError);
  }

  sendDigest(userId: Guid): Observable<void> {
    const url = `${this._url}/digest/send`;
    return this.httpService
      .post(url, {
        users: [userId.toString()]
      })
      .map(_ => {})
      .catch(logError);
  }

  private trackTaskCreated(task: Task): Task {
    this.trackingService.increment('Tasks Created');
    this.trackingService.track('Task Created', TaskHttpService.getTrackingFields(task));
    return task;
  }

  private trackTaskModified(task: Task, isCompleted?: boolean): Task {
    const fields = TaskHttpService.getTrackingFields(task);
    this.trackingService.increment('Tasks Updated');
    this.trackingService.track('Task Updated', fields);
    if (isCompleted) {
      this.trackingService.track('Task Completed', fields);
    }
    return task;
  }

  private trackTaskDeleted(taskId: Guid): void {
    this.trackingService.increment('Tasks Deleted');
    this.trackingService.track('Task Deleted', { 'Task ID': taskId });
  }

  private static getTrackingFields(task: Task): any {
    return {
      'Task ID': task.id.toString(),
      'Is Overdue': task.isOverdue,
      'Has Assignee': task.hasAssignee
    };
  }
}
