/* @ngInject */
export function SystemTemplateField(Cache, $q: ng.IQService): any {
  let fields = [];

  return {
    activate: activate,
    getField: getField,
    allStatus: allStatus
  };

  function activate() {
    return Cache.fields().then(function(data) {
      fields = data;
    });
  }

  function getField(systemField) {
    if (!systemField) {
      return null;
    }

    return fields.find(f => f.id === systemField.fieldId);
  }

  function allStatus() {
    const deferred = $q.defer();

    Cache.claimTemplates().then(function(templates) {
      const statusObj = {};

      templates.forEach(function(tpl) {
        const statusField = tpl.systemFields.find(x => x.name === 'status');
        const field = getField(statusField);
        if (field) {
          field.settings.status.forEach(function(sts) {
            statusObj[sts.name.replace(' ', '_')] = sts;
          });
        }
      });

      deferred.resolve({ status: statusObj });
    });

    return deferred.promise;
  }
}
