/**
 * All possible report types.
 *
 * @export
 * @class ReportTypes
 */
export class ReportTypes {
  /**
   * A claim report.
   *
   * @readonly
   * @static
   * @type {string}
   * @memberOf ReportTypes
   */
  public static get Claim(): string {
    return 'claim';
  }

  /**
   * A Timesheet report.
   *
   * @readonly
   * @static
   * @type {string}
   * @memberOf ReportTypes
   */
  public static get Timesheet(): string {
    return 'timesheet';
  }

  /**
   * A Invoice Summary report.
   *
   * @readonly
   * @static
   * @type {string}
   * @memberOf ReportTypes
   */
  public static get InvoiceSummary(): string {
    return 'invoice_summary';
  }

  /**
   * A Invoice Detail report.
   *
   * @readonly
   * @static
   * @type {string}
   * @memberOf ReportTypes
   */
  public static get InvoiceDetail(): string {
    return 'invoice_detail';
  }

  /**
   * A Payment Summary report.
   *
   * @readonly
   * @static
   * @type {string}
   * @memberOf ReportTypes
   */
  public static get PaymentSummary(): string {
    return 'payment_summary';
  }

  /**
   * A Payment Detail report.
   *
   * @readonly
   * @static
   * @type {string}
   * @memberOf ReportTypes
   */
  public static get PaymentDetail(): string {
    return 'payment_detail';
  }
}
