import { Guid } from './guid';
import { DateTime } from 'luxon';
import { DateTimeHelper } from '../utils';
import { colorPalettes, IColor } from '../../instance/config';

export class Tag {
  id: Guid;
  name: string;
  description: string;
  color: string;
  created: DateTime;
  lastModified: DateTime;
  lastModifiedBy: Guid;

  static fromJson(json: any): Tag {
    const tag = Object.assign(new Tag(), json) as Tag;
    tag.id = new Guid(json.id);
    tag.created = DateTimeHelper.parseUtcDateTime(json.created);
    tag.lastModified = DateTimeHelper.parseUtcDateTime(json.lastModified);
    tag.lastModifiedBy = new Guid(json.lastModifiedBy);

    return tag;
  }

  static useWhiteColor(hex: string) {
    let color: IColor;
    colorPalettes.forEach(x => {
      const result = x.colors.find(y => y.hex === hex);
      if (result) {
        color = result;
      }
    });
    if (color) {
      return color.useWhiteColor;
    }
    return true;
  }
}
