import { ICountryService } from '../../../core/services/country';

export class CountryFieldController {
  static $inject = ['CountryService'];

  public countries: string[];

  constructor(public CountryService: ICountryService) {
    this.countries = CountryService.getCountries();
  }
}
