import { IAlertService } from '../../core/services/alert';
import '../../data/resources/entity';

/* @ngInject */
export default function EntityNewController(
  $scope,
  $state,
  $stateParams,
  Cache,
  EntityResource,
  AlertService: IAlertService
) {
  $scope.templateId = $stateParams.templateId;
  $scope.model = {
    templateId: $scope.templateId
  };

  Cache.entityTemplate($scope.templateId).then(function(template) {
    $scope.template = template;
  });

  $scope.save = function() {
    if (!$scope.formNewEntity.$valid) {
      return;
    }

    EntityResource.save($scope.model)
      .$promise.then(function(saved) {
        $scope.formNewEntity.$setPristine();
        AlertService.success($scope.template.name + ' successfully created.');
        $state.go('app.entity.edit', { entityId: saved.id });
      })
      .catch(function(error) {
        AlertService.error(
          'Could not create ' + $scope.template.name + ', please try again.'
        );
      });
  };
}
