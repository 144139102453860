// https://medium.com/@alSkachkov/using-async-await-function-in-angular-1-5-babel-6-387f7c43948c

export class DecoratorHelper {
  injectorInstance: any;
  get injector() {
    return this.injectorInstance;
  }
  set injector(injector) {
    this.injectorInstance = injector;
  }
}

export const decoratorHelper = new DecoratorHelper();

/**
 * Current decorator allow async function to be used in angular
 * without adding unnecessary digest calls
 */
export const ngAsync = function() {
  return function(target, key, descriptor) {
    const fn = descriptor.value;
    descriptor.value = function() {
      const result = fn.apply(this, arguments);

      // To work our decorator we need injector, but we
      // managed to access $injector, which is set in the run phase
      const $injector = decoratorHelper.injector;

      $injector.invoke(
        /* @ngInject */ ($rootScope, $q) => {
          $q.when(result)
            .then(() => $rootScope.$applyAsync())
            .catch(err => console.log(err));
        }
      );
    };
  };
};
