import { IAlertService, ISecurityService } from '../../../core/services';
import { IClaimManagerService } from '../claim-manager-service';
import reject from 'lodash/reject';
import * as ng from 'angular';

interface IEmailTemplate {
  body: string;
  subject: string;
}

/* @ngInject */
export default function EmailModalController(
  $scope: any,
  $http: ng.IHttpService,
  $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
  emailTemplate: IEmailTemplate,
  claimId: string,
  ClaimManagerService: IClaimManagerService,
  SecurityService: ISecurityService,
  AlertService: IAlertService
) {
  if (!emailTemplate.body) {
    const user = SecurityService.getCurrentUser();
    if (user.emailSignature) {
      emailTemplate.body = user.emailSignature;
    }
  }

  $scope.folderId = ClaimManagerService.claim().folderId;
  $scope.attachments = [];
  $scope.contacts = {
    to: [],
    cc: [],
    bcc: []
  };

  $scope.email = {
    subject: emailTemplate.subject || '',
    body: emailTemplate.body
  };

  $scope.searchEmails = [];

  $scope.findEmails = function(search) {
    $http({
      url: '/api/v1/emails/search',
      method: 'GET',
      headers: {
        'Content-Type': 'application/json'
      },
      data: { q: search }
    })
      .then(function(result: any) {
        $scope.searchEmails = result.data.items || [];
      })
      .catch(function() {
        AlertService.error("Couldn't search emails, please try again");
      });
  };

  $scope.saveNewEmail = function(term) {
    const itemFound = $scope.searchEmails.find(x => x.id === term.id);
    if (!itemFound) {
      // Removes the id content from term because it's not a valid one.
      term.id = null;

      $http({
        url: '/api/v1/emails/suggestions',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data: term
      });
    }
  };

  $scope.createEmail = function createChoice(term) {
    // There is a bug in ui-select where returning nothing or null
    // actually raises an error - so returning invlaid email for now
    // https://github.com/angular-ui/ui-select/issues/755

    return { id: term, text: term, name: term, email: term };
  };

  $scope.$watchCollection('contacts.to', function(newNames) {
    $scope.email.to = '';
    if (newNames && newNames.length) {
      $scope.email.to = newNames.map(x => x.email).join(',');
    }
  });

  $scope.$watchCollection('contacts.cc', function(newNames) {
    $scope.email.cc = '';
    if (newNames && newNames.length) {
      $scope.email.cc = newNames.map(x => x.email).join(',');
    }
  });

  $scope.$watchCollection('contacts.bcc', function(newNames) {
    $scope.email.bcc = '';
    if (newNames && newNames.length) {
      $scope.email.bcc = newNames.map(x => x.email).join(',');
    }
  });

  $scope.grabAttachments = function(selectedAttachments) {
    (selectedAttachments || []).forEach(att => {
      $scope.attachments.push(att);
    });
  };

  $scope.removeAttachment = function(att) {
    $scope.attachments = reject($scope.attachments, { id: att.id });
  };

  $scope.send = function() {
    if (!$scope.email) {
      return;
    }

    const hasRecipients = $scope.email.to || $scope.email.cc || $scope.email.bcc;

    if (!hasRecipients) {
      AlertService.warn('No recipients are set');
      return;
    }

    const hasValidRecipients =
      isValidList('To', $scope.email.to) &&
      isValidList('CC', $scope.email.cc) &&
      isValidList('BCC', $scope.email.bcc);

    if (!hasValidRecipients) {
      return;
    }

    $scope.email.documents = $scope.attachments.map(x => x.id);
    $uibModalInstance.close($scope.email);
  };

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };

  /**
   * Validates if the specified string contains valid recipients.
   *
   * @param {string} list The name of the recipient list.
   * @param {string} recipientList The list of recipients.
   */
  function isValidList(list: string, recipientList: string) {
    if ((recipientList || '').length === 0) {
      return true;
    }

    const theEmailRegex = new RegExp(
      '' +
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@/.source +
        /((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          .source
    );

    const recipients = recipientList.split(',');
    for (const recipient of recipients) {
      if (recipient.length > 0 && !theEmailRegex.test(recipient)) {
        AlertService.warn('Invalid ' + list + " email '" + recipient + "'");
        return false;
      }
    }

    return true;
  }
}
