export class SortDirections {
  /**
   * Sort in ascending order.
   *
   * @readonly
   * @static
   * @type {string}
   * @memberOf SortDirections
   */
  public static get Ascending(): string {
    return 'asc';
  }

  /**
   * Sort in descending order.
   *
   * @readonly
   * @static
   * @type {string}
   * @memberOf SortDirections
   */
  public static get Descending(): string {
    return 'desc';
  }
}
