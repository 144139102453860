import { EntityLinkController } from './entityLink.controller';
import { ComponentBase } from '../../../core/utils/componentBase';

export class EntityLinkComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      id: '<',
      text: '<'
    };
    this.controller = EntityLinkController;
    this.template = require('./entityLink.html');
  }
}
