import { ISessionService } from '../../core/services';
import * as ng from 'angular';

/* @ngInject */
export default function AuthService(
  $q: ng.IQService,
  $http: ng.IHttpService,
  SessionService: ISessionService
): any {
  return {
    login: login
  };

  /**
   * Attempts to authenticate the specified user.
   */
  function login(email, password) {
    const deferred = $q.defer();

    const data = {
      username: email,
      password: password,
      host: window.location.hostname
    };

    $http({
      method: 'POST',
      url: '/api/v1/auth/token',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
      },
      transformRequest: function(obj) {
        const str = [];
        for (const key in obj) {
          if (obj.hasOwnProperty(key)) {
            str.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
          }
        }
        return str.join('&');
      },
      data: data
    })
      .then(loginSuccess)
      .catch(loginFailure);

    return deferred.promise;

    /**
     * Handles a successful login.
     */
    function loginSuccess(response) {
      SessionService.setSession(response.data);
      deferred.resolve({});
    }

    /**
     * Handles a failed login.
     */
    function loginFailure(err) {
      deferred.reject(err);
    }
  }
}
