const REGEX_EXTENSION = /.+(\.[0-9a-z]+)$/i;
const REGEX_FILENAME = /(.+)(\.[0-9a-z]+)$/i;

export const FileHelper = {
  sanitizeFileName(fileName: string): string {
    return (fileName || '').replace(/[\\<>#:""/|?*]/g, '');
  },

  getExtension(name: string): string {
    const match = (name || '').match(REGEX_EXTENSION);
    return !match ? '' : match[1];
  },

  getNameWithoutExtension(name: string): string {
    const match = (name || '').match(REGEX_FILENAME);
    return !match ? name : match[1];
  }
};
