import EmailModalController from './email-modal-controller';
import { EmailTemplate, SendEmail } from '../../../core/models';
import { ILogService } from '../../../core/services';
import ng from 'angular';

export interface IEmailModalService {
  sendEmail(
    claimId: string,
    template: EmailTemplate,
    cb: (email: SendEmail) => void
  ): void;
}

/* @ngInject */
export default function EmailModalService(
  $uibModal: ng.ui.bootstrap.IModalService,
  $http: ng.IHttpService,
  LogService: ILogService,
  ClaimManagerService
): IEmailModalService {
  return {
    sendEmail: sendEmail
  };

  function sendEmail(
    claimId: string,
    template: EmailTemplate,
    cb: (email: SendEmail) => void
  ) {
    const templateOrEmpty = template || {
      body: '',
      subject: '',
      claimId: ClaimManagerService.claim().id
    };

    $http
      .post('/api/v1/emails/parse', JSON.stringify(templateOrEmpty))
      .then(function(response: any) {
        const data = response.data;
        const tempTemplate = ng.copy(templateOrEmpty);
        tempTemplate.body = data.body;
        tempTemplate.subject = data.subject;

        callModal(claimId, tempTemplate, cb);
      })
      .catch(function(err) {
        LogService.error(err, { action: 'Send email failed' });
      });
  }

  function callModal(claimId, template, cb) {
    const modalInstance = $uibModal.open({
      template: require('./email-modal.html'),
      controller: EmailModalController,
      resolve: {
        claimId: function() {
          return claimId;
        },
        emailTemplate: function() {
          return template || {};
        }
      },
      backdrop: 'static',
      keyboard: false
    });

    modalInstance.result
      .then(function(email) {
        cb(email);
      })
      .catch(() => {});
  }
}
