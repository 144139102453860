import CioFileMultipickerController from './cio-file-multipicker.controller';

export default class CioFileMultipickerComponent implements ng.IComponentOptions {
  public controller: any;
  public template: string;
  public bindings: any;

  constructor() {
    this.bindings = {
      callbackFn: '<',
      buttonText: '<',
      folderId: '<'
    };
    this.controller = CioFileMultipickerController;
    this.template = require('./cio-file-multipicker.html');
  }
}
