/* @ngInject */
export default function ClaimTypeSelectModalController($uibModalInstance, $state, types) {
  const vm = this;
  vm.types = types;

  vm.select = function(item) {
    $uibModalInstance.close(item);
    $state.go('app.claim.new', { templateId: item.id });
  };

  vm.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };
}
