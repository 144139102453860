import { UserAvatarController } from './userAvatar.controller';
import { ComponentBase } from '../../../core/utils/componentBase';

export class UserAvatarComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      user: '<',
      userId: '<',
      size: '<',
      showName: '<'
    };
    this.controller = UserAvatarController;
    this.template = require('./userAvatar.html');
  }
}
