import { ISearchColumn } from './search.interfaces';

export class SearchHeaderController {
  static $inject = [];

  sortDescending: boolean;
  private _sort: string;
  private _isSort: boolean;
  private _column: ISearchColumn;

  get sort(): string {
    return this._sort;
  }

  set sort(value: string) {
    this._sort = value;
    if (this.column) {
      this._isSort = this.column.fieldName === value;
    }
  }

  get column(): ISearchColumn {
    return this._column;
  }

  set column(value: ISearchColumn) {
    this._column = value;
    this._isSort = value.fieldName === this._sort;
  }

  get isSort(): boolean {
    return this._isSort;
  }

  toggleSort(): void {
    if (!this.isSort) {
      this.setSort(false);
      return;
    }
    this.setSort(!this.sortDescending);
  }

  setSort(sortDescending: boolean): void {
    (this as any).onSort({ column: this.column, sortDescending });
  }
}
