import { DateViewController } from './dateView.controller';
import { ComponentBase } from '../../../core/utils/componentBase';

export class DateViewComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      date: '<'
    };
    this.controller = DateViewController;
    this.template = require('./dateView.html');
  }
}
