import { Invoice } from '../../core/models';
import * as ng from 'angular';

/* tslint:disable-next-line:no-empty-interface */
export interface InvoiceResource
  extends ng.resource.IResourceClass<ng.resource.IResource<Invoice>> {
  update(invoice: Invoice): ng.resource.IResource<Invoice>;
}

/* @ngInject */
export default function InvoiceResource(
  $resource: ng.resource.IResourceService
): InvoiceResource {
  return <InvoiceResource>$resource<ng.resource.IResource<Invoice>>(
    '/api/v1/invoices/:id',
    { id: '@id' },
    {
      update: {
        method: 'PUT',
        params: {
          id: '@id'
        }
      },
      query: { method: 'GET' }
    }
  );
}
