import { ISearchColumn, IOnSelectRow } from './search.interfaces';
import { Guid } from '../../core/models';
import { SearchRow } from './searchRow';

export class SearchCellController {
  static $inject = [];

  values: any[] = [];
  onSelect: IOnSelectRow;
  recordType: string;
  clickable: boolean;
  private _row: SearchRow;
  private _column: ISearchColumn;

  get displayType(): string {
    switch (this.column.fieldName.toLowerCase()) {
      case 'reference':
      case 'title':
        return 'reference';

      case 'status':
        return 'statusset';

      case 'assignees':
        return 'user[]';

      case 'isclosed':
      case 'is_closed':
        return 'boolean';

      default:
        return this.column.fieldType.toLowerCase();
    }
  }

  get column(): ISearchColumn {
    return this._column;
  }

  set column(value: ISearchColumn) {
    this._column = value;
    this.setValues();
  }

  set row(value: SearchRow) {
    this._row = value;
    this.setValues();
  }

  get templateId(): Guid {
    return this._row.templateId;
  }

  get id(): Guid {
    return this._row.id;
  }

  select() {
    this.onSelect({ row: this._row });
  }

  private setValues() {
    if (!this._column || !this._row) {
      this.values.length = 0;
      return;
    }

    const found = this._row.getValueByName(this._column.fieldName);
    if (!found) {
      this.values.length = 0;
      return;
    }

    this.values.length = 0;
    this.values.push.apply(this.values, found.values);
  }
}
