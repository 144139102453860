import { Guid } from './guid';

export class SearchField {
  id?: Guid;
  name: string;
  type: string;

  static fromJson(json: any): SearchField {
    const field = Object.assign(new SearchField(), json) as SearchField;
    field.id = json.id ? new Guid(json.id) : undefined;
    return field;
  }
}

export interface ISearchValue {
  name: string;
  values: any[];
}

export class SearchValue implements ISearchValue {
  name: string;
  values: any[] = [];

  toString() {
    if (!this.values) {
      return '';
    }
    return this.values.join(', ');
  }

  static fromJson(json: any): SearchValue {
    return Object.assign(new SearchValue(), json) as SearchValue;
  }
}
