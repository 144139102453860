import CioFileMultipickerModalController from './cio-file-multipicker-modal.controller';
import { Guid, StorageFile } from '../../../core/models';
import { IPopupManager } from '../../../core/utils';

export default class CioFileMultipickerController {
  static $inject = ['$uibModal', 'PopupManager'];

  folderId: Guid;
  callbackFn: any;
  searchTerm: string;
  buttonText: string;

  constructor(private uibModal: any, private popupManager: IPopupManager) {}

  openModal(): void {
    const modalInstance: any = this.uibModal.open({
      template: require('./cio-file-multipicker-modal.html'),
      controller: CioFileMultipickerModalController,
      controllerAs: '$ctrl',
      size: 'lg',
      resolve: {
        folderId: () => {
          return this.folderId;
        },
        popupManager: () => {
          return this.popupManager;
        }
      }
    });

    modalInstance.result.then((selectedFiles: StorageFile[]) => {
      this.callbackFn(selectedFiles);
    });
  }
}
