import {
  DropdownFieldDefinition,
  DropdownFieldDefinitionOption,
  IWorkflowFilter
} from '../../../../core/models';
import { ICacheService } from '../../../services/cache';

export class WorkflowFilterClaimTypeController {
  static $inject = ['Cache'];

  filter: IWorkflowFilter;
  options: DropdownFieldDefinitionOption[] = [];
  private _field: DropdownFieldDefinition;

  constructor(private cacheService: ICacheService) {}

  get field(): DropdownFieldDefinition {
    return this._field;
  }

  set field(value: DropdownFieldDefinition) {
    this._field = value;
    this.options = [];

    this.cacheService.claimTemplates().then(templates => {
      this.options = templates.map(x => {
        return { value: x.name, label: x.name };
      });
    });
  }
}
