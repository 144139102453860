import { DateTime } from 'luxon';
import { Clock } from './clock';

export const DateTimeHelper = {
  /**
   * Parses the specified date time as local.
   *
   * Important: The date time is converted to local if in another
   * timezone.
   *
   * @param {DateTime | Date | string | undefined} value The value to parse.
   * @returns {DateTime | undefined} The local date time or undefined.
   */
  parseLocalDateTime(value: DateTime | Date | string | undefined): DateTime {
    if (value === undefined || value === null) {
      return undefined;
    }
    if (value instanceof DateTime) {
      return value.toLocal();
    }
    if (value instanceof Date) {
      return DateTime.fromJSDate(value as any).toLocal();
    }
    if (typeof value === 'string' || (value as any) instanceof String) {
      let date = DateTime.fromISO(value);
      if (date.isValid) {
        return date.toLocal();
      }
      date = DateTime.fromSQL(value, { locale: 'en-US' });
      if (date.isValid) {
        return date.toLocal();
      }
      console.warn(`Invalid date '${value}' for parseLocalDateTime`);
      return undefined;
    }
    return undefined;
  },

  /**
   * Parses the specified date time as UTC.
   *
   * Important: The date time is converted to UTC if in another
   * timezone.
   *
   * @param {DateTime | Date | string | undefined} value The value to parse.
   * @returns {DateTime | undefined} The UTC date time or undefined.
   */
  parseUtcDateTime(value: DateTime | Date | string | undefined): DateTime {
    if (value === undefined || value === null) {
      return undefined;
    }
    if (value instanceof DateTime) {
      return value.toUTC();
    }
    if (value instanceof Date) {
      return DateTime.fromJSDate(value as any).toUTC();
    }
    if (typeof value === 'string' || (value as any) instanceof String) {
      let date = DateTime.fromISO(value, { zone: 'UTC' });
      if (date.isValid) {
        return date.toUTC();
      }
      date = DateTime.fromSQL(value, { zone: 'UTC', locale: 'en-US' });
      if (date.isValid) {
        return date.toUTC();
      }
      console.warn(`Invalid date '${value}' for parseUtcDateTime`);
      return undefined;
    }
    return undefined;
  },

  /**
   * Parses the specified date as UTC (ignores any time component).
   *
   * Important: The date time is converted to UTC if in another
   * timezone.
   *
   * @param {DateTime | Date | string | undefined} value The value to parse.
   * @returns {DateTime | undefined} The UTC date time or undefined.
   */
  parseUtcDate(value: DateTime | Date | string | undefined): DateTime {
    if (value === undefined || value === null) {
      return undefined;
    }
    if (value instanceof DateTime) {
      return value.toUTC().startOf('day');
    }
    if (value instanceof Date) {
      return DateTime.fromJSDate(value as any)
        .toUTC()
        .startOf('day');
    }
    if (typeof value === 'string' || (value as any) instanceof String) {
      let date = DateTime.fromISO(value, { zone: 'UTC' });
      if (date.isValid) {
        return date.toUTC().startOf('day');
      }
      date = DateTime.fromSQL(value, { zone: 'UTC', locale: 'en-US' });
      if (date.isValid) {
        return date.toUTC().startOf('day');
      }
      console.warn(`Invalid date '${value}' for parseUtcDate`);
      return undefined;
    }
    return undefined;
  },

  /**
   * Extracts the date component from the specified date as the
   * UTC date (without timezone conversion).
   *
   * Important: No timezone conversions are done.
   *
   * @param {Date} jsDate The javascript date.
   * @returns {DateTime}
   */
  extractUtcDate(jsDate: Date): DateTime {
    if (!jsDate) {
      return undefined;
    }
    return DateTime.fromISO(jsDate.toISOString(), { zone: 'utc' }).startOf('day');
  },

  /**
   * Extracts the date component from the specified date as the
   * local date (without timezone conversion).
   *
   * Important: No timezone conversions are done.
   *
   * @param {Date} jsDate The javascript date.
   * @returns {DateTime}
   */
  extractLocalDate(jsDate: Date): DateTime {
    if (!jsDate) {
      return undefined;
    }
    return DateTime.local(jsDate.getFullYear(), jsDate.getMonth() + 1, jsDate.getDate());
  },

  /**
   * Compares the date component of two dates, irrespective of
   * time or timezone.
   *
   * Important: No timezone conversions are done of the instants.
   *
   * @param {DateTime} instant1 The first date time.
   * @param {DateTime} instant2 The second date time.
   * @returns {Boolean} True if the same dates, else false.
   */
  isEqualDate(instant1: DateTime, instant2: DateTime): boolean {
    return (
      instant1.year === instant2.year &&
      instant1.month === instant2.month &&
      instant1.day === instant2.day
    );
  },

  /**
   * Checks if the date of the specified instant is today for the
   * local time.
   *
   * Important: No timezone conversions are done of the instants.
   *
   * @param {DateTime} instant The date time.
   */
  isDateToday(instant: DateTime) {
    return this.isEqualDate(Clock.local().startOfToday(), instant);
  },

  /**
   * Checks if the date of the specified instant is yesterday for the
   * local time.
   *
   * Important: No timezone conversions are done of the instants.
   *
   * @param {DateTime} instant The date time.
   */
  isDateYesterday(instant: DateTime) {
    return this.isEqualDate(Clock.local().startOfYesterday(), instant);
  },

  /**
   * Checks if the date of the specified instant is tomorrow for the
   * local time.
   *
   * Important: No timezone conversions are done of the instants.
   *
   * @param {DateTime} instant The date time.
   */
  isDateTomorrow(instant: DateTime) {
    return this.isEqualDate(Clock.local().startOfTomorrow(), instant);
  },

  /**
   * Checks if the date of the specified instant is tomorrow for the
   * local time.
   *
   * Important: No timezone conversions are done of the instants.
   *
   * @param {DateTime} instant The date time.
   */
  isDateThisYear(instant: DateTime) {
    return this.isEqualDate(Clock.local().startOfTomorrow(), instant);
  }
};
