import UserController from './cio-user-controller';

export default class CioUserComponent implements ng.IComponentOptions {
  public controller: any;
  public bindings: any;

  /**
   * Selects the correct template based off the mode.
   */
  static _selectTemplate(mode) {
    switch (mode) {
      case 'edit':
        return require('./cio-user-edit.html');
      case 'link':
        return require('./cio-user-link.html');
      default:
        return require('./cio-user-none.html');
    }
  }

  constructor() {
    this.bindings = {
      id: '=',
      type: '@',
      mode: '@', // none, link, edit
      group: '@', // in case a group is specified, user will be filtered by that group
      emptyText: '@',
      show: '@',
      displayRemove: '@',
      selectChange: '&',
      removeClick: '&'
    };
    this.controller = UserController;
  }

  /**
   * Returns the template for the component.
   */
  /* @ngInject */
  template($element, $attrs) {
    return CioUserComponent._selectTemplate($attrs.mode);
  }
}
