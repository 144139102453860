import CioImageController from './cio-image-controller';

export default class CioImage implements ng.IComponentOptions {
  public controller: any;
  public template: string;
  public bindings: any;

  constructor() {
    this.bindings = {
      src: '<'
    };
    this.controller = CioImageController;
    this.template = require('./cio-image.html');
  }
}
