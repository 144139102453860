import { Guid } from './guid';
import { DateTime } from 'luxon';
import { DateTimeHelper } from '../../core/utils';

export class Note {
  id: Guid;
  claimId: Guid;
  content: string;
  created: DateTime;
  createdBy: Guid;
  lastModified: DateTime;
  lastModifiedBy: Guid;

  static fromJson(json: any): Note {
    const note = Object.assign(new Note(), json) as Note;
    note.id = new Guid(json.id);
    note.claimId = new Guid(json.claimId);
    note.created = DateTimeHelper.parseUtcDateTime(json.created);
    note.createdBy = new Guid(json.createdBy);
    note.lastModified = DateTimeHelper.parseUtcDateTime(json.lastModified);
    note.lastModifiedBy = new Guid(json.lastModifiedBy);
    return note;
  }
}
