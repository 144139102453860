import { Tag } from '../../core/models';

/**
 * @desc Changes the background color of the bootstrap ui-select elements.
 * @example <element cio-tags="#FFFFFF"></element>
 */
/* @ngInject */
export function CioTags(): any {
  return {
    restrict: 'A',
    multiElement: true,
    link: link
  };

  function link(scope, element, attrs) {
    updateColor(element, attrs.cioTags);
    element
      .parent()
      .parent()[0]
      .querySelector('.close')
      .addEventListener('click', fixOnRemoveColors);
  }

  /**
   * Event needed because on remove a tag the background color doesn't change
   *
   * @param e: MouseEvent
   */
  function fixOnRemoveColors(e) {
    const rootElement = e.target.parentNode.parentNode.parentNode;
    if (!rootElement) {
      return;
    }

    rootElement.childNodes.forEach(x => {
      if (!x.querySelector) {
        return;
      }
      const elementColor = x.querySelector('.ng-binding').getAttribute('cio-tags');

      const elementUseWhiteColor = Tag.useWhiteColor(elementColor);
      if (x.children) {
        x.children[0].style.backgroundColor = elementColor;
        x.children[0].style.color = elementUseWhiteColor ? '#FFFFFF' : '#000000';
      }
    });
  }

  function updateColor(element, hex) {
    const useWhiteColor = Tag.useWhiteColor(hex);
    element
      .parent()
      .parent()
      .css({
        'background-color': hex,
        color: useWhiteColor ? '#FFFFFF' : '#000000',
        'border-color': 'transparent'
      });
  }
}

/**
 * @desc Changes the background color of the bootstrap ui-select elements.
 * @example <element cio-tags-select="#FFFFFF"></element>
 */
/* @ngInject */
export function CioTagsSelect(): any {
  return {
    restrict: 'A',
    multiElement: true,
    link: link
  };

  function link(scope, element, attrs) {
    const useWhiteColor = Tag.useWhiteColor(attrs.cioTagsSelect);
    element.parent().css({
      padding: '8px 10px',
      color: useWhiteColor ? '#FFFFFF' : '#000000'
    });
  }
}
