import { ICacheService } from '../../services/cache';
import { Guid, User } from '../../../core/models';

export class UserSelectController {
  static $inject = ['$scope', 'Cache'];

  selected: string;
  selectedGuid: Guid;
  users: User[] = [];
  cleanup: () => {};

  constructor(private scope: any, private cacheService: ICacheService) {}

  $onInit() {
    this.loadUsers();
    this.selectedGuid = this.selected && new Guid(this.selected);

    this.cleanup = this.scope.$watch(
      scope => {
        return this.selected;
      },
      (newValue, oldValue) => {
        this.selectedGuid = newValue && new Guid(newValue);
      }
    );
  }

  $destroy() {
    this.cleanup();
  }

  get userId(): Guid {
    return this.selectedGuid;
  }

  set userId(value: Guid) {
    if (!value) {
      this.selected = undefined;
      this.selectedGuid = undefined;
      return;
    }
    this.selectedGuid = new Guid(value);
    this.selected = this.selectedGuid.toString();
  }

  // changed() {
  //   this.selectedGuid = this.selected && new Guid(this.selected);
  // }

  private loadUsers(): void {
    this.cacheService
      .users()
      .then(users => (this.users = <any>users))
      .catch(err => console.error(err));
  }
}
