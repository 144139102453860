import { IAlertService } from '../../../core/services';
import { IGroupStore } from '../../../data/stores/group';
import { StateService } from 'angular-ui-router';

/* @ngInject */
export default function GroupNewController(
  $scope: any,
  $state: StateService,
  GroupStore: IGroupStore,
  AlertService: IAlertService
): void {
  $scope.group = {};
  $scope.groupForm = {};
  $scope.groupForm.active = false;

  $scope.saveGroup = function(group) {
    $scope.groupForm.active = true;

    GroupStore.save(group)
      .then(function(updated) {
        AlertService.success('New group ' + updated.name + ' has been created.');
        $scope.groupForm.active = false;
        $scope.groupForm.$setPristine();
        $state.go('app.group.edit', { id: updated.id });
      })
      .catch(function(error) {
        AlertService.error('Could not create group, please try again.');
        $scope.groupForm.active = false;
      });
  };
}
