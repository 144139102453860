import { Notification } from '../../core/models';
import { StateService } from 'angular-ui-router';

export class NotificationListController {
  static $inject = ['$state'];

  constructor(private stateService: StateService) {}

  goToNotification(notification: Notification) {
    this.stateService.go('app.notification', {
      id: notification.id.toString()
    });
  }
}
