import { EntityResource } from '../../../data/resources/entity';
import ng from 'angular';
import includes from 'lodash/includes';
import reject from 'lodash/reject';

/* tslint:disable:no-shadowed-variable */
/* @ngInject */
export function GroupEntitySelectController(
  $scope: any,
  $uibModalInstance,
  title: string,
  roleParticipation,
  EntityResource: EntityResource,
  Cache
): void {
  /* tslint:enable:no-shadowed-variable */

  $scope.title = title;
  $scope.selected = {};
  $scope.entityTypes = [];
  $scope.entities = [];

  let roleEntities = ng.copy(roleParticipation.value.entities);

  Cache.entityTemplates().then(function(templates) {
    $scope.entityTypes = templates;
  });

  $scope.$watch('selected.entityType', function(newValue, oldValue) {
    if (newValue) {
      EntityResource.queryByTemplate({ id: newValue.id }).$promise.then(function(data) {
        const entities = [];

        data.items.forEach(item => {
          const isSelected = includes(roleEntities, item.id) ? true : false;

          entities.push({
            id: item.id,
            name: item.name || '',
            selected: isSelected
          });
        });

        $scope.entities = entities;
      });
    }
  });

  const evaluateAllSelected = function() {
    let allSelected = true;

    for (let i = 0; i < $scope.entities.length; ++i) {
      if ($scope.entities[i].selected === false) {
        allSelected = false;
        break;
      }
    }

    $scope.allSelected = allSelected;
  };

  const updateRoleEntities = function(item) {
    if (item.selected) {
      if (includes(roleEntities, item.id) === false) {
        roleEntities.push(item.id);
      }
    } else {
      roleEntities = reject(<any[]>roleEntities, function(entity) {
        return entity === item.id;
      });
    }
  };

  $scope.toggleAll = function() {
    const toggle = !$scope.allSelected;

    $scope.entities.forEach(item => {
      item.selected = toggle;
      updateRoleEntities(item);
    });

    evaluateAllSelected();
  };

  $scope.entitySelectCheck = function(item) {
    updateRoleEntities(item);
    evaluateAllSelected();
  };

  $scope.ok = function() {
    $uibModalInstance.close(roleEntities);
  };

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };
}
