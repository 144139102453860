import { CurrencyViewController } from './currencyView.controller';
import { ComponentBase } from '../../../core/utils/componentBase';

export class CurrencyViewComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      currency: '<'
    };
    this.controller = CurrencyViewController;
    this.template = require('./currencyView.html');
  }
}
