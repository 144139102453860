/* @ngInject */
export default function CioLogout(): any {
  return {
    restrict: 'E',
    controller: 'cioLogoutController',
    controllerAs: 'logout',
    bindToController: true,
    template: ''
  };
}
