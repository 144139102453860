import { HttpService } from './http';
import { Contact, Guid, Page } from '../models';
import { logError } from '../utils/rx';
import { Observable } from 'rxjs/Observable';

export interface IContactHttpService {
  search(search: ContactSearch): Observable<Page<Contact>>;
}

export class ContactSearch {
  entityId: Guid;
  entityTypeId: Guid;
  take: number = 100;
  skip: number = 0;
  sort: string = 'Name';
  sortDescending: boolean = false;
}

export class ContactHttpService implements IContactHttpService {
  static $inject = ['HttpService'];

  private readonly _url: string = '/api/v1/contacts';

  constructor(private httpService: HttpService) {}

  search(search: ContactSearch): Observable<Page<Contact>> {
    return this.httpService
      .post(`${this._url}/search`, search)
      .map(res => Page.fromJson(res.json(), Contact.fromJson))
      .catch(logError);
  }
}
