import { Guid, User } from '../../../core/models';
import { IGravatarService } from '../../../core/services';
import { ICacheService } from '../../services/cache';

const DEFAULT_SIZE = 24;
const DEFAULT_EMPTY_NAME = 'No one';

export class UserAvatarController {
  static $inject = ['Cache', 'GravatarService'];

  avatarUrl?: string;
  name?: string;
  private _size: number;
  private _user?: User;
  private _userId?: Guid;

  constructor(private cache: ICacheService, private gravatarService: IGravatarService) {}

  $onChanges(changes) {
    this.setUrl();
  }

  get size(): number {
    return this._size || DEFAULT_SIZE;
  }

  set size(value: number) {
    this._size = value;
  }

  get doubleSize(): number {
    return this.size * 2;
  }

  get user(): User {
    return this._user;
  }

  set user(user: User) {
    this._user = user || undefined;
    if (user) {
      this._userId = undefined;
    }
  }

  set userId(value: Guid | string) {
    this._userId = value ? new Guid(value) : undefined;
    if (value) {
      this._user = undefined;
    }
  }

  get userGuid(): Guid | undefined {
    return this._userId;
  }

  private setUrl() {
    if (!this.user && (!this.userGuid || !Guid.isValid(this.userGuid))) {
      this.showEmpty();
      return;
    }
    this.showUser();
  }

  private showEmpty() {
    this.name = DEFAULT_EMPTY_NAME;
    this.avatarUrl = this.gravatarService.forUnknown(this.doubleSize);
  }

  private showUser() {
    if (this.user) {
      this.setUserDetails(this.user);
      return;
    }
    this.cache
      .user(this.userGuid.toString())
      .then(user => {
        this.setUserDetails(user);
      })
      .catch(error => {
        this.showEmpty();
      });
  }

  private setUserDetails(user: User) {
    this.name = user.name;
    this.avatarUrl = this.gravatarService.forEmail(user.email, this.doubleSize);
  }
}
