import { IClaimHttpService } from '../../core/http';
import {
  ClaimSearchResults,
  FieldDefinition,
  Guid,
  IClaimSearchQuery,
  Page,
  SavedSearch
} from '../../core/models';
import { ICommentModalService } from '../claims/activities/commentModal.service';
import { ITimesheetModalService } from '../claims/timesheets/timesheetModal.service';
import { ISearchColumn, ISearchFilter } from '../search/search.interfaces';
import { SearchRow } from '../search/searchRow';
import { ICacheService } from '../services/cache';

const DEFAULT_PAGE_SIZE: number = 100;

export class EntityClaimsController {
  static $inject = [
    '$scope',
    '$location',
    'ClaimHttpService',
    'Cache',
    'TimesheetModalService',
    'CommentModalService'
  ];

  currentSearch: SavedSearch;
  savedSearches: SavedSearch[] = [];
  columns: ISearchColumn[] = [];
  filters: ISearchFilter[] = [];
  searchQuery: string;
  sort: string = undefined;
  sortDescending: boolean = false;
  pageNumber: number = 1;
  currentPage: Page<SearchRow> = undefined;
  private _entityId: Guid;

  constructor(
    private scope: any,
    private locationService: ng.ILocationService,
    private claimHttpService: IClaimHttpService,
    private cacheService: ICacheService,
    private timesheetModalService: ITimesheetModalService,
    private commentModalService: ICommentModalService
  ) {}

  get entityId(): Guid {
    return this._entityId;
  }

  set entityId(value: Guid) {
    this._entityId = value ? new Guid(value) : undefined;
  }

  $onInit(): void {
    this.loadSearch();
  }

  search() {
    this.loadPage(1);
  }

  pageChanged(pageNumber: number): void {
    this.loadPage(pageNumber);
  }

  searchChanged() {
    this.setSearch(this.currentSearch);
  }

  addTimesheet(row: SearchRow) {
    this.timesheetModalService.create(row.id).catch(() => {});
  }

  addComment(row: SearchRow) {
    this.commentModalService.create(row.id).catch(() => {});
  }

  private loadSearch() {
    this.cacheService.savedSearches().then(searches => {
      this.savedSearches = searches;
      this.cacheService.userSettings().then(userSettings => {
        let search: SavedSearch;
        if (userSettings.settings.lastUsedSearch) {
          search = searches.find(x =>
            new Guid(x.id).equals(userSettings.settings.lastUsedSearch)
          );
        }
        if (!search && searches.length) {
          search = searches[0];
        }
        if (search) {
          this.setSearch(search);
        }
      });
    });
  }

  private setSearch(search: SavedSearch) {
    this.currentSearch = search;
    this.sort = search.sortField;
    this.sortDescending = search.reverse;
    this.filters.length = 0;
    this.filters.push.apply(this.filters, search.filters);
    this.locationService.search('search', search.id);

    this.setFields(search.fields.map(x => x.fieldName)).then(() => {
      this.locationService.search('search', search.id);
    });
  }

  private setFields(fields: string[]): Promise<void> {
    return this.cacheService
      .fields()
      .then(fieldDefinitions => {
        const columns: ISearchColumn[] = [];
        let index = 0;
        for (const item of fields) {
          const column = getColumn(index, fieldDefinitions, item);
          if (column) {
            columns.push(column);
          }
          index += 1;
        }
        this.columns.length = 0;
        this.columns.push.apply(this.columns, columns);
        this.loadPage(1);
      })
      .catch(error => console.log(error));
  }

  private loadPage(pageNumber: number) {
    this.claimHttpService
      .search({
        query: this.searchQuery,
        entityId: this.entityId,
        fields: this.columns.map(x => x.fieldName),
        sort: this.sort,
        sortDescending: this.sortDescending,
        skip: (pageNumber - 1) * DEFAULT_PAGE_SIZE,
        take: DEFAULT_PAGE_SIZE
      } as IClaimSearchQuery)
      .safeApply(this.scope, results => this.setResults(results))
      .subscribe();
  }

  private setResults(results: ClaimSearchResults) {
    this.currentPage = results.page as any;
    this.currentPage.items = results.page.items.map(x => new SearchRow(x));
    this.pageNumber = results.page.page;
  }
}

function getColumn(
  index: number,
  fields: FieldDefinition[],
  fieldName: string
): ISearchColumn {
  const field = fields.find(x => x.name.toLowerCase() === fieldName.toLowerCase());
  if (!field) {
    return {
      columnNumber: index,
      fieldId: undefined,
      fieldName: fieldName,
      fieldLabel: fieldName.slice(0, 1).toUpperCase() + fieldName.slice(1),
      fieldType: 'Text'
    } as ISearchColumn;
  }
  return {
    columnNumber: index,
    fieldId: field.id ? new Guid(field.id) : undefined,
    fieldName: field.name,
    fieldLabel: field.label,
    fieldType: field.type
  } as ISearchColumn;
}
