import { StateProvider } from 'angular-ui-router';

/* @ngInject */
export function OrganizationRoutes($stateProvider: StateProvider) {
  $stateProvider.state('app.organization', <any>{
    title: 'Organization',
    url: '/settings/organization',
    template: '<organization-component></organization-component>'
  });
}
