import { TaxCode } from '../../core/models';
import * as ng from 'angular';

/* tslint:disable-next-line:no-empty-interface */
export interface TaxCodeResource
  extends ng.resource.IResourceClass<ng.resource.IResource<TaxCode>> {
  update(taxCode: TaxCode): ng.resource.IResource<TaxCode>;
}

/* @ngInject */
export default function TaxCodeResource(
  $resource: ng.resource.IResourceService
): TaxCodeResource {
  return <TaxCodeResource>$resource<ng.resource.IResource<TaxCode>>(
    '/api/v1/tax-codes/:id',
    { id: '@id' },
    {
      update: {
        method: 'PUT',
        params: {
          id: '@id'
        }
      },
      query: { method: 'GET' }
    }
  );
}
