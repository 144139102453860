import { ComponentBase } from '../../core/utils/componentBase';
import { FolderViewerListController } from './folderViewerList.controller';

export class FolderViewerListComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      folders: '<',
      files: '<',
      starred: '<',
      searchTerm: '<',
      onPreview: '&',
      onRename: '&',
      onDownload: '&',
      onDelete: '&',
      onAddStar: '&',
      onRemoveStar: '&'
    };
    this.controller = FolderViewerListController;
    this.template = require('./folderViewerList.html');
  }
}
