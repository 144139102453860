export const TypeHelper = {
  isObject(value: any): boolean {
    return value === Object(value);
  },
  isString(value: any): boolean {
    return typeof value === 'string' || value instanceof String;
  },
  isNullEmpty(value: any): boolean {
    return (value || '').trim().length === 0;
  }
};
