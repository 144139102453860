export class ClaimsHistogramWidgetController {
  static $inject = ['$http'];

  items: any[] = [];

  config = { interval: 'month' };
  availableOpts = [
    {
      name: 'interval',
      title: 'Interval',
      type: 'dropdown',
      values: [
        { name: 'Year', value: 'year' },
        { name: 'Month', value: 'month' },
        { name: 'Day', value: 'day' }
      ]
    }
  ];

  constructor(private http: ng.IHttpService) {}

  $onInit() {
    this.load();
  }

  load() {
    this.http
      .post<{ items: any[] }>('/api/v1/reports/claims', {
        parts: [
          {
            name: 'claims_histogram',
            type: 'Histogram',
            field: 'created',
            interval: this.config.interval || 'month'
          }
        ]
      })
      .then(response => {
        this.items = (response.data.items || [])[0].items || [];
      });
  }
}
