import { Report, ReportField } from '../../../core/models';
import { ReportFieldResource } from '../../../data/resources/report-field';
import * as angular from 'angular';

export default class ReportFieldsModalController {
  static $inject = ['report', '$uibModalInstance', 'ReportFieldResource'];

  public allFields: ReportField[];
  public selectedFields: ReportField[];
  public selected: any;
  public editor = {};

  constructor(
    public report: Report,
    private modalInstance: ng.ui.bootstrap.IModalServiceInstance,
    private reportFieldResource: ReportFieldResource
  ) {}

  $onInit() {
    // We clone the fields in case the modal is terminated without
    // without saving (cancel)

    this.selectedFields = angular.copy(this.report.fields || []);
    this.loadFields();

    // Setup editor
    angular.forEach(this.selectedFields, f => {
      this.editor[f.name] = { edit: false, label: '' };
    });
  }

  /**
   * Closes the modal without saving.
   */
  cancel(): void {
    this.modalInstance.dismiss('cancel');
  }

  /**
   * Closes the modal and saves the result of the selected fields.
   */
  save(): void {
    this.modalInstance.close(this.selectedFields);
  }

  /**
   * Selects a field and adds it to the report.
   *
   * @param {*} field The field to add to the report.
   */
  select(field: any): void {
    this.selected = null;

    // Ensure we do not add the same field to the
    // report twice

    let found = false;
    for (const i of this.selectedFields) {
      if (i.name === field.name) {
        found = true;
        break;
      }
    }

    if (!found) {
      this.selectedFields.push({
        name: field.name,
        label: field.label,
        dataType: field.dataType,
        reportType: this.report.type,
        category: field.category,
        format: field.format,
        defaultOrder: 0
      });

      // Make the field available to the editor
      this.editor[field.name] = { edit: false, label: '' };
    }
  }

  /**
   * Toggles the edit for the given field.
   *
   * @param {ReportField} field The field to toggle edit mode.
   */
  toggleFieldEdit(field: ReportField) {
    if (!this.editor[field.name]) {
      return;
    }

    this.editor[field.name].label = !this.editor[field.name].edit ? field.label : '';

    this.editor[field.name].edit = !this.editor[field.name].edit;
  }

  /**
   * Removes the specified field.
   *
   * @param {ReportField} field The field to remove.
   * @param {number} $index The index of the field.
   */
  removeField(field: ReportField, $index: number) {
    this.selectedFields.splice($index, 1);
    this.editor[field.name] = undefined;
  }

  /**
   * Save edit on the given field.
   *
   * @param {ReportField} field The field to save.
   */
  saveEdit(field: ReportField) {
    if (this.editor[field.name].label.trim().length === 0) {
      return;
    }

    field.label = this.editor[field.name].label;
    this.toggleFieldEdit(field);
  }

  /**
   * Cancel edit on the given field.
   *
   * @param {ReportField} field The field to cancel.
   */
  cancelEdit(field: ReportField) {
    this.toggleFieldEdit(field);
  }

  /**
   * Loads all available fields based on the type of report.
   */
  loadFields() {
    const search = <ReportField>{
      reportType: this.report.type
    };

    (<any>this.reportFieldResource).save(search).$promise.then((result: any) => {
      this.allFields = result.items;
    });
  }
}
