import { ICountryService } from '../../../core/services';
import * as ng from 'angular';

/* @ngInject */
export default function AddressModalController(
  $scope: any,
  $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
  CountryService: ICountryService,
  address: any
) {
  $scope.address = address;
  $scope.countries = CountryService.getCountries();

  $scope.ok = function() {
    $uibModalInstance.close($scope.address);
  };

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };
}
