import { Invoice, Claim, User } from '../../../../core/models';
import { IAlertService } from '../../../../core/services';
import * as ng from 'angular';

/* @ngInject */
export default function InvoiceNotesService($uibModal): any {
  return {
    manageNotes: manageNotes
  };

  function manageNotes(invoice, claim, currentUser) {
    const modalInstance = $uibModal.open({
      template: require('./invoice-notes-modal.html'),
      controller: InvoiceNotesModalController,
      controllerAs: 'invoice',
      resolve: {
        invoice: function() {
          return invoice;
        },
        claim: function() {
          return claim;
        },
        currentUser: function() {
          return currentUser;
        }
      }
    });

    return modalInstance.result;
  }
}

export function InvoiceNotesModalController(
  $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
  invoice: Invoice,
  claim: Claim,
  currentUser: User,
  AlertService: IAlertService
): void {
  const vm = this;
  vm.note = {};
  vm.invoiceId = invoice.id;
  vm.claim = claim;
  vm.invoice = invoice;

  vm.saveNote = function() {
    if (!vm.note.note) {
      AlertService.error('Please specify the note.');
      return;
    }

    vm.note.userId = currentUser.id;
    vm.invoice.notes.push(vm.note);
  };

  vm.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };

  vm.close = function() {
    $uibModalInstance.close(vm.invoice);
  };

  vm.removeNote = function($index) {
    vm.invoice.notes.splice($index, 1);
  };
}
