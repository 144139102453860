import { WorkflowActionAddCommentController } from './workflowActionAddComment.controller';
import { ComponentBase } from '../../../../core/utils/componentBase';

export class WorkflowActionAddCommentComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      data: '='
    };
    this.controller = WorkflowActionAddCommentController;
    this.template = require('./workflowActionAddComment.html');
  }
}
