import { Entity, Guid, RecordAssociation, Tag } from '../../../core/models';
import { ITaskService } from '../../tasks/task.service';
import { StateService } from 'angular-ui-router';

export class EntityTasksController {
  static $inject = ['$state', 'TaskService'];

  entity: Entity;
  record: RecordAssociation;

  constructor(private stateService: StateService, private taskService: ITaskService) {}

  $onInit() {
    this.record = Object.assign(new RecordAssociation(), {
      type: 'entity',
      id: new Guid(this.entity.id),
      title: (<any>this.entity).title || (<any>this.entity).name
    });
  }

  get isClosed(): boolean {
    return false;
  }

  createTask() {
    this.taskService
      .createTask(this.record)
      .result.then(t => this.stateService.go('.'))
      .catch(err => {});
  }
}
