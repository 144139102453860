import { ICacheService } from '../services/cache';
import { Entity, Guid } from '../../core/models';
import { IAlertService } from '../../core/services/alert';
import { ILogService } from '../../core/services/log';
import { EntityResource } from '../../data/resources/entity';
import { StateService } from 'angular-ui-router';

/* @ngInject */
export default function EntityEditController(
  $scope: any,
  $state: StateService,
  Cache: ICacheService,
  // tslint:disable-next-line:no-shadowed-variable
  EntityResource: EntityResource,
  entity: Entity,
  AlertService: IAlertService,
  LogService: ILogService
) {
  $scope.model = entity;
  $scope.entityId = new Guid(entity.id);

  Cache.entityTemplate(entity.templateId)
    .then(function(template) {
      $scope.template = template;
    })
    .catch(function(error) {
      AlertService.error(`Could not load ${$scope.template.name} please try again.`);
      LogService.error(error);
      $state.go('app.search.grid');
    });

  $scope.save = function(form) {
    if (!form.$valid) {
      return;
    }

    EntityResource.update($scope.model)
      .$promise.then(function() {
        form.$setPristine();
        AlertService.success($scope.template.name + ' updated successfully.');
      })
      .catch(function(error) {
        AlertService.error(`Could not update ${$scope.template.name} please try again.`);
        LogService.error(error);
      });
  };
}
