const SEPARATOR = '...';

/**
 * Filter for truncating long file names, keeping both the start and end.
 * @param {string} The filename to truncate
 * @param {number} The maximum length, before the string will be truncated. The default is 50.
 * @return The truncated string.
 *
 *  <!-- input: amazon-web-services-aws-collection-first-release.ai; output: amazon-web-services-aws-...rst-release.ai -->
 *  <div>{{ item.name | filename }}</div>
 *
 */
export function filenameFilter() {
  return function(text: string, length: number) {
    length = length || 50;

    if (text.length + SEPARATOR.length <= length) {
      return text;
    }

    const charsToShow = length - SEPARATOR.length,
      frontChars = Math.ceil(charsToShow * 0.7),
      backChars = Math.floor(charsToShow * 0.3);

    return text.substr(0, frontChars) + SEPARATOR + text.substr(text.length - backChars);
  };
}
