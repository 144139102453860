import { DateTime } from 'luxon';
import { Guid } from './guid';
import { DateTimeHelper } from '../../core/utils';

export const REFERENCE_STRATEGY_TYPES = {
  Undefined: 'Undefined',
  Incremental: 'Incremental',
  Manual: 'Manual'
};

export const CLAIM_ASSIGNEE_STRATEGY_TYPES = {
  NotAssigned: 'NotAssigned',
  CurrentUser: 'CurrentUser',
  SpecificUser: 'SpecificUser'
};

type ReferenceStrategyTypes = 'Undefined' | 'Incremental' | 'Manual';

type ClaimAssigneeStrategyTypes = 'NotAssigned' | 'CurrentUser' | 'SpecificUser';

type InvoiceNumberStrategyTypes = 'Blank' | 'IncrementingNumber';

export class Organization {
  id: Guid;
  name: string;
  referenceStrategy: ReferenceStrategyTypes = 'Undefined';
  accountsPayable: Guid[];
  accountsReceivable: Guid[];
  defaultCurrency: string;
  currencies: string[];
  created: DateTime;
  createdBy: Guid;
  lastModified: DateTime;
  lastModifiedBy: Guid;
  templateFolderId: Guid;
  publicFolderId: Guid;
  logoFileId?: Guid;
  defaultCountryCode: string;
  smsSendingNumber: string;
  smsUnknownEmail: string;
  convertMailAttachmentsToPdf: boolean = true;
  invoiceNumberStrategy: InvoiceNumberStrategyTypes;
  invoiceNumberNext?: number;
  invoiceNumberPrefix: string;
  claimAssigneeStrategy: ClaimAssigneeStrategyTypes;
  claimAssigneeDefaultUser?: Guid;
  disableSumInsuredPaymentTracking: boolean = false;

  static fromJson(json: any): Organization {
    const organization = Object.assign(new Organization(), json) as Organization;
    organization.id = new Guid(json.id);
    organization.created = DateTimeHelper.parseUtcDateTime(json.created);
    organization.createdBy = new Guid(json.createdBy);
    organization.lastModified = DateTimeHelper.parseUtcDateTime(json.lastModified);
    organization.lastModifiedBy = new Guid(json.lastModifiedBy);
    organization.accountsPayable = (json.accountsPayable || []).map(x => new Guid(x));
    organization.accountsReceivable = (json.accountsReceivable || []).map(
      x => new Guid(x)
    );
    organization.templateFolderId =
      json.templateFolderId && new Guid(json.templateFolderId);
    organization.publicFolderId = json.publicFolderId && new Guid(json.publicFolderId);
    organization.logoFileId = json.logoFileId && new Guid(json.logoFileId);
    organization.claimAssigneeDefaultUser =
      json.claimAssigneeDefaultUser && new Guid(json.claimAssigneeDefaultUser);
    return organization;
  }
}
