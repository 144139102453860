export function FileDropZoneDirective() {
  let lastEnter: any;

  return {
    restrict: 'E',
    template: [
      '<div class="cio-fileDropZone">',
      '  <div class="cio-fileDropZone--overlay">Drop Files</div>',
      '  <div class="cio-fileDropZone--content" ng-transclude></div>',
      '</div>'
    ].join(''),
    replace: true,
    transclude: true,
    scope: {
      onFiles: '&'
    },
    link: (scope, element, attrs) => {
      element.on('dragenter', dragEnter);
      element.on('dragleave', stopDrag);
      element.on('dragend', stopDrag);
      element.on('dragover', dragOver);
      element.on('drop', drop);

      scope.$on('$destroy', () => {
        element.off('dragenter', dragEnter);
        element.off('dragleave', stopDrag);
        element.off('dragend', stopDrag);
        element.off('dragover', dragOver);
        element.off('drop', drop);
      });

      function dragOver(ev: DragEvent) {
        noPropagation(ev);
        startDrag(ev);
      }

      function dragEnter(ev: DragEvent) {
        noPropagation(ev);
        startDrag(ev);
      }

      function drop(ev: DragEvent) {
        noPropagation(ev);
        stopDrag(ev);

        const files = [];
        if (ev.dataTransfer.items) {
          // Use DataTransferItemList interface to access the file(s)
          for (let i = 0; i < ev.dataTransfer.items.length; i++) {
            if (ev.dataTransfer.items[i].kind === 'file') {
              files.push(ev.dataTransfer.items[i].getAsFile());
            }
          }
        } else {
          // Use DataTransfer interface to access the file(s)
          for (let i = 0; i < ev.dataTransfer.files.length; i++) {
            files.push(ev.dataTransfer.files[i]);
          }
        }

        filesDropped(files);
      }

      function noPropagation(ev) {
        ev.stopPropagation();
        if (ev.preventDefault) {
          return ev.preventDefault();
        } else {
          return (ev.returnValue = false);
        }
      }

      function filesDropped(files: File[]) {
        if (scope.onFiles) {
          scope.onFiles({ files });
        }
      }

      function startDrag(event) {
        lastEnter = event.target;
        element[0].classList.add('cio-fileDropZone--hover');
      }

      function stopDrag(event) {
        if (lastEnter === event.target) {
          element[0].classList.remove('cio-fileDropZone--hover');
        }
      }
    }
  };
}
