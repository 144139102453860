import '../../../core/services/alert';
import '../../../data/resources/emailTemplate';

/* @ngInject */
export default function EmailTemplateEditController(
  $scope,
  $state,
  $stateParams,
  EmailTemplateResource,
  AlertService
) {
  $scope.model = {};
  $scope.newForm = {};
  $scope.newForm.active = false;

  EmailTemplateResource.get({ id: $stateParams.id }).$promise.then(function(data) {
    $scope.model = data;
  });

  $scope.deleteModel = function() {
    $scope.newForm.active = true;
    EmailTemplateResource.delete({ id: $stateParams.id })
      .$promise.then(function(updated) {
        AlertService.success('Email ' + $scope.model.name + ' has been deleted.');
        $state.go('app.emailTemplates');
      })
      .catch(function(error) {
        AlertService.error('Could not delete email, please try again.');
        $scope.newForm.active = false;
      });
  };

  $scope.saveModel = function(model) {
    $scope.newForm.active = true;

    EmailTemplateResource.update(model)
      .$promise.then(function(updated) {
        AlertService.success('Email ' + updated.name + ' has been updated.');
        $scope.newForm.active = false;
        $scope.newForm.$setPristine();
        $scope.model = updated;
      })
      .catch(function(error) {
        AlertService.error('Could not create email, please try again.');
        $scope.newForm.active = false;
      });
  };
}
