import { IHookRegistry, Transition } from 'angular-ui-router';

/* @ngInject */
export default function CioProtectedForm(
  $window: ng.IWindowService,
  $transitions: IHookRegistry
): any {
  let unloadtext,
    forms = [],
    isDirty = false,
    navigationFuncSeted = false;

  function findDirtyForm() {
    isDirty = false;
    for (let i = 0; i < forms.length; i++) {
      const form = forms[i];
      if (form.name && form.scope[form.name] && form.scope[form.name].$dirty) {
        isDirty = true;
        break;
      }
    }
  }

  function handleUnload() {
    findDirtyForm();
    if (isDirty) {
      return unloadtext;
    } else {
      return undefined;
    }
  }

  function handleLocationChangeStart($transition$: Transition) {
    findDirtyForm();
    if (isDirty) {
      if (!confirm(unloadtext + '\n\nAre you sure you want to leave this page?')) {
        return false;
      } else {
        // navigation will be succeeded
        forms = [];
      }
    }
    return true;
  }

  return function($scope: any, $element) {
    if ($element[0].localName !== 'form' && $element.attr('ng-form') === undefined) {
      throw new Error(
        'cio-protected-form directive must only be set on a angularjs form!'
      );
    }

    forms.push({
      name: $element[0].name,
      scope: $scope
    });

    $window.onbeforeunload = handleUnload;
    if (!navigationFuncSeted) {
      $transitions.onBefore({ to: '*' }, handleLocationChangeStart);
      navigationFuncSeted = true;
    }

    unloadtext = 'There are unsaved changes.';
  };
}
