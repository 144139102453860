import {
  IWorkflowFilter,
  FieldDefinition,
  WorkflowFilterTypes
} from '../../../../core/models';

interface IWorkflowOperation {
  id: WorkflowFilterTypes;
  name: string;
  types: string[];
}

const WorkflowOperations: IWorkflowOperation[] = [
  {
    id: WorkflowFilterTypes.Added,
    name: 'Added',
    types: [
      'address',
      'text',
      'user',
      'number',
      'date',
      'datetime',
      'dropdown',
      'entity',
      'entitycontact',
      'statusset',
      'bool',
      'country',
      'claimtype'
    ]
  },
  {
    id: WorkflowFilterTypes.Modified,
    name: 'Modified',
    types: [
      'address',
      'text',
      'user',
      'number',
      'date',
      'datetime',
      'dropdown',
      'entity',
      'entitycontact',
      'statusset',
      'bool',
      'country',
      'claimtype'
    ]
  },
  {
    id: WorkflowFilterTypes.Removed,
    name: 'Removed',
    types: [
      'address',
      'text',
      'user',
      'number',
      'date',
      'datetime',
      'dropdown',
      'entity',
      'entitycontact',
      'statusset',
      'bool',
      'country',
      'claimtype'
    ]
  },
  {
    id: WorkflowFilterTypes.IsEmpty,
    name: 'Is empty',
    types: [
      'address',
      'text',
      'user',
      'number',
      'date',
      'datetime',
      'dropdown',
      'entity',
      'entitycontact',
      'statusset',
      'bool',
      'country',
      'claimtype',
      'tags'
    ]
  },
  {
    id: WorkflowFilterTypes.IsNotEmpty,
    name: 'Is not empty',
    types: [
      'address',
      'text',
      'user',
      'number',
      'date',
      'datetime',
      'dropdown',
      'entity',
      'entitycontact',
      'statusset',
      'country',
      'claimtype',
      'tags'
    ]
  },
  {
    id: WorkflowFilterTypes.Equals,
    name: 'Equals',
    types: [
      'address',
      'text',
      'user',
      'number',
      'date',
      'datetime',
      'dropdown',
      'entity',
      'entitycontact',
      'statusset',
      'bool',
      'country',
      'claimtype'
    ]
  },
  {
    id: WorkflowFilterTypes.NotEquals,
    name: "Doesn't equal",
    types: [
      'address',
      'text',
      'user',
      'number',
      'date',
      'datetime',
      'dropdown',
      'entity',
      'entitycontact',
      'statusset',
      'country',
      'claimtype'
    ]
  },
  {
    id: WorkflowFilterTypes.Contains,
    name: 'Contains',
    types: ['text', 'address', 'country', 'user[]', 'entity[]', 'tags']
  },
  {
    id: WorkflowFilterTypes.NotContains,
    name: "Doesn't contain",
    types: ['text', 'address', 'country', 'user[]', 'entity[]', 'tags']
  },
  {
    id: WorkflowFilterTypes.StartsWith,
    name: 'Starts with',
    types: ['text', 'address']
  },
  {
    id: WorkflowFilterTypes.IsBefore,
    name: 'Is before',
    types: ['date', 'datetime']
  },
  {
    id: WorkflowFilterTypes.IsAfter,
    name: 'Is after',
    types: ['date', 'datetime']
  }
  // {
  //   id: 'MatchRegex',
  //   name: 'Match Regex',
  //   types: ["text"]
  // },
  // {
  //   id: 'IsBetween',
  //   name: 'Is Between',
  //   types: ['number', 'date', 'datetime']
  // },
  // {
  //   id: 'GreaterThan',
  //   name: 'Greater Than',
  //   types: ['number']
  // },
  // {
  //   id: 'GreaterThanOrEqualTo',
  //   name: 'Greater Than Or Equal To',
  //   types: ['number']
  // },
  // {
  //   id: 'LessThan',
  //   name: 'Less Than',
  //   types: ['number']
  // },
  // {
  //   id: 'LessThanOrEqualTo',
  //   name: 'Less Than Or Equal To',
  //   types: ['number']
  // }
];

export class WorkflowFilterController {
  static $inject = ['CountryService'];

  onDelete: Function;
  field: FieldDefinition;
  private _filter: IWorkflowFilter;
  private _fields: FieldDefinition[] = [];

  $onInit() {
    this.setFieldFromFilter();
  }

  get fields(): FieldDefinition[] {
    return this._fields;
  }

  set fields(value: FieldDefinition[]) {
    this._fields = value;
  }

  get filter(): IWorkflowFilter {
    return this._filter;
  }

  set filter(value: IWorkflowFilter) {
    this._filter = value;
  }

  get fieldType(): string {
    if (!this.field) {
      return 'text';
    }
    return this.field.type.toLowerCase();
  }

  get hasFilterValue(): boolean {
    return (
      this.field &&
      this._filter.filterType &&
      this._filter.filterType !== WorkflowFilterTypes.IsEmpty &&
      this._filter.filterType !== WorkflowFilterTypes.IsNotEmpty &&
      this._filter.filterType !== WorkflowFilterTypes.Added &&
      this._filter.filterType !== WorkflowFilterTypes.Modified &&
      this._filter.filterType !== WorkflowFilterTypes.Removed
    );
  }

  fieldChange(): void {
    const fieldChange =
      (!!this.field.id && this.filter.fieldId !== this.field.id) ||
      (!!this.field.name && this.filter.fieldName !== this.field.name);
    this.filter.fieldId = this.field.id;
    this.filter.fieldName = this.field.name;
    if (fieldChange) {
      this.filter.value = undefined;
      this.filter.filterType = undefined;
    }
  }

  getOperations(): IWorkflowOperation[] {
    if (!this.field) {
      return [];
    }
    const operations = WorkflowOperations.filter(f => {
      return f.types.some(x => x === this.fieldType);
    });
    if (!this.filter.filterType && operations.length) {
      this.filter.filterType = operations[0].id;
    }
    return operations;
  }

  removeFilter() {
    this.onDelete({ filter: this.filter });
  }

  private setFieldFromFilter() {
    if (this._filter.fieldId) {
      this.field = this.fields.find(x => x.id === this._filter.fieldId);
      if (this.field) {
        return;
      }
    }
    if (this._filter.fieldName) {
      this.field = this.fields.find(x => x.name === this._filter.fieldName);
    }
  }
}
