import { UserListReportFieldController } from './userListReportField.controller';
import { ComponentBase } from '../../../../core/utils/componentBase';

export class UserListReportFieldComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      users: '<'
    };
    this.controller = UserListReportFieldController;
    this.template = require('./userListReportField.html');
  }
}
