import { TemplateController } from './template.controller';

export class TemplateComponent implements ng.IComponentOptions {
  public controller: any;
  public bindings: any;
  public template: string;

  constructor() {
    this.bindings = {
      model: '<',
      type: '<',
      formDisabled: '<'
    };
    this.controller = TemplateController;
    this.template = require('./template.html');
  }
}
