import ReportFieldsService from './modals/report-fields.service';
import ReportFiltersService from './modals/report-filters.service';
import ReportSaveService from './modals/report-save.service';
import ReportComponent from './report';
import ReportListComponent from './report-list';
import ReportNewComponent from './report-new';
import ReportViewComponent from './report-view';
import ReportService from './report.service';
import ReportRoutes from './reports.routes';
import { UserListReportFieldComponent } from './reportFields/userListReportField/userListReportField.component';
import { CoreModule } from '../../core/module';
import { DataModule } from '../../data/module';
import { UIModule } from '../../ui/module';
import * as angular from 'angular';

export const ReportsModule = angular
  .module('app.instance.reports', [DataModule.name, CoreModule.name, UIModule.name])

  // Components
  .component('reportListComponent', new ReportListComponent())
  .component('reportComponent', new ReportComponent())
  .component('reportViewComponent', new ReportViewComponent())
  .component('reportNewComponent', new ReportNewComponent())
  .component('cioUserListReportField', new UserListReportFieldComponent())

  .service('ReportFieldsService', ReportFieldsService)
  .service('ReportFiltersService', ReportFiltersService)
  .service('ReportSaveService', ReportSaveService)
  .service('ReportService', ReportService)

  // Config
  .config(ReportRoutes);
