import { IAlertService } from '../../../core/services';
import { ISavedSearchStore } from '../../../data/stores/savedSearch';
import { ICacheService } from '../../services/cache';
import { StateService } from 'angular-ui-router';
import reject from 'lodash/reject';
import ng from 'angular';

function arrayMove(arrayVar, from, to) {
  arrayVar.splice(to, 0, arrayVar.splice(from, 1)[0]);
}

/* @ngInject */
export default function SavedSearchEditController(
  $scope: any,
  $state: StateService,
  $stateParams: { id: string },
  SavedSearchStore: ISavedSearchStore,
  Cache: ICacheService,
  AlertService: IAlertService
) {
  $scope.fields = [];
  $scope.addressFields = [];
  $scope.searchFields = [];
  $scope.searchForm = {};
  $scope.searchForm.active = false;
  $scope.search = {};
  $scope.selectedListFields = [];

  Cache.savedSearches()
    .then(searches => ($scope.search = searches.find(x => x.id === $stateParams.id)))
    .then(() => {
      Cache.fields().then(fields => {
        $scope.fields = fields;
        $scope.addressFields = fields.filter(x => x.type === 'Address');
        setSearchFields();
      });
    });

  function setSearchFields() {
    const fieldsArray = ng.copy($scope.fields);
    let newArr = <any[]>fieldsArray.map(f => {
      return {
        id: f.id,
        name: f.name,
        label: f.label,
        labelOriginal: f.label,
        type: f.type,
        checked: false
      };
    });

    // Select included fields
    $scope.selectedListFields.length = 0;
    $scope.search.fields.forEach(f => {
      const item = newArr.find(x => x.name === f.fieldName);
      if (item) {
        $scope.selectedListFields.push(item);
        newArr = reject(newArr, { name: item.name });
      }
    });

    $scope.searchFields = newArr;
  }

  $scope.addFields = function() {
    $scope.searchForm.selectedFields.forEach(field => {
      $scope.selectedListFields.push(field);
      $scope.searchFields = reject($scope.searchFields, { name: field.name });
    });
  };

  $scope.removeFields = function() {
    $scope.searchForm.selectedSearchFields.forEach(field => {
      $scope.searchFields.push(field);
      $scope.selectedListFields = reject($scope.selectedListFields, {
        name: field.name
      });
    });
  };

  /**
   * Move the selected fields up.
   */
  $scope.moveUp = function() {
    // Ignore if nothing selected
    if (!$scope.searchForm.selectedSearchFields) {
      return;
    }

    const item = $scope.searchForm.selectedSearchFields[0],
      idx = $scope.selectedListFields.indexOf(item);

    if (idx === 0) {
      return;
    }

    arrayMove($scope.selectedListFields, idx, idx - 1);
  };

  /**
   * Move the selected fields down.
   */
  $scope.moveDown = function() {
    // Ignore if nothing selected
    if (!$scope.searchForm.selectedSearchFields) {
      return;
    }

    const item = $scope.searchForm.selectedSearchFields[0],
      idx = $scope.selectedListFields.indexOf(item);

    if (idx === $scope.selectedListFields.length - 1) {
      return;
    }

    arrayMove($scope.selectedListFields, idx, idx + 1);
  };

  $scope.saveSearch = function() {
    $scope.searchForm.active = true;

    // Map the selected fields
    $scope.search.fields = <any[]>$scope.selectedListFields.map(s => {
      return {
        fieldName: s.name,
        labelOverride: s.labelOriginal !== s.label && s.label !== '' ? s.label : undefined
      };
    });

    // Save the changes
    SavedSearchStore.update($scope.search)
      .then(function(updated) {
        $scope.search = updated;
        setSearchFields();

        AlertService.success('View ' + updated.name + ' has been updated.');
        $scope.searchForm.active = false;
        $scope.searchForm.$setPristine();
      })
      .catch(function(error) {
        AlertService.error('Could not save view, please try again.');
        $scope.searchForm.active = false;
      });
  };

  $scope.deleteSearch = function() {
    $scope.searchForm.active = true;

    SavedSearchStore.delete($scope.search)
      .then(function(deleted) {
        AlertService.success('View ' + deleted.name + ' has been deleted.');
        $scope.searchForm.active = false;
        $scope.searchForm.$setPristine();

        $state.go('app.saved-searches');
      })
      .catch(function(error) {
        AlertService.error('Could not delete view, please try again.');
        $scope.searchForm.active = false;
      });
  };

  $scope.viewSearch = function() {
    Cache.userSettings().then(userSettings => {
      userSettings.settings.lastUsedSearch = $scope.search.id;
      (userSettings as any).update(userSettings);
      $state.go('app.search');
    });
  };

  $scope.filterChanged = function(filters, text) {
    $scope.search.filters = filters;
    $scope.search.query = text;
  };
}
