import { IAlertService } from '../../../core/services/alert';
import { ISecurityService } from '../../../core/services/security';
import { IDownloadService } from './../../../core/services/download';
import { CostCategoryResource } from '../../../data/resources/costCategory';
import { TimesheetResource } from '../../../data/resources/timesheet';
import { IClaimManagerService } from '../claim-manager-service';
import { Timesheet } from 'app/core/models';

/* @ngInject */
export default function ClaimTimesheetController(
  ClaimManagerService: IClaimManagerService,
  SecurityService: ISecurityService,
  // tslint:disable-next-line:no-shadowed-variable
  CostCategoryResource: CostCategoryResource,
  $stateParams: any,
  // tslint:disable-next-line:no-shadowed-variable
  TimesheetResource: TimesheetResource,
  DownloadService: IDownloadService,
  AlertService: IAlertService
) {
  const claimId = $stateParams.claimId;

  const vm = this;
  vm.claim = ClaimManagerService.claim();
  vm.times = [];
  vm.categories = [];
  vm.catObj = {};
  vm.dateFormat = SecurityService.getDateFormat();
  vm.addTime = addTime;
  vm.openCalendar = openCalendar;
  vm.save = save;
  vm.remove = remove;
  vm.exportExcel = exportExcel;
  vm.exportPdf = exportPdf;

  init();

  function init() {
    CostCategoryResource.query().$promise.then(function(data: any) {
      vm.categories = data.items;
      (data.items || []).forEach(function(c) {
        vm.catObj[c.id] = c.name;
      });
    });

    TimesheetResource.query({ claimId: claimId }).$promise.then(function(data: any) {
      (data.items || []).forEach(function(i) {
        i.mode = 'read';
      });
      vm.times = data.items;
    });
  }

  function addTime() {
    vm.times.push({
      mode: 'edit',
      user: SecurityService.getCurrentUser().id,
      createdBy: SecurityService.getCurrentUser().id,
      claimId: claimId,
      date: new Date().toISOString(),
      billable: true
    });
  }

  function openCalendar($event, time) {
    $event.preventDefault();
    $event.stopPropagation();

    time.isOpen = true;
  }

  function save(timesheet: Timesheet & { mode: string }) {
    if (!timesheet.id) {
      TimesheetResource.save(timesheet)
        .$promise.then(saveSuccess(timesheet))
        .catch(saveError);
    } else {
      TimesheetResource.update(timesheet)
        .$promise.then(saveSuccess(timesheet))
        .catch(saveError);
    }
  }

  function saveSuccess(timesheet: Timesheet & { mode: string }) {
    return (saved: Timesheet) => {
      AlertService.success('Timesheet successfully saved.');
      timesheet.id = saved.id;
      timesheet.mode = 'read';
    };
  }

  function saveError(error: any) {
    AlertService.error('Could not save timesheet, please try again.');
  }

  function remove(timesheet: Timesheet, idx: number) {
    if (!confirm('Are you sure ?')) {
      return;
    }
    if (timesheet.id) {
      TimesheetResource.delete({ id: timesheet.id })
        .$promise.then(function(saved) {
          AlertService.success('Timesheet successfully delet ed.');
          vm.times.splice(idx, 1);
        })
        .catch(function() {
          AlertService.error('Could not save timesheet, please try again.');
        });
    } else {
      vm.times.splice(idx, 1);
    }
  }

  function exportExcel() {
    exportTimesheets('xlsx', 'timesheets.xlsx');
  }

  function exportPdf() {
    exportTimesheets('pdf', 'timesheets.pdf');
  }

  function exportTimesheets(exportType: string, filename: string): void {
    DownloadService.downloadPost(
      '/api/v1/timesheets/export',
      { Claim: claimId, ExportType: exportType },
      filename
    );
  }
}
