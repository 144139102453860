import { IFolderViewerAdapter } from './folderViewerAdapter';
import { StorageFile, StorageFolder } from '../../core/models';
import { IFileService } from '../files/file.service';

export class FolderViewerGridController {
  static $inject = ['FileService'];

  sortReverse: boolean = true;
  sortBy: string = 'lastModified';
  folders: StorageFolder[];
  files: StorageFile[];
  starred: StorageFile[];
  searchTerm: string;
  selectedFile?: StorageFile;

  constructor(private fileService: IFileService) {}

  get asAdapter(): IFolderViewerAdapter {
    return (<any>this) as IFolderViewerAdapter;
  }

  selectFile($event: Event, file: StorageFile): void {
    $event.preventDefault();
    this.selectedFile = file;
  }

  downloadFile($event: Event, file: StorageFile): void {
    $event.preventDefault();
    this.asAdapter.onDownload({ file });
  }

  showFile($event: Event, file: StorageFile): void {
    $event.preventDefault();
    this.selectedFile = file;
    this.asAdapter.onPreview({ file, showRevisions: false });
  }

  renameFile($event: Event, file: StorageFile): void {
    $event.preventDefault();
    this.asAdapter.onRename({ file });
  }

  deleteFile($event: Event, file: StorageFile): void {
    $event.preventDefault();
    this.asAdapter.onDelete({ file });
  }

  addStar($event: Event, file: StorageFile): void {
    $event.preventDefault();
    this.asAdapter.onAddStar({ file });
  }

  removeStar($event: Event, file: StorageFile): void {
    $event.preventDefault();
    this.asAdapter.onRemoveStar({ file });
  }

  getThumbnailUrl(file: StorageFile): void {
    return this.fileService.getThumbnailUrl(file, 384, 384);
  }
}
