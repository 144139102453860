/* @ngInject */
export default function CioDropdown(): any {
  return {
    controller: 'CioDropDownController',
    link: link
  };

  function link(scope, element, attrs, dropdownCtrl) {
    dropdownCtrl.init(element);
  }
}
