import { ISecurityService } from '../../../core/services';
import { DateTimeHelper } from '../../../core/utils';
import { DateTime } from 'luxon';
import { ITimeValue } from '../timeEdit/timeEdit.controller';

export class DateTimeEditController {
  static $inject = ['SecurityService'];

  isRequired: boolean = false;
  isPickerOpen: boolean = false;
  dateOptions = {
    showWeeks: false,
    formatDay: 'd',
    startingDay: 1
  };
  inputValue: Date;
  dateFormat: string;
  dateAltFormats: string[] = [];
  noMinutes: boolean = false;
  time: ITimeValue = undefined;

  constructor(securityService: ISecurityService) {
    this.dateFormat = securityService.getDateFormat();
    this.dateAltFormats = [
      // short dates
      this.dateFormat.replace('dd', 'd').replace('MM', 'M'),
      // hyphens
      this.dateFormat.replace(/\//g, '-'),
      this.dateFormat
        .replace(/\//g, '-')
        .replace('dd', 'd')
        .replace('MM', 'M'),
      // no year
      this.dateFormat
        .replace('dd', 'd')
        .replace('MM', 'M')
        .replace('/yyyy', ''),
      this.dateFormat
        .replace(/\//g, '-')
        .replace('dd', 'd')
        .replace('MM', 'M')
        .replace('-yyyy', '')
    ];
  }

  set dateTime(value: DateTime) {
    const parsed = DateTimeHelper.parseLocalDateTime(value);
    if (parsed) {
      const local = parsed.toLocal();
      this.inputValue = local.toJSDate();
      this.time = { hour: local.hour, minute: local.minute };
      return;
    }
    this.resetValues();
  }

  get placeholderText(): string {
    return this.dateFormat ? this.dateFormat.toUpperCase() : '';
  }

  updateFromInput(): void {
    if (!this.inputValue) {
      this.resetValues();
      (this as any).onChange({ dateTime: undefined });
      return;
    }
    const date = DateTime.fromJSDate(this.inputValue);
    if (!date.isValid) {
      console.log(`Input invalid: ${this.inputValue}`);
      return;
    }
    this.change(date);
  }

  toggleOpen() {
    this.isPickerOpen = !this.isPickerOpen;
  }

  timeUpdated(time: ITimeValue) {
    if (!time && !this.inputValue) {
      return;
    }
    if (!time) {
      time = { hour: 0, minute: 0 };
    }
    const date = DateTime.fromJSDate(this.inputValue).set({
      hour: time.hour,
      minute: time.minute,
      second: 0,
      millisecond: 0
    });
    this.inputValue = date.toJSDate();
    this.time = { hour: date.hour, minute: date.minute };
    this.change(date);
  }

  private change(date: DateTime) {
    (this as any).onChange({ dateTime: date });
  }

  private resetValues() {
    this.inputValue = undefined;
    this.time = undefined;
  }
}
