import { Page, Report, SortDirections } from '../../core/models';
import { IAlertService } from '../../core/services';

export default class ReportListController {
  static $inject = ['$http', 'AlertService'];

  public query: string = '';
  public reports: Report[];
  public ready: boolean = false;
  public currentPage: number;
  public itemsPerPage: number = 25;
  public totalItems: number;
  public result: Page<Report>;

  constructor(private $http: ng.IHttpService, private AlertService: IAlertService) {}

  $onInit() {
    this.reports = [];
    this.loadReports();
  }

  loadReports() {
    const reportSearch = this.buildReportSearch(this.currentPage, this.itemsPerPage);

    this.$http
      .post<{ items: any[] }>('/api/v1/reports/search', reportSearch)
      .then(response => {
        this.result = <any>response.data;
        this.reports = this.result.items;
        this.totalItems = this.result.count;
        this.ready = true;
      });
  }

  buildReportSearch(page: number, pageSize: number) {
    return {
      name: this.query || '',
      sort: 'name',
      sortDirection: SortDirections.Ascending,
      page: page,
      pageSize: pageSize
    };
  }

  remove(report: Report) {
    this.$http.delete<{}>(`/api/v1/reports/${report.id}`).then(response => {
      this.AlertService.success(`${report.name} removed successfully.`);
      this.loadReports();
    });
  }
}
