export class TemplateLoaderDirective implements ng.IDirective {
  static $inject = ['$compile'];
  public static compile: any;

  public template: string;
  public partials: any = {};
  public scope = {
    partialName: '=',
    model: '=',
    vm: '='
  };

  constructor($compile: ng.ICompileService) {
    TemplateLoaderDirective.compile = $compile;

    this.partials.claimOverview = require('../../claims/claim-overview.html');
    this.partials.claimForm = require('../../claims/claim-form.html');
    this.partials.activities = require('../../claims/activities/activities.html');
    this.partials.timesheet = require('../../claims/timesheets/timesheet.html');
    this.partials.invoices = require('../../claims/invoices/invoices.html');
    this.partials.invoiceNew = require('../../claims/invoices/invoice-new.html');
    this.partials.payments = require('../../claims/payments/payments.html');
    this.partials.paymentNew = require('../../claims/payments/payment-new.html');
    this.partials.sumsInsured = require('../../claims/sumsInsured/sumsInsured.html');
    this.partials.sumsInsuredNew = require('../../claims/sumsInsured/sumInsured-new.html');

    this.partials.fieldsText = require('../../settings/fields/partials/text.html');
    this.partials.fieldsDropdown = require('../../settings/fields/partials/dropdown.html');
    this.partials.fieldsCountry = require('../../settings/fields/partials/country.html');
    this.partials.fieldsAddress = require('../../settings/fields/partials/address.html');
    this.partials.fieldsEntity = require('../../settings/fields/partials/entity.html');
    this.partials.fieldsStatusSet = require('../../settings/fields/partials/status-set.html');
    this.partials.fieldsUser = require('../../settings/fields/partials/user.html');
    this.partials.fieldsEntityContact = require('../../settings/fields/partials/entity-contact.html');
  }

  link(scope: ng.IScope, elem: any, attributes: ng.IAttributes) {
    const that = this;
    const compile = TemplateLoaderDirective.compile;

    scope.$watch('partialName', function(value: string) {
      if (value) {
        that.template = that.partials[value];
        elem.html(that.template);
        compile(elem.contents())(scope);
      } else {
        elem.html('');
      }
    });
  }
}
