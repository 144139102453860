import { ComponentBase } from '../../core/utils/componentBase';
import { PdfViewerController } from './pdfViewer.controller';

export class PdfViewerComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      url: '<'
    };
    this.controller = PdfViewerController;
    this.template = require('./pdfViewer.html');
  }
}
