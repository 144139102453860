import { DocumentTemplate } from '../../../../core/models';
import { IDocumentHttpService } from '../../../../core/http';

interface IWorkflowActionCreateDocument {
  document: string;
  from: string;
  to: string;
  cc: string;
  bcc: string;
  subject: string;
  body: string;
}

export class WorkflowActionCreateDocumentController {
  static $inject = ['$scope', 'DocumentHttpService'];

  documents: DocumentTemplate[] = [];
  data: IWorkflowActionCreateDocument = {} as any;

  constructor(private scope: any, private documentHttpService: IDocumentHttpService) {}

  $onInit() {
    this.load();
  }

  private load() {
    // TODO: Add pagination support
    this.documentHttpService
      .getTemplateFiles(100, 0)
      .safeApply(
        this.scope,
        documents => {
          this.documents = documents.items;
        },
        error => console.error(error)
      )
      .subscribe();
  }
}
