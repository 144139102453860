import { TimesheetModalController } from './timesheetModal.controller';
import { Guid, Timesheet } from '../../../core/models';

export interface ITimesheetModalService {
  /**
   * Opens a modal and adds a timesheet.
   * @returns {ng.ui.bootstrap.IModalInstanceService}
   */
  create(claimId: Guid): ng.IPromise<Timesheet>;
}

export class TimesheetModalService implements ITimesheetModalService {
  static $inject = ['$uibModal'];

  constructor(private modalService: ng.ui.bootstrap.IModalService) {}

  create(claimId: Guid): ng.IPromise<Timesheet> {
    const modalInstance = this.modalService.open({
      template: require('./timesheetModal.html'),
      controller: TimesheetModalController,
      controllerAs: '$ctrl',
      resolve: {
        claimId() {
          return claimId;
        }
      }
    });
    return modalInstance.result;
  }
}
