import { ComponentBase } from '../../core/utils';
import { SearchCellController } from './searchCell.controller';

export class SearchCellComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      row: '<',
      column: '<',
      recordType: '<',
      clickable: '<',
      onSelect: '&'
    };
    this.controller = SearchCellController;
    this.template = require('./searchCell.html');
  }
}
