export class FieldController {
  static $inject = [];

  public definition: any;
  public field: any;
  public templateDefinition: any;
  public templateFields: any[];
  public formDisabled: boolean;
  public sections: any[];

  normalizeFieldType(type: string) {
    let lower = type.toLowerCase();
    if (lower === 'modalentity') {
      lower = 'modalEntity';
    } else if (lower === 'multipleentity') {
      lower = 'multipleEntity';
    }
    return lower;
  }
}
