import { Claim, Guid } from '../../core/models';
import { ILogService } from '../../core/services';
import { EmailTemplateResource } from '../../data/resources/emailTemplate';
import { IClaimStore } from '../../data/stores/claim';
import { ICacheService } from '../services/cache';
import { IClaimHttpService } from 'app/core/http';

export interface IClaimManagerService {
  claim(): Claim;
  template(): any;
  emailTemplates(): any;
  activate(claimId: string): any;
  updateClaim(): any;
  isWatching(): boolean;
}

export class ClaimManagerService implements IClaimManagerService {
  static $inject = [
    '$rootScope',
    '$q',
    'LogService',
    'ClaimStore',
    'ClaimHttpService',
    'EmailTemplateResource',
    'Cache'
  ];

  private _claim: Claim = null;
  private _template: any = null;
  private _isWatching: boolean = false;
  private _emailTemplates: any = [];

  constructor(
    private rootScope: any,
    private qService: ng.IQService,
    private logService: ILogService,
    private claimStore: IClaimStore,
    private claimHttpService: IClaimHttpService,
    private emailTemplateResource: EmailTemplateResource,
    private cacheService: ICacheService
  ) {}

  claim(): Claim {
    return this._claim;
  }

  template(): any {
    return this._template;
  }

  emailTemplates(): any {
    return this._emailTemplates;
  }

  isWatching(): boolean {
    return this._isWatching;
  }

  activate(claimId: string): any {
    const defer = this.qService.defer();

    this.claimStore.get(claimId).then((claim: any) => {
      if (claim.tagIds && claim.tagIds.length > 0) {
        const items = claim.tagIds.map(id => new Guid(id));
        claim.tagIds = [...items];
      }

      this._claim = claim;

      const templatePromise = this.cacheService.claimTemplate(claim.templateId);
      const emailTemplatesPromise = this.emailTemplateResource.query().$promise;

      this.qService
        .all([templatePromise, emailTemplatesPromise])
        .then(values => {
          const template = values[0],
            emailTemplates = (<any>values[1]).items;
          this._template = template;
          this._emailTemplates = emailTemplates;
          defer.resolve({});
        })
        .catch(error => {
          this.logService.error(
            'Could not load claim template ' +
              claim.templateId +
              '. Error: ' +
              JSON.stringify(error)
          );
          defer.reject({});
        });

      this.claimHttpService.isSubscriber(new Guid(claimId)).subscribe(watching => {
        this._isWatching = watching;
      });
    });

    return defer.promise;
  }

  updateClaim(): any {
    const defer = this.qService.defer();
    this.claimStore
      .update(this._claim)
      .then(data => {
        this._claim = data;
        this.rootScope.$emit('claim.updated', this._claim);
        defer.resolve({});
      })
      .catch(error => {
        this.logService.error(error, { action: 'Update claim' });
        defer.reject({});
      });
    return defer.promise;
  }
}
