import { ComponentBase } from '../../core/utils/componentBase';
import { FolderViewerController } from './folderViewer.controller';

export class FolderViewerComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      folderId: '<'
    };
    this.controller = FolderViewerController;
    this.template = require('./folderViewer.html');
  }
}
