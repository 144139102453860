import { ClaimsHistogramWidgetController } from './claimsHistogramWidget.controller';

export class ClaimsHistogramWidgetComponent implements ng.IComponentOptions {
  public controller: any;
  public template: string;
  public bindings: any;

  constructor() {
    this.bindings = { config: '<' };
    this.controller = ClaimsHistogramWidgetController;
    this.template = require('./claimsHistogramWidget.html');
  }
}
