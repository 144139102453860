import d3 from 'd3';

/* @ngInject */
export default function CioBars(): any {
  return {
    restrict: 'EA',
    scope: {
      data: '='
    },
    link: link
  };

  function link(scope, element, attrs) {
    const margin = parseInt(attrs.margin, 10) || 20,
      barHeight = parseInt(attrs.barHeight, 10) || 20,
      barPadding = parseInt(attrs.barPadding, 10) || 5;

    const svg = d3
      .select(element[0])
      .append('svg')
      .style('width', '100%');

    // Browser onresize event
    window.onresize = function() {
      scope.$apply();
    };

    // Watch for resize event
    scope.$watch(
      function() {
        return window.innerWidth;
      },
      function() {
        scope.render(scope.data);
      }
    );

    // watch for data changes and re-render
    scope.$watch(
      'data',
      function(newVals, oldVals) {
        return scope.render(newVals);
      },
      true
    );

    scope.render = function(data) {
      // remove all previous items before render
      svg.selectAll('*').remove();

      // If we don't pass any data, return out of the element
      if (!data) return;

      // setup variables
      const width = (<any>d3.select(element[0]).node()).offsetWidth - margin,
        // calculate the height
        height = scope.data.length * (barHeight + barPadding),
        // Use the category20() scale function for multicolor support
        color = d3.scale.category20(),
        // our xScale
        xScale = d3.scale
          .linear()
          .domain([
            0,
            d3.max(data, function(d: any) {
              return d.count;
            })
          ])
          .range([0, width]);

      // set the height based on the calculations above
      svg.attr('height', height);

      // create the rectangles for the bar chart
      svg
        .selectAll('rect')
        .data(data)
        .enter()
        .append('rect')
        .attr('height', barHeight)
        .attr('width', 140)
        .attr('x', Math.round(margin / 2))
        .attr('y', function(d, i) {
          return i * (barHeight + barPadding);
        })
        .attr('fill', function(d: any) {
          return color(d.count);
        })
        .text(function(d: any) {
          return d.term;
        })
        .transition()
        .duration(1000)
        .attr('width', function(d: any) {
          return xScale(d.count);
        });

      // handles chart text
      svg
        .selectAll('text')
        .data(data)
        .enter()
        .append('text')
        .text(function(d: any) {
          return d.term + ' - ' + d.count;
        })
        .attr('x', Math.round(margin / 2) + 5)
        .attr('y', function(d, i) {
          return i * (barHeight + barPadding) + 15;
        })
        .attr('font-family', 'sans-serif')
        .attr('font-size', '11px')
        .attr('fill', 'white');
    };
  }
}
