import { Invoice } from '../../core/models';
import * as ng from 'angular';

/* tslint:disable-next-line:no-empty-interface */
export interface ClaimInvoiceResource
  extends ng.resource.IResourceClass<ng.resource.IResource<Invoice>> {}

/* @ngInject */
export default function ClaimInvoiceResource(
  $resource: ng.resource.IResourceService
): ClaimInvoiceResource {
  return <ClaimInvoiceResource>$resource<ng.resource.IResource<Invoice>>(
    '/api/v1/claims/:claimId/invoices',
    { claimId: '@claimId' },
    {
      query: { method: 'GET' }
    }
  );
}
