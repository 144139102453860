import ReportViewController from './report-view.controller';

export default class ReportViewComponent implements ng.IComponentOptions {
  public controller: any;
  public template: string;
  public bindings: any;

  constructor() {
    this.bindings = {
      report: '<',
      onUpdate: '&'
    };
    this.controller = ReportViewController;
    this.template = require('./report-view.html');
  }
}
