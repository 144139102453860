import { SearchUserSelectController } from './searchUserSelect.controller';
import { ComponentBase } from '../../../core/utils/componentBase';

export class SearchUserSelectComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      selected: '<',
      unassigned: '<',
      onChange: '&'
    };
    this.controller = SearchUserSelectController;
    this.template = require('./searchUserSelect.html');
  }
}
