import { IAlertService } from '../../../core/services/alert';
import { EntityTemplateResource } from '../../../data/resources/entityTemplate';
import { StateService } from 'angular-ui-router';

/* tslint:disable:no-shadowed-variable */
/* @ngInject */
export default function EntityTypeNewController(
  $scope: any,
  $state: StateService,
  Cache,
  EntityTemplateResource: EntityTemplateResource,
  AlertService: IAlertService
): void {
  /* tslint:disable:no-shadowed-variable */

  $scope.model = {};
  $scope.newForm = {};
  $scope.newForm.active = false;
  $scope.kinds = [
    { id: 'Entity', name: 'Person / Company' },
    { id: 'Record', name: 'Record' },
    { id: 'Policy', name: 'Policy' }
  ];

  $scope.saveModel = function(model) {
    // This is a hack to set a title template, which is
    // required for creation - however we need to really
    // move to a create template with fields model

    switch (model.kind) {
      case 'Entity':
        model.titleTemplate = '{{name}}';
        break;
      default:
        model.titleTemplate = '{{not-set}}';
    }

    EntityTemplateResource.save(model)
      .$promise.then(function(updated) {
        AlertService.success(
          'New entity template ' + updated.name + ' has been created.'
        );
        $scope.newForm.active = false;

        // invalidates cache
        Cache.entityTemplates(true);
        $scope.newForm.$setPristine();

        $state.go('app.entityType.edit', { id: updated.id });
      })
      .catch(function(error) {
        AlertService.error('Could not create entity template, please try again.');
        $scope.newForm.active = false;
      });
  };
}
