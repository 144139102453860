import { FileAddButtonDirective } from './fileAddButton.directive';
import { FileChangeDirective } from './fileChange.directive';
import { FileDropZoneDirective } from './fileDropZone.directive';
import { FileUploadService } from './fileUpload.service';
import { FolderViewerComponent } from './folderViewer.component';
import { FolderViewerGridComponent } from './folderViewerGrid.component';
import { FolderViewerListComponent } from './folderViewerList.component';
import { CoreModule } from '../../core/module';
import { DataModule } from '../../data/module';
import { UIModule } from '../../ui/module';
import { FilesModule } from '../files/files.module';
import angular from 'angular';

export const FoldersModule = angular
  .module('app.instance.folders', [
    DataModule.name,
    CoreModule.name,
    UIModule.name,
    FilesModule.name
  ])

  // Directives
  .directive('fileDropZone', FileDropZoneDirective)
  .directive('fileChange', FileChangeDirective)
  .directive('fileAddButton', FileAddButtonDirective)

  // Components
  .component('cioFolderViewer', new FolderViewerComponent())
  .component('cioFolderViewerGrid', new FolderViewerGridComponent())
  .component('cioFolderViewerList', new FolderViewerListComponent())

  // Services
  .service('FileUploadService', FileUploadService);
