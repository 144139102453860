export function ResizeDirective($parse, $timeout, resize): ng.IDirective {
  return {
    compile: function($element: any, attr: any) {
      const fn = $parse(attr.cioResize);
      return function(scope, element) {
        scope.$on('resize', function(event, data) {
          $timeout(function() {
            scope.$apply(function() {
              fn(scope, { $event: data });
            });
          });
        });
      };
    }
  };
}
