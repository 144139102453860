import { StatusBadgeController } from './statusBadge.controller';
import { ComponentBase } from '../../../core/utils/componentBase';

export class StatusBadgeComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      status: '<',
      templateId: '<'
    };
    this.controller = StatusBadgeController;
    this.template = require('./statusBadge.html');
  }
}
