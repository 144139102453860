import { IResourceStore, IResourceStoreFactory } from './store';
import { EntityContact } from '../../core/models';

/* tslint:disable-next-line:no-empty-interface */
export interface IEntityContactStore extends IResourceStore<EntityContact> {}

/* @ngInject */
export default function EntityContactStore(
  StoreFactory: IResourceStoreFactory<EntityContact>
): IEntityContactStore {
  return <IEntityContactStore>StoreFactory('/api/v1/contacts/:id', { id: '@id' });
}
