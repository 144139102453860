// import { ILogService } from '../../core/services';
import WidgetModalController from './widget-modal-controller';

export class WidgetController {
  static $inject = ['$injector', '$uibModal'];

  public widget: any;
  public templateName: string;
  public model: any;
  public editable: boolean;
  private service: any;

  constructor(private injector: any, private uibModal: any) {}

  $onInit(): void {
    // this.service = this.injector.get(this.widget.type);
    // this.templateName = '';
    // this.service.setUserConfig(this.widget);
    // this.loadWidget();
  }

  // loadWidget(): void {
  //   this.service
  //     .load()
  //     .then(response => {
  //       this.templateName = this.service.config.templateName;
  //       this.model = response.data.items || response.data;
  //     })
  //     .catch(err =>
  //       this.logService.error(`Dashboard widget load error '${err.toString()}'`)
  //     );
  // }

  removeWidget() {
    (<any>this).onRemove();
  }

  configureWidget(): void {
    const _service = this.service;
    const _widget = this.widget;

    const modalInstance = this.uibModal.open({
      template: require('./widget-modal.html'),
      controller: WidgetModalController,
      controllerAs: '$ctrl',
      resolve: {
        widget: function() {
          return _widget;
        },
        availableOpts: function() {
          return _service.availableOpts ? _service.availableOpts : [];
        }
      }
    });

    modalInstance.result.then(configWidget => {
      _widget.updated = true;
      // this.loadWidget();
    });
  }
}
