import { IFieldHttpService } from '../../../../core/http';
import {
  FieldDefinition,
  IWorkflowFilter,
  StatusSetFieldDefinition
} from '../../../../core/models';

interface IStatusItem {
  value: string;
  label: string;
}

export class WorkflowFilterStatusController {
  static $inject = ['$scope', 'FieldHttpService'];

  filter: IWorkflowFilter;
  statuses: IStatusItem[] = [];
  fields: FieldDefinition[] = [];

  constructor(private scope: any, private fieldHttpService: IFieldHttpService) {}

  $onInit() {
    this.loadFields();
  }

  private loadFields(): void {
    this.fieldHttpService
      .all()
      .safeApply(this.scope, fields => this.setFields(fields))
      .subscribe();
  }

  private setFields(fields: FieldDefinition[]) {
    for (const field of fields) {
      if (
        this.filter.fieldName === 'status' ||
        field.id === this.filter.fieldId ||
        field.name === this.filter.fieldName
      ) {
        const statusField = field as StatusSetFieldDefinition;
        const values = (statusField.settings.status || []).map(
          x => ({ value: x.name, label: x.name } as IStatusItem)
        );
        this.statuses.push(...values);
      }
    }
  }
}
