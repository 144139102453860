import { DocumentEditController } from './documentEdit.controller';
import { ComponentBase } from '../../../core/utils/componentBase';

export class DocumentEditComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {};
    this.controller = DocumentEditController;
    this.template = require('./documentEdit.html');
  }
}
