export interface IColor {
  hex?: string;
  useWhiteColor: boolean;
}

export interface IColorPalette {
  name: string;
  value: string;
  colors: IColor[];
}

export const colorPalettes: IColorPalette[] = [
  {
    name: 'Legacy palette',
    value: 'legacyPalette',
    colors: [
      {
        hex: '#6182F2',
        useWhiteColor: true
      },
      {
        hex: '#7BD12F',
        useWhiteColor: true
      },
      {
        hex: '#A9BCFF',
        useWhiteColor: true
      },
      {
        hex: '#55D5DC',
        useWhiteColor: true
      },
      {
        hex: '#7DE6BB',
        useWhiteColor: true
      },
      {
        hex: '#52B639',
        useWhiteColor: true
      },
      {
        hex: '#FBBA71',
        useWhiteColor: true
      },
      {
        hex: '#F7D848',
        useWhiteColor: true
      },
      {
        hex: '#FB8B7A',
        useWhiteColor: true
      },
      {
        hex: '#D82D24',
        useWhiteColor: true
      },
      {
        hex: '#DBACFF',
        useWhiteColor: true
      },
      {
        hex: '#E1E1E1',
        useWhiteColor: false
      }
    ]
  },
  {
    name: 'Pastel palette',
    value: 'pastelPalette',
    colors: [
      {
        hex: '#DAEDFB',
        useWhiteColor: false
      },
      {
        hex: '#DCF7F3',
        useWhiteColor: false
      },
      {
        hex: '#DADDFB',
        useWhiteColor: false
      },
      {
        hex: '#E8DAFB',
        useWhiteColor: false
      },
      {
        hex: '#F8DAFB',
        useWhiteColor: false
      },
      {
        hex: '#FFFCDD',
        useWhiteColor: false
      },
      {
        hex: '#FFD8D8',
        useWhiteColor: false
      },
      {
        hex: '#F5A2A2',
        useWhiteColor: false
      },
      {
        hex: '#E1F5C4',
        useWhiteColor: false
      },
      {
        hex: '#FFF4E3',
        useWhiteColor: false
      },
      {
        hex: '#F0FFC9',
        useWhiteColor: false
      },
      {
        hex: '#FFE4B8',
        useWhiteColor: false
      },
      {
        hex: '#FFCFAD',
        useWhiteColor: false
      },
      {
        hex: '#E6D1B1',
        useWhiteColor: false
      },
      {
        hex: '#F4FCE2',
        useWhiteColor: false
      },
      {
        hex: '#D3EBC7',
        useWhiteColor: false
      },
      {
        hex: '#AEE1D3',
        useWhiteColor: false
      },
      {
        hex: '#F9FC9D',
        useWhiteColor: false
      }
    ]
  },

  {
    name: 'Standard palette',
    value: 'standardPalette',
    colors: [
      {
        hex: '#0033CC',
        useWhiteColor: true
      },
      {
        hex: '#0961B5',
        useWhiteColor: true
      },
      {
        hex: '#0854C7',
        useWhiteColor: true
      },
      {
        hex: '#420ACC',
        useWhiteColor: true
      },
      {
        hex: '#9B59B6',
        useWhiteColor: true
      },
      {
        hex: '#C60CEA',
        useWhiteColor: true
      },
      {
        hex: '#AD4363',
        useWhiteColor: true
      },
      {
        hex: '#DD1651',
        useWhiteColor: true
      },
      {
        hex: '#C0392B',
        useWhiteColor: true
      },
      {
        hex: '#520536',
        useWhiteColor: true
      },
      {
        hex: '#49436B',
        useWhiteColor: true
      },
      {
        hex: '#036564',
        useWhiteColor: true
      },
      {
        hex: '#355E32',
        useWhiteColor: true
      },
      {
        hex: '#CC0033',
        useWhiteColor: true
      },
      {
        hex: '#2ECC71',
        useWhiteColor: true
      },
      {
        hex: '#7DA0F2',
        useWhiteColor: true
      },
      {
        hex: '#FFFF00',
        useWhiteColor: false
      },
      {
        hex: '#F1C40F',
        useWhiteColor: true
      }
    ]
  }
];
