import { StateService } from 'angular-ui-router';
import { IAlertService } from '../../../core/services/alert';
import { ILogService } from '../../../core/services/log';
import '../../../data/resources/sum-insured-category';

/* @ngInject */
export default function SumInsuredCategoryEditController(
  SumInsuredCategoryResource,
  Cache,
  AlertService: IAlertService,
  LogService: ILogService,
  $state: StateService
): void {
  const vm = this;
  vm.model = {};
  vm.newForm = {};
  vm.newForm.active = false;

  vm.update = update;

  init();

  function init() {
    Cache.claimTemplates().then(function(result) {
      vm.claimTypes = result;
    });

    SumInsuredCategoryResource.get({
      id: (<any>$state.params).id
    }).$promise.then(function(data) {
      vm.model = data;
    });
  }

  function update(model) {
    vm.newForm.active = true;

    SumInsuredCategoryResource.update(model)
      .$promise.then(function(updated) {
        AlertService.success('Category ' + updated.name + ' has been updated.');
        vm.newForm.active = false;
        vm.newForm.$setPristine();

        vm.model = updated;
      })
      .catch(function(error) {
        AlertService.error('Could not create category, please try again.');
        LogService.error('Could not create category. Error: ' + JSON.stringify(error));
        vm.newForm.active = false;
      });
  }
}
