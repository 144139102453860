import { WorkflowListController } from './workflowList.controller';
import { ComponentBase } from '../../../core/utils/componentBase';

export class WorkflowListComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {};
    this.controller = WorkflowListController;
    this.template = require('./workflowList.html');
  }
}
