import { SumInsuredCategory } from '../../core/models';
import * as ng from 'angular';

/* tslint:disable-next-line:no-empty-interface */
export interface SumInsuredCategoryResource
  extends ng.resource.IResourceClass<ng.resource.IResource<SumInsuredCategory>> {}

/* @ngInject */
export default function SumInsuredCategoryResource(
  $resource: ng.resource.IResourceService
): SumInsuredCategoryResource {
  return <SumInsuredCategoryResource>$resource<ng.resource.IResource<SumInsuredCategory>>(
    '/api/v1/sum-insured-categories/:id',
    { id: '@id' },
    {
      update: {
        method: 'PUT',
        params: {
          id: '@id'
        }
      },
      query: { method: 'GET' }
    }
  );
}
