import { WorkflowFilterDropdownController } from './workflowFilterDropdown.controller';
import { ComponentBase } from '../../../../core/utils/componentBase';

export class WorkflowFilterDropdownComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      filter: '=',
      field: '<'
    };
    this.controller = WorkflowFilterDropdownController;
    this.template = require('./workflowFilterDropdown.html');
  }
}
