import { IOrganizationHttpService } from '../../../core/http';
import { Guid, StorageFile } from '../../../core/models';
import { IAlertService } from '../../../core/services';
import { FileHelper, continueWith } from '../../../core/utils';
import { IFileUploadService } from '../../folders/fileUpload.service';
import { StateService } from 'angular-ui-router';

export class DocumentNewController {
  static $inject = [
    '$state',
    'AlertService',
    'OrganizationHttpService',
    'FileUploadService'
  ];

  file?: File;
  model: StorageFile;
  isBusy: boolean = false;

  constructor(
    private state: StateService,
    private alertService: IAlertService,
    private organizationHttpService: IOrganizationHttpService,
    private fileUploadService: IFileUploadService
  ) {}

  $onInit() {
    this.model = new StorageFile();
  }

  fileChange(file) {
    this.file = file;
    this.fileChanged(this.file);
  }

  createDocument() {
    if (!this.file) {
      this.alertService.error('You need to choose a file, please try again.');
      return;
    }

    this.setIsBusy();
    this.organizationHttpService
      .getOrganization()
      .switchMap(org => {
        const storageFile = StorageFile.createTemplate(
          org.templateFolderId,
          this.model.name,
          this.model.title,
          this.model.description
        );
        return this.fileUploadService.uploadFile(storageFile, this.file);
      })
      .finally(() => this.setNotBusy())
      .catch(continueWith(() => this.onCreateError))
      .do(f => this.alertService.success('Document saved.'))
      .do(f => this.gotoDocument(f.id))
      .subscribe();
  }

  cleanFileName() {
    this.model.name = FileHelper.sanitizeFileName(this.model.name);
  }

  private onCreateError(error: any): void {
    this.alertService.error('Could not create document, please try again.');
  }

  private gotoDocument(id: Guid): void {
    this.state.go('app.document.edit', { id: id.toString() });
  }

  private setIsBusy(): void {
    this.isBusy = true;
  }

  private setNotBusy(): void {
    this.isBusy = false;
  }

  private fileChanged(file: File) {
    if (!file) {
      return;
    }

    let fpath = file.name;
    fpath = fpath.replace(/\\/g, '/');

    const fileExtension = fpath.substring(fpath.lastIndexOf('.') + 1);
    const mimeType = file.type;
    if (
      fileExtension !== 'doc' &&
      fileExtension !== 'docx' &&
      mimeType !== 'application/msword' &&
      mimeType !==
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      this.alertService.error('Only .doc and .docx are allowed');
      return;
    }

    if (!this.model.name || this.model.name.length === 0) {
      this.model.name = fpath.substring(fpath.lastIndexOf('/') + 1);
    }

    if (!this.model.title || this.model.title.length === 0) {
      this.model.title = fpath.substring(
        fpath.lastIndexOf('/') + 1,
        fpath.lastIndexOf('.')
      );
    }
  }
}
