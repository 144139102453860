import { WorkflowFilterEntityContactController } from './workflowFilterEntityContact.controller';
import { ComponentBase } from '../../../../core/utils/componentBase';

export class WorkflowFilterEntityContactComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = { filter: '=', fields: '<', field: '<' };
    this.controller = WorkflowFilterEntityContactController;
    this.template = require('./workflowFilterEntityContact.html');
  }
}
