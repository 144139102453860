import UserSelectController from './user-select-controller';
import { UserSummaryResource } from '../../../data/resources/user-summary';
import * as ng from 'angular';
import { User } from 'app/core/models';

export interface IUserSelectService {
  open: (config: { title: string; group: string }) => ng.IPromise<User>;
}

/* tslint:disable:no-shadowed-variable */
/* @ngInject */
export default function UserSelectService(
  $uibModal: ng.ui.bootstrap.IModalService,
  $http: ng.IHttpService,
  UserSummaryResource: UserSummaryResource
): any {
  /* tslint:enable:no-shadowed-variable */

  const self = this;

  /**
   * Opens a modal and allows the user to select a user.
   * @param {string} [title] The modal window title.
   * @param {object[]} [users] The list of users to select from; all users shown if not specified.
   * @returns {promise}
   */
  self.open = function(config): ng.IPromise<any> {
    const modalInstance = $uibModal.open({
      template: require('./user-select.html'),
      controller: UserSelectController,
      resolve: {
        title: function() {
          return config.title || 'Select a user';
        },
        config: function() {
          return config;
        },
        users: function(): any {
          if (config.group) {
            return $http.get('/api/v1/groups/' + config.group + '/members');
          }

          return UserSummaryResource.query().$promise;
        }
      }
    });

    return modalInstance.result;
  };
}
