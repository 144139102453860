import { User, IWorkflowFilter } from '../../../../core/models';
import { ICacheService } from '../../../services/cache';

export class WorkflowFilterUserController {
  static $inject = ['Cache'];

  filter: IWorkflowFilter;
  users: User[] = [];

  constructor(private cacheService: ICacheService) {}

  $onInit() {
    this.loadUsers();
  }

  private loadUsers(): void {
    this.cacheService
      .users()
      .then(users => (this.users = users))
      .catch(() => {});
  }
}
