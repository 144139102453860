import { ComponentBase } from '../../core/utils/componentBase';
import { FolderViewerGridController } from './folderViewerGrid.controller';

export class FolderViewerGridComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      folders: '<',
      files: '<',
      starred: '<',
      searchTerm: '<',
      onPreview: '&',
      onRename: '&',
      onDownload: '&',
      onDelete: '&',
      onAddStar: '&',
      onRemoveStar: '&'
    };
    this.controller = FolderViewerGridController;
    this.template = require('./folderViewerGrid.html');
  }
}
