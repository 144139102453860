import { IFieldStore } from '../../../data/stores/field';
import { EntityTemplateResource } from '../../../data/resources/entityTemplate';
import reject from 'lodash/reject';
import { FieldDefinitionScopes } from 'app/core/models';

/* @ngInject */
export default function EntityTemplateManagerService(
  $q: ng.IQService,
  $uibModal: ng.ui.bootstrap.IModalService,
  // tslint:disable-next-line:no-shadowed-variable
  EntityTemplateResource: EntityTemplateResource,
  FieldStore: IFieldStore
): any {
  let theEntityTemplate = null,
    theFields = null;

  const service = {
    template: function() {
      return theEntityTemplate;
    },
    fields: function() {
      return theFields;
    },

    activate: activate,
    showModal: showModal
  };

  return service;

  function activate(entityTypeId) {
    const defer = $q.defer();

    const templatePromise = EntityTemplateResource.get({ id: entityTypeId }).$promise,
      fieldsPromise = FieldStore.allFieldsPromise();

    $q.all([templatePromise, fieldsPromise]).then(function(values) {
      theEntityTemplate = values[0];
      theFields = reject(values[1], { scope: FieldDefinitionScopes.Claim });

      defer.resolve({});
    });

    return defer.promise;
  }

  function showModal(theConfig) {
    return $uibModal.open({
      template: require('../claimTypes/add-field.html'),
      controller: 'FieldAddModalController',
      resolve: {
        fields: function() {
          return theFields;
        },
        config: function() {
          return theConfig || {};
        }
      }
    });
  }
}
