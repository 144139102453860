import AuthService from './auth-service';
import CacheFactory from './cache';
import ConfirmationModalService from './confirmation-modal';
import ConfirmationModalController from './confirmation-modal-controller';
import { FilterBuilder } from './filter-builder';
import InstanceService from './instance-service';
import LoadingService from './loading';
import { MapService } from './maps';
import { SystemTemplateField } from './system-template-field';
import { CoreModule } from '../../core/module';
import { DataModule } from '../../data/module';
import { UIModule } from '../../ui/module';
import angular from 'angular';

export const ServicesModule = angular
  .module('app.instance.services', [DataModule.name, CoreModule.name, UIModule.name])

  // Factories
  .factory('Cache', CacheFactory)
  .factory('MapService', MapService)
  .factory('SystemTemplateField', SystemTemplateField)
  .factory('FilterBuilder', FilterBuilder)

  // Services
  .service('AuthService', AuthService)
  .service('InstanceService', InstanceService)
  .service('ConfirmationModalService', ConfirmationModalService)
  .service('LoadingService', LoadingService)

  // Controllers
  .controller('ConfirmationModalController', ConfirmationModalController);
