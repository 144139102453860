/**
 * Splits words where upper case characters exist.
 */
export function splitUpperCaseFilter() {
  return function(text: string) {
    if (text) {
      const match = text.match(/[A-Z][a-z]+/g);
      if (match && match.length) {
        return match.join(' ');
      }
    }

    return text;
  };
}
