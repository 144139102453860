import { WorkflowActionSendEmailController } from './workflowActionSendEmail.controller';
import { ComponentBase } from '../../../../core/utils/componentBase';

export class WorkflowActionSendEmailComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = { data: '=' };
    this.controller = WorkflowActionSendEmailController;
    this.template = require('./workflowActionSendEmail.html');
  }
}
