import { Page } from './page';
import { SearchField, SearchValue } from './search';

export interface IClaimSearchQuery {
  skip: number;
  take: number;
  fields: string[];
  query: string;
  sort: string;
  sortDescending: boolean;
}

export class ClaimSearchResults {
  search: IClaimSearchQuery;
  fields: SearchField[] = [];
  page: Page<SearchValue[]>;

  static fromJson(json: any): ClaimSearchResults {
    const results = new ClaimSearchResults();
    results.search = json.search as IClaimSearchQuery;
    results.page = Page.fromJson(json.page, item =>
      (item || []).map(x => SearchValue.fromJson(x))
    );
    results.fields = (json.fields || []).map(x => SearchField.fromJson(x));
    return results;
  }
}
