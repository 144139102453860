import { DateTimeEditController } from './dateTimeEdit.controller';
import { ComponentBase } from '../../../core/utils/componentBase';

export class DateTimeEditComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      dateTime: '<',
      isRequired: '<',
      noMinutes: '<',
      onChange: '&'
    };
    this.controller = DateTimeEditController;
    this.template = require('./dateTimeEdit.html');
  }
}
