import { ILogService, ISecurityService, ISessionService } from '../../core/services';
import { IMapService } from './maps';
import { ICacheService } from './cache';

/* @ngInject */
export default function LoadingService(
  $q: ng.IQService,
  SessionService: ISessionService,
  Cache: ICacheService,
  SecurityService: ISecurityService,
  LogService: ILogService,
  MapService: IMapService
): any {
  const deferred = $q.defer();

  return {
    load: load
  };

  function load() {
    LogService.debug('App :: Loading app');
    MapService.load();
    SecurityService.loadUser(SessionService.getUserId())
      .then(function() {
        const tasks = [
          $q.when(Cache.initialize()),
          SecurityService.loadPermissions(SessionService.getUserId())
        ];
        $q.all(tasks)
          .then(loadSuccess)
          .catch(loadFailure);
      })
      .catch(loadFailure);

    return deferred.promise;
  }

  function loadSuccess() {
    LogService.debug('App :: Load successful');
    deferred.resolve();
  }

  function loadFailure(err) {
    LogService.error('App :: Load failed: ' + err);
    deferred.reject(err);
  }
}
