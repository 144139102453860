import { CurrencyDirective } from './directives/currency';
import {
  bytesFilter,
  checkmarkFilter,
  filenameFilter,
  formatterFilter,
  splitUpperCaseFilter,
  truncateFilter,
  underscoreReplaceFilter
} from './filters';
import {
  ActivityHttpService,
  ClaimHttpService,
  ContactHttpService,
  CurrencyHttpService,
  DocumentHttpService,
  EntityHttpService,
  HttpService,
  NoteHttpService,
  NotificationHttpService,
  NotificationSettingsHttpService,
  OrganizationHttpService,
  StorageFileHttpService,
  StorageFolderHttpService,
  SubscriptionHttpService,
  TaskHttpService,
  TagCacheService,
  TagHttpService,
  WorkflowHttpService,
  FieldHttpService,
  SettingHttpService
} from './http';
import { ApiInterceptor } from './interceptors/api';
import {
  AlertService,
  CountryService,
  DateService,
  DownloadService,
  GravatarService,
  HashService,
  LocalStorageService,
  LogService,
  MessagingFactory,
  RedirectService,
  SecurityService,
  SessionService,
  TrackingService
} from './services';
import { appConfig } from './utils/appConfig';
import PopupManager from './utils/popupManager';
import angular from 'angular';
import 'angular-file-saver';
import 'angular-toastr';
import 'rx-angular';

export const CoreModule = angular
  .module('app.core', ['toastr', 'ngFileSaver', 'rx'])

  // Constants
  .constant('config', appConfig)

  // Filters
  .filter('bytes', bytesFilter)
  .filter('checkmark', checkmarkFilter)
  .filter('filename', filenameFilter)
  .filter('formatter', formatterFilter)
  .filter('splitUpperCase', splitUpperCaseFilter)
  .filter('truncate', truncateFilter)
  .filter('underscoreReplace', underscoreReplaceFilter)

  // Directives
  .directive('cioCurrency', CurrencyDirective)

  // Interceptors
  .factory('ApiInterceptor', ApiInterceptor)

  // Services
  .factory('CountryService', CountryService)
  .service('AlertService', AlertService)
  .service('DateService', DateService)
  .service('DownloadService', DownloadService)
  .service('GravatarService', GravatarService)
  .service('HashService', HashService)
  .service('LocalStorageService', LocalStorageService)
  .service('LogService', LogService)
  .service('PopupManager', PopupManager)
  .service('RedirectService', RedirectService)
  .service('SecurityService', SecurityService)
  .service('SessionService', SessionService)
  .service('TrackingService', TrackingService)

  // Http
  .service('ActivityHttpService', ActivityHttpService)
  .service('ClaimHttpService', ClaimHttpService)
  .service('CurrencyHttpService', CurrencyHttpService)
  .service('ContactHttpService', ContactHttpService)
  .service('DocumentHttpService', DocumentHttpService)
  .service('EntityHttpService', EntityHttpService)
  .service('FieldHttpService', FieldHttpService)
  .service('HttpService', HttpService)
  .service('MessagingService', MessagingFactory)
  .service('NoteHttpService', NoteHttpService)
  .service('NotificationHttpService', NotificationHttpService)
  .service('NotificationSettingsHttpService', NotificationSettingsHttpService)
  .service('OrganizationHttpService', OrganizationHttpService)
  .service('SettingHttpService', SettingHttpService)
  .service('StorageFileHttpService', StorageFileHttpService)
  .service('StorageFolderHttpService', StorageFolderHttpService)
  .service('SubscriptionHttpService', SubscriptionHttpService)
  .service('TaskHttpService', TaskHttpService)
  .service('WorkflowHttpService', WorkflowHttpService)
  .service('TagHttpService', TagHttpService)
  .service('TagCacheService', TagCacheService);
