import { NotificationViewController } from './notificationView.controller';
import { ComponentBase } from '../../core/utils/componentBase';

export class NotificationViewComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {};
    this.controller = NotificationViewController;
    this.template = require('./notificationView.html');
  }
}
