import { StateProvider } from 'angular-ui-router';

/* @ngInject */
export function DocumentSettingsRoutes($stateProvider: StateProvider): void {
  $stateProvider
    .state('app.documents', <any>{
      title: 'Documents',
      url: '/settings/documents?page&records',
      params: {
        page: { dynamic: true },
        records: { dynamic: true }
      },
      template: '<cio-documents></cio-documents>'
    })
    .state('app.document', {
      abstract: true,
      url: '/settings/document',
      template: '<ui-view/>'
    })
    .state('app.document.new', <any>{
      title: 'New Document',
      url: '/new',
      template: '<cio-document-new></cio-document-new>'
    })
    .state('app.document.edit', <any>{
      title: 'Edit Document',
      url: '/{id}',
      template: '<cio-document-edit></cio-document-edit>'
    });
}
