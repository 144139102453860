import { WorkflowFilterTextController } from './workflowFilterText.controller';
import { ComponentBase } from '../../../../core/utils/componentBase';

export class WorkflowFilterTextComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      filter: '='
    };
    this.controller = WorkflowFilterTextController;
    this.template = require('./workflowFilterText.html');
  }
}
