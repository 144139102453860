import { HttpService } from './http';
import { PageHelper } from './pageHelper';
import { Guid, Page, StorageFile, StorageFolder } from '../models';
import { logError } from '../utils/rx';
import { Observable } from 'rxjs/Observable';

export interface IStorageFolderHttpService {
  getPublicFolder(): Observable<StorageFolder>;
  getPublicFiles(take?: number, skip?: number): Observable<Page<StorageFile>>;
  getFolder(folderId: Guid): Observable<StorageFolder>;
  getFiles(folderId: Guid, take?: number, skip?: number): Observable<Page<StorageFile>>;
  getStarredFiles(
    folderId: Guid,
    take?: number,
    skip?: number
  ): Observable<Page<StorageFile>>;
  getFolders(
    folderId: Guid,
    take?: number,
    skip?: number
  ): Observable<Page<StorageFolder>>;
}

export class StorageFolderHttpService implements IStorageFolderHttpService {
  static $inject = ['HttpService'];

  private readonly _url: string = '/api/v1/folders';

  constructor(private httpService: HttpService) {}

  getPublicFolder(): Observable<StorageFolder> {
    const url = `${this._url}/special/public`;
    return this.httpService
      .get(url)
      .map(res => StorageFolder.fromJson(res.json()))
      .catch(logError);
  }

  getPublicFiles(take: number = 50, skip: number = 0): Observable<Page<StorageFile>> {
    const url = PageHelper.query(`${this._url}/special/public/files/list`, take, skip);
    return this.httpService
      .get(url)
      .map(res => Page.fromJson(res.json(), StorageFile.fromJson))
      .catch(logError);
  }

  getFolder(folderId: Guid): Observable<StorageFolder> {
    const url = `${this._url}/${folderId.toString()}`;
    return this.httpService
      .get(url)
      .map(res => StorageFolder.fromJson(res.json()))
      .catch(logError);
  }

  getFiles(
    folderId: Guid,
    take: number = 50,
    skip: number = 0
  ): Observable<Page<StorageFile>> {
    const url = PageHelper.query(
      `${this._url}/${folderId.toString()}/files/list?starred=false`,
      take,
      skip
    );
    return this.httpService
      .get(url)
      .map(res => Page.fromJson(res.json(), StorageFile.fromJson))
      .catch(logError);
  }

  getStarredFiles(
    folderId: Guid,
    take: number = 50,
    skip: number = 0
  ): Observable<Page<StorageFile>> {
    const url = PageHelper.query(
      `${this._url}/${folderId.toString()}/files/list?starred=true`,
      take,
      skip
    );
    return this.httpService
      .get(url)
      .map(res => Page.fromJson(res.json(), StorageFile.fromJson))
      .catch(logError);
  }

  getFolders(
    folderId: Guid,
    take: number = 50,
    skip: number = 0
  ): Observable<Page<StorageFolder>> {
    const url = PageHelper.query(
      `${this._url}/${folderId.toString()}/folders/list`,
      take,
      skip
    );
    return this.httpService
      .get(url)
      .map(res => Page.fromJson(res.json(), StorageFolder.fromJson))
      .catch(logError);
  }
}
