import { EntityContact } from '../../../core/models';
import * as ng from 'angular';

/* @ngInject */
export default function EntityContactModalController(
  $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
  $filter: ng.IFilterService,
  contacts: EntityContact[]
): void {
  const vm = this;
  vm.contacts = contacts;
  vm.search2 = '';
  vm.filter = filter;
  vm.select = select;
  vm.cancel = cancel;

  function filter(contact) {
    if (vm.search2 === '') {
      return true;
    }

    return (contact.name || '').toLowerCase().indexOf(vm.search2.toLowerCase()) > -1;
  }

  function select(contact) {
    $uibModalInstance.close(contact);
  }

  function cancel() {
    $uibModalInstance.dismiss('cancel');
  }
}
