import { Guid } from '../../../core/models';

export class RecordLinkController {
  static $inject = [];

  text: string;
  recordType: string;
  private _id: Guid;

  get id(): Guid {
    return this._id;
  }

  set id(value: Guid) {
    this._id = new Guid(value);
  }

  get isClaim(): boolean {
    return this.recordType === 'Claim';
  }

  get isEntity(): boolean {
    return this.recordType === 'Entity';
  }
}
