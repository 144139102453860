import { ReportField } from '../../core/models';
import * as ng from 'angular';

/* tslint:disable-next-line:no-empty-interface */
export interface ReportFieldResource
  extends ng.resource.IResourceClass<ng.resource.IResource<ReportField>> {}

/* @ngInject */
export default function ReportFieldResource(
  $resource: ng.resource.IResourceService
): ReportFieldResource {
  return <ReportFieldResource>(
    $resource<ng.resource.IResource<ReportField>>('/api/v1/reports/fields', null, {
      query: { method: 'POST' }
    })
  );
}
