import { IAlertService } from '../../../core/services';
import { StateService } from 'angular-ui-router';
import { IFieldHttpService } from 'app/core/http';
import sortBy from 'lodash/sortBy';
import { Observable } from 'rxjs/Observable';

/* @ngInject */
export default function FieldListController(
  $scope: any,
  $state: StateService,
  AlertService: IAlertService,
  FieldHttpService: IFieldHttpService
): void {
  $scope.models = [];
  $scope.fieldScope = $state.current.data.fieldScope;

  $scope.loadFields = function(sortClause) {
    FieldHttpService.all()
      .safeApply($scope, fields => {
        $scope.models = sortBy(fields.filter(x => x.scope === $scope.fieldScope), [
          sortClause || 'Name'
        ]);
      })
      .catch(error => {
        AlertService.error('Could not load fields, please try again.');
        return Observable.throw(error);
      })
      .subscribe();
  };

  $scope.loadFields();

  $scope.new = function() {
    $state.go($state.current.data.newState);
  };

  $scope.view = function(model) {
    $state.go($state.current.data.editState, { id: model.id });
  };
}
