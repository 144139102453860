import { Guid } from './guid';
import { FileHelper } from '../../core/utils/fileHelper';
import { DateTime } from 'luxon';
import { DateTimeHelper } from '../../core/utils';
import { ITemplateRequirement } from './templateRequirement';

export interface StorageFileProperties {
  [index: string]: any;
  title?: string;
  description?: string;
  requirements?: ITemplateRequirement[];
}

export class StorageFileRevision {
  id: Guid;
  size: number = 0;
  created: DateTime;
  createdBy: Guid;
  isDeleted: boolean = false;
  deleted?: DateTime;
  deletedBy?: Guid;

  static fromJson(json) {
    const revision = Object.assign(new StorageFileRevision(), json);
    revision.id = new Guid(json.id);
    revision.created = DateTimeHelper.parseUtcDateTime(json.created);
    revision.createdBy = new Guid(json.createdBy);
    revision.deleted = json.deleted && DateTimeHelper.parseUtcDateTime(json.deleted);
    revision.deletedBy = json.deletedBy && new Guid(json.deletedBy);
    return revision;
  }
}

export class StorageFile {
  id: Guid;
  name: string;
  folder: Guid;
  revisions?: StorageFileRevision[] = [];
  created: DateTime;
  createdBy: Guid;
  lastModified: DateTime;
  lastModifiedBy: Guid;
  isStarred: boolean = false;
  isDeleted: boolean = false;
  deleted?: DateTime;
  deletedBy?: Guid;
  size: number;
  mimeType: string;
  headRevisionId: Guid;
  properties: StorageFileProperties = {};

  static createTemplate(
    folderId: Guid,
    name: string,
    title: string,
    description: string
  ): StorageFile {
    const file = Object.assign(new StorageFile(), {});
    file.id = Guid.newGuid();
    file.folder = folderId;
    file.name = name;
    file.properties.title = title || '';
    file.properties.description = description || '';
    return file;
  }

  static fromJson(json: any): StorageFile {
    const file = Object.assign(new StorageFile(), json) as StorageFile;
    file.id = new Guid(json.id);
    file.folder = new Guid(json.folder);
    file.headRevisionId = new Guid(json.headRevisionId);
    file.created = DateTimeHelper.parseUtcDateTime(json.created);
    file.createdBy = new Guid(json.createdBy);
    file.lastModified = DateTimeHelper.parseUtcDateTime(json.lastModified);
    file.lastModifiedBy = new Guid(json.lastModifiedBy);
    file.deleted = json.deleted && DateTimeHelper.parseUtcDateTime(json.deleted);
    file.deletedBy = json.deletedBy && new Guid(json.deletedBy);
    file.revisions = (json.revisions || []).map(StorageFileRevision.fromJson);
    file.properties = json.properties || {};
    return file;
  }

  get headRevision(): StorageFileRevision {
    return (
      this.revisions.find(x => x.id.equals(this.headRevisionId)) ||
      (this.revisions.length && this.revisions[0])
    );
  }

  get extension(): string {
    return FileHelper.getExtension(this.name);
  }

  set title(value) {
    this.properties.title = value;
  }

  get title(): string {
    return this.properties.title;
  }

  get description(): string {
    return this.properties.description;
  }

  set description(value) {
    this.properties.description = value;
  }

  getDownloadUrl(): string {
    return !this.headRevisionId || Guid.empty().equals(this.headRevisionId)
      ? `/api/v1/files/${this.id}/download`
      : `/api/v1/files/${this.id}/revisions/${this.headRevisionId}/download`;
  }

  getRevisionDownloadUrl(revision: StorageFileRevision): string {
    return `/api/v1/files/${this.id}/revisions/${revision.id}/download`;
  }

  getPreviewUrl(): string {
    return !this.headRevisionId || Guid.empty().equals(this.headRevisionId)
      ? `/api/v1/files/${this.id}/preview`
      : `/api/v1/files/${this.id}/revisions/${this.headRevisionId}/preview`;
  }

  getRevisionPreviewUrl(revision: StorageFileRevision): string {
    return `/api/v1/files/${this.id}/revisions/${revision.id}/preview`;
  }

  getThumbnailUrl(width: number = 100, height: number = 100): string {
    return `/api/v1/files/${this.id}/thumbnail?w=${width}&h=${height}`;
  }

  getRevisionThumbnailUrl(
    revision: StorageFileRevision,
    width: number = 100,
    height: number = 100
  ): string {
    return `/api/v1/files/${this.id}/revisions/${
      revision.id
    }/thumbnail?w=${width}&h=${height}`;
  }

  getExportUrl(format: string): string {
    return !this.headRevisionId || Guid.empty().equals(this.headRevisionId)
      ? `/api/v1/files/${this.id}/export?format=${format}`
      : `/api/v1/files/${this.id}/revisions/${
          this.headRevisionId
        }/export?format=${format}`;
  }

  getRevisionExportUrl(revision: StorageFileRevision, format: string): string {
    return `/api/v1/files/${this.id}/revisions/${revision.id}/export?format=${format}`;
  }
}
