import { Guid, Entity } from '../../core/models';
import { EntitySearchModalController } from './entitySearchModal.controller';
import { EntityResource } from 'app/data/resources/entity';
import { EntityEditModalController } from './entityEditModal.controller';

export interface IEntityService {
  /**
   * Open the entity modal with a list of entities for the user to select from.
   *
   * @param {Guid[]} templateIds The allowed entity templates to select from
   * @param {Boolean} allowCreate Indicates if create should be allowed.
   */
  selectEntity(typeIds: Guid[], allowCreate: boolean): Promise<Guid>;

  /**
   * Creates a new entity of the specified template.
   *
   * @param {Guid} entityTemplateId The entity template to base the entity off.
   */
  createEntity(entityTemplateId: Guid): ng.IPromise<Entity | undefined>;

  /**
   * Selects the entity to
   * @param {Guid} entityId The ID of the entity to edit.
   */
  editEntity(entityId: Guid): ng.IPromise<Entity | undefined>;
}

export class EntityService implements IEntityService {
  static $inject = ['$uibModal', 'EntityResource'];

  constructor(
    private modalService: ng.ui.bootstrap.IModalService,
    private entityResource: EntityResource
  ) {}

  selectEntity(templateIds: Guid[], allowCreate: boolean = true): Promise<Guid> {
    const modalInstance = this.modalService.open({
      template: require('./entitySearchModal.html'),
      controller: EntitySearchModalController,
      controllerAs: '$ctrl',
      size: 'lg',
      resolve: {
        typeIds() {
          return templateIds;
        },
        allowCreate() {
          return !!allowCreate;
        }
      }
    });

    return modalInstance.result as any;
  }

  createEntity(entityTemplateId: Guid): ng.IPromise<Entity | undefined> {
    return this.modalService
      .open({
        template: require('./entityEditModal.html'),
        controller: EntityEditModalController,
        size: 'lg',
        resolve: {
          entity: function() {
            return { templateId: entityTemplateId };
          }
        }
      })
      .result.catch(() => {});
  }

  editEntity(entityId: Guid): ng.IPromise<Entity | undefined> {
    return this.entityResource
      .get({ id: entityId })
      .$promise.then((entity: Entity) => {
        return this.modalService
          .open({
            template: require('./entityEditModal.html'),
            controller: EntityEditModalController,
            size: 'lg',
            resolve: {
              entity: function() {
                return entity;
              }
            }
          })
          .result.catch(() => {});
      })
      .catch(() => {});
  }
}
