import { ISecurityService } from '../services/security';
import { DateTime } from 'luxon';

/**
 * Applies Multiples filters when we dont know the value origin
 *
 *  <div>{{ file.modified | formatter }}</div>
 */
export function formatterFilter(SecurityService: ISecurityService) {
  return function(value: string) {
    // tests value for Date and try localize
    const date = DateTime.fromISO(value).toLocal();
    if (date.isValid) {
      // value was a strict ISO date value
      return date.toFormat(SecurityService.getDateFormat());
    }

    return value;
  };
}
