import { IGroupStore } from '../../../data/stores/group';
import { StateService } from 'angular-ui-router';

/* @ngInject */
export default function GroupListController(
  $scope: any,
  $state: StateService,
  GroupStore: IGroupStore
) {
  $scope.groups = [];

  GroupStore.query().then(function(result) {
    $scope.groups = result.items;
  });

  $scope.viewGroup = function(group) {
    $state.go('app.group.edit', { id: group.id });
  };

  $scope.newGroup = function() {
    $state.go('app.group.new');
  };
}
