import { ISecurityService } from '../../../core/services/security';
import { IFileUploadService } from '../../folders/fileUpload.service';
import { INotificationModalService } from '../../notifications/notificationModal.service';
import ng from 'angular';

/* @ngInject */
export default function CioApplicationController(
  $window: ng.IWindowService,
  NotificationModalService: INotificationModalService,
  FileUploadService: IFileUploadService,
  SecurityService: ISecurityService
): void {
  const vm = this;
  vm.ready = false;
  vm.showNotifications = showNotifications;
  vm.currentUser = SecurityService.getCurrentUser();
  vm.files = () => FileUploadService.queue;
  vm.toogleSideBar = toogleSideBar;
  vm.showSideBar = false;
  vm.isMobile = false;

  /**
   * Shows the notifications modal.
   */
  function showNotifications() {
    NotificationModalService.show().result.catch(() => {});
  }

  function toogleSideBar() {
    vm.showSideBar = !vm.showSideBar;
  }

  function resize() {
    if ($window.innerWidth <= 760) {
      vm.isMobile = true;
    } else {
      vm.isMobile = false;
    }
  }

  $window.addEventListener('resize', resize);
  resize();
}
