import { WorkflowActionUpdateFieldsController } from './workflowActionUpdateFields.controller';
import { ComponentBase } from '../../../../core/utils/componentBase';

export class WorkflowActionUpdateFieldsComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = { data: '=' };
    this.controller = WorkflowActionUpdateFieldsController;
    this.template = require('./workflowActionUpdateFields.html');
  }
}
