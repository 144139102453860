import { ComponentBase } from '../../core/utils/componentBase';
import { FileRenameController } from './fileRename.controller';

export class FileRenameComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      file: '<'
    };
    this.controller = FileRenameController;
    this.template = require('./fileRename.html');
  }
}
