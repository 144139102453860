import { IAlertService } from '../../core/services/alert';

/* @ngInject */
export function EntityTypesController($state, Cache, AlertService: IAlertService): void {
  const vm = this;
  vm.entityTypes = [];
  vm.showEntitiesForType = showEntitiesForType;

  Cache.entityTemplates()
    .then(loadSuccess)
    .catch(loadFailure);

  function loadSuccess(templates) {
    const enabled = templates.filter(t => !t.disabled);
    vm.entityTypes = enabled;
  }

  function loadFailure(error) {
    AlertService.error('Could not load entity templates, please try again.');
  }

  function showEntitiesForType(entityType) {
    $state.go('app.entities', { templateId: entityType.id });
  }
}
