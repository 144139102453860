import angular from 'angular';
import 'angular-drag-and-drop-lists';
import 'angular-elastic';
import 'angular-file-saver';
import 'angular-sanitize';
import 'angular-toastr';
import 'angular-ui-bootstrap';
import 'angular-ui-router';
import 'angulartics';
import 'angulartics-google-analytics';
import 'ui-select';

/**
 * Module: App.UI
 *
 * This module holds all the shared UI imports that are used by
 * other modules so that they do not have to be, re-imported for
 * each module.
 *
 * If a component is only used by one module, it should
 * not be imported here.
 *
 */
export const UIModule = angular.module('app.ui', [
  'angulartics.google.analytics',
  'angulartics',
  'dndLists',
  'monospaced.elastic',
  'ngFileSaver',
  'ngSanitize',
  'toastr',
  'ui.bootstrap',
  'ui.router',
  'ui.select'
]);
