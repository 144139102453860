import { IAlertService } from '../../../core/services/alert';
import { ILogService } from '../../../core/services/log';
import { ISavedSearchStore } from '../../../data/stores/savedSearch';
import { ICacheService } from '../../services/cache';
import { StateService } from 'angular-ui-router';
import reject from 'lodash/reject';
import ng from 'angular';

/* @ngInject */
export default function SavedSearchNewController(
  $scope: any,
  $state: StateService,
  LogService: ILogService,
  SavedSearchStore: ISavedSearchStore,
  Cache: ICacheService,
  AlertService: IAlertService
) {
  $scope.fields = [];
  $scope.addressFields = [];
  $scope.searchFields = [];
  $scope.searchForm = {};
  $scope.searchForm.active = false;
  $scope.search = {};
  $scope.selectedListFields = [];

  Cache.fields().then(fields => {
    $scope.fields = fields;
    $scope.addressFields = fields.filter(x => x.type === 'Address');
    setSearchFields();
  });

  function setSearchFields() {
    const fieldsArray = ng.copy($scope.fields);
    const newArr = <any[]>fieldsArray.map(f => {
      return {
        id: f.id,
        name: f.name,
        label: f.label,
        labelOriginal: f.label,
        type: f.type,
        checked: false
      };
    });
    $scope.searchFields = newArr;
  }

  $scope.addFields = function() {
    $scope.searchForm.selectedFields.forEach(field => {
      $scope.selectedListFields.push(field);
      $scope.searchFields = reject($scope.searchFields, { name: field.name });
    });
  };

  $scope.removeFields = function() {
    $scope.searchForm.selectedSearchFields.forEach(field => {
      $scope.searchFields.push(field);
      $scope.selectedListFields = reject($scope.selectedListFields, {
        name: field.name
      });
    });
  };

  /*  array reorder */
  $scope.moveUp = function() {
    const item = $scope.searchForm.selectedSearchFields[0],
      idx = $scope.selectedListFields.indexOf(item);

    if (idx === 0) {
      return;
    }

    arrayMove($scope.selectedListFields, idx, idx - 1);
  };

  $scope.moveDown = function() {
    const item = $scope.searchForm.selectedSearchFields[0],
      idx = $scope.selectedListFields.indexOf(item);

    if (idx === $scope.selectedListFields.length - 1) {
      return;
    }

    arrayMove($scope.selectedListFields, idx, idx + 1);
  };

  $scope.saveSearch = function() {
    $scope.searchForm.active = true;

    // Map the selected fields
    $scope.search.fields = <any[]>$scope.selectedListFields.map(s => {
      return {
        fieldName: s.name,
        labelOverride: s.labelOriginal !== s.label && s.label !== '' ? s.label : undefined
      };
    });

    SavedSearchStore.save($scope.search)
      .then(function(updated) {
        AlertService.success('View ' + updated.name + ' saved successfully.');
        $scope.searchForm.active = false;
        $scope.searchForm.$setPristine();

        Cache.savedSearches(true).then(() =>
          $state.go('app.savedSearch.edit', { id: updated.id })
        );
      })
      .catch(function(error) {
        AlertService.error('Could not save view, please try again.');
        $scope.searchForm.active = false;
      });
  };

  $scope.filterChanged = function(filters, text) {
    $scope.search.filters = filters;
    $scope.search.query = text;
  };
}

function arrayMove(arrayVar, from, to) {
  arrayVar.splice(to, 0, arrayVar.splice(from, 1)[0]);
}
