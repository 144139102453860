import { ComponentBase } from '../../core/utils/componentBase';
import { FileRevisionListController } from './fileRevisionList.controller';

export class FileRevisionListComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      revisions: '<',
      selectedRevision: '<',
      onSelect: '&',
      onClose: '&'
    };
    this.controller = FileRevisionListController;
    this.template = require('./fileRevisionList.html');
  }
}
