import { ITaskService } from './task.service';
import { ITaskHttpService, TaskSearch, TaskSearchStatuses } from '../../core/http';
import { Guid, Page, RecordAssociation, Task, User } from '../../core/models';
import {
  ILogService,
  ISecurityService,
  IDateService,
  IAlertService
} from '../../core/services';
import { Clock } from '../../core/utils';
import { ICacheService } from '../services/cache';
import { StateService } from 'angular-ui-router';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/of';

const DEFAULT_PAGE_SIZE = 50;

enum DateRanges {
  Anytime = 'anytime',
  Overdue = 'overdue',
  Today = 'today',
  ThisWeek = 'this-week',
  NextWeek = 'next-week',
  ThisMonth = 'this-month',
  NextMonth = 'next-month'
}

function statusToString(status: TaskSearchStatuses) {
  switch (status) {
    case TaskSearchStatuses.All:
      return 'all';
    case TaskSearchStatuses.Complete:
      return 'complete';
    default:
      return 'open';
  }
}

function dueToString(due: string | DateRanges) {
  // TODO: Handle custom date ranges
  switch (due) {
    case DateRanges.Anytime:
      return 'anytime';
    case DateRanges.Overdue:
      return 'overdue';
    case DateRanges.Today:
      return 'today';
    case DateRanges.ThisWeek:
      return 'this-week';
    case DateRanges.NextWeek:
      return 'next-week';
    case DateRanges.ThisMonth:
      return 'this-month';
    case DateRanges.NextMonth:
      return 'next-month';
    default:
      return 'anytime';
  }
}

export class TaskListController {
  static $inject = [
    'SecurityService',
    '$rootScope',
    '$scope',
    '$state',
    'Cache',
    'TaskService',
    'DateService',
    'LogService',
    'TaskHttpService',
    'AlertService'
  ];

  taskOpen: boolean = false;
  currentUser: User;
  users: User[] = [];
  dateRange: DateRanges | string = DateRanges.Anytime;
  showCreate: boolean = false;
  showAssociations: boolean = true;
  record?: RecordAssociation;
  searching: boolean = false;
  pageNumber: number = 1;
  currentPage: Page<Task> = new Page<Task>();
  currentTaskId?: Guid;
  search: TaskSearch = new TaskSearch();
  complete?: boolean;
  completeFor?: Guid;
  locationChangeSubscription: () => {};

  constructor(
    securityService: ISecurityService,
    private rootScope: any,
    private scope: any,
    private stateService: StateService,
    private cacheService: ICacheService,
    private taskService: ITaskService,
    private dateService: IDateService,
    private logService: ILogService,
    private taskHttpService: ITaskHttpService,
    private alertService: IAlertService
  ) {
    this.currentUser = securityService.getCurrentUser();
  }

  $onInit() {
    this.resetSearch();
    this.setFromState();
    this.subscribeToLocationChanges();
    this.refresh();
    this.loadUsers();
  }

  $onDestroy(): void {
    this.unsubscribeFromLocationChanges();
  }

  get statusIsAll(): boolean {
    return this.search.status === TaskSearchStatuses.All;
  }

  get statusIsComplete(): boolean {
    return this.search.status === TaskSearchStatuses.Complete;
  }

  get statusIsIncomplete(): boolean {
    return this.search.status === TaskSearchStatuses.Incomplete;
  }

  get isDueAnytime(): boolean {
    return this.dateRange === DateRanges.Anytime;
  }

  get isDueOverdue(): boolean {
    return this.dateRange === DateRanges.Overdue;
  }

  get isDueToday(): boolean {
    return this.dateRange === DateRanges.Today;
  }

  get isDueThisWeek(): boolean {
    return this.dateRange === DateRanges.ThisWeek;
  }

  get isDueNextWeek(): boolean {
    return this.dateRange === DateRanges.NextWeek;
  }

  get isDueThisMonth(): boolean {
    return this.dateRange === DateRanges.ThisMonth;
  }

  get isDueNextMonth(): boolean {
    return this.dateRange === DateRanges.NextMonth;
  }

  get assigneeName(): string {
    if (!this.search.assignee || !this.users) {
      return 'anyone';
    }
    if (this.search.assignee.equals(this.currentUser.id)) {
      return 'Me';
    }
    const u = this.users.find((user) => this.search.assignee.equals(user.id));
    return (u || { name: 'others' }).name;
  }

  showTaskFromState() {
    if (this.taskOpen) {
      return;
    }
    if (!this.stateService.params.view) {
      return;
    }
  }

  setFromState() {
    this.setDueFromState();
    this.setStatusFromState();
    this.showTaskFromState();
    this.setCompleteFromState();
    this.setViewFromState();
  }

  setCompleteFromState() {
    if (!Guid.isValid(this.stateService.params.view)) {
      this.completeFor = undefined;
      this.complete = undefined;
      return;
    }
    if (
      this.stateService.params.complete !== 'false' &&
      this.stateService.params.complete !== 'true'
    ) {
      this.completeFor = undefined;
      this.complete = undefined;
      return;
    }
    this.complete = this.stateService.params.complete;
    this.completeFor = new Guid(this.stateService.params.view);
  }

  setDueFromState() {
    if (!this.stateService.params.due) {
      return;
    }

    this.setDueValue((this.stateService.params.due || '').toLowerCase());
  }

  setStatusFromState() {
    if (!this.stateService.params.status) {
      return;
    }

    switch ((this.stateService.params.status || '').toLowerCase()) {
      case 'complete':
        this.search.status = TaskSearchStatuses.Complete;
        break;
      case 'all':
        this.search.status = TaskSearchStatuses.All;
        break;
      default:
        this.search.status = TaskSearchStatuses.Incomplete;
        break;
    }
  }

  setViewFromState() {
    if (!Guid.isValid(this.stateService.params.view)) {
      return;
    }
    this.showTask(new Guid(this.stateService.params.view));
  }

  loadUsers(): void {
    this.cacheService
      .users()
      .then((users) => (this.users = (users as User[]).filter((x) => x.isActive)))
      .catch((err) => this.logService.error(err));
  }

  getDueText(): string {
    switch (this.dateRange) {
      case DateRanges.Anytime:
        return 'any time';
      case DateRanges.Overdue:
        return 'overdue';
      case DateRanges.Today:
        return 'Today';
      case DateRanges.ThisWeek:
        return 'this week';
      case DateRanges.NextWeek:
        return 'next week';
      case DateRanges.ThisMonth:
        return 'this month';
      case DateRanges.NextMonth:
        return 'next month';
      default:
        return '';
    }
  }

  setDueRange(range: DateRanges): void {
    this.setDueValue(range);
    this.refresh();
  }

  setDueValue(range: string | DateRanges): void {
    switch (range) {
      case DateRanges.Anytime:
        this.search.from = undefined;
        this.search.to = undefined;
        this.dateRange = range;
        return;
      case DateRanges.Overdue:
        this.search.from = undefined;
        this.search.to = Clock.utc().now();
        this.search.status = TaskSearchStatuses.Incomplete;
        this.dateRange = DateRanges.Overdue;
        return;
      case DateRanges.Today:
        this.search.from = Clock.utc().startOfToday();
        this.search.to = Clock.utc().endOfToday();
        this.dateRange = DateRanges.Today;
        return;
      case DateRanges.ThisWeek:
        this.search.from = Clock.utc().startOfThisWeek();
        this.search.to = Clock.utc().endOfThisWeek();
        this.dateRange = DateRanges.ThisWeek;
        return;
      case DateRanges.NextWeek:
        this.search.from = Clock.utc().startOfNextWeek();
        this.search.to = Clock.utc().endOfNextWeek();
        this.dateRange = DateRanges.NextWeek;
        return;
      case DateRanges.ThisMonth:
        this.search.from = Clock.utc().startOfThisMonth();
        this.search.to = Clock.utc().endOfThisMonth();
        this.dateRange = DateRanges.ThisMonth;
        return;
      case DateRanges.NextMonth:
        this.search.from = Clock.utc().startOfNextMonth();
        this.search.to = Clock.utc().endOfNextMonth();
        this.dateRange = DateRanges.NextMonth;
        return;
      default:
        this.search.from = undefined;
        this.search.to = undefined;
        break;
    }

    if (!/(next|last)-(\d)-days/.test(range)) {
      this.setDueDefault();
      return;
    }

    const value = /(next|last)-(\d)-days/.exec(range);

    const days = parseInt(value[2], 10);
    if (days <= 0) {
      this.setDueDefault();
      return;
    }

    this.dateRange = range;
    if (value[1] === 'next') {
      this.search.from = Clock.utc().startOfToday();
      this.search.to = Clock.utc()
        .endOfToday()
        .plus({ days: days - 1 });
    } else {
      this.search.from = Clock.utc().startOfToday().minus({ days: days });
      this.search.to = Clock.utc().startOfToday();
    }
  }

  setDueDefault() {
    this.search.from = Clock.utc().startOfNextMonth();
    this.search.to = Clock.utc().endOfNextMonth();
    this.dateRange = DateRanges.Anytime;
  }

  getStatusText(): string {
    if (this.isDueOverdue) {
      return 'overdue';
    }
    switch (this.search.status) {
      case TaskSearchStatuses.Incomplete:
        return 'open';
      case TaskSearchStatuses.Complete:
        return 'completed';
      default:
        return 'all';
    }
  }

  clearUserFilter(): void {
    this.search.assignee = undefined;
    this.refresh();
  }

  filterByUser(user: User): void {
    this.search.assignee = new Guid(user.id);
    this.refresh();
  }

  addQueryParamsToUrl(queryParams: string) {
    if (history.pushState) {
      const newURL = `${location.protocol}//${location.host}${location.pathname}?${queryParams}`;
      history.pushState({ path: newURL }, '', newURL);
    }
  }

  showDue(dueTime: DateRanges): void {
    this.stateService.go('.', { due: dueTime }, { notify: false }).then(() => {
      this.setDueRange(this.stateService.params.due as DateRanges);
    });
  }

  showDueAnytime(): void {
    this.setDueRange(DateRanges.Anytime);
  }

  showDueOverdue(): void {
    this.setDueRange(DateRanges.Overdue);
  }

  showDueToday(): void {
    this.setDueRange(DateRanges.Today);
  }

  showDueThisWeek(): void {
    this.setDueRange(DateRanges.ThisWeek);
  }

  showDueNextWeek(): void {
    this.setDueRange(DateRanges.NextWeek);
  }

  showDueThisMonth(): void {
    this.setDueRange(DateRanges.ThisMonth);
  }

  showDueNextMonth(): void {
    this.setDueRange(DateRanges.NextMonth);
  }

  pageChanged(pageNumber) {
    this.loadPage(pageNumber);
  }

  find() {
    this.refresh();
  }

  changeStatusFilter(status: TaskSearchStatuses) {
    if (
      status !== TaskSearchStatuses.Incomplete &&
      this.dateRange === DateRanges.Overdue
    ) {
      this.setDueValue(DateRanges.Anytime);
    }

    this.stateService
      .go('.', {
        status: statusToString(status),
        due: dueToString(this.search.due)
      })
      .then(() => {
        this.setStatusFromState();
        this.refresh();
      });
  }

  changeTaskStatus(task: Task) {
    task.toggleCompleted(new Guid(this.currentUser.id));
    this.taskHttpService
      .modifyTask(task, !!task.complete)
      .safeApply(this.scope, () => {
        this.reload();
      })
      .catch((err) => {
        this.logService.error(err);
        this.alertService.error('Failed to save task, please try again');
        return Observable.of('Could not save');
      })
      .subscribe();
  }

  createTask() {
    this.taskService
      .createTask(this.record)
      .result.then((t) => this.refresh())
      .catch(() => {});
  }

  editTask(currentTask: Task) {
    this.showTask(currentTask.id, (task) => {
      if (task) {
        Object.assign(currentTask, task);
      } else {
        // The task was deleted if undefined returned
        this.currentPage.items = this.currentPage.items.filter(
          (x) => !x.id.equals(currentTask.id)
        );
      }
    });
  }

  showTask(taskId: Guid, onUpdate?: (task: Task) => void) {
    if (this.currentTaskId && this.currentTaskId.equals(taskId)) {
      return;
    }
    this.currentTaskId = taskId;
    this.taskHttpService
      .getTask(taskId)
      .safeApply(this.scope, (task) => {
        this.stateService
          .go('.', {
            view: task.id
          })
          .then(() =>
            this.taskService
              .openTask(task, taskId.equals(this.completeFor) ? this.complete : undefined)
              .result.then((updated) => {
                if (onUpdate) {
                  onUpdate(updated);
                }
              })
              .finally(() => {
                this.currentTaskId = undefined;
                this.stateService.go('.', { view: undefined, complete: undefined });
              })
          )
          .catch(() => {});
      })
      .subscribe();
  }

  showAssociation(association: RecordAssociation): void {
    if (association.type.toLowerCase() === 'claim') {
      this.stateService.go('app.claim.tasks', { claimId: association.id });
    } else if (association.type.toLowerCase() === 'entity') {
      this.stateService.go('app.entity.edit', { entityId: association.id });
    }
  }

  getLocalTime(task: Task) {
    if (!task.dueDate) {
      return 'Not available';
    }
    return task.hasTime
      ? this.dateService.formatLocalDateTime(task.dueDate, true)
      : this.dateService.formatUtcDate(task.dueDate);
  }

  private refresh() {
    this.loadPage(1);
  }

  private reload() {
    this.loadPage(this.pageNumber);
  }

  private resetSearch() {
    this.search = Object.assign(new TaskSearch(), {
      skip: 0,
      take: DEFAULT_PAGE_SIZE,
      status: TaskSearchStatuses.Incomplete,
      associations: !!this.record ? [this.record] : []
    });
  }

  private loadPage(pageNumber: number) {
    this.search.skip = (pageNumber - 1) * DEFAULT_PAGE_SIZE;
    this.search.take = DEFAULT_PAGE_SIZE;

    this.taskHttpService
      .findTasks(this.search)
      .safeApply(this.scope, (page) => {
        this.pageNumber = pageNumber;
        this.currentPage = page;
      })
      .subscribe();
  }

  private subscribeToLocationChanges(): void {
    this.locationChangeSubscription = this.rootScope.$on('$locationChangeSuccess', () => {
      this.setFromState();
    });
  }

  private unsubscribeFromLocationChanges(): void {
    if (this.locationChangeSubscription) {
      this.locationChangeSubscription();
    }
  }
}
