import { WorkflowActionCreateTaskController } from './workflowActionCreateTask.controller';
import { ComponentBase } from '../../../../core/utils/componentBase';

export class WorkflowActionCreateTaskComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = { data: '=' };
    this.controller = WorkflowActionCreateTaskController;
    this.template = require('./workflowActionCreateTask.html');
  }
}
