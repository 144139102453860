import { NotificationModalController } from './notificationModal.controller';
import ng from 'angular';

export interface INotificationModalService {
  show(): ng.ui.bootstrap.IModalInstanceService;
}

export class NotificationModalService implements INotificationModalService {
  static $inject = ['$uibModal'];

  constructor(private modalService: ng.ui.bootstrap.IModalService) {}

  /**
   * Shows the notifications modal window.
   */
  show(): ng.ui.bootstrap.IModalInstanceService {
    const modalInstance = this.modalService.open({
      template: require('./notificationModal.html'),
      controllerAs: '$ctrl',
      controller: NotificationModalController
    });

    return modalInstance;
  }
}
