import { Report, ReportField, ReportFieldTypes, ReportTypes } from '../../core/models';
import { ISecurityService } from '../../core/services/security';
import { ReportFieldResource } from '../../data/resources/report-field';
import head from 'lodash/head';
import sortBy from 'lodash/sortBy';

export default class ReportViewController {
  static $inject = ['SecurityService', 'ReportFieldResource'];

  constructor(
    private securityService: ISecurityService,
    private reportFieldResource: ReportFieldResource
  ) {}

  /**
   * Selects a type of report to create.
   *
   * @param {string} type The type of report
   */
  select(type: string) {
    this.createReport(type);
  }

  /**
   * Informs the report parent of of a report creation event.
   *
   * @param {any} report The report to create.
   */
  create(report): void {
    (<any>this).onCreate({
      $event: {
        report: report
      }
    });
  }

  /**
   * Creates a new report based on the claim template.
   *
   * @param {string} type The report type.
   */
  createReport(type: string): void {
    const report = <Report>{
      name: 'New Report',
      type: type,
      filters: [],
      fields: [],
      userId: this.securityService.getCurrentUser().id,
      sort: '',
      sortDirection: 'asc'
    };

    const search = <ReportField>{
      reportType: type
    };

    (<any>this.reportFieldResource).save(search).$promise.then((result: any) => {
      this.setFields(report, result.items);
      this.create(report);
    });
  }

  /**
   * Selects the fields to add to the report based on the claim template.
   *
   * @param {Report} report The report.
   * @param {ReportField[]} fields All available fields for the report type.
   */
  setFields(report: Report, fields: ReportField[]): void {
    // Add claim title to selected fields
    const f = head(
      fields.filter(field => {
        return field && field.name === 'title';
      })
    );
    const titleField = this.createField(f, report.type);

    report.fields.push(titleField);

    if (report.type !== ReportTypes.Claim) {
      const reportType = this.getFieldType(report.type);
      const fieldsBasedOnReportType = sortBy(
        fields.filter(field => field.category === reportType),
        'defaultOrder'
      );

      for (let i = 0; i < fieldsBasedOnReportType.length; i++) {
        const currentField = fieldsBasedOnReportType[i];
        if (!currentField) {
          continue;
        }
        report.fields.push(this.createField(currentField, report.type));
      }
    }
  }

  /**
   * Selects a field and adds it to the report.
   *
   * @param {*} field The field to add to the report.
   */
  createField(field: any, type: string): ReportField {
    return <ReportField>{
      name: field.name,
      label: field.label,
      dataType: field.dataType,
      reportType: '',
      category: field.category,
      format: field.format
    };
  }

  /**
   * Converts from the report type to the field report type.
   *
   * @param {string} reportType The report type.
   * @returns The field report type.
   */
  getFieldType(reportType: string) {
    switch (reportType) {
      case ReportTypes.Claim:
        return ReportFieldTypes.Claim;
      case ReportTypes.InvoiceDetail:
        return ReportFieldTypes.InvoiceDetail;
      case ReportTypes.InvoiceSummary:
        return ReportFieldTypes.InvoiceSummary;
      case ReportTypes.PaymentDetail:
        return ReportFieldTypes.PaymentDetail;
      case ReportTypes.PaymentSummary:
        return ReportFieldTypes.PaymentSummary;
      case ReportTypes.Timesheet:
        return ReportFieldTypes.Timesheet;
      default:
        return ReportTypes.Claim;
    }
  }
}
