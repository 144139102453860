import { IDocumentHttpService, IOrganizationHttpService } from '../../../core/http';
import { DocumentCreatedMessage } from '../../../core/messages';
import {
  Entity,
  Guid,
  ITemplateRequirementValue,
  TemplateRequirementTypes
} from '../../../core/models';
import { IAlertService, ILogService, IMessagingService } from '../../../core/services';
import { Clock } from '../../../core/utils';
import { ClaimTemplateResource } from '../../../data/resources/claimTemplate';
import { PaymentResource } from '../../../data/resources/payment';
import { SumInsuredResource } from '../../../data/resources/sum-insured';
import { SumInsuredCategoryResource } from '../../../data/resources/sum-insured-category';
import { TaxCodeResource } from '../../../data/resources/tax-code';
import { IDocumentService } from '../../documents/document.service';
import { IClaimManagerService } from '../claim-manager-service';
import { StateService } from 'angular-ui-router';
import ng from 'angular';

/* @ngInject */
export default function ClaimNewPaymentController(
  $filter: ng.IFilterService,
  $scope: any,
  $state: StateService,
  $stateParams: any,
  AlertService: IAlertService,
  ClaimManagerService: IClaimManagerService,
  // tslint:disable-next-line:no-shadowed-variable
  ClaimTemplateResource: ClaimTemplateResource,
  DocumentService: IDocumentService,
  LogService: ILogService,
  // tslint:disable-next-line:no-shadowed-variable
  PaymentResource: PaymentResource,
  OrganizationHttpService: IOrganizationHttpService,
  // tslint:disable-next-line:no-shadowed-variable
  SumInsuredCategoryResource: SumInsuredCategoryResource,
  // tslint:disable-next-line:no-shadowed-variable
  SumInsuredResource: SumInsuredResource,
  // tslint:disable-next-line:no-shadowed-variable
  TaxCodeResource: TaxCodeResource,
  DocumentHttpService: IDocumentHttpService,
  MessagingService: IMessagingService
): void {
  const vm = this;

  const claimId = $stateParams.claimId;
  const paymentId = $stateParams.paymentId;

  vm.claim = ClaimManagerService.claim();
  vm.availableStatus = ['Unpaid', 'Paid'];
  vm.paymentId = paymentId;
  vm.taxCodes = [];
  vm.sumCategories = {};
  vm.sumsInsured = [];
  vm.payment = null;
  vm.organization = null;
  vm.claimTypeSettings = {};

  vm.newPayment = newPayment;
  vm.addItem = addItem;
  vm.getItemTotal = getItemTotal;
  vm.calculateTax = calculateTax;
  vm.removeItem = removeItem;
  vm.savePayment = savePayment;
  vm.createDocument = createDocument;
  vm.displaySumInsured = displaySumInsured;
  vm.setEntity = setEntity;
  vm.getSubTotal = getSubTotal;
  vm.getTaxTotal = getTaxTotal;
  vm.getTotal = getTotal;
  vm.showSumInsured = showSumInsured;

  activate();

  function activate() {
    if (paymentId) {
      PaymentResource.get({
        id: paymentId
      }).$promise.then(function(data) {
        vm.payment = data;
      });
    } else {
      newPayment();
    }

    OrganizationHttpService.getOrganization()
      .safeApply(
        $scope,
        organization => {
          vm.organization = organization;
        },
        err => LogService.error('Failed to load organization', err)
      )
      .subscribe();

    TaxCodeResource.query().$promise.then(function(result: any) {
      vm.taxCodes = result.items;
    });

    SumInsuredCategoryResource.query().$promise.then(function(result: any) {
      (result.items || []).forEach(function(c) {
        vm.sumCategories[c.id] = c.name;
      });
    });

    SumInsuredResource.query({
      claimId: claimId
    }).$promise.then(function(result: any) {
      vm.sumsInsured = result.items;
      LogService.debug(vm.sumsInsured);
    });

    ClaimTemplateResource.query({ id: vm.claim.templateId }).$promise.then(
      (data: any) => {
        vm.claimTypeSettings = data;
      }
    );
  }

  function showSumInsured() {
    if (!vm.claimTypeSettings || !vm.organization) {
      return false;
    }

    return (
      !vm.claimTypeSettings.hideSumsInsured &&
      !vm.organization.disableSumInsuredPaymentTracking
    );
  }

  function newPayment() {
    vm.payment = {
      number: '',
      status: 'Unpaid',
      items: [],
      claimId: claimId,
      reference: '',
      paymentDate: new Date().toISOString(),
      dueDate: Clock.local()
        .now()
        .plus({ days: 30 })
        .toISO()
    };

    addItem();
  }

  function addItem() {
    vm.payment.items.push({
      quantity: 1,
      unitCost: 0,
      taxCode: vm.taxCodes.length > 0 ? vm.taxCodes[0].id : null,
      taxTotal: 0
    });
  }

  function getSubTotal() {
    if (!vm.payment) {
      return 0;
    }
    let total = 0;
    vm.payment.items.forEach(function(i) {
      total += i.quantity * i.unitCost;
    });

    return total;
  }

  function getTaxTotal() {
    if (!vm.payment) {
      return 0;
    }
    let total = 0;
    vm.payment.items.forEach(function(i) {
      total += i.quantity * i.taxTotal;
    });

    return total;
  }

  function getTotal() {
    return getSubTotal() + getTaxTotal();
  }

  function getItemTotal(item) {
    calculateTax(item);
    const subTotal = item.quantity * item.unitCost;
    const taxTotal = item.quantity * item.taxTotal;

    return subTotal + taxTotal;
  }

  function calculateTax(item) {
    const taxCode = vm.taxCodes.find(x => x.id === item.taxCode);
    if (taxCode) {
      item.taxTotal = item.unitCost * (taxCode.percentage / 100);
    }
  }

  function removeItem(index) {
    vm.payment.items.splice(index, 1);
    $scope.$apply();
  }

  function savePayment(form) {
    if (!vm.payment.entityId || Guid.isEmpty(vm.payment.entityId)) {
      AlertService.error('Please specify who the payment is to.');
      return;
    }

    (vm.payment.id
      ? PaymentResource.update(vm.payment)
      : PaymentResource.save(vm.payment)
    ).$promise.then(function(saved) {
      AlertService.success('Payment created successfully.');

      form.$setPristine();
      if (!vm.payment.id) {
        $state.go('app.claim.payments.edit', {
          claimId: claimId,
          paymentId: saved.id
        });
      }
    });
  }

  function setEntity(entity: Entity) {
    if (!entity) {
      vm.payment.entityId = undefined;
      return;
    }
    vm.payment.entityId = entity.id;
  }

  function createDocument() {
    const payment = vm.payment;
    const requirements: ITemplateRequirementValue[] = [
      { type: TemplateRequirementTypes.Claim, field: '', recordId: new Guid(claimId) },
      {
        type: TemplateRequirementTypes.Payment,
        recordId: new Guid(payment.id),
        field: 'payment'
      }
    ];
    DocumentService.select(requirements)
      .then(selection => {
        if (!selection.document) {
          return;
        }
        DocumentHttpService.createDocument(
          vm.claim.folderId,
          selection.document.id,
          selection.values
        )
          .safeApply($scope, file => {
            if (!file) {
              return;
            }
            AlertService.success('Document created.');
            this.stateService.go('app.claim.files', { claimId: claimId });
            MessagingService.publish(new DocumentCreatedMessage(file));
          })
          .subscribe();
      })
      .catch(() => {});
  }

  function displaySumInsured(s) {
    return vm.sumCategories[s.categoryId] + ' (' + $filter('currency')(s.value, '') + ')';
  }
}
