/* @ngInject */
export default function CioErrorDirective($parse) {
  return {
    restrict: 'A',
    compile: function(element, attr) {
      const fn = $parse(attr.ngError);

      return function(scope, $element, $attr) {
        $element.on('error', onError);
        scope.$on('$destroy', handleDestroy);

        function handleDestroy() {
          $element.off('error', onError);
        }

        function onError(event) {
          fn(scope, { $event: event });
        }
      };
    }
  };
}
