import {
  FieldDefinitionScopes,
  FieldDefinition,
  FieldDefinitionTypes
} from '../../../core/models';
import { RESERVED_CLAIMS_FIELDS } from '../../services/filter-builder';

export class WorkflowService {
  public static getClaimFields(fields: FieldDefinition[]) {
    const mapped = [];
    for (const field of fields) {
      if (
        field.scope === FieldDefinitionScopes.Entity ||
        (field.type === FieldDefinitionTypes.StatusSet && field.name !== 'status') ||
        (!field.isBuiltin && RESERVED_CLAIMS_FIELDS.some(x => x === field.name))
      ) {
        continue;
      }
      if (field.name === 'type') {
        field.type = FieldDefinitionTypes.ClaimType;
      }
      mapped.push(field);
    }
    return mapped;
  }
}
