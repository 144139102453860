import SearchFilterController from './cio-search-filter-controller';

export default class CioSearchFilterComponent implements ng.IComponentOptions {
  public controller: any;
  public bindings: any;

  constructor() {
    this.bindings = {
      savedFilters: '=',
      savedText: '=',
      valueOnly: '=',
      hideFreeSearch: '@',
      customFilters: '=',
      filterChanged: '&'
    };
    this.controller = SearchFilterController;
  }

  /* @ngInject */
  template($element, $attrs) {
    return require('./cio-search-filter.html');
  }
}
