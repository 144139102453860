import { TasksWidgetController } from './tasksWidget.controller';

export class TasksWidgetComponent implements ng.IComponentOptions {
  public controller: any;
  public template: string;
  public bindings: any;

  constructor() {
    this.bindings = {
      config: '<'
    };
    this.controller = TasksWidgetController;
    this.template = require('./tasksWidget.html');
  }
}
