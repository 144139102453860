import EntityListController from './entitiesList.controller';
import EntityContactDetailController from './entity-contact-details';
import EntityContactsController from './entity-contacts';
import EntityEditController from './entity-edit';
import EntityNewController from './entity-new';
import { EntityTypesController } from './entity-types';
import { EntityService } from './entity.service';
import { EntityClaimsComponent } from './entityClaims.component';
import { EntityFilesComponent } from './files/entityFiles.component';
import EntityFieldService from './modals/entity-field.service';
import { EntityTasksComponent } from './tasks/entityTasks.component';
import { CoreModule } from '../../core/module';
import { DataModule } from '../../data/module';
import { UIModule } from '../../ui/module';
import { EmailsModule } from '../emails/emails.module';
import { FoldersModule } from '../folders/folders.module';
import { TasksModule } from '../tasks/tasks.module';
import ng from 'angular';

export const EntitiesModule = ng
  .module('app.instance.entities', [
    DataModule.name,
    CoreModule.name,
    UIModule.name,
    FoldersModule.name,
    TasksModule.name,
    EmailsModule.name
  ])

  // Components
  .component('entityFilesComponent', new EntityFilesComponent())
  .component('entityTasksComponent', new EntityTasksComponent())
  .component('cioEntityClaims', new EntityClaimsComponent())

  // Services
  .service('EntityFieldService', EntityFieldService)
  .service('EntityService', EntityService)

  // Controllers
  .controller('EntityContactDetailController', EntityContactDetailController)
  .controller('EntityContactsController', EntityContactsController)
  .controller('EntityEditController', EntityEditController)
  .controller('EntityListController', EntityListController)
  .controller('EntityNewController', EntityNewController)
  .controller('EntityTypesController', EntityTypesController);
