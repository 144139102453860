import { Workflow } from '../../core/models';
import * as ng from 'angular';

export interface WorkflowResource
  extends ng.resource.IResourceClass<ng.resource.IResource<Workflow>> {
  update(workflow: Workflow): ng.resource.IResource<Workflow>;
  actionsTriggers(): any;
}

/* @ngInject */
export default function WorkflowResource(
  $resource: ng.resource.IResourceService
): WorkflowResource {
  return <WorkflowResource>$resource<ng.resource.IResource<Workflow>>(
    '/api/v1/workflows/:id',
    { id: '@id' },
    {
      update: {
        method: 'PUT',
        params: {
          id: '@id'
        }
      },
      query: { method: 'GET' },
      remove: { method: 'DELETE' },
      actionsTriggers: { method: 'GET', url: '/api/v1/workflows/actions-triggers' }
    }
  );
}
