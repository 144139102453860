/**
 * Provides logging support.
 */
export interface ILogService {
  debug(...args: any[]): void;
  info(...args: any[]): void;
  warn(...args: any[]): void;
  error(...args: any[]): void;
  deprecated(...args: any[]): void;

  /**
   * Sets the debug flag that determines if debug messages should be logged.
   */
  enableDebug(flag: boolean): void;

  /**
   * Sets the console flag that determines if messages should be logged
   * to the console.
   */
  enableConsole(flag: boolean): void;
}

export class LogService implements ILogService {
  static $inject = ['$log'];

  private debugEnabled: boolean = false;
  private consoleEnabled: boolean = true;

  constructor(private innerLog: ng.ILogService) {}

  public debug(...args: any[]): void {
    this.logMessage('DEBUG', 'debug', ...args);
  }

  public info(...args: any[]): void {
    this.logMessage('INFO', 'info', ...args);
  }

  public warn(...args: any[]): void {
    this.logMessage('WARN', 'warn', ...args);
  }

  public error(...args: any[]): void {
    this.logMessage('ERROR', 'error', ...args);
  }

  public deprecated(...args: any[]): void {
    this.logMessage('DEPRECATED', 'warn', ...args);
  }

  public enableDebug(flag: boolean): void {
    this.debugEnabled = !!flag;
  }

  public enableConsole(flag: boolean): void {
    this.consoleEnabled = !!flag;
  }

  private logMessage(name: string, level: string, ...args: any[]): void {
    if (!args) {
      return;
    }
    this.logToConsole(name, level, ...args);
    this.logToAI(name, level, ...args);
  }

  private logToConsole(name: string, level: string, ...args: any[]): void {
    if (!this.consoleEnabled) {
      return;
    }
    if (!this.debugEnabled && level === 'debug') {
      return;
    }
    this.innerLog[level](name, ...args);
  }

  private logToAI(name: string, level: string, ...args: any[]): void {
    if (level !== 'error' && level !== 'warn') {
      return;
    }
    if (!window.appInsights) {
      return;
    }
    try {
      window.appInsights.trackException(
        args[0] instanceof Error ? args[0] : new Error(args.join(' '))
      );
    } catch (inner) {
      this.innerLog.error(inner);
      window.appInsights.trackException(inner);
    }
  }
}
