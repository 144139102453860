import {
  ClaimTemplate,
  EntityTemplate,
  FieldDefinition,
  User,
  UserSettings,
  SavedSearch
} from '../../core/models';
import { ClaimTemplateResource } from '../../data/resources/claimTemplate';
import { EntityTemplateResource } from '../../data/resources/entityTemplate';
import { UserSummaryResource } from '../../data/resources/user-summary';
import { IFieldStore } from '../../data/stores/field';
import { ISecurityService } from '../../core/services';
import { ISavedSearchStore } from '../../data/stores/savedSearch';
import { IUserSettingsStore } from '../../data/stores/userSettings';
import * as ng from 'angular';

const keys = [];
const cacheControl = {};

export interface ICacheService {
  initialize();
  registerLookup(key: string, func: () => Promise<any>);
  registerQuery(key: string, func: () => Promise<any>);
  userSettings(force?: boolean): Promise<UserSettings>;
  savedSearches(force?: boolean): Promise<SavedSearch[]>;
  fields(force?: boolean): Promise<FieldDefinition[]>;
  claimTemplates(force?: boolean): Promise<ng.resource.IResourceArray<ClaimTemplate>>;
  entityTemplates(force?: boolean): Promise<ng.resource.IResourceArray<EntityTemplate>>;
  users(force?: boolean): Promise<ng.resource.IResourceArray<User>>;
  user(id: string): Promise<User>;
  claimTemplate(id: string): Promise<ClaimTemplate>;
  entityTemplate(id: string): Promise<EntityTemplate>;
  field(id: string): Promise<FieldDefinition>;
}

/* @ngInject */
export default function CacheFactory(
  $q: ng.IQService,
  $timeout: ng.ITimeoutService,
  FieldStore: IFieldStore,
  SavedSearchStore: ISavedSearchStore,
  SecurityService: ISecurityService,
  UserSettingsStore: IUserSettingsStore,
  // tslint:disable-next-line:no-shadowed-variable
  ClaimTemplateResource: ClaimTemplateResource,
  // tslint:disable-next-line:no-shadowed-variable
  EntityTemplateResource: EntityTemplateResource,
  // tslint:disable-next-line:no-shadowed-variable
  UserSummaryResource: UserSummaryResource
): any {
  const cache = new Cache($q, $timeout);

  cache.registerQuery('fields', function() {
    return FieldStore.allFieldsPromise();
  });

  cache.registerQuery('claimTemplates', function() {
    return ClaimTemplateResource.query().$promise;
  });

  cache.registerQuery('entityTemplates', function() {
    return EntityTemplateResource.query().$promise;
  });

  cache.registerQuery('users', function() {
    return UserSummaryResource.query().$promise;
  });

  cache.registerQuery('savedSearches', function() {
    return SavedSearchStore.query();
  });

  cache.registerQuery('userSettings', function() {
    return UserSettingsStore.byUserId(SecurityService.getCurrentUser().id);
  });

  cache.registerLookup('user', function(id) {
    const deferred = this.$q.defer();
    this.users()
      .then(function(items) {
        const item = items.find((i: User) => {
          return i.id === id;
        });
        if (item) {
          deferred.resolve(item);
          return;
        }

        deferred.reject('user not found "' + id + '".');
      })
      .catch(function(error) {
        deferred.reject(error);
      });

    return deferred.promise;
  });

  cache.registerLookup('entityTemplate', function(id) {
    const deferred = this.$q.defer();

    this.entityTemplates()
      .then(function(items) {
        const item = items.find(i => {
          return i.id === id;
        });
        if (item) {
          deferred.resolve(item);
          return;
        }

        deferred.reject('template not found "' + id + '".');
      })
      .catch(function(error) {
        deferred.reject(error);
      });

    return deferred.promise;
  });

  cache.registerLookup('claimTemplate', function(id) {
    const deferred = this.$q.defer();

    this.claimTemplates()
      .then(function(items) {
        const item = items.find(i => {
          return i.id === id;
        });
        if (item) {
          deferred.resolve(item);
          return;
        }

        deferred.reject('template not found "' + id + '".');
      })
      .catch(function(error) {
        deferred.reject(error);
      });

    return deferred.promise;
  });

  cache.registerLookup('field', function(id) {
    const deferred = this.$q.defer();

    this.fields()
      .then(function(items) {
        const item = items.find(i => {
          return i.id === id;
        });
        if (item) {
          deferred.resolve(item);
          return;
        }

        deferred.reject('field not found "' + id + '".');
      })
      .catch(function(error) {
        deferred.reject(error);
      });

    return deferred.promise;
  });

  return cache;
}

/**
 * Constructor.
 * @param $q
 * @param $timeout
 * @constructor
 */
export function Cache($q, $timeout) {
  this.$q = $q;
  this.$timeout = $timeout;
  this.properties = cacheControl;
}

/**
 * Initialises the cache values.
 */
Cache.prototype.initialize = function() {
  const that = this;
  keys.forEach(key => that[key](true));
};

/**
 * Registers a query cache handler.
 * @param {string} key - the cache key.
 * @param {function} factory - the factory for loading the cache values.
 */
Cache.prototype.registerQuery = function(key, factory) {
  const that = this;

  const cacheItem = {
    initialized: false,
    values: undefined,
    promise: undefined
  };

  keys.push(key);
  cacheControl[key] = cacheItem;

  that[key] = function(force) {
    return load();

    function load() {
      if (cacheItem.initialized && !force) {
        const deferred = that.$q.defer();
        deferred.resolve(cacheItem.values);
        return deferred.promise;
      }

      // if we are currently loading then return the loading promise
      const loadingPromise = cacheItem.promise;
      if (loadingPromise && !force) {
        return loadingPromise;
      }

      return loadCacheForKey();
    }

    function loadCacheForKey() {
      const promise = factory()
        .then(function(result) {
          cacheItem.initialized = true;
          cacheItem.values = result.items || result;
          cacheItem.promise = undefined;
          return cacheItem.values;
        })
        .catch(err => console.log(err));

      cacheItem.promise = promise;
      return promise;
    }
  };
};

Cache.prototype.registerLookup = function(key, func) {
  this[key] = func;
};
