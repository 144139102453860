import { DateTime } from 'luxon';
import { Guid } from './guid';
import { DateTimeHelper } from '../../core/utils';

export const STORAGE_FOLDER_TYPES = {
  Claim: 'Claim',
  Entity: 'Entity',
  Template: 'Template',
  Public: 'Public'
};

type StorageFolderTypes = 'Claim' | 'Entity' | 'Template' | 'Public';

export class StorageFolder {
  id: Guid;
  name: string;
  type: StorageFolderTypes;
  recordId?: Guid;
  parent?: Guid;
  created: DateTime;
  createdBy: Guid;
  lastModified: DateTime;
  lastModifiedBy: Guid;
  isDeleted: boolean = false;
  deleted?: DateTime;
  deletedBy?: Guid;

  static fromJson(json: any): StorageFolder {
    const folder = Object.assign(new StorageFolder(), json) as StorageFolder;
    folder.id = new Guid(json.id);
    folder.recordId = json.recordId && new Guid(json.recordId);
    folder.parent = json.parent && new Guid(json.parent);
    folder.created = DateTimeHelper.parseUtcDateTime(json.created);
    folder.createdBy = new Guid(json.createdBy);
    folder.lastModified = DateTimeHelper.parseUtcDateTime(json.lastModified);
    folder.lastModifiedBy = new Guid(json.lastModifiedBy);
    folder.deleted = json.deleted && DateTimeHelper.parseUtcDateTime(json.deleted);
    folder.deletedBy = json.deletedBy && new Guid(json.deletedBy);
    return folder;
  }
}
