import {
  DropdownFieldDefinition,
  DropdownFieldDefinitionOption
} from '../../core/models';

export const FieldDefinitionHelper = {
  /**
   * Parses the dropdown options.
   * @param {DropdownFieldDefinition} field The dropdown field.
   * @returns {DropdownFieldDefinitionOption[]}
   */
  parseDropdownOptions(field: DropdownFieldDefinition): DropdownFieldDefinitionOption[] {
    const fieldOptions = field.settings.options.split('$$');
    const options = [];
    for (const option of fieldOptions) {
      const value = option.split('|');
      options.push({ label: value[1], value: value[1] });
    }
    return options;
  }
};
