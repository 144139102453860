import { ICacheService } from '../../services/cache';
import { Guid, User } from '../../../core/models';
import { ISecurityService } from '../../../core/services';

interface UserSelectionChange {
  (changes: { selected: Guid[]; unassigned: boolean }): any;
}

interface IUserItem {
  id: Guid;
  name: string;
  checked: boolean;
  isCurrentUser: boolean;
}

export class SearchUserSelectController {
  static $inject = ['Cache', 'SecurityService'];

  userId: Guid;
  users: IUserItem[] = [];
  currentChecked: boolean;
  unassignedChecked: boolean;
  onChange: UserSelectionChange;
  private _selected: Guid[];

  constructor(
    private cacheService: ICacheService,
    private securityService: ISecurityService
  ) {}

  $onInit() {
    this.userId = this.securityService.getCurrentUserId();
    this.loadUsers();
  }

  get selected(): Guid[] {
    return this._selected;
  }

  set selected(value: Guid[]) {
    this._selected = (value || []).map(x => new Guid(x));
  }

  get unassigned(): boolean {
    return this.unassignedChecked;
  }

  set unassigned(value: boolean) {
    this.unassignedChecked = value;
    if (this.unassignedChecked && this._selected.length) {
      this._selected.length = 0;
    }
  }

  selectCurrentUser() {
    const currentUser = this.users.find(x => x.isCurrentUser);
    if (currentUser) {
      currentUser.checked = this.currentChecked;
      this.changed();
    }
  }

  selectUnassigned() {
    this.changed();
  }

  selectUser(user: IUserItem) {
    if (user.isCurrentUser) {
      this.currentChecked = user.checked;
    }
    this.changed();
  }

  selectAll() {
    this.currentChecked = true;
    this.unassignedChecked = true;
    for (const user of this.users) {
      user.checked = true;
    }
    this.changed();
  }

  clear() {
    this.currentChecked = false;
    this.unassignedChecked = false;
    for (const user of this.users) {
      user.checked = false;
    }
    this.changed();
  }

  private loadUsers(): void {
    this.cacheService
      .users()
      .then((users: User[]) => {
        this.users = users.map(x => this.createUser(x, this.userId));
      })
      .catch(err => console.error(err));
  }

  private changed() {
    const selected = this.users.filter(x => x.checked).map(x => x.id);
    this.onChange({ selected: selected, unassigned: this.unassignedChecked });
  }

  private createUser(user: User, currentUserId: Guid): IUserItem {
    return {
      id: new Guid(user.id),
      name: user.name,
      checked: false,
      isCurrentUser: currentUserId.equals(user.id)
    } as IUserItem;
  }
}
