import { WorkflowNewController } from './workflowNew.controller';
import { ComponentBase } from '../../../core/utils/componentBase';

export class WorkflowNewComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {};
    this.controller = WorkflowNewController;
    this.template = require('./workflowNew.html');
  }
}
