export default class CioImageController {
  static $inject = ['$timeout'];

  public src: string;
  public displayUrl: string;
  private errorCount: number;

  constructor(public $timeout: ng.ITimeoutService) {}

  $onInit() {
    if (!this.src) {
      this.setImage(this.getDefaultImage());
    }
  }

  $onChanges(changes) {
    if (changes.src) {
      this.setImage(changes.src.currentValue);
    }
  }

  setImage(url: string) {
    this.errorCount = 0;
    this.displayUrl = url;
  }

  error() {
    const originalUrl = this.displayUrl;

    if (this.errorCount > 5) {
      this.setImage(this.getDefaultImage());
      return;
    }

    this.$timeout(() => {
      this.errorCount++;
      this.displayUrl = originalUrl;
    }, 3000);
  }

  getDefaultImage() {
    return '/assets/img/documents/file-default.png';
  }
}
