import { HttpService } from './http';
import { NotificationSettings, Guid } from '../models';
import { logError } from '../utils';
import { Observable } from 'rxjs/Observable';

export interface INotificationSettingsHttpService {
  getByUserId(userId: Guid): Observable<NotificationSettings>;
  modify(userId: Guid, settings: NotificationSettings): Observable<NotificationSettings>;
}

export class NotificationSettingsHttpService implements INotificationSettingsHttpService {
  static $inject = ['HttpService'];

  constructor(private httpService: HttpService) {}

  getByUserId(userId: Guid): Observable<NotificationSettings> {
    return this.httpService
      .get(NotificationSettingsHttpService.getUrl(userId))
      .map(res => res.json())
      .catch(logError);
  }

  modify(userId: Guid, settings: NotificationSettings): Observable<NotificationSettings> {
    settings.userId = userId.toString();
    return this.httpService
      .put(NotificationSettingsHttpService.getUrl(userId), settings)
      .map(res => res.json())
      .catch(logError);
  }

  static getUrl(userId: string | Guid): string {
    return `/api/v1/notification-settings/${userId.toString()}`;
  }
}
