import { IRedirectService, ISessionService } from '../services';

/* @ngInject */
export function ApiInterceptor(
  $q: ng.IQService,
  SessionService: ISessionService,
  RedirectService: IRedirectService
): any {
  return {
    /**
     * Manipulates the request before sending.
     */
    request: function(config: any) {
      // Dont inject for unauthenticated paths
      if (config.url.indexOf('/api/v1/auth/token') >= 0) {
        return config;
      }

      // Append the bearer token to the request
      const token = SessionService.getToken();
      if (token) {
        config.headers.authorization = 'Bearer ' + token.token;
      }

      return config;
    },

    /**
     * Handles a successful response.
     */
    response: function(extResponse: any): ng.IPromise<any> {
      return extResponse || $q.when(extResponse);
    },

    /**
     * Handles a failed response.
     */
    responseError: function(
      rejection: ng.IHttpPromiseCallbackArg<any>
    ): ng.IPromise<any> {
      // Check for token authentication failure that could be a
      // result of the token expiring or being revoked.
      if (rejection.status === 401) {
        RedirectService.redirectToLogin();
      }

      logRejection(rejection, 'response');
      return $q.reject(rejection);
    },

    /**
     * Handles request errors.
     */
    requestError: function(rejection: ng.IHttpPromiseCallbackArg<any>): ng.IPromise<any> {
      logRejection(rejection, 'request');
      return $q.reject(rejection);
    },

    logRejection: logRejection
  };

  function logRejection(rejection: any, type: string): void {
    let message = 'Failed $http ' + type;
    if (rejection.data && rejection.data.message) {
      message = rejection.data.message;
    }
    if (window.appInsights) {
      window.appInsights.trackException(new Error(message), 'unhandled', { rejection });
    }
  }
}
