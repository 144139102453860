import { StateService } from 'angular-ui-router';
import { IAlertService, ISecurityService } from '../../../core/services';
import { IUserDelegationStore } from '../../../data/stores/userDelegation';

/* @ngInject */
export class DelegationEditController {
  static $inject = [
    'SecurityService',
    'AlertService',
    'UserDelegationStore',
    '$state',
    '$stateParams'
  ];

  public delegation: any = {};
  public delegationForm: any = { active: false };
  public dateFormat: string;

  constructor(
    securityService: ISecurityService,
    private alertService: IAlertService,
    private userDelegationStore: IUserDelegationStore,
    private stateService: StateService,
    private stateParams: any
  ) {
    this.dateFormat = securityService.getDateFormat();
  }

  $onInit() {
    this.userDelegationStore.get(this.stateParams.id).then(data => {
      this.delegation = data;
    });
  }

  save() {
    this.delegationForm.active = true;

    this.userDelegationStore
      .update(this.delegation)
      .then(updated => {
        this.alertService.success('New delegation has been created.');
        this.delegationForm.active = false;
        this.delegationForm.$setPristine();

        this.stateService.go('app.delegation.edit', { id: updated.id });
      })
      .catch(error => {
        this.alertService.error('Could not create delegation, please try again.');
        this.delegationForm.active = false;
      });
  }

  delete() {
    this.delegationForm.active = true;

    this.userDelegationStore
      .delete(this.delegation)
      .then(deleted => {
        this.alertService.success('Delegation has been deleted.');
        this.delegationForm.active = false;
        this.delegationForm.$setPristine();

        this.stateService.go('app.delegations');
      })
      .catch(error => {
        this.alertService.error('Could not delete delegation, please try again.');
        this.delegationForm.active = false;
      });
  }
}
