import { IWorkflowHttpService } from '../../../core/http';
import { Workflow } from '../../../core/models';
import { IAlertService } from '../../../core/services';
import ng from 'angular';
import { StateService } from 'angular-ui-router';

export class WorkflowNewController {
  static $inject = ['$scope', '$state', 'WorkflowHttpService', 'AlertService'];

  newForm: ng.IFormController = {} as any;
  busy: boolean = false;
  workflow: Workflow;

  constructor(
    private scope: any,
    private stateService: StateService,
    private workflowHttpService: IWorkflowHttpService,
    private alertService: IAlertService
  ) {
    this.workflow = Workflow.create();
  }

  save() {
    if (this.newForm.$invalid || this.busy) {
      return;
    }

    this.busy = true;
    this.workflowHttpService
      .createWorkflow(this.workflow)
      .safeApply(
        this.scope,
        saved => {
          this.alertService.success('New workflow has been created.');
          this.busy = false;
          this.stateService.go('app.workflow.edit', { id: saved.id.toString() });
        },
        error => {
          this.alertService.error('Could not create workflow, please try again.');
          this.busy = false;
        }
      )
      .subscribe();
  }
}
