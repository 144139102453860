import { Guid, Tag } from '../../../core/models';
import { IFilter, IFilterBuilder } from '../../services/filter-builder';
import debounce from 'lodash/debounce';
import omit from 'lodash/omit';
import { DateTime } from 'luxon';

interface FilterChangeFunc {
  (changes: { filters: IFilter[]; text: string }): any;
}

export default class CioUserController {
  static $inject = ['FilterBuilder'];

  public filters: IFilter[];
  public customFilters: any;
  public getFilterDisplay: Function;
  public delayedSearch: Function;
  public filterChanged: FilterChangeFunc;
  public hideFreeSearch: boolean;
  public valueOnly: boolean; // if true, displays only text box for simple input value (required for workflow updating fields)
  private savedFilters: any[];
  private savedText: string;
  private freeSearch: string;

  constructor(private filterBuilder: IFilterBuilder) {
    this.getFilterDisplay = filterBuilder.getFilterDisplay;
    this.delayedSearch = debounce(this.filterChange, 300);
  }

  $onInit(): void {
    this.loadAvailableFields();
  }

  loadAvailableFields(): void {
    this.filterBuilder.allClaimFilters().then(filters => {
      this.filters = filters;
      this.mergeWithPreExistingFilters();
      this.addCustomFilters();
    });
  }

  mergeWithPreExistingFilters(): void {
    this.freeSearch = this.savedText || '';
    // Set pre-built filters, if any
    if (this.savedFilters && this.savedFilters.length) {
      for (const filter of this.savedFilters) {
        const theFilter = this.filters.find(f => f.field === filter.field);
        if (theFilter) {
          Object.assign(theFilter, omit(filter, '$$hashKey'));
        }
      }
    }
  }

  addCustomFilters(): void {
    if (this.customFilters && this.customFilters.length) {
      this.filters.push(...this.customFilters);
    }
  }

  setFilterVisibility(filter: any): void {
    filter.visible = !filter.visible;
    this.filterChange();
  }

  removeFilter($event: Event, filter: any): void {
    $event.stopImmediatePropagation();
    $event.preventDefault();
    this.filterBuilder.hideFilter(filter);
    this.filterChange();
  }

  getFilterTemplate(filterType): string {
    const localType = this.valueOnly ? 'Text' : filterType;
    return `/app/instance/directives/cio-search-filter/partials/${localType}.html`;
  }

  selectAllStatues(statuses) {
    for (const status of statuses) {
      status.checked = true;
    }
    this.filterChange();
  }

  selectNoStatuses(statuses) {
    for (const status of statuses) {
      status.checked = false;
    }
    this.filterChange();
  }

  setDateTo(filter: any, date?: DateTime) {
    filter.dateTo = date;
    this.filterChange();
  }

  setDateFrom(filter: any, date?: DateTime) {
    filter.dateFrom = date;
    this.filterChange();
  }

  usersSelected(filter: any, selected: Guid[], unassigned: boolean) {
    if (!filter.selectedUsers) {
      filter.selectedUsers = [];
    } else {
      filter.selectedUsers.length = 0;
    }
    filter.selectedUsers.push.apply(filter.selectedUsers, selected);
    filter.unassigned = unassigned;
    this.filterChange();
  }

  trigger(): void {
    this.filterChange();
  }

  tagsChanged(filter: any, tags: { tag: Tag; selected: boolean }[]) {
    filter.selectedTags = tags;
    this.filterChange();
  }

  getSelectedTagIds(filter: { selectedTags?: { tag: Tag; selected: boolean }[] }) {
    return (filter.selectedTags || []).map(x => x.tag.id);
  }

  private filterChange() {
    const selectedFilters = this.filters.filter(x => !!x.visible);
    this.filterChanged({
      filters: selectedFilters,
      text: this.freeSearch
    });
  }
}
