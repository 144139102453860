import { WorkflowFilterAddressController } from './workflowFilterAddress.controller';
import { ComponentBase } from '../../../../core/utils/componentBase';

export class WorkflowFilterAddressComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      filter: '='
    };
    this.controller = WorkflowFilterAddressController;
    this.template = require('./workflowFilterAddress.html');
  }
}
