import ReportListController from './report-list.controller';

export default class ReportListComponent implements ng.IComponentOptions {
  public controller: any;
  public template: string;
  public bindings: any;

  constructor() {
    this.bindings = {};
    this.controller = ReportListController;
    this.template = require('./report-list.html');
  }
}
