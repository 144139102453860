const defaultColors = [
  '#7bd148',
  '#5484ed',
  '#a4bdfc',
  '#46d6db',
  '#7ae7bf',
  '#51b749',
  '#fbd75b',
  '#ffb878',
  '#ff887c',
  '#dc2127',
  '#dbadff',
  '#e1e1e1'
];

/* @ngInject */
export default function CioColorPicker(): any {
  return {
    link: link,
    restrict: 'AE',
    scope: {
      selected: '=',
      customizedColors: '=colors'
    },
    template: [
      '<ul>' +
        '  <li ng-repeat="color in colors" ng-class="{selected: ' +
        '(color===selected)}" ng-click="pick(color)" ng-attr-style="background-color:{{color}};">' +
        '    <b>c</b>' +
        '  </li>' +
        '</ul>'
    ].join()
  };

  function link(scope, element, attr) {
    scope.colors = scope.customizedColors || defaultColors;
    scope.selected = scope.selected || scope.colors[0];

    scope.pick = function(color) {
      scope.selected = color;
    };
  }
}
