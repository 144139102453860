import { ComponentBase } from '../../../core/utils/componentBase';
import { DocumentsController } from './documents.controller';

export class DocumentsComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {};
    this.controller = DocumentsController;
    this.template = require('./documents.html');
  }
}
