import { WorkflowFilterClaimTypeController } from './workflowFilterClaimType.controller';
import { ComponentBase } from '../../../../core/utils/componentBase';

export class WorkflowFilterClaimTypeComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      filter: '=',
      field: '<'
    };
    this.controller = WorkflowFilterClaimTypeController;
    this.template = require('./workflowFilterClaimType.html');
  }
}
