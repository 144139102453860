import { WorkflowFilterGroupsController } from './workflowFilterGroups.controller';
import { ComponentBase } from '../../../../core/utils/componentBase';

export class WorkflowFilterGroupsComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = { workflow: '=' };
    this.controller = WorkflowFilterGroupsController;
    this.template = require('./workflowFilterGroups.html');
  }
}
