import { Guid } from '../../../core/models';
import { StateService } from 'angular-ui-router';

export class ReferenceLinkController {
  static $inject = ['$state'];

  reference: string;
  recordId: Guid;
  recordType: string;

  constructor(private stateService: StateService) {}

  get hasReference(): boolean {
    return (this.reference || '').trim().length > 0;
  }

  show() {
    if (!this.recordId || !this.recordType) {
      return;
    }
    if (this.isClaim) {
      this.stateService.go('app.claim.edit', { claimId: this.recordId.toString() });
    } else {
      this.stateService.go('app.entity.edit', { entityId: this.recordId.toString() });
    }
  }

  private get isClaim(): boolean {
    return this.recordType.toLowerCase() === 'claim';
  }
}
