import { IEntityFieldService } from './modals/entity-field.service';
import { IAlertService, IDateService } from '../../core/services';
import { EntityResource } from '../../data/resources/entity';
import { EntityTemplateResource } from '../../data/resources/entityTemplate';
import { ICacheService } from '../services/cache';
import { StateService } from 'angular-ui-router';
import uniqBy from 'lodash/uniqBy';
import head from 'lodash/head';
import { DateTime } from 'luxon';
import { DateTimeHelper } from '../../core/utils';
import { IFieldHttpService } from 'app/core/http';

/* @ngInject */
export default function EntityListController(
  $scope: any,
  $state: StateService,
  $stateParams: any,
  Cache: ICacheService,
  DateService: IDateService,
  FieldHttpService: IFieldHttpService,
  // tslint:disable-next-line:no-shadowed-variable
  EntityResource: EntityResource,
  EntityFieldService: IEntityFieldService,
  // tslint:disable-next-line:no-shadowed-variable
  EntityTemplateResource: EntityTemplateResource,
  AlertService: IAlertService
) {
  const templateId = $stateParams.templateId;

  $scope.entities = [];
  $scope.tableFields = [];
  $scope.reverse = false;

  init();
  function init() {
    FieldHttpService.all()
      .safeApply($scope, fields => {
        $scope.fields = fields;
        loadTemplate();
      })
      .subscribe();
  }

  $scope.editFields = function() {
    // Open a modal with the fields
    EntityFieldService.selectFields($scope.template, $scope.fields, $scope.tableFields)
      .then((fields: any[]) => {
        $scope.template.sections.forEach(section => {
          section.fields.forEach(field => {
            const fieldToDisplay = fields.find(x => x.id === field.fieldId);
            field.tableDisplay = fieldToDisplay ? true : false;
          });
        });

        if (fields.length) {
          EntityTemplateResource.update($scope.template).$promise.then(function(updated) {
            loadTemplate();
          });
        }
      })
      .catch(() => {});
  };

  function loadTemplate() {
    Cache.entityTemplate(templateId)
      .then(function(template) {
        $scope.template = template;
        const sectionFields = [];
        $scope.tableFields = [];

        if ($scope.template) {
          $scope.template.sections.forEach(section => {
            section.fields.forEach(field => {
              if (field.tableDisplay) {
                sectionFields.push(field);
              }
            });
          });

          if ($scope.template.entityReferenceStrategy === 'IncrementingNumber') {
            $scope.tableFields.push({ name: 'reference', label: 'Reference' });
          }

          sectionFields.forEach(field => {
            $scope.tableFields.push($scope.fields.find(x => x.id === field.fieldId));
          });

          if ($scope.tableFields.length <= 1) {
            $scope.tableFields.push($scope.fields.find(x => x.name === 'name'));
          }

          if (!$scope.predicate) {
            $scope.predicate = (<any>head($scope.tableFields)).name;
          }

          $scope.tableFields = uniqBy($scope.tableFields, 'name');
        }

        EntityResource.queryByTemplate({ id: templateId })
          .$promise.then(function(data) {
            $scope.entities = data.items;
          })
          .catch(function(error) {
            AlertService.error('Could not load entities, please try again.');
          });
      })
      .catch(function(error) {
        AlertService.error('Could not load entity templates, please try again.');
        $state.go('app.entity-types');
      });
  }

  $scope.loadEntity = function(entity) {
    $state.go('app.entity.edit', { entityId: entity.id });
  };

  $scope.formatText = function(field, text) {
    if (field.name === 'reference') {
      return text || 'Not set';
    }
    switch (field.type) {
      case 'Date':
        const date = DateTimeHelper.parseUtcDate(text);
        if (date && date.isValid) {
          return DateService.formatUtcDate(date);
        }
        return text;
      case 'DateTime':
      case 'Datetime':
        const datetime = DateTimeHelper.parseUtcDateTime(text);
        if (datetime && datetime.isValid) {
          return DateService.formatLocalDateTime(datetime);
        }
        return text;
      default:
        return text;
    }
  };

  $scope.setSort = setSort;

  function setSort(fieldName) {
    if ($scope.predicate === fieldName) {
      $scope.reverse = !$scope.reverse;
    } else {
      $scope.predicate = fieldName;
      $scope.reverse = false;
    }
  }

  $scope.sortValue = function(entity) {
    const field = entity[$scope.predicate];

    if (!field) {
      return undefined;
    }

    if (field.raw && (field.type === 'Date' || field.type === 'DateTime')) {
      return DateTime.fromISO(field.raw).valueOf();
    }

    return field;
  };
}
