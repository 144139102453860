import { HttpService } from './http';
import { Organization } from '../models';
import { ILogService, ISecurityService } from '../services';
import { logError } from '../utils/rx';
import { Observable } from 'rxjs/Observable';

export interface IOrganizationHttpService {
  getOrganization(): Observable<Organization>;
  modifyOrganization(organization: Organization): Observable<Organization>;
  uploadLogo(
    organization: Organization,
    data: Blob,
    progress?: (value: number) => {}
  ): Observable<boolean>;
  removeLogo(organization: Organization): Observable<boolean>;
}

export class OrganizationHttpService implements IOrganizationHttpService {
  static $inject = ['HttpService', 'SecurityService', 'LogService'];

  private readonly _url: string = '/api/v1/organizations';

  constructor(
    private httpService: HttpService,
    private securityService: ISecurityService,
    private logService: ILogService
  ) {}

  getOrganization(): Observable<Organization> {
    return this.httpService
      .get(this._url)
      .map(res => Organization.fromJson(res.json()))
      .catch(logError);
  }

  modifyOrganization(organization: Organization): Observable<Organization> {
    if (!this.canModify()) {
      return this.accessDenied();
    }
    return this.httpService
      .put(this._url, JSON.stringify(organization))
      .map(res => Organization.fromJson(res.json()))
      .catch(logError);
  }

  uploadLogo(
    organization: Organization,
    data: Blob,
    progress?: (value: number) => {}
  ): Observable<boolean> {
    if (!this.canModify()) {
      return this.accessDenied();
    }

    const form = new FormData();
    form.append('file', data, 'logo.jpg');

    const config = {
      uploadEventHandlers: {
        progress: function(e) {
          if (e.lengthComputable && progress) {
            progress((e.loaded * 100) / e.total);
          }
        }
      }
    };

    return this.httpService
      .postForm(`${this._url}/logo`, form, config)
      .map(_ => true)
      .catch(logError);
  }

  removeLogo(organization: Organization): Observable<boolean> {
    if (!this.canModify()) {
      return this.accessDenied();
    }
    return this.httpService
      .delete(`${this._url}/logo`)
      .map(_ => true)
      .catch(logError);
  }

  private canModify(): boolean {
    return this.securityService.isAdministrator();
  }

  private accessDenied() {
    this.logService.error('Modify organization attempted for non-administrator.');
    return Observable.throw('User not authorized');
  }
}
