import { Clock } from '../../../core/utils';

export class ClaimStatsWidgetController {
  static $inject = ['$http'];

  items: any[] = [];

  config = { filter: '', field: 'type' };
  availableOpts = [
    {
      name: 'field',
      title: 'Field',
      type: 'dropdown',
      values: [] // disabled for now
    },
    {
      name: 'view',
      title: 'View',
      type: 'dropdown',
      values: [
        { name: 'Bar Chart', value: 'bar' },
        { name: 'Pie Chart', value: 'pie' },
        { name: 'Table', value: 'table' }
      ]
    },
    {
      name: 'filter',
      title: 'Filter',
      type: 'dropdown',
      values: [
        { name: 'All Time', value: '' },
        { name: 'Last Month', value: 'last-month' },
        { name: 'This Month', value: 'this-month' },
        { name: 'This Week', value: 'this-week' },
        { name: 'Last Week', value: 'last-week' }
      ]
    }
  ];

  constructor(private http: ng.IHttpService) {}

  $onInit() {
    this.load();
  }

  load() {
    const filter = this.config.filter || '';
    let start = '';
    let end = '';

    switch (filter) {
      case 'last-month':
        start = Clock.local()
          .startOfLastMonth()
          .toISO();
        end = Clock.local()
          .endOfLastMonth()
          .toISO();
        break;

      case 'this-month':
        start = Clock.local()
          .startOfThisMonth()
          .toISO();
        end = Clock.local()
          .endOfThisMonth()
          .toISO();
        break;

      case 'this-week':
        start = Clock.local()
          .startOfThisWeek()
          .toISO();
        end = Clock.local()
          .endOfThisWeek()
          .toISO();
        break;

      case 'last-week':
        start = Clock.local()
          .startOfLastWeek()
          .toISO();
        end = Clock.local()
          .endOfLastWeek()
          .toISO();
        break;

      default:
        break;
    }

    this.http
      .post<{ items: any[] }>('/api/v1/reports/claims', {
        parts: [
          {
            name: 'claims_aggr',
            type: 'Terms',
            field: this.config.field || 'type',
            created: { start: start, end: end }
          }
        ]
      })
      .then(response => {
        this.items = (response.data.items || [])[0].items || [];
      });
  }
}
