import { StateService } from 'angular-ui-router';
import { IAlertService } from '../../../core/services/alert';
import { IUserStore } from '../../../data/stores/user';

/* @ngInject */
export default function UserNewController(
  $scope: any,
  $state: StateService,
  UserStore: IUserStore,
  Cache,
  AlertService: IAlertService
): void {
  $scope.user = {};
  $scope.userForm = {};
  $scope.userForm.active = false;

  $scope.saveUser = function(user) {
    $scope.userForm.active = true;

    // enable the new user
    user.isActive = true;

    UserStore.save(user)
      .then(function(updated) {
        AlertService.success('User successfully created.');
        $scope.userForm.active = false;
        $scope.userForm.$setPristine();

        // refresh users
        Cache.users(true);

        $state.go('app.user.edit', { id: updated.id });
      })
      .catch(function(error) {
        AlertService.error('Could not create user, please try again.');
        $scope.userForm.active = false;
      });
  };
}
