export class QueryParams {
  private parts = {};

  constructor(data?: any) {
    if (data) {
      for (const key in data) {
        if (!this.parts.hasOwnProperty(key)) {
          continue;
        }
        const value = data[key];
        this.set(key, value == null ? '' : value.toString());
      }
    }
  }

  /**
   * Appends a specified key/value pair as a new search parameter.
   */
  append(name: string, value: string): void {
    this.appendTo(this.parts, name, value);
  }

  /**
   * Deletes the given search parameter, and its associated value,
   * from the list of all search parameters.
   */
  delete(name: string): void {
    delete this.parts[name];
  }

  /**
   * Returns the first value associated to the given search parameter.
   */
  get(name: string): string | null {
    return name in this.parts ? this.parts[name][0] : null;
  }

  /**
   * Returns all the values association with a given search parameter.
   */
  getAll(name: string): string[] {
    return name in this.parts ? this.parts[name].slice(0) : [];
  }

  /**
   * Returns a Boolean indicating if such a search parameter exists.
   */
  has(name: string): boolean {
    return name in this.parts;
  }

  /**
   * Sets the value associated to a given search parameter to
   * the given value. If there were several values, delete the
   * others.
   */
  set(name: string, value: string): void {
    this.parts[name] = ['' + value];
  }

  /**
   * Returns a string containg a query string suitable for use in a URL.
   */
  toString(): string {
    const query = [];
    let i, key, name, value;
    for (key in this.parts) {
      if (!this.parts.hasOwnProperty(key)) {
        continue;
      }
      name = this.encode(key);
      for (i = 0, value = this.parts[key]; i < value.length; i++) {
        query.push(name + '=' + this.encode(value[i]));
      }
    }
    return query.join('&');
  }

  private appendTo(dict: any, name: string, value: string): void {
    if (name in dict) {
      dict[name].push('' + value);
    } else {
      dict[name] = ['' + value];
    }
  }

  private encode(str: string): string {
    const replace = {
      '!': '%21',
      "'": '%27',
      '(': '%28',
      ')': '%29',
      '~': '%7E',
      '%20': '+',
      '%00': '\x00'
    };
    return encodeURIComponent(str).replace(/[!'\(\)~]|%20|%00/g, function(match) {
      return replace[match];
    });
  }
}
