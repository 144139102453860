import d3 from 'd3';

/* @ngInject */
export default function cioHistogram(): any {
  return {
    restrict: 'EA',
    scope: {
      data: '=',
      interval: '=',
      key: '<'
    },
    link: link
  };

  function link(scope, element, attrs) {
    const svg = d3
      .select(element[0])
      .append('svg')
      .style('width', '100%');

    // Browser onresize event
    window.onresize = function() {
      scope.$apply();
    };

    // Watch for resize event
    scope.$watch(
      function() {
        return window.innerWidth;
      },
      function() {
        scope.render(scope.data);
      }
    );

    // watch for data changes and re-render
    scope.$watch(
      'data',
      function(newVals) {
        return scope.render(newVals);
      },
      true
    );

    scope.render = function(data) {
      // remove all previous items before render
      svg.selectAll('*').remove();

      // If we don't pass any data, return out of the element
      if (!data) return;

      // convert data
      const mappedData = data.map(function(d) {
        return { date: new Date(d.date), value: d.value };
      });

      // Use the category20() scale function for multicolor support
      const color = d3.scale.category20(),
        barWidth = 40,
        width = (barWidth + 10) * mappedData.length,
        height = 250;
      let format = '%Y-%m';

      const interval = scope.interval || 'month';
      switch (interval) {
        case 'year':
          format = '%Y';
          break;
        case 'month':
          // format = '%Y-%m';
          format = '%b %Y';
          break;
        case 'day':
          // format = '%Y-%m-%d';
          format = '%d %b %Y';
          break;
        default:
          break;
      }

      const x = d3.scale
        .linear()
        .domain([0, mappedData.length])
        .range([0, width]);
      const y = d3.scale
        .linear()
        .domain([
          0,
          d3.max(mappedData, function(datum: any) {
            return datum.value;
          })
        ])
        .rangeRound([0, height]);
      const formatFx = d3.time.format(format);

      // add the canvas to the DOM
      const barDemo = svg
        .attr('width', width + 30)
        .attr('height', height + 60) // for x axis text
        .attr('style', 'margin-left:auto; margin-right: auto;');

      // adds the bars
      barDemo
        .selectAll('rect')
        .data(mappedData)
        .enter()
        .append('rect')
        .attr('x', function(datum, index) {
          return x(index);
        })
        .attr('y', function(datum: any) {
          return height - y(datum.value);
        })
        .attr('height', function(datum: any) {
          return y(datum.value);
        })
        .attr('width', barWidth)
        .attr('fill', function(d: any) {
          return color(d.value);
        });

      // adds text inside the bars
      barDemo
        .selectAll('text')
        .data(mappedData)
        .enter()
        .append('text')
        .attr('x', function(datum, index) {
          return x(index) + barWidth;
        })
        .attr('y', function(datum: any) {
          return height - y(datum.value);
        })
        .attr('dx', -barWidth / 2)
        .attr('dy', '1.2em')
        .attr('text-anchor', 'middle')
        .text(function(datum: any) {
          return datum.value;
        })
        .attr('fill', 'white');

      // adds x axis text
      barDemo
        .selectAll('text.yAxis')
        .data(mappedData)
        .enter()
        .append('text')
        .attr('x', function(datum, index) {
          return x(index) + barWidth;
        })
        .attr('y', height)
        .attr('dx', -barWidth / 2)
        .attr('text-anchor', 'middle')
        .attr('style', 'font-size: 12;')
        .text(function(datum: any) {
          return formatFx(datum.date);
        })
        .attr('transform', function(datum, index) {
          const xPosition = x(index) + barWidth;
          return 'translate(0, 18) rotate(-45, ' + xPosition + ', ' + height + ')';
        })
        .attr('class', 'xAxis');
    };
  }
}
