import { RecordLinkController } from './recordLink.controller';
import { ComponentBase } from '../../../core/utils/componentBase';

export class RecordLinkComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      id: '<',
      text: '<',
      recordType: '<'
    };
    this.controller = RecordLinkController;
    this.template = require('./recordLink.html');
  }
}
