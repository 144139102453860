import * as angular from 'angular';
import { ICountryService } from '../../core/services/country';

/* @ngInject */
export default function EntityContactDetailController(
  $scope: any,
  $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
  contact: any,
  CountryService: ICountryService
) {
  // we copy to avoid updating the original
  // object in case of cancel
  $scope.contact = angular.copy(contact);
  $scope.countries = CountryService.getCountries();

  $scope.ok = function() {
    $uibModalInstance.close($scope.contact);
  };

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };
}
