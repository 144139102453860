import { Guid, IWorkflowFilter, EntityFieldDefinition } from '../../../../core/models';

export class WorkflowFilterEntityController {
  static $inject = [];

  filter: IWorkflowFilter;
  validTypes: Guid[] = [];
  private _field: EntityFieldDefinition;

  get field(): EntityFieldDefinition {
    return this._field;
  }

  set field(value: EntityFieldDefinition) {
    this._field = value;
    if (!this._field || !this._field.settings || !this._field.settings.entityTypeId) {
      this.validTypes = [];
    } else {
      this.validTypes = [new Guid(this._field.settings.entityTypeId)];
    }
  }
}
