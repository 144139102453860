import { DocumentNewController } from './documentNew.controller';
import { ComponentBase } from '../../../core/utils/componentBase';

export class DocumentNewComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {};
    this.controller = DocumentNewController;
    this.template = require('./documentNew.html');
  }
}
