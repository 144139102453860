import HomeController from './home.controller';
import HomeRoutes from './home.routes';
import { CoreModule } from '../../core/module';
import { DataModule } from '../../data/module';
import { UIModule } from '../../ui/module';
import * as angular from 'angular';

export const HomeModule = angular
  .module('app.instance.home', [DataModule.name, CoreModule.name, UIModule.name])

  .config(HomeRoutes)
  .controller('HomeController', HomeController);
