import { EntityTemplate } from '../../core/models';
import * as ng from 'angular';

/* tslint:disable-next-line:no-empty-interface */
export interface EntityTemplateResource
  extends ng.resource.IResourceClass<ng.resource.IResource<EntityTemplate>> {
  update(model: EntityTemplate): ng.resource.IResource<EntityTemplate>;
}

/* @ngInject */
export default function EntityTemplateResource(
  $resource: ng.resource.IResourceService
): EntityTemplateResource {
  return <EntityTemplateResource>$resource<ng.resource.IResource<EntityTemplate>>(
    '/api/v1/entity-templates/:id',
    { id: '@id' },
    {
      update: {
        method: 'PUT',
        params: {
          id: '@id'
        }
      },
      query: {
        method: 'GET'
      }
    }
  );
}
