import WidgetComponent from './widget.component';
import DashboardController from './dashboard';
import WidgetsModalController from './widgets-modal-controller';
import { TasksWidgetComponent } from './tasks/tasksWidget.component';
import { RecentClaimsWidgetComponent } from './recent-claims/recentClaimsWidget.component';
import { NotificationsWidgetComponent } from './notifications/notificationsWidget.component';
import { ClaimsStatsWidgetComponent } from './claims-stats/claimStatsWidget.component';
import { ClaimsHistogramWidgetComponent } from './claims-histogram/claimsHistogramWidget.component';
import WidgetsService from './widgets-service';
import { CoreModule } from '../../core/module';
import { DataModule } from '../../data/module';
import { UIModule } from '../../ui/module';
import * as angular from 'angular';

export const DashboardModule = angular
  .module('app.instance.dashbaord', [DataModule.name, CoreModule.name, UIModule.name])

  // Components
  .component('cioWidgetComponent', new WidgetComponent())
  .component('cioTasksWidgetComponent', new TasksWidgetComponent())
  .component('cioRecentClaimsWidgetComponent', new RecentClaimsWidgetComponent())
  .component('cioNotificationsWidgetComponent', new NotificationsWidgetComponent())
  .component('cioClaimsStatsWidgetComponent', new ClaimsStatsWidgetComponent())
  .component('cioClaimsHistogramWidgetComponent', new ClaimsHistogramWidgetComponent())

  // Factories
  .factory('WidgetsService', WidgetsService)

  // Controllers
  .controller('DashboardController', DashboardController)
  .controller('WidgetsModalController', WidgetsModalController);
