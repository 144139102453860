import { ISearchColumn, ISearchFilter } from './search.interfaces';
import { ISearchChangeModalService } from './searchChangeModal.service';
import { SearchRow } from './searchRow';
import { IClaimHttpService } from '../../core/http';
import {
  ClaimSearchResults,
  FieldDefinition,
  Guid,
  Page,
  SavedSearch
} from '../../core/models';
import { IDownloadService } from '../../core/services';
import { ICommentModalService } from '../claims/activities/commentModal.service';
import { ITimesheetModalService } from '../claims/timesheets/timesheetModal.service';
import { ICacheService } from '../services/cache';
import { IFilterBuilder } from '../services/filter-builder';
import { StateService } from 'angular-ui-router';
import ng from 'angular';

const DEFAULT_PAGE_SIZE: number = 50;

export class SearchController {
  static $inject = [
    '$scope',
    '$state',
    '$location',
    'ClaimHttpService',
    'FilterBuilder',
    'Cache',
    'DownloadService',
    'SearchChangeModalService',
    'TimesheetModalService',
    'CommentModalService'
  ];

  columns: ISearchColumn[] = [];
  filters: ISearchFilter[] = [];
  searchText: string;
  searchQuery: string;
  sort: string = undefined;
  sortDescending: boolean = false;
  pageNumber: number = 1;
  currentPage: Page<SearchRow> = undefined;
  currentSearch: SavedSearch;

  constructor(
    private scope: any,
    private stateService: StateService,
    private locationService: ng.ILocationService,
    private claimHttpService: IClaimHttpService,
    private filterBuilder: IFilterBuilder,
    private cacheService: ICacheService,
    private downloadService: IDownloadService,
    private searchChangeModalService: ISearchChangeModalService,
    private timesheetModalService: ITimesheetModalService,
    private commentModalService: ICommentModalService
  ) {}

  $onInit(): void {
    this.loadSearch();
  }

  search() {
    this.loadPage(1);
  }

  filterBy(filters: ISearchFilter[], text: string): void {
    this.filters = filters;
    this.searchText = text;
    this.searchQuery = this.createQuery(this.filters, this.searchText);
    this.loadPage(1);
  }

  sortBy(column: ISearchColumn, sortDescending: boolean): void {
    this.sort = column.fieldName;
    this.sortDescending = sortDescending;
    this.loadPage(1);
  }

  pageChanged(pageNumber: number): void {
    this.loadPage(pageNumber);
  }

  changeSearch() {
    this.searchChangeModalService.select(search => this.setSearch(search));
  }

  newSearch() {
    this.stateService.go('app.savedSearch.new');
  }

  editSearch() {
    this.stateService.go('app.savedSearch.edit', {
      id: this.currentSearch.id
    });
  }

  exportSearch() {
    let url = `/api/v1/claims/export?q=${this.searchQuery}`;
    if (this.currentSearch) {
      url += `&s=${this.currentSearch.id}`;
    }
    this.downloadService.downloadGet(
      url,
      (this.currentSearch.name || 'claim-export') + '.xlsx'
    );
  }

  addTimesheet(row: SearchRow) {
    this.timesheetModalService.create(row.id).catch(() => {});
  }

  addComment(row: SearchRow) {
    this.commentModalService.create(row.id).catch(() => {});
  }

  selectRow(row: SearchRow) {
    this.stateService.go('app.claim.edit', { claimId: row.id.toString() });
  }

  private loadSearch() {
    this.cacheService.savedSearches().then(searches => {
      this.cacheService.userSettings().then(userSettings => {
        let search: SavedSearch;
        const qs = this.locationService.search();
        if (qs.search) {
          search = searches.find(x => new Guid(x.id).equals(qs.search));
        }
        if (!search && userSettings.settings.lastUsedSearch) {
          search = searches.find(x =>
            new Guid(x.id).equals(userSettings.settings.lastUsedSearch)
          );
        }
        if (!search && searches.length) {
          search = searches[0];
        }
        if (search) {
          this.setSearch(search);
        }
      });
    });
  }

  private setSearch(search: SavedSearch) {
    this.currentSearch = search;
    this.searchText = search.query;
    this.sort = search.sortField;
    this.sortDescending = search.reverse;
    this.filters.length = 0;
    this.filters.push.apply(this.filters, search.filters);
    this.searchQuery = this.createQuery(this.filters, this.searchText);

    this.cacheService.userSettings().then(userSettings => {
      userSettings.settings.lastUsedSearch = search.id;
      (userSettings as any).update(userSettings);
    });

    this.setFields(search.fields.map(x => x.fieldName)).then(() => {
      this.locationService.search('search', search.id);
    });
  }

  private setFields(fields: string[]): Promise<void> {
    return this.cacheService
      .fields()
      .then(fieldDefinitions => {
        const columns: ISearchColumn[] = [];
        let index = 0;
        for (const item of fields) {
          const column = getColumn(index, fieldDefinitions, item);
          if (column) {
            columns.push(column);
          }
          index += 1;
        }
        this.columns.length = 0;
        this.columns.push.apply(this.columns, columns);
        this.loadPage(1);
      })
      .catch(error => console.error(error));
  }

  private loadPage(pageNumber: number) {
    const query = {
      query: this.searchQuery,
      fields: this.columns.map(x => x.fieldName),
      sort: this.sort,
      sortDescending: this.sortDescending,
      skip: (pageNumber - 1) * DEFAULT_PAGE_SIZE,
      take: DEFAULT_PAGE_SIZE
    };
    this.claimHttpService
      .search(query)
      .safeApply(this.scope, results => this.setResults(results))
      .subscribe();
  }

  private setResults(results: ClaimSearchResults) {
    this.currentPage = results.page as any;
    this.currentPage.items = results.page.items.map(x => new SearchRow(x));
    this.pageNumber = results.page.page;
  }

  private createQuery(filters, text) {
    const data = this.filterBuilder.build(filters);
    let filterSearch = text && text.length ? text + '*' : '';
    if (data.claimSearch && data.claimSearch.length) {
      if (filterSearch.length) {
        filterSearch += ' AND';
      }
      filterSearch += ' ' + data.claimSearch;
    }
    return (filterSearch || '').trim();
  }
}

function getColumn(
  index: number,
  fields: FieldDefinition[],
  fieldName: string
): ISearchColumn {
  const field = fields.find(x => x.name.toLowerCase() === fieldName.toLowerCase());
  if (!field) {
    return {
      columnNumber: index,
      fieldId: undefined,
      fieldName: fieldName,
      fieldLabel: fieldName.slice(0, 1).toUpperCase() + fieldName.slice(1),
      fieldType: 'Text'
    } as ISearchColumn;
  }
  return {
    columnNumber: index,
    fieldId: field.id ? new Guid(field.id) : undefined,
    fieldName: field.name,
    fieldLabel: field.label,
    fieldType: field.type
  } as ISearchColumn;
}
