import { Role } from '../../../core/models';
import { IAlertService } from '../../../core/services';
import { IRoleStore } from '../../../data/stores/role';
import ng from 'angular';

export interface IRoleSelectService {
  open(title?: string, selectedRoles?: Role[]): Promise<any>;
}

/* @ngInject */
export function RoleSelectService(
  $uibModal: ng.ui.bootstrap.IModalService
): IRoleSelectService {
  const self = this;

  /**
   * Opens a modal and allows the user to select roles.
   * @param {string} [title=Select roles] - the modal title.
   * @param {object[]} [selectedRoles] - the list of already selected roles.
   * @returns {promise}
   */
  self.open = function(title?: string, selectedRoles?: Role[]) {
    title = title || 'Select roles';
    selectedRoles = selectedRoles || [];

    const modalInstance = $uibModal.open({
      template: require('./role-select.html'),
      controller: 'RoleSelectController',
      resolve: {
        title: function() {
          return title;
        },
        selectedRoles: function() {
          return selectedRoles;
        }
      }
    });

    return modalInstance.result;
  };

  return self;
}

/* tslint:disable:no-shadowed-variable */
/* @ngInject */
export function RoleSelectController(
  $scope: any,
  $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
  title: string,
  selectedRoles: Role[],
  RoleStore: IRoleStore,
  AlertService: IAlertService
) {
  /* tslint:enable:no-shadowed-variable */

  $scope.title = title;
  $scope.groups = [];
  $scope.allSelected = false;
  $scope.query = '';

  const evaluateAllSelected = function() {
    let allSelected = true;

    for (let i = 0; i < $scope.groups.length; ++i) {
      if ($scope.groups[i].selected === false) {
        allSelected = false;
        break;
      }
    }

    $scope.allSelected = allSelected;
  };

  RoleStore.query()
    .then(function(result) {
      const list = [];
      result.items.forEach(item => {
        const isSelected = selectedRoles.some(x => x.id === item.id) ? true : false;
        list.push({ selected: isSelected, role: item });
      });

      $scope.groups = list;

      evaluateAllSelected();
    })
    .catch(function(error) {
      AlertService.error('Could not load roles.');
      $uibModalInstance.dismiss('cancel');
    });

  $scope.toggleAll = function() {
    const toggle = !$scope.allSelected;

    $scope.groups.forEach(item => {
      item.selected = toggle;
    });

    evaluateAllSelected();
  };

  $scope.groupSelect = function(item) {
    item.selected = !item.selected;
    evaluateAllSelected();
  };

  $scope.ok = function() {
    const selected = [];
    $scope.groups.forEach(item => {
      if (item.selected) {
        selected.push(item.role);
      }
    });

    $uibModalInstance.close(selected);
  };

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };
}
