import { ILocalStorageService } from './localStorage';
import { Guid, Session, SessionToken } from '../models';

const STORAGE_KEY = 'Session::Token';

/**
 * Manages the current authentication session.
 */
export interface ISessionService {
  /**
   * Determines if the specified session is still active based on the
   * specified expiry date of the token.
   *
   * Note: Its still possible that the token has been revoked, so this
   * is not an absolute guarantee that it will work.
   */
  isActive(): boolean;

  /**
   * Returns the current session token, if any - otherwise an undefined
   * value will be returned.
   */
  getToken(): SessionToken;

  /**
   * Stores the specified token against the current session.
   */
  setSession(session: Session): void;

  /**
   * Removes the current authentication token.
   */
  clearSession(): void;

  /**
   * Returns the current user ID if we have a valid token set.
   */
  getUserId(): Guid;
}

export class SessionService implements ISessionService {
  static $inject = ['LocalStorageService'];

  private session: Session = undefined;

  constructor(private localStorageService: ILocalStorageService) {}

  setSession(session: Session): void {
    if (!session || !session.token || !session.user) {
      throw new Error('Cannot set invalid session: ' + JSON.stringify(session));
    }

    this.session = session;
    this.localStorageService.setItem(STORAGE_KEY, session);
  }

  clearSession(): void {
    this.session = undefined;
    this.localStorageService.removeItem(STORAGE_KEY);
  }

  getToken(): SessionToken {
    const session = this.getSession();
    if (!session) {
      return undefined;
    }

    return session.token;
  }

  getUserId(): Guid {
    const session = this.getSession();
    if (!session) {
      return undefined;
    }

    return new Guid(session.user);
  }

  isActive(): boolean {
    return this.localStorageService.hasItem(STORAGE_KEY);
  }

  private getSession(): Session {
    if (this.session) {
      return this.session;
    }

    this.session = this.localStorageService.getItem(STORAGE_KEY);
    return this.session;
  }
}
