import {
  INotificationSettingsHttpService,
  IClaimHttpService,
  ISubscriptionHttpService
} from '../../core/http';
import { IAlertService, ISecurityService } from '../../core/services';
import { Guid } from 'app/core/models';

/* @ngInject */
export function UserClaimsController(
  $scope: any,
  ClaimHttpService: IClaimHttpService,
  SubscriptionHttpService: ISubscriptionHttpService,
  SecurityService: ISecurityService,
  NotificationSettingsHttpService: INotificationSettingsHttpService,
  AlertService: IAlertService
): void {
  const vm = this;

  vm.claims = [];
  vm.notificationSettings = {};
  vm.unwatch = unwatch;
  vm.unwatchAll = unwatchAll;
  vm.update = update;

  init();

  function init(): void {
    loadWatching();
    loadSettings();
  }

  function loadWatching(): void {
    ClaimHttpService.searchBySubscriber()
      .safeApply($scope, data => {
        vm.claims = data.items.map(transformSearchResults);
      })
      .subscribe();
  }

  function loadSettings(): void {
    NotificationSettingsHttpService.getByUserId(SecurityService.getCurrentUserId())
      .safeApply($scope, settings => {
        vm.notificationSettings = settings || {};
      })
      .subscribe();
  }

  function transformSearchResults(dicItem: any[]): any {
    const obj: any = {};
    (dicItem || []).forEach(item => {
      obj[item.name] = item.values ? item.values.join(', ') : '';
    });
    return obj;
  }

  function unwatch(claim: { id: string }): void {
    ClaimHttpService.unsubscribe(new Guid(claim.id))
      .safeApply($scope, () => {
        AlertService.success('Stopped watching claim');
        loadWatching();
      })
      .subscribe();
  }

  function unwatchAll(): void {
    SubscriptionHttpService.unsubscribeFromAll()
      .safeApply($scope, () => {
        AlertService.success('All claims updated successfully.');
        loadWatching();
      })
      .subscribe();
  }

  function update(): void {
    NotificationSettingsHttpService.modify(
      SecurityService.getCurrentUserId(),
      vm.notificationSettings
    )
      .safeApply($scope, settings => {
        vm.notificationSettings = settings;
        AlertService.success('Settings updated successfully.');
      })
      .subscribe();
  }
}
