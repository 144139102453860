import { Payment } from '../../core/models';
import * as ng from 'angular';

export interface PaymentResource
  extends ng.resource.IResourceClass<ng.resource.IResource<Payment>> {
  update(payment: Payment);
}

/* @ngInject */
export default function PaymentResource(
  $resource: ng.resource.IResourceService
): PaymentResource {
  return <PaymentResource>$resource<ng.resource.IResource<Payment>>(
    '/api/v1/payments/:id',
    { id: '@id' },
    {
      update: {
        method: 'PUT',
        params: {
          id: '@id'
        }
      },
      query: { method: 'GET' }
    }
  );
}
