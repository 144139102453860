import { TemplateSection } from './templateSection';

export enum EntityTemplateKinds {
  Entity = 'Entity',
  Record = 'Record',
  Policy = 'Policy'
}

export enum EntityReferenceStrategies {
  Blank = 'Blank',
  IncrementingNumber = 'IncrementingNumber'
}

export interface EntityTemplate {
  id: string;
  name: string;
  description: string;
  sections: TemplateSection[];
  systemFields: any[];
  disabled: boolean;
  kind: EntityTemplateKinds;
  titleTemplate: string;
  entityReferenceStrategy: EntityReferenceStrategies;
  entityReferencePrefix: string;
  entityReferenceSuffix: string;
  created: Date;
  createdBy: string;
  lastModified: Date;
  lastModifiedBy: string;
}
