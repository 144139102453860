import {
  Report,
  ReportField,
  ReportFieldCondition,
  ReportFilter,
  ReportFilterCondition,
  ReportOperation
} from '../../../core/models';
import { ReportFieldResource } from '../../../data/resources/report-field';
import { ReportFieldConditionResource } from '../../../data/resources/reportFieldCondition';
import * as angular from 'angular';

export default class ReportFiltersModalController {
  static $inject = [
    'report',
    '$uibModalInstance',
    'ReportFieldResource',
    'ReportFieldConditionResource'
  ];

  public allFields: ReportField[];
  public selectedFilters: ReportFilter[];
  public selected: any;
  public filterConditions: ReportFieldCondition[];

  constructor(
    public report: Report,
    private modalInstance: ng.ui.bootstrap.IModalServiceInstance,
    private reportFieldResource: ReportFieldResource,
    private reportFieldConditionResource: ReportFieldConditionResource
  ) {}

  $onInit() {
    // We clone the fields in case the modal is terminated without
    // without saving (cancel)

    this.selectedFilters = angular.copy(this.report.filters || []);
    this.loadFields();
  }

  /**
   * Closes the modal without saving.
   */
  cancel(): void {
    this.modalInstance.dismiss('cancel');
  }

  /**
   * Closes the modal and saves the result of the selected fields.
   */
  save(): void {
    this.modalInstance.close(this.selectedFilters);
  }

  /**
   * Loads all available fields based on the type of report.
   */
  loadFields() {
    const search = <ReportField>{
      reportType: this.report.type
    };

    (<any>this.reportFieldResource).save(search).$promise.then((result: any) => {
      this.allFields = result.items;
    });
  }

  addAndCondition() {
    this.selectedFilters.push({
      conditions: <ReportFilterCondition[]>[
        {
          field: <ReportField>{},
          operation: <ReportOperation>{},
          value: '',
          dateFrom: null,
          dateTo: null
        }
      ]
    });
  }

  addOrCondition(index) {
    this.selectedFilters[index].conditions.push({
      field: <ReportField>{},
      operation: <ReportOperation>{},
      value: '',
      dateFrom: null,
      dateTo: null
    });
  }

  removeAndCondition(filterIndex) {
    this.selectedFilters.splice(filterIndex, 1);
  }

  removeOrCondition(filterIndex, conditionIndex) {
    this.selectedFilters[filterIndex].conditions.splice(conditionIndex, 1);

    if (this.selectedFilters[filterIndex].conditions.length === 0) {
      this.removeAndCondition(filterIndex);
    }
  }

  /**
   * Selects a field and adds it to the report.
   *
   * @param {*} field The field to add to the report.
   *
   * @memberOf ReportStepFieldsController
   */
  selectFilter(filterIndex: number, conditionIndex: number, field: any): void {
    if (field) {
      const search = <ReportFieldCondition>{
        fieldType: field.dataType
      };

      (<any>this.reportFieldConditionResource)
        .query(search)
        .$promise.then((result: any) => {
          this.filterConditions = result.items;
        });
    }
  }
}
