import { AddressFieldComponent } from './addressField/addressField.component';
import { CountryFieldComponent } from './countryField/countryField.component';
import { DateFieldComponent } from './dateField/dateField.component';
import { DateTimeFieldComponent } from './dateTimeField/dateTimeField.component';
import { DropdownFieldComponent } from './dropdownField/dropdownField.component';
import { EmailFieldComponent } from './emailField/emailField.component';
import { EntityContactFieldComponent } from './entityContactField/entityContactField.component';
import { EntityFieldComponent } from './entityField/entityField.component';
import { FieldComponent } from './field/field.component';
import { TemplateComponent } from './template/template.component';
import { TemplateService } from './template/template.service';
import { TextFieldComponent } from './textField/textField.component';
import { UserFieldComponent } from './userField/userField.component';
import { CoreModule } from '../../core/module';
import { DataModule } from '../../data/module';
import { UIModule } from '../../ui/module';
import * as angular from 'angular';

export const TemplatesModule = angular
  .module('app.instance.templates', [DataModule.name, CoreModule.name, UIModule.name])

  // Factories
  .factory('TemplateService', TemplateService)

  // Components
  .component('cioAddressField', new AddressFieldComponent())
  .component('cioCountryField', new CountryFieldComponent())
  .component('cioDateField', new DateFieldComponent())
  .component('cioDateTimeField', new DateTimeFieldComponent())
  .component('cioDropdownField', new DropdownFieldComponent())
  .component('cioEmailField', new EmailFieldComponent())
  .component('cioEntityContactField', new EntityContactFieldComponent())
  .component('cioEntityField', new EntityFieldComponent())
  .component('cioField', new FieldComponent())
  .component('cioTemplateComponent', new TemplateComponent())
  .component('cioTextField', new TextFieldComponent())
  .component('cioUserField', new UserFieldComponent());
