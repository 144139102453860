import { IAlertService } from '../../../core/services/alert';
import { IUserStore } from '../../../data/stores/user';
import ng from 'angular';

/* @ngInject */
export default function UserMultiSelectController(
  $scope: any,
  $uibModalInstance: ng.ui.bootstrap.IModalInstanceService,
  title: string,
  selectedUsers,
  UserStore: IUserStore,
  AlertService: IAlertService
) {
  $scope.title = title;
  $scope.users = [];
  $scope.allSelected = false;
  $scope.query = '';

  const evaluateAllSelected = function() {
    let allSelected = true;

    for (let i = 0; i < $scope.users.length; ++i) {
      if ($scope.users[i].selected === false) {
        allSelected = false;
        break;
      }
    }

    $scope.allSelected = allSelected;
  };

  UserStore.query()
    .then(function(result) {
      const list = [];
      result.items.forEach(item => {
        const isSelected = selectedUsers.some(x => x.id === item.id) ? true : false;
        list.push({ selected: isSelected, user: item });
      });

      $scope.users = list;

      evaluateAllSelected();
    })
    .catch(function(error) {
      AlertService.error('Could not load users.');
      $uibModalInstance.dismiss('cancel');
    });

  $scope.userFilter = function(user) {
    if ($scope.query === '') {
      return true;
    }

    return (
      (user.fields.name || '').toLowerCase().indexOf($scope.query.toLowerCase()) > -1
    );
  };

  $scope.toggleAll = function() {
    const toggle = !$scope.allSelected;

    $scope.users.forEach(item => {
      item.selected = toggle;
    });

    evaluateAllSelected();
  };

  $scope.userSelect = function(item) {
    evaluateAllSelected();
  };

  $scope.ok = function() {
    const selected = [];
    $scope.users.forEach(item => {
      if (item.selected) {
        selected.push(item.user);
      }
    });

    $uibModalInstance.close(selected);
  };

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };
}
