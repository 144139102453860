import { DateEditController } from './dateEdit.controller';
import { ComponentBase } from '../../../core/utils/componentBase';

export class DateEditComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      date: '<',
      isRequired: '<',
      onChange: '&'
    };
    this.controller = DateEditController;
    this.template = require('./dateEdit.html');
  }
}
