import { StateService } from 'angular-ui-router';
import { ILogService, ITrackingService } from '../../../core/services';
import { IClaimsWindow } from '../../../core/utils/claimsWindow';
import { IInstanceService } from '../../services/instance-service';
import '../../services/auth-service';

/* @ngInject */
export default function CioLoginController(
  $scope: any,
  $state: StateService,
  $rootScope: any,
  $window: IClaimsWindow,
  AuthService: any,
  LogService: ILogService,
  InstanceService: IInstanceService,
  TrackingService: ITrackingService
): void {
  const vm = this;
  vm.instance = null;
  vm.email = '';
  vm.password = '';
  vm.remember = false;
  vm.alerts = [];

  vm.login = login;

  init();

  function init() {
    InstanceService.getInfo().then(data => (vm.instance = data));
  }

  function login(): void {
    vm.alerts = [];

    AuthService.login(vm.email, vm.password, vm.remember)
      .then(loginSuccess)
      .catch(loginFailure);
  }

  function loginSuccess() {
    LogService.debug('Auth :: Login success');

    logSuccess();

    // If we redirect here, then return back
    if ($rootScope.nextState) {
      $state.go($rootScope.nextState, $rootScope.nextStateParams);
    } else {
      $state.go('app.dashboard');
    }
  }

  function loginFailure() {
    vm.alerts = [{ type: 'danger', msg: 'Invalid email or password' }];
    LogService.debug('Auth :: Login failed');

    logFailure();
  }

  function logSuccess() {
    try {
      TrackingService.track('Login Success', {
        Instance: $window.location.hostname,
        Email: vm.email
      });
    } catch {}
  }

  function logFailure() {
    try {
      TrackingService.track('Login Failure', {
        Instance: $window.location.hostname,
        Email: vm.email
      });
    } catch {}
  }
}
