import { Task } from '../../core/models';
import { Clock, DateTimeHelper } from '../../core/utils';
import { DateTime } from 'luxon';

export class TaskDueDateEditController {
  selectedDate?: DateTime;
  hasDate: boolean;
  hasTime: boolean;

  set task(task: Task) {
    this.reset();
    if (!task) {
      return;
    }
    this.hasDate = !!task.dueDate;
    this.hasTime = task.hasTime;
    this.selectedDate = this.hasDate
      ? this.hasTime
        ? task.dueDate.toLocal()
        : task.dueDate.toUTC()
      : undefined;
  }

  addDate() {
    this.selectedDate = Clock.local().startOfToday();
    this.hasDate = true;
    this.hasTime = false;
    this.changed();
  }

  removeDate() {
    this.reset();
    this.changed();
  }

  addTime() {
    const now = Clock.local().now();
    this.selectedDate = DateTime.local(
      this.selectedDate.year,
      this.selectedDate.month,
      this.selectedDate.day,
      now.hour
    );
    this.hasTime = true;
    this.changed();
  }

  removeTime() {
    this.selectedDate = DateTimeHelper.extractUtcDate(this.selectedDate.toJSDate());
    this.hasTime = false;
    this.changed();
  }

  dateOnlyChanged(date: DateTime) {
    this.selectedDate = date;
    this.changed();
  }

  dateTimeChanged(dateTime: DateTime) {
    this.selectedDate = dateTime;
    this.changed();
  }

  changed() {
    if (!this.hasDate || !this.selectedDate) {
      (this as any).onChange({ dueDate: undefined, hasTime: false });
      return;
    }
    (this as any).onChange({
      dueDate: this.selectedDate.toUTC(),
      hasTime: this.hasTime
    });
  }

  private reset() {
    this.hasDate = false;
    this.hasTime = false;
    this.selectedDate = undefined;
  }
}
