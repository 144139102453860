import { Report } from '../../core/models';
import * as ng from 'angular';

/* tslint:disable-next-line:no-empty-interface */
export interface ReportResource
  extends ng.resource.IResourceClass<ng.resource.IResource<Report>> {
  update(report: Report): ng.resource.IResource<Report>;
}

/* @ngInject */
export default function ReportResource(
  $resource: ng.resource.IResourceService
): ReportResource {
  return <ReportResource>$resource<ng.resource.IResource<Report>>(
    '/api/v1/reports/:id',
    { id: '@id' },
    {
      update: {
        method: 'PUT',
        params: {
          id: '@id'
        }
      },
      query: { method: 'GET' }
    }
  );
}
