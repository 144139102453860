import { IFolderViewerAdapter } from './folderViewerAdapter';
import { StorageFile, StorageFolder } from '../../core/models';
import { IFileService } from '../files/file.service';
import orderBy from 'lodash/orderBy';

export class FolderViewerListController {
  static $inject = ['FileService'];

  sortReverse: boolean = false;
  sortBy: string = 'lastModified';
  folders: StorageFolder[];
  files: StorageFile[];
  starred: StorageFile[];
  searchTerm: string;

  constructor(private fileService: IFileService) {}

  get asAdapter(): IFolderViewerAdapter {
    return (<any>this) as IFolderViewerAdapter;
  }

  setSort(field: string): void {
    if (this.sortBy === field) {
      this.sortReverse = !this.sortReverse;
      this.files = this.files.reverse();
      return;
    } else {
      this.sortBy = field;
      if (field === 'lastModified') {
        this.sortReverse = true;
      }
    }
    this.sortFiles(this.sortBy, this.sortReverse);
  }

  downloadFile($event: Event, file: StorageFile): void {
    $event.preventDefault();
    this.asAdapter.onDownload({ file });
  }

  showFile($event: Event, file: StorageFile) {
    $event.preventDefault();
    this.asAdapter.onPreview({ file, showRevisions: false });
  }

  showFileRevisions($event: Event, file: StorageFile) {
    $event.preventDefault();
    if (file.revisions.length <= 1) {
      return;
    }
    this.asAdapter.onPreview({ file, showRevisions: true });
  }

  renameFile($event: Event, file: StorageFile): void {
    $event.preventDefault();
    this.asAdapter.onRename({ file });
  }

  deleteFile($event: Event, file: StorageFile): void {
    $event.preventDefault();
    this.asAdapter.onDelete({ file });
  }

  addStar($event: Event, file: StorageFile): void {
    $event.preventDefault();
    this.asAdapter.onAddStar({ file });
  }

  removeStar($event: Event, file: StorageFile): void {
    $event.preventDefault();
    this.asAdapter.onRemoveStar({ file });
  }

  getThumbnailUrl(file: StorageFile): void {
    return this.fileService.getThumbnailUrl(file, 128, 128);
  }

  private sortFiles(field, reverse) {
    this.files = orderBy(this.files, file => this.getSortValue(file, field));
    if (reverse) {
      this.files = this.files.reverse();
    }
  }

  private getSortValue(file: StorageFile, field) {
    switch (field) {
      case 'lastModified':
        return file.lastModified.valueOf();
      case 'lastModifiedBy':
        return file.lastModifiedBy;
      case 'size':
        return file.size;
      case 'name':
        return file.name;
      default:
        return undefined;
    }
  }
}
