import { ILogService } from './log';
import { IUserStore } from '../../data/stores/user';
import { Guid, PERMISSIONS, PermissionTypes, User } from '../models';
import { DEFAULT_DATE_FORMAT, DEFAULT_TIME_FORMAT } from '../utils';
import { IClaimsWindow } from '../utils/claimsWindow';
import { ITrackingService } from './tracking';

/**
 * Provides access to the currently authenticated user.
 */
export interface ISecurityService {
  loadUser(userId: Guid): ng.IPromise<any>;
  loadPermissions(userId: Guid): ng.IPromise<any>;
  initialize(user: User): void;
  getDateFormat(): string;
  getTimeFormat(): string;
  getDateTimeFormat(): string;

  /**
   * Returns the current user ID.
   * @returns {Guid}
   */
  getCurrentUserId(): Guid;

  /**
   * Returns the current user.
   * @returns {User}
   */
  getCurrentUser(): User;

  /**
   * Returns if the current user has the specified permission.
   * @param {String} permission The permission to check for.
   * @returns {boolean} True if the user has it; else false.
   */
  hasPermission(permission: PermissionTypes): boolean;

  /**
   * Returns if the user is an administrator.
   * @returns {boolean} True if the user is an administrator; else false.
   */
  isAdministrator(): boolean;
}

function dateFormatMap(format) {
  if (!format) {
    return DEFAULT_DATE_FORMAT;
  }
  switch (format.toLowerCase().indexOf('mm/dd/yyyy')) {
    case 0:
      return 'MM/dd/yyyy';
    default:
      return DEFAULT_DATE_FORMAT;
  }
}

export class SecurityService implements ISecurityService {
  static $inject = ['$q', '$window', 'UserStore', 'LogService', 'TrackingService'];

  private currentUser: User;
  private permissions: PermissionTypes[] = [];

  constructor(
    private q: ng.IQService,
    private window: IClaimsWindow,
    private userStore: IUserStore,
    private logService: ILogService,
    private trackingService: ITrackingService
  ) {}

  initialize(user: User): void {
    this.currentUser = user;
    if (!this.currentUser.dateFormat) {
      this.currentUser.dateFormat = dateFormatMap('');
    }
    this.trackingService.setUser(user);
  }

  getDateFormat(): string {
    if (!this.currentUser.dateFormat) {
      return DEFAULT_DATE_FORMAT;
    }
    return dateFormatMap(this.currentUser.dateFormat);
  }

  getTimeFormat(): string {
    return this.currentUser.timeFormat
      ? this.currentUser.timeFormat
      : DEFAULT_TIME_FORMAT;
  }

  getDateTimeFormat(): string {
    return `${this.getDateFormat()} ${this.getTimeFormat()}`;
  }

  getCurrentUserId(): Guid {
    return new Guid(this.getCurrentUser().id);
  }

  getCurrentUser(): User {
    if (!this.currentUser) {
      this.logService.error(
        new Error('Attempting to access currentUser before its set.')
      );
    }
    return this.currentUser;
  }

  hasPermission(permission: PermissionTypes): boolean {
    return (this.permissions || []).indexOf(permission) >= 0;
  }

  isAdministrator(): boolean {
    return this.hasPermission(PERMISSIONS.Administrator);
  }

  loadUser(userId: Guid): ng.IPromise<any> {
    const deferred = this.q.defer();
    this.userStore.get(userId.toString()).then((user: User) => {
      this.initialize(user);
      deferred.resolve({});
    });

    return deferred.promise;
  }

  loadPermissions(userId: Guid): ng.IPromise<any> {
    const deferred = this.q.defer();
    this.userStore
      .permissions(userId.toString())
      .then((permissions: PermissionTypes[]) => {
        this.permissions = permissions || [];
        deferred.resolve({});
      });

    return deferred.promise;
  }
}
