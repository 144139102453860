/* @ngInject */
export default function FieldAddModalController(
  $scope: any,
  $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
  fields: any[],
  config: any
) {
  // we copy to avoid updating th original
  // object in case of cancel
  $scope.fields = fields;

  if (config.fieldType) {
    $scope.fields = fields.filter(x => x.type === config.fieldType);
  }

  $scope.ok = function(field) {
    $uibModalInstance.close(field);
  };

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };
}
