import { IStorageFolderHttpService } from '../../../core/http';
import { Guid, StorageFile } from '../../../core/models';
import { IPopupManager } from '../../../core/utils';
import { IFileService } from '../../files/file.service';
import * as ng from 'angular';

const MAX_FILES: number = 500;

export default class CioFileMultipickerModalController {
  static $inject = [
    '$scope',
    '$uibModalInstance',
    'StorageFolderHttpService',
    'FileService',
    'popupManager',
    'folderId'
  ];

  loading: boolean = false;
  callbackFn: any;
  files: StorageFile[] = [];
  selectedFiles: StorageFile[] = [];

  constructor(
    private scope: any,
    private modalInstance: ng.ui.bootstrap.IModalInstanceService,
    private storageFolderHttpService: IStorageFolderHttpService,
    private fileService: IFileService,
    private popupManager: IPopupManager,
    private folderId: Guid
  ) {}

  $onInit() {
    this.refresh();
  }

  getThumbnailUrl(file: StorageFile): void {
    return this.fileService.getThumbnailUrl(file, 128, 128);
  }

  openCenteredPopup(url: string, title: string, w = 960, h = 700): void {
    this.popupManager.openCenteredPopup(url, title, w, h);
  }

  updateSelection(selected: boolean, file: StorageFile, index): void {
    if (selected) {
      this.selectedFiles.push(file);
    } else {
      this.selectedFiles.splice(index, 1);
    }
  }

  ok(): void {
    this.modalInstance.close(this.selectedFiles);
  }

  cancel(): void {
    this.modalInstance.dismiss('cancel');
  }

  private refresh() {
    this.loading = true;
    this.storageFolderHttpService
      .getFiles(this.folderId, MAX_FILES)
      .safeApply(this.scope, page => {
        this.files = page.items;
        this.loading = false;
      })
      .subscribe();
  }
}
