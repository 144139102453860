import { Guid } from '../../../../core/models';

interface IWorkflowActionUpdateTask {
  complete: boolean;
  emptyTags: string[];
  tagIds: Guid[];
}

export class WorkflowActionUpdateTaskController {
  static $inject = [];
  private _data: IWorkflowActionUpdateTask;

  get data(): IWorkflowActionUpdateTask {
    return this._data;
  }

  set data(item: IWorkflowActionUpdateTask) {
    this._data = item;
    this._data.tagIds = this._data.tagIds || [];
  }
}
