import { IReportService } from './report.service';
import { Page, Report, ReportTypes, SortDirections } from '../../core/models';
import { IDateService, IAlertService, ILogService } from '../../core/services';
import { DateTimeHelper } from '../../core/utils';
import { StateService } from 'angular-ui-router';
import capitalize from 'lodash/capitalize';
import { parse } from 'path';

type ReportColumn = {
  label: string;
  name: string;
  dataType: string;
};

interface IColumnsOfTypeNumber {
  [name: string]: number;
}

export default class ReportViewController {
  static $inject = [
    '$http',
    '$state',
    '$filter',
    'DateService',
    'ReportService',
    'AlertService',
    'LogService'
  ];

  public ready: boolean = false;
  public report: Report;
  public result: Page<any>;
  public totals: number[] = [];
  public currentPage: number;
  public itemsPerPage: number = 100;
  public totalItems: number;
  public tableVisible: boolean = true;
  public columnsOfTypeNumber: IColumnsOfTypeNumber = {};
  public finalValue: IColumnsOfTypeNumber = {};
  public theActualColumn: number = 0;
  public isLastLineAndColumn: boolean = false;
  public i: number = 0;

  constructor(
    private $http: ng.IHttpService,
    private $state: StateService,
    private $filter: ng.IFilterService,
    private dateService: IDateService,
    private reportService: IReportService,
    private alertService: IAlertService,
    private logService: ILogService
  ) {}

  $onInit() {
    this.runReport();
  }

  /**
   * Handles change events on the report.
   *
   * @param {any} changes The component change model.
   */
  $onChanges(changes) {
    if (changes.report) {
      this.runReport();
    }
  }

  /**
   * Runs the current report.
   */
  runReport(): void {
    if (!this.report) {
      return;
    }

    this.$http
      .post<Page<any>>('/api/v1/reports/run', this.report)
      .then(result => this.runReportSuccess(result.data))
      .catch(err => this.runReportError(err));
  }

  runReportSuccess(data: Page<any>) {
    this.totals.length = 0;
    this.totals.push(...this.report.fields.map(_ => 0));

    for (const row of data.items) {
      let colIndex = -1;
      for (const col of this.report.fields) {
        colIndex++;
        if (col.dataType !== 'number' && col.dataType !== 'currency') {
          continue;
        }

        switch (col.dataType) {
          case 'currency':
          case 'number':
            const value = row[col.name];
            if (value === undefined) {
              continue;
            }
            const num = parseFloat(value);
            if (num === NaN) {
              continue;
            }
            this.totals[colIndex] += num;
            break;
          default:
            break;
        }
      }
    }

    this.result = data;
    this.ready = true;
  }

  runReportError(error: any) {
    this.alertService.error('Failed to load results, please try again.');
    this.logService.error(error);
  }

  update(): void {
    // Inform the parent of the changes
    (<any>this).onUpdate({
      $event: {
        report: this.report
      }
    });

    this.runReport();
  }

  viewTableFilters() {
    this.tableVisible = !this.tableVisible;
  }

  /**
   * Sorts the report by the given column.
   *
   * @param {String} columnName The name of the column to sort by.
   */
  sortBy(columnName: string): void {
    if (this.report.sort === columnName) {
      this.report.sortDirection =
        this.report.sortDirection === SortDirections.Ascending
          ? SortDirections.Descending
          : SortDirections.Ascending;
    } else {
      this.report.sort = columnName;
      this.report.sortDirection = SortDirections.Ascending;
    }

    this.update();
  }

  /**
   * Returns the friendly name for the report type.
   *
   * @returns {String} The report type.
   */
  getReportType(): string {
    return this.reportService.getTypeText(this.report.type);
  }

  formatText(text: string) {
    return capitalize(
      text
        .replace('.', ' ')
        .replace('_', ' ')
        .replace(/-/g, ' ')
    );
  }

  formatValueText(field: any) {
    if (field.value !== null && field.value !== '') {
      return field.value;
    }

    if (!field.dateTo) {
      const date = DateTimeHelper.parseUtcDate(field.dateFrom);
      if (date.isValid) {
        return this.dateService.formatUtcDate(date);
      }
      return field.dateTo;
    } else {
      const dateFrom = DateTimeHelper.parseUtcDate(field.dateFrom);
      const dateTo = DateTimeHelper.parseUtcDate(field.dateTo);
      if (dateFrom.isValid && dateTo.isValid) {
        return `${this.dateService.formatUtcDate(
          dateFrom
        )} and ${this.dateService.formatUtcDate(dateTo)}`;
      }
    }
  }

  /**
   * Returns the correct styles to apply to the column based off the data type.
   *
   * @param {String} dataType The data type of the column.
   * @returns {String} The style to apply to the column.
   */
  getColumnStyle(dataType: string): string {
    switch (dataType) {
      case 'number':
      case 'currency':
      case 'date':
      case 'date_time':
        return 'text-right';
      default:
        return 'text-left';
    }
  }

  /**
   * Formats the value based on the column data type and configuration.
   *
   * @param {ReportColumn} column The column the data is being shown in.
   * @param {any} value The value to be formatted.
   * @returns {string} The formatted value.
   */
  formatTotalValue(column: ReportColumn, value: any): string {
    if (column.dataType === 'currency') {
      return this.$filter('currency')(<any>value, '');
    }

    if (column.dataType === 'number') {
      return this.$filter('number')(value);
    }

    return '';
  }

  /**
   * Formats the value based on the column data type and configuration.
   *
   * @param {ReportColumn} column The column the data is being shown in.
   * @param {any} value The value to be formatted.
   * @returns The formatted value.
   */
  formatValue(column: ReportColumn, value: any) {
    if (!column.dataType) {
      return value;
    }

    if (column.dataType === 'currency') {
      return this.$filter('currency')(<any>value, '');
    }

    if (column.dataType === 'number') {
      return this.$filter('number')(value);
    }

    if (column.dataType === 'date') {
      const date = DateTimeHelper.parseUtcDate(value);
      if (date.isValid) {
        return this.dateService.formatUtcDate(date);
      }
      return value;
    }

    if (column.dataType === 'date_time') {
      const date = DateTimeHelper.parseUtcDateTime(value);
      if (date.isValid) {
        return this.dateService.formatLocalDateTime(date);
      }
      return value;
    }

    if (column.dataType === 'boolean') {
      return value ? 'Yes' : 'No';
    }

    if (column.dataType === 'user') {
      return value;
    }

    if (column.dataType === 'user[]' && value instanceof Array) {
      return (value || []).join(', ');
    }

    return value;
  }

  loadClaim(item: any) {
    if (this.report.type === ReportTypes.Claim) {
      this.$state.go('app.claim.edit', { claimId: item.id });
    } else {
      this.$state.go('app.claim.edit', { claimId: item.claimId });
    }
  }

  loadClaimItem(item: any) {
    switch (this.report.type) {
      case ReportTypes.InvoiceSummary:
      case ReportTypes.InvoiceDetail:
        this.$state.go('app.claim.invoices.edit', {
          claimId: item.claimId,
          invoiceId: item.id
        });
        break;

      case ReportTypes.PaymentSummary:
      case ReportTypes.PaymentDetail:
        this.$state.go('app.claim.payments.edit', {
          claimId: item.claimId,
          paymentId: item.id
        });
        break;

      case ReportTypes.Timesheet:
        this.$state.go('app.claim.timesheet', { claimId: item.claimId });
        break;

      default:
        this.$state.go('app.claim.edit', { claimId: item.id });
        break;
    }
  }
}
