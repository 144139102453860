import { Report } from '../../../core/models';
import ReportSaveModalController from './report-save-modal.controller';

/**
 * A service for saving a report.
 */
export interface IReportSaveService {
  /**
   * Saves or updates the specified report.
   *
   * @param {Report} report The report to save.
   * @returns {Promise<Report>} The saved report.
   */
  save(report: Report): ng.IPromise<Report>;
}

export default class ReportSaveService implements IReportSaveService {
  static $inject = ['$uibModal'];

  constructor(private $uibModal: ng.ui.bootstrap.IModalService) {}

  save(report: Report): ng.IPromise<Report> {
    const modalInstance = this.$uibModal.open({
      template: require('./report-save-modal.html'),
      controller: ReportSaveModalController,
      controllerAs: '$ctrl',
      resolve: {
        report: function() {
          return report;
        }
      }
    });

    return <ng.IPromise<Report>>modalInstance.result;
  }
}
