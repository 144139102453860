import { Report } from '../../../core/models';
import { ReportResource } from '../../../data/resources/report';
import { ISecurityService, IAlertService } from '../../../core/services';
import * as ng from 'angular';

export default class ReportSaveModalController {
  static $inject = [
    'report',
    '$uibModalInstance',
    'AlertService',
    'SecurityService',
    'ReportResource'
  ];

  public name: string;
  public shared: boolean;

  constructor(
    public report: Report,
    private modalInstance: ng.ui.bootstrap.IModalServiceInstance,
    private alertService: IAlertService,
    private securityService: ISecurityService,
    private reportResource: ReportResource
  ) {
    this.name = report.name;
    this.shared = !report.userId;
  }

  /**
   * Closes the modal without saving.
   */
  cancel(): void {
    this.modalInstance.dismiss('cancel');
  }

  /**
   * Closes the modal and saves the result of the selected fields.
   */
  save(): void {
    this.report.name = this.name;
    this.report.userId = !this.shared ? this.securityService.getCurrentUser().id : null;

    let action: ng.resource.IResource<Report>;
    if (!this.report.id) {
      action = this.reportResource.save(this.report);
    } else {
      action = this.reportResource.update(this.report);
    }

    action.$promise
      .then(saved => {
        this.modalInstance.close(saved);
      })
      .catch(error => {
        this.alertService.error('Save failed, please try again.');
      });
  }
}
