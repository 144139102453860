import { IClaimsWindow } from '../../core/utils/claimsWindow';
import { IAlertService, ITrackingService } from '../../core/services';
import { IClaimManagerService } from './claim-manager-service';

/* @ngInject */
export default function ClaimFormController(
  $scope: any,
  $window: IClaimsWindow,
  AlertService: IAlertService,
  ClaimManagerService: IClaimManagerService,
  TrackingService: ITrackingService
): void {
  const vm = this;
  vm.save = save;
  vm.model = ClaimManagerService.claim();

  function save() {
    if (!$scope.formEditClaim.$valid) {
      AlertService.error('Please complete all required fields.');
      return;
    }

    return ClaimManagerService.updateClaim().then(
      function() {
        vm.model = ClaimManagerService.claim();
        $scope.formEditClaim.$setPristine();
        logClaimUpdated();
        AlertService.success('Claim updated successfully.');
      },
      function() {
        AlertService.error('Could not update claim, please try again.');
      }
    );
  }

  function logClaimUpdated() {
    try {
      TrackingService.track('Claim Updated', {
        'Claim ID': vm.model.id,
        'Template ID': vm.model.templateId
      });
    } catch {}
  }
}
