/* @ngInject */
export default function CioDropDownService($document): any {
  let openScope = null;

  return {
    open: open,
    close: close,
    closeDropdown: closeDropdown
  };

  function open(dropdownScope) {
    if (!openScope) {
      $document.bind('click', closeDropdown);
      $document.bind('keydown', escapeKeyBind);
    }

    if (openScope && openScope !== dropdownScope) {
      openScope.isOpen = false;
    }

    openScope = dropdownScope;
  }

  function close(dropdownScope) {
    if (openScope === dropdownScope) {
      openScope = null;
      $document.unbind('click', closeDropdown);
      $document.unbind('keydown', escapeKeyBind);
    }
  }

  function closeDropdown(evt) {
    const toggleElement = openScope.getToggleElement();
    if (evt && toggleElement && toggleElement.parent()[0].contains(evt.target)) {
      return;
    }

    openScope.$apply(function() {
      openScope.isOpen = false;
    });
  }

  function escapeKeyBind(evt) {
    if (evt.which === 27) {
      openScope.focusToggleElement();
      closeDropdown(openScope);
    }
  }
}
