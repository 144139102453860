import { ClaimTasksController } from './claimTasks.controller';
import { ComponentBase } from '../../../core/utils/componentBase';

export class ClaimTasksComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      claim: '<'
    };
    this.controller = ClaimTasksController;
    this.template = require('./claimTasks.html');
  }
}
