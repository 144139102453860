import { HttpService } from './http';
import { Page, Tag, Guid } from '../models';
import { logError, QueryParams } from '../utils';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';

export interface ITagHttpService {
  getTag(id: Guid): Observable<Tag>;
  getTags(query: TagSearch): Observable<Page<Tag>>;
  getAllTags(): Observable<Tag[]>;
}

export class TagSearch {
  take: number;
  skip: number = 0;
  ids: Guid[] = [];

  constructor(props?: any) {
    Object.assign(this, props);
    if (props.ids) {
      this.ids = (props.ids || []).map((g: any) => new Guid(g));
    }
  }

  toParams(): string {
    const params = new QueryParams();
    if (this.skip > 0 && !isNaN(this.skip)) {
      params.set('skip', Math.max(0, this.skip || 0).toString());
    }
    params.set('take', Math.max(1, isNaN(this.take) ? 1 : this.take).toString());
    if (this.ids.length) {
      params.set('ids', this.ids.join(','));
    }
    return params.toString();
  }
}

export interface ITagCacheService {
  getTag(id: Guid): Observable<Tag>;
}

export class TagCacheService implements ITagCacheService {
  static $inject = ['TagHttpService', 'rx'];

  tags: Tag[] = [];

  constructor(private tagHttpService: ITagHttpService, private rx: any) {}

  getTag(id: Guid): Observable<Tag> {
    const existing = this.getExisting(id);
    if (existing) {
      return this.rx.Observable.of(existing);
    }

    return this.tagHttpService.getTag(id).do(tag => {
      if (this.getExisting(id)) {
        return;
      }
      this.tags.push(tag);
    });
  }

  private getExisting(id: Guid) {
    return this.tags.find(x => x.id.equals(id));
  }
}

export class TagHttpService implements ITagHttpService {
  static $inject = ['HttpService'];

  private readonly _url: string = '/api/v1/tags';

  constructor(private httpService: HttpService) {}

  getTag(id: Guid): Observable<Tag> {
    return this.httpService
      .get(`${this._url}/${id.toString()}`)
      .map(res => Tag.fromJson(res.json()))
      .catch(logError);
  }

  getTags(query: TagSearch): Observable<Page<Tag>> {
    return this.httpService
      .get(`${this._url}?${query.toParams()}`)
      .map(res => Page.fromJson(res.json(), item => Tag.fromJson(item)))
      .catch(logError);
  }

  getAllTags() {
    return this.httpService
      .get(`${this._url}?${new TagSearch({ take: 1000 }).toParams()}`)
      .map(res => res.json<Page<any>>().items.map(item => Tag.fromJson(item)))
      .catch(logError);
  }
}
