import { IResourceStore, IResourceStoreFactory } from './store';
import { GroupSummary } from '../../core/models';

/* tslint:disable-next-line:no-empty-interface */
export interface IGroupSummaryStore extends IResourceStore<GroupSummary> {}

/* @ngInject */
export default function GroupSummaryStore(
  StoreFactory: IResourceStoreFactory<GroupSummary>
): IGroupSummaryStore {
  return <IGroupSummaryStore>StoreFactory('/api/v1/groups/:id/summary');
}
