import { ClaimTemplate } from '../../core/models';
import * as ng from 'angular';

/* tslint:disable-next-line:no-empty-interface */
export interface ClaimTemplateResource
  extends ng.resource.IResourceClass<ng.resource.IResource<ClaimTemplate>> {
  update(model: ClaimTemplate): ng.resource.IResource<ClaimTemplate>;
}

/* @ngInject */
export default function ClaimTemplateResource(
  $resource: ng.resource.IResourceService
): ClaimTemplateResource {
  return <ClaimTemplateResource>$resource<ng.resource.IResource<ClaimTemplate>>(
    '/api/v1/claim-templates/:id',
    { id: '@id' },
    {
      update: {
        method: 'PUT',
        params: {
          id: '@id'
        }
      },
      query: {
        method: 'GET'
      }
    }
  );
}
