import { WorkflowFilterStatusController } from './workflowFilterStatus.controller';
import { ComponentBase } from '../../../../core/utils/componentBase';

export class WorkflowFilterStatusComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      filter: '='
    };
    this.controller = WorkflowFilterStatusController;
    this.template = require('./workflowFilterStatus.html');
  }
}
