import { Tag, Guid } from '../../../core/models';

export class TagListController {
  static $inject = [];

  _ids: Guid[] = [];
  tags: Tag[];

  get ids(): Guid[] {
    return this._ids;
  }

  set ids(ids: Guid[]) {
    this._ids = (ids || []).map(t => new Guid(t));
  }
}
