import { RecordTypes } from './recordTypes';
import { Guid } from './guid';
import { IRecordReference } from './recordReference';

export class RecordAssociation implements IRecordReference {
  id: Guid;
  type: RecordTypes;
  title: string;

  constructor(type?: RecordTypes, id?: string | Guid, title?: string) {
    this.id = !!id && new Guid(id);
    this.type = type;
    this.title = title;
  }

  static fromJson(json: any): RecordAssociation {
    const association = Object.assign(new RecordAssociation(), json);
    association.id = json.id && new Guid(json.id);
    association.type = (association.type || '').toLowerCase();
    return association;
  }

  toString(): string {
    return `${this.type}/${this.id.toString()}`;
  }
}
