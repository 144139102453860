import { IDocumentTemplateSelection } from './document.interfaces';
import { DocumentSelectModalController } from './documentSelectModal.controller';
import { ITemplateRequirementValue } from '../../core/models';
import * as ng from 'angular';

export interface IDocumentService {
  select(values: ITemplateRequirementValue[]): ng.IPromise<IDocumentTemplateSelection>;
}

export class DocumentService implements IDocumentService {
  static $inject = ['$uibModal'];

  constructor(private uibModal: ng.ui.bootstrap.IModalService) {}

  select(values: ITemplateRequirementValue[]): ng.IPromise<IDocumentTemplateSelection> {
    return this.uibModal.open({
      template: require('./documentSelectModal.html'),
      controllerAs: '$ctrl',
      controller: DocumentSelectModalController,
      resolve: {
        values() {
          return values;
        }
      }
    }).result;
  }
}
