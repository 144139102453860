import { EntityResource } from '../../../data/resources/entity';
import { IEntityService } from 'app/instance/entities/entity.service';
import { Guid, Entity } from 'app/core/models';
import ng from 'angular';

export class EntityFieldController {
  static $inject = ['$uibModal', 'EntityResource', 'EntityService'];

  public field: any;
  public sections: any[];
  public templateFields: any[];
  public definition: any;

  constructor(
    private modalService: ng.ui.bootstrap.IModalService,
    private entityResource: EntityResource,
    private entityService: IEntityService
  ) {}

  openEntityEditModal(entityId: string): void {
    this.entityService.editEntity(new Guid(entityId));
  }

  openEntityModal(entityTemplateId: string, isMultiple: any): void {
    this.entityService
      .selectEntity([new Guid(entityTemplateId)], true)
      .then(selectedId => {
        if (!selectedId) {
          return;
        }

        return this.entityResource
          .get({ id: selectedId.toString() })
          .$promise.then((entity: Entity) => {
            if (!entity) {
              return;
            }
            this.field.value = { id: entity.id, name: entity.title || '(no title)' };
          });
      })
      .catch(() => {});
  }

  removeEntity(entityId: string): void {
    delete this.field.value;
  }
}
