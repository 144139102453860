import { IAlertService } from '../../../core/services/alert';
import { IFieldStore } from '../../../data/stores/field';
import { ICacheService } from '../../services/cache';
import { fieldTypeList } from './fieldTypeList';
import { StateService } from 'angular-ui-router';
import { FieldDefinition } from 'app/core/models';
import ng from 'angular';

/* @ngInject */
export default function FieldNewController(
  $scope: any,
  $filter: ng.IFilterService,
  $state: StateService,
  Cache: ICacheService,
  FieldStore: IFieldStore,
  AlertService: IAlertService
): void {
  $scope.model = {};
  $scope.newForm = {};
  $scope.newForm.active = false;
  $scope.fieldTypes = fieldTypeList;
  $scope.generateName = generateName;

  function generateName() {
    $scope.model.name = ($scope.model.label || '').toLowerCase();
    $scope.nameChange();
  }

  $scope.nameChange = function() {
    // The reason why ngPattern is not used on the input, is
    // because it prevents model to be updated

    if ($scope.model.name) {
      // lowercase
      let str = $filter('lowercase')($scope.model.name);

      // Replace invalid chars, valid pattern: ^[a-z]+[a-z0-9_]*[a-z]+$
      str = str.replace(/^([0-9_])/, '');
      str = str.replace(/([^a-z0-9_]+)/gi, '_');

      $scope.model.name = str;
    }
  };

  $scope.backToList = function() {
    $state.go($state.current.data.listState);
  };

  $scope.saveModel = function(model: FieldDefinition) {
    model.scope = $state.current.data.fieldScope;
    FieldStore.save(model)
      .then(function(updated: any) {
        AlertService.success('New field ' + updated.name + ' has been created.');
        $scope.newForm.active = false;
        $scope.newForm.$setPristine();

        // invalidates cache
        Cache.fields(true);
        Cache.claimTemplates(true);
        Cache.entityTemplates(true);

        $state.go($state.current.data.editState, { id: updated.id });
      })
      .catch(function(error) {
        $scope.newForm.active = false;

        if (error.data && error.data.message) {
          AlertService.error(error.data.message);
        } else {
          AlertService.error('Could not create field, please try again.');
        }
      });
  };
}
