import { StorageFile } from './storageFile';
import { TemplateRequirement } from './templateRequirement';

export class DocumentTemplate extends StorageFile {
  static fromJson(json) {
    const file = StorageFile.fromJson(json);
    file.properties.requirements = (file.properties.requirements || []).map(x =>
      TemplateRequirement.fromJson(x)
    );
    return file;
  }
}
