import { IStorageFileHttpService } from '../../../core/http';
import {
  Guid,
  StorageFile,
  TemplateRequirement,
  TemplateRequirementTypes,
  EntityTemplate
} from '../../../core/models';
import { IAlertService } from '../../../core/services';
import { continueWith, FileHelper } from '../../../core/utils';
import { IFileService } from '../../files/file.service';
import { IFileUploadService } from '../../folders/fileUpload.service';
import { StateService } from 'angular-ui-router';
import { IDocumentRequirementService } from './documentRequirement.service';
import { ICacheService } from '../../services/cache';
import 'rxjs/add/operator/finally';

export class DocumentEditController {
  static $inject = [
    '$scope',
    '$state',
    'AlertService',
    'StorageFileHttpService',
    'FileUploadService',
    'FileService',
    'DocumentRequirementService',
    'Cache'
  ];

  file?: File;
  model: StorageFile = new StorageFile();
  requirements: TemplateRequirement[] = [];
  isBusy: boolean = false;
  private _entityTemplates: EntityTemplate[] = [];

  constructor(
    private scope: any,
    private state: StateService,
    private alertService: IAlertService,
    private storageFileHttpService: IStorageFileHttpService,
    private fileUploadService: IFileUploadService,
    private fileService: IFileService,
    private documentRequirementService: IDocumentRequirementService,
    private cacheService: ICacheService
  ) {}

  $onInit() {
    this.loadDocument();
    this.loadTemplates();
  }

  fileChange(file): void {
    this.file = file;
    this.fileChanged(this.file);
  }

  modifyDocument(): void {
    this.setIsBusy();
    this.model.properties.requirements = this.requirements;
    (!!this.file
      ? this.fileUploadService.uploadFileRevision(this.model, this.file)
      : this.storageFileHttpService.modifyFile(this.model)
    )
      .finally(() => this.setNotBusy())
      .catch(continueWith(() => this.onModifyError))
      .safeApply(this.scope, storageFile => (this.model = storageFile))
      .do(f => this.alertService.success('Document updated.'))
      .do(f => this.gotoDocument())
      .subscribe();
  }

  cleanFileName(): void {
    this.model.name = FileHelper.sanitizeFileName(this.model.name);
  }

  openDocument(): void {
    this.fileService.downloadFile(this.model);
  }

  deleteDocument(): void {
    this.setIsBusy();
    this.storageFileHttpService
      .deleteFile(this.model.id)
      .finally(() => this.setNotBusy())
      .catch(continueWith(() => this.onDeleteError))
      .do(() => this.alertService.success('Document deleted.'))
      .do(() => this.gotoDocumentsList())
      .subscribe();
  }

  addRequirement() {
    this.documentRequirementService
      .create()
      .then(created => {
        if (!created) {
          return;
        }
        if (this.requirements.some(x => x.field === created.field)) {
          this.alertService.warn(`Field name '${created.field}' already used.`);
          this.editRequirement(created);
          return;
        }
        this.requirements.push(created);
      })
      .catch(() => {});
  }

  editRequirement(requirement: TemplateRequirement) {
    this.documentRequirementService
      .edit(requirement)
      .then(updated => {
        if (!updated) {
          return;
        }
        if (
          this.requirements.some(
            x => x.field === updated.field && !x.id.equals(requirement.id)
          )
        ) {
          this.alertService.warn(`Field '${updated.field}' already used.`);
          this.editRequirement(updated);
          return;
        }
        this.requirements = this.requirements
          .filter(x => !x.id.equals(requirement.id))
          .concat(updated);
      })
      .catch(() => {});
  }

  removeRequirement(requirement: TemplateRequirement) {
    this.requirements = this.requirements.filter(x => !x.id.equals(requirement.id));
  }

  getRequirementType(requirement: TemplateRequirement) {
    switch (requirement.type) {
      case TemplateRequirementTypes.Claim:
        return 'Claim';
      case TemplateRequirementTypes.Entity:
        const template =
          requirement.templateId &&
          this._entityTemplates.find(x => requirement.templateId.equals(x.id));
        return template ? template.name : 'Entity';
      case TemplateRequirementTypes.Contact:
        return 'Contact';
      case TemplateRequirementTypes.Invoice:
        return 'Invoice';
      case TemplateRequirementTypes.Payment:
        return 'Payment';
      default:
        return 'Unknown';
    }
  }

  private loadDocument(): void {
    this.setIsBusy();
    this.storageFileHttpService
      .getFile(new Guid(this.state.params.id))
      .finally(() => this.setNotBusy())
      .safeApply(this.scope, storageFile => {
        this.model = storageFile;
        this.requirements = (storageFile.properties.requirements || []).map(x =>
          TemplateRequirement.fromJson(x)
        );
      })
      .catch(continueWith(() => this.onLoadError))
      .subscribe();
  }

  private onDeleteError(error: any): void {
    this.alertService.error('Could not delete document, please try again.');
  }

  private onModifyError(error: any): void {
    this.alertService.error('Could not update document, please try again.');
  }

  private onLoadError(error: any): void {
    this.alertService.error('Could not load document.');
    this.gotoDocumentsList();
  }

  private gotoDocument(): void {
    this.state.go('app.document.edit', { id: this.model.id.toString() });
  }

  private gotoDocumentsList(): void {
    this.state.go('app.documents');
  }

  private setIsBusy(): void {
    this.isBusy = true;
  }

  private setNotBusy(): void {
    this.isBusy = false;
  }

  private fileChanged(file: File): void {
    if (!file) {
      return;
    }

    let fpath = file.name;
    fpath = fpath.replace(/\\/g, '/');

    const fileExtension = fpath.substring(fpath.lastIndexOf('.') + 1);
    const mimeType = file.type;
    if (
      fileExtension !== 'doc' &&
      fileExtension !== 'docx' &&
      mimeType !== 'application/msword' &&
      mimeType !==
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
    ) {
      this.alertService.error('Only .doc and .docx are allowed');
      return;
    }

    if (!this.model.name || this.model.name.length === 0) {
      this.model.name = fpath.substring(fpath.lastIndexOf('/') + 1);
    }

    if (!this.model.title || this.model.title.length === 0) {
      this.model.title = fpath.substring(
        fpath.lastIndexOf('/') + 1,
        fpath.lastIndexOf('.')
      );
    }
  }

  private loadTemplates() {
    this.cacheService
      .entityTemplates()
      .then((entityTemplates: EntityTemplate[]) => {
        this._entityTemplates = entityTemplates;
      })
      .catch(err => console.log(err));
  }
}
