import { IResourceStore, IResourceStoreFactory } from './store';
import { FieldDefinition } from '../../core/models';
import * as ng from 'angular';

/* tslint:disable-next-line:no-empty-interface */
export interface IFieldStore extends IResourceStore<FieldDefinition> {
  allFieldsPromise(): Promise<FieldDefinition[]>;
}

/* @ngInject */
export default function FieldStore(
  StoreFactory: IResourceStoreFactory<FieldDefinition>,
  $q: ng.IQService
): IFieldStore {
  const store = StoreFactory('/api/v1/fields/:id', { id: '@id' });

  store.withStoreExtension('allFieldsPromise', function() {
    const deferred = $q.defer();

    this.$http.get('/api/v1/fields/all').then(function(result: any) {
      deferred.resolve(result.data);
    });

    return deferred.promise;
  });

  return <IFieldStore>store;
}
