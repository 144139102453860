import { IStorageFileHttpService } from '../../core/http/storageFile';
import { IStorageFolderHttpService } from '../../core/http/storageFolder';
import { Guid, StorageFile, StorageFolder } from '../../core/models';
import { IMessagingService } from '../../core/services';
import { FileHelper } from '../../core/utils/fileHelper';
import { Observable } from 'rxjs/Observable';
import { UploadCompleteMessage } from '../../core/messages/uploadComplete';

const MAX_PERCENT: number = 100;

export class FileUpload {
  progress: number = 0;

  constructor(
    readonly name: string,
    readonly type: string,
    readonly id: Guid = Guid.newGuid()
  ) {}

  get isDone(): boolean {
    return this.progress === MAX_PERCENT;
  }

  equals(upload: FileUpload): boolean {
    return this.id.equals(upload.id);
  }
}

export interface IFileUploadService {
  queue: FileUpload[];
  uploadToFolder(folder: StorageFolder, file: File): Observable<StorageFile>;
  uploadFile(storageFile: StorageFile, file: File): Observable<StorageFile>;
  uploadFileRevision(storageFile: StorageFile, file: File): Observable<StorageFile>;
}

export class FileUploadService implements IFileUploadService {
  static $inject = [
    '$rootScope',
    'StorageFileHttpService',
    'StorageFolderHttpService',
    'MessagingService'
  ];

  queue: FileUpload[] = [];

  constructor(
    private rootScope: any,
    private storageFileHttpService: IStorageFileHttpService,
    private storageFolderHttpService: IStorageFolderHttpService,
    private messagingService: IMessagingService
  ) {}

  uploadFile(storageFile: StorageFile, file: File): Observable<StorageFile> {
    return this.storageFolderHttpService
      .getFolder(storageFile.folder)
      .switchMap(folder => this.upload(folder, storageFile, file, false));
  }

  uploadFileRevision(storageFile: StorageFile, file: File): Observable<StorageFile> {
    return this.storageFolderHttpService
      .getFolder(storageFile.folder)
      .switchMap(folder => this.upload(folder, storageFile, file, true));
  }

  uploadToFolder(folder: StorageFolder, file: File): Observable<StorageFile> {
    const storageFile = Object.assign(new StorageFile(), {
      id: Guid.newGuid(),
      name: FileHelper.sanitizeFileName(file.name),
      mimeType: file.type,
      size: file.size,
      folder: folder.id
    });

    return this.upload(folder, storageFile, file, false);
  }

  private upload(
    folder: StorageFolder,
    storageFile: StorageFile,
    file: File,
    isRevision: boolean
  ): Observable<StorageFile> {
    if (!this.canWriteToFolder(folder)) {
      return;
    }

    storageFile.size = file.size;
    storageFile.mimeType = file.type;

    const upload = new FileUpload(storageFile.name, storageFile.mimeType, storageFile.id);
    this.queue.push(upload);

    if (isRevision) {
      return this.storageFileHttpService
        .uploadFileRevision(storageFile, file, progress => (upload.progress = progress))
        .safeApply(this.rootScope, () => this.removeItem(upload))
        .safeApply(this.rootScope, () =>
          this.messagingService.publish(new UploadCompleteMessage(folder, storageFile))
        );
    }

    return this.storageFileHttpService
      .uploadFile(storageFile, file, progress => (upload.progress = progress))
      .safeApply(this.rootScope, () => this.removeItem(upload))
      .safeApply(this.rootScope, () =>
        this.messagingService.publish(new UploadCompleteMessage(folder, storageFile))
      );
  }

  private canWriteToFolder(folder: StorageFolder): boolean {
    // TODO: Add permission checks to folder
    return true;
  }

  private removeItem(upload: FileUpload): void {
    this.queue = this.queue.filter(item => !item.equals(upload));
  }
}
