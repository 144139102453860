import { ISecurityService } from '../../core/services/security';
import { DateTime } from 'luxon';

/**
 * @desc - Shows a thumbnail caption on hover.
 * @example <input type="text" ng-model="field.value" date-parser="{{dateFormat}}"/>
 */
/* @ngInject */
export default function dateParser(SecurityService: ISecurityService): any {
  return {
    link: link,
    restrict: 'A',
    require: 'ngModel'
  };

  function link(scope, element, attrs, ngModel) {
    // alternative do accept days and months with a single digit

    // CHECK: Single and double digit dates are handled correctly

    const dateFormat =
      attrs.dateParser && attrs.dateParser.length
        ? attrs.dateParser
        : SecurityService.getDateFormat();

    // ngModel will not detect programattic changes to the
    // underlying model, in these cases we should watch the model
    // and update validity where a valid date is set

    const watcher = scope.$watch(attrs.ngModel, (newValue, oldValue) => {
      if (newValue && !ngModel.$valid && isDate(newValue)) {
        ngModel.$setValidity('date', DateTime.fromJSDate(newValue).isValid);
      }
    });

    // Clean up the watcher on tear down
    scope.$on('$destroy', () => watcher());

    // use push to make sure our parser will be the last to run
    ngModel.$formatters.push(formatter);
    ngModel.$parsers.push(parser);

    function parser(viewValue) {
      // value that none of the parsers touched
      const value = ngModel.$viewValue;
      if (value) {
        const date = DateTime.fromFormat(value, dateFormat);
        ngModel.$setValidity('date', date.isValid);

        return date.isValid ? date : value;
      }
      return value;
    }

    function formatter(value) {
      if (!value) {
        return value;
      }

      // If the value is a date in string format (ISO most likely) then we
      // need to provide the format options, otherwise we can just pass in
      // the date

      let date: DateTime;
      if (typeof value === 'string') {
        date = DateTime.fromISO(value).toLocal();
      } else if (isDate(value)) {
        date = DateTime.fromJSDate(value).toLocal();
      }

      return date.isValid ? date.toFormat(dateFormat) : value;
    }
  }
}

function isDate(value: any) {
  return value instanceof Date;
}
