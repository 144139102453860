import { IDateService } from '../../../core/services';
import { DateTimeHelper } from '../../../core/utils';
import { DateTime } from 'luxon';

export class DateViewController {
  static $inject = ['DateService'];

  title: string;
  text: string;
  private _date: DateTime;

  constructor(private dateService: IDateService) {}

  get date(): DateTime | undefined {
    return this._date;
  }

  set date(value: DateTime | undefined) {
    this._date = DateTimeHelper.parseUtcDate(value);
    this.setText();
  }

  private setText() {
    if (!this._date) {
      this.text = '';
      this.title = '';
      return;
    }
    this.title = this._date.toLocal().toFormat('DDDD');
    this.text = this.dateService.formatUtcDate(this._date);
  }
}
