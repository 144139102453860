import { StorageFile } from '../../core/models';

export interface IFolderNavigator {
  index: number;
  current: StorageFile | undefined;
  hasNext: boolean;
  hasPrevious: boolean;
  previous(): StorageFile | undefined;
  next(): StorageFile | undefined;
  getFiles(): StorageFile[];
}

export class FolderNavigator implements IFolderNavigator {
  private _current: StorageFile;

  constructor(
    private files: StorageFile[],
    current: StorageFile,
    private callback?: (current: StorageFile) => void
  ) {
    files = files || [];
    this._current = current;
  }

  get index(): number {
    // Due to the fact that the array can change at any point
    // like after an upload, we need to check the index on demand
    let counter = 0;
    for (const f of this.files) {
      if (f.id.equals(this._current.id)) {
        return counter;
      }
      counter += 1;
    }
    return 0;
  }

  get current(): StorageFile {
    return this._current;
  }

  get hasNext(): boolean {
    return this.index < this.files.length - 1;
  }

  get hasPrevious(): boolean {
    return this.index > 0;
  }

  getFiles(): StorageFile[] {
    return this.files;
  }

  previous(): StorageFile {
    this._current = this.files[this.index - 1];
    this.raiseCallback();
    return this._current;
  }

  next(): StorageFile {
    const newIndex = Math.max(0, Math.min(this.files.length - 1, this.index + 1));
    this._current = this.files[newIndex];
    this.raiseCallback();
    return this._current;
  }

  private raiseCallback() {
    if (!this.callback) {
      return;
    }
    this.callback(this._current);
  }
}
