import { WorkflowFilterBooleanController } from './workflowFilterBoolean.controller';
import { ComponentBase } from '../../../../core/utils/componentBase';

export class WorkflowFilterBooleanComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      filter: '='
    };
    this.controller = WorkflowFilterBooleanController;
    this.template = require('./workflowFilterBoolean.html');
  }
}
