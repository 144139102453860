import { StateService } from 'angular-ui-router';
import { ISecurityService } from '../../core/services/security';

/* @ngInject */
export function ProfileController(
  $state: StateService,
  SecurityService: ISecurityService
): void {
  $state.go('app.user.edit', { id: SecurityService.getCurrentUser().id });
}
