import { WorkflowFilterEntityController } from './workflowFilterEntity.controller';
import { ComponentBase } from '../../../../core/utils/componentBase';

export class WorkflowFilterEntityComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = { filter: '=', field: '<' };
    this.controller = WorkflowFilterEntityController;
    this.template = require('./workflowFilterEntity.html');
  }
}
