import { ClaimSummary } from '../../core/models';
import * as ng from 'angular';

/* tslint:disable-next-line:no-empty-interface */
export interface ClaimSummaryResource
  extends ng.resource.IResourceClass<ng.resource.IResource<ClaimSummary>> {}

/* @ngInject */
export default function ClaimSummaryResource(
  $resource: ng.resource.IResourceService
): ClaimSummaryResource {
  return <ClaimSummaryResource>(
    $resource<ng.resource.IResource<ClaimSummary>>(
      '/api/v1/claims/:id/summary',
      { id: '@id' },
      {}
    )
  );
}
