import { ComponentBase } from '../../core/utils/componentBase';
import { EntityClaimsController } from './entityClaims.controller';

export class EntityClaimsComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      entityId: '<'
    };
    this.controller = EntityClaimsController;
    this.template = require('./entityClaims.html');
  }
}
