import ChecklistModel from './checklist';
import AddButtonDirective from './cio-add-button/cio-add-button';
import CioApplicationDirective from './cio-application/cio-application';
import CioApplicationController from './cio-application/cio-application-controller';
import CioAvatarTopbar from './cio-avatar-topbar/cio-avatar-topbar';
import CioDropdownToggle from './cio-dropdown-toggle/cio-dropdown-toggle';
import CioDropdown from './cio-dropdown/cio-dropdown';
import CioDropDownController from './cio-dropdown/cio-dropdown-controller';
import CioDropDownService from './cio-dropdown/cio-dropdown-service';
import CioEntityListSelect from './entity-select-list';
import CioErrorDirective from './cio-error/cio-error';
import CioImage from './cio-image/cio-image';
import CioLoginDirective from './cio-login/cio-login';
import CioLoginController from './cio-login/cio-login-controller';
import CioLogout from './cio-logout/cio-logout';
import CioLogoutController from './cio-logout/cio-logout-controller';
import CioSearchFilterComponent from './cio-search-filter/cio-search-filter';
import CioUserComponent from './cio-user/cio-user';
import CioUserController from './cio-user/cio-user-controller';
import CioBars from './cioBars';
import CioHistogram from './cioHistogram';
import CioPermission from './cioPermission';
import { TrixDirective } from './cio-trix/trix.directive';
import CioPie from './cioPie';
import CioClaimStatus from './claim-status';
import CioColorPicker from './color-picker';
import CioConfirm from './confirm';
import CioCreateClaim from './create-claim';
import DateParser from './date-parser';
import CioEntityDisplay from './entity-display';
import { FileChangeDirective } from './fileChange/fileChange.directive';
import googleplace from './googleplace';
import CioProtectedForm from './protected-form';
import { ResizeDirective } from './resize/resize.directive';
import { ResizeProvider } from './resize/resize.provider';
import CioSumInsuredCategoryDisplay from './sumInsuredCategoryDisplay';
import { TemplateLoaderDirectiveFactory } from './templateLoader/templateLoader.factory';
import { CoreModule } from '../../core/module';
import { DataModule } from '../../data/module';
import { UIModule } from '../../ui/module';
import { CioTags, CioTagsSelect } from './cioTags';
import angular from 'angular';

export const DirectivesModule = angular
  .module('app.instance.directives', [DataModule.name, CoreModule.name, UIModule.name])

  // Services
  .service('CioDropDownService', CioDropDownService)

  // Components
  .component('cioUser', new CioUserComponent())
  .component('cioImage', new CioImage())
  .component('cioSearchFilter', new CioSearchFilterComponent())

  // Directives
  .directive('checklistModel', ChecklistModel)
  .directive('cioAddButton', AddButtonDirective)
  .directive('cioApplication', CioApplicationDirective)
  .directive('cioAvatarTopbar', CioAvatarTopbar)
  .directive('cioBars', CioBars)
  .directive('cioClaimStatus', CioClaimStatus)
  .directive('cioColorPicker', CioColorPicker)
  .directive('cioConfirm', CioConfirm)
  .directive('cioCreateClaim', CioCreateClaim)
  .directive('cioDropdown', CioDropdown)
  .directive('cioDropdownToggle', CioDropdownToggle)
  .directive('cioEntityDisplay', CioEntityDisplay)
  .directive('cioEntityListSelect', CioEntityListSelect)
  .directive('cioError', CioErrorDirective)
  .directive('cioFileChange', FileChangeDirective)
  .directive('cioHistogram', CioHistogram)
  .directive('cioLogin', CioLoginDirective)
  .directive('cioLogout', CioLogout)
  .directive('cioPermission', CioPermission)
  .directive('cioPie', CioPie)
  .directive('cioResize', ResizeDirective)
  .directive('cioSumInsuredCategoryDisplay', CioSumInsuredCategoryDisplay)
  .directive('cioTemplateLoader', TemplateLoaderDirectiveFactory())
  .directive('dateParser', DateParser)
  .directive('googleplace', googleplace)
  .directive('cioProtectedForm', CioProtectedForm)
  .directive('cioTags', CioTags)
  .directive('cioTagsSelect', CioTagsSelect)
  .directive('cioTrix', TrixDirective)

  // Controllers
  .controller('cioApplicationController', CioApplicationController)
  .controller('cioLoginController', CioLoginController)
  .controller('CioDropDownController', CioDropDownController)
  .controller('cioLogoutController', CioLogoutController)
  .controller('CioUserController', CioUserController)

  // Providers
  .provider('resize', ResizeProvider);
