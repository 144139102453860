import { IResourceStore, IResourceStoreFactory } from './store';
import { Group } from '../../core/models';

/* tslint:disable-next-line:no-empty-interface */
export interface IGroupStore extends IResourceStore<Group> {
  getMembers(): any;
}

/* @ngInject */
export default function GroupStore(
  StoreFactory: IResourceStoreFactory<Group>
): IGroupStore {
  const store = StoreFactory('/api/v1/groups/:id', { id: '@id' });

  store.withExtension('getMembers', function() {
    return StoreFactory('/api/v1/groups/:id/members', { id: this.id }).query();
  });

  return <IGroupStore>store;
}
