import { StateService } from 'angular-ui-router';
import { IUserStore } from '../../../data/stores/user';

/* @ngInject */
export default function UserListController(
  $scope: any,
  $state: StateService,
  UserStore: IUserStore
): void {
  $scope.users = [];

  UserStore.query().then(function(result) {
    $scope.users = result.items;
  });

  $scope.viewUser = function(user) {
    $state.go('app.user.edit', { id: user.id });
  };

  $scope.newUser = function() {
    $state.go('app.user.new');
  };
}
