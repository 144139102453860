import './entity-template-service';
import { IAlertService } from '../../../core/services/alert';
import { EntityTemplateResource } from '../../../data/resources/entityTemplate';
import reject from 'lodash/reject';

/* tslint:disable:no-shadowed-variable */
/* @ngInject */
export default function EntityTypeEditController(
  $scope: any,
  Cache,
  EntityTemplateManagerService,
  EntityTemplateResource: EntityTemplateResource,
  AlertService: IAlertService
): void {
  /* tslint:enable:no-shadowed-variable */

  const vm = this;
  const fields = EntityTemplateManagerService.fields();

  vm.model = EntityTemplateManagerService.template();
  vm.section = null;
  vm.field = null;
  vm.formActive = false;
  vm.addSection = addSection;
  vm.removeSection = removeSection;
  vm.editSection = editSection;
  vm.addField = addField;
  vm.saveModel = saveModel;
  vm.editField = editField;
  vm.removeField = removeField;
  vm.kinds = [
    { id: 'Entity', name: 'Person / Company' },
    { id: 'Record', name: 'Record' },
    { id: 'Policy', name: 'Policy' }
  ];

  init();

  function init() {
    vm.model.sections = vm.model.sections || [];
    vm.model.sections.forEach(section => {
      const foundFields = [];
      section.fields = section.fields || [];
      section.fields.forEach(fd => {
        const field = fields.find(x => x.id === fd.fieldId);
        if (field) {
          fd.name = field.name;
          fd.label = field.label;
          fd.type = field.type;
          fd.tableDisplay = field.tableDisplay;

          foundFields.push(fd);
        }
      });

      section.fields = foundFields;
    });
  }

  function addSection() {
    vm.model.sections.push({ name: 'New Section', fields: [] });
  }

  function removeSection(section) {
    vm.model.sections = reject(vm.model.sections, { name: section.name });
    vm.section = null;
    vm.field = null;
    $scope.$apply();
  }

  function editSection(section) {
    vm.section = section;
    vm.field = null;
  }

  function addField(section) {
    EntityTemplateManagerService.showModal().result.then(function(selectedField) {
      const hasField = section.fields.find(x => x.fieldId === selectedField.id);
      if (hasField) {
        return;
      }

      if (typeof section.fields === 'undefined') {
        section.fields = [];
      }

      section.fields.push({
        fieldId: selectedField.id,
        isRequired: selectedField.isRequired,
        name: selectedField.name,
        label: selectedField.label,
        type: selectedField.type,
        tableDisplay: selectedField.tableDisplay
      });

      // If we are adding the first field, then lets assume that this
      // is the title field and update the title template

      let totalFields = 0;
      for (let i = 0; i < vm.model.sections.length; i++) {
        totalFields += (vm.model.sections[i].fields || []).length;
      }

      if (isEmptyTitleTemplate(vm.model.titleTemplate) || totalFields === 1) {
        vm.model.titleTemplate = `{{${selectedField.name}}}`;
      }
    });
  }

  function editField(section, field) {
    vm.section = section;
    vm.field = field;
  }

  function removeField(section, fieldId) {
    section.fields = reject(section.fields, x => x.fieldId === fieldId);
    vm.section = null;
    vm.field = null;
    $scope.$apply();
  }

  function saveModel() {
    vm.formActive = true;

    if (isEmptyTitleTemplate(vm.model.titleTemplate)) {
      alert(
        "Please set the 'Title' to the default field that the entity should be referred to by"
      );

      vm.formActive = false;
      return;
    }

    EntityTemplateResource.update(vm.model)
      .$promise.then(function(updated) {
        AlertService.success('Entity template ' + updated.name + ' has been updated.');
        vm.formActive = false;
        Cache.entityTemplates(true);
        $scope.newForm.$setPristine();
      })
      .catch(function(error) {
        AlertService.error('Could not update entity template, please try again.');
        vm.formActive = false;
      });
  }

  /**
   * Checks if the title template is empty or set to the default
   * placeholder (needs to be changed before save).
   */
  function isEmptyTitleTemplate(titleTemplate: string): boolean {
    return /^\s*$/i.test(titleTemplate) || titleTemplate === '{{not-set}}';
  }
}
