export interface IPopupManager {
  openCenteredPopup(url: string, title: string, w?: number, h?: number): void;
}

/**
 * The "top" & "left" properties are not standard properties.
 *
 * See: https://developer.mozilla.org/en-US/docs/Web/API/Screen
 */
interface ScreenUnsupported extends Screen {
  left: number;
  top: number;
}

/* @ngInject */
export default class PopupManager implements IPopupManager {
  constructor() {}

  openCenteredPopup(url: string, title: string, w = 960, h = 700): void {
    const dualScreenLeft: number =
      window.screenLeft !== undefined
        ? window.screenLeft
        : (<ScreenUnsupported>window.screen).left;
    const dualScreenTop: number =
      window.screenTop !== undefined
        ? window.screenTop
        : (<ScreenUnsupported>window.screen).top;
    const width: number = window.innerWidth
      ? window.innerWidth
      : document.documentElement.clientWidth
      ? document.documentElement.clientWidth
      : screen.width;
    const height: number = window.innerHeight
      ? window.innerHeight
      : document.documentElement.clientHeight
      ? document.documentElement.clientHeight
      : screen.height;
    const left: number = width / 2 - w / 2 + dualScreenLeft;
    const top: number = height / 2 - h / 2 + dualScreenTop;
    const newWindow: Window = window.open(
      url,
      title,
      `toolbar=no, location=no, directories=no, status=no, menubar=no, scrollbars=yes, width=${w}, height=${h}, top=${top}, left=${left}`
    );

    if (!newWindow)
      alert(
        'Popup blocker is enabled. In order to use this site properly, please add "claims.io" to your exception\'s list.'
      );
    else {
      ready(() => {
        setTimeout(function() {
          if (newWindow.screenX === 0) {
            alert(
              'Popup blocker is enabled. In order to use this site properly, please add "claims.io" to your exception list.'
            );
          } else if (window.focus) {
            newWindow.focus();
          }
        }, 0);
      });
    }
  }
}

function ready(fn) {
  if (
    (document as any).attachEvent
      ? document.readyState === 'complete'
      : document.readyState !== 'loading'
  ) {
    fn();
  } else {
    document.addEventListener('DOMContentLoaded', fn);
  }
}
