import { FileModalController } from './fileModal.controller';
import { StorageFile, StorageFileRevision } from '../../core/models';
import { IFolderNavigator } from '../folders/folder.navigator';

export interface IFileModalService {
  show(
    file: StorageFile,
    revision: StorageFileRevision,
    showRevisions: boolean,
    navigator?: IFolderNavigator
  ): ng.ui.bootstrap.IModalServiceInstance;
}

export class FileModalService implements IFileModalService {
  static $inject = ['$uibModal'];

  constructor(private uibModal: ng.ui.bootstrap.IModalService) {}

  show(
    file: StorageFile,
    revision: StorageFileRevision,
    showRevisions: boolean = false,
    navigator: IFolderNavigator = undefined
  ): ng.ui.bootstrap.IModalServiceInstance {
    return this.uibModal.open({
      template: require('./fileModal.html'),
      controllerAs: '$ctrl',
      controller: FileModalController,
      windowClass: 'cio-fileModal',
      backdropClass: 'cio-fileModal--backdrop',
      resolve: {
        file: () => file,
        revision: () => revision,
        showRevisions: () => showRevisions,
        navigator: () => navigator
      }
    });
  }
}
