import { PageHelper } from '../../../core/http/pageHelper';
import { Page, Task } from '../../../core/models';

const DEFAULT_TAKE = 10;

export class TasksWidgetController {
  static $inject = ['$http'];

  tasks: Task[] = [];
  config: any = { take: DEFAULT_TAKE };

  constructor(private http: ng.IHttpService) {}

  $onInit() {
    this.config.take = this.config.take || DEFAULT_TAKE;
    this.load();
  }

  load() {
    const url = PageHelper.query(
      '/api/v1/tasks/search?status=incomplete&sort=asc&sortBy=dueDate',
      this.config.take,
      0
    );
    this.http.get<Page<Task>>(url).then(response => {
      this.tasks = (response.data.items || []).map(x => Task.fromJson(x));
    });
  }
}
