import { SearchComponent } from './search.component';
import { SearchCellComponent } from './searchCell.component';
import { SearchChangeModalService } from './searchChangeModal.service';
import { SearchHeaderComponent } from './searchHeader.component';
import { CoreModule } from '../../core/module';
import { DataModule } from '../../data/module';
import { UIModule } from '../../ui/module';
import * as angular from 'angular';

export const SearchModule = angular
  .module('app.instance.search', [DataModule.name, CoreModule.name, UIModule.name])

  // Services
  .service('SearchChangeModalService', SearchChangeModalService)

  // Components
  .component('cioSearch', new SearchComponent())
  .component('cioSearchHeader', new SearchHeaderComponent())
  .component('cioSearchCell', new SearchCellComponent());
