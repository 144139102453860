import ClaimInvoiceResource from './resources/claimInvoice';
import ClaimSummaryResource from './resources/claimSummary';
import ClaimSumsInsuredResource from './resources/claimSumsInsured';
import ClaimTemplateResource from './resources/claimTemplate';
import CostCategoryResource from './resources/costCategory';
import DashboardResource from './resources/dashboard';
import EmailTemplateResource from './resources/emailTemplate';
import EntityResource from './resources/entity';
import EntityTemplateResource from './resources/entityTemplate';
import InstanceResource from './resources/instance';
import InvoiceResource from './resources/invoice';
import PaymentResource from './resources/payment';
import PermissionResource from './resources/permission';
import ReportResource from './resources/report';
import ReportFieldResource from './resources/report-field';
import ReportFieldConditionResource from './resources/reportFieldCondition';
import SumInsuredResource from './resources/sum-insured';
import SumInsuredCategoryResource from './resources/sum-insured-category';
import TaxCodeResource from './resources/tax-code';
import TimesheetResource from './resources/timesheet';
import UserSummaryResource from './resources/user-summary';
import WorkflowResource from './resources/workflow';
import ClaimStore from './stores/claim';
import EntityContactStore from './stores/entityContact';
import FieldStore from './stores/field';
import GroupStore from './stores/group';
import GroupSummaryStore from './stores/groupSummary';
import RoleStore from './stores/role';
import SavedSearchStore from './stores/savedSearch';
import StoreFactory from './stores/store';
import UserStore from './stores/user';
import UserDelegationStore from './stores/userDelegation';
import UserSettingsStore from './stores/userSettings';
import { CoreModule } from '../core/module';
import angular from 'angular';
import 'angular-resource';

export const DataModule = angular
  .module('app.data', ['ngResource', 'ui.router', CoreModule.name])

  // Stores
  .service('StoreFactory', StoreFactory)
  .factory('ClaimStore', ClaimStore)
  .factory('EntityContactStore', EntityContactStore)
  .factory('FieldStore', FieldStore)
  .factory('GroupStore', GroupStore)
  .factory('groupSummaryStore', GroupSummaryStore)
  .factory('RoleStore', RoleStore)
  .factory('SavedSearchStore', SavedSearchStore)
  .factory('UserStore', UserStore)
  .factory('UserSettingsStore', UserSettingsStore)

  // Resources
  .factory('ClaimInvoiceResource', ClaimInvoiceResource)
  .factory('ClaimSummaryResource', ClaimSummaryResource)
  .factory('ClaimSumsInsuredResource', ClaimSumsInsuredResource)
  .factory('ClaimTemplateResource', ClaimTemplateResource)
  .factory('CostCategoryResource', CostCategoryResource)
  .factory('DashboardResource', DashboardResource)
  .factory('EmailTemplateResource', EmailTemplateResource)
  .factory('EntityResource', EntityResource)
  .factory('EntityTemplateResource', EntityTemplateResource)
  .factory('InstanceResource', InstanceResource)
  .factory('InvoiceResource', InvoiceResource)
  .factory('PaymentResource', PaymentResource)
  .factory('ReportResource', ReportResource)
  .factory('PermissionResource', PermissionResource)
  .factory('SumInsuredResource', SumInsuredResource)
  .factory('SumInsuredCategoryResource', SumInsuredCategoryResource)
  .factory('TaxCodeResource', TaxCodeResource)
  .factory('TimesheetResource', TimesheetResource)
  .service('UserSummaryResource', UserSummaryResource)
  .factory('WorkflowResource', WorkflowResource)
  .factory('UserDelegationStore', UserDelegationStore)
  .factory('ReportFieldResource', ReportFieldResource)
  .factory('ReportFieldConditionResource', ReportFieldConditionResource);
