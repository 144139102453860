import {
  ICurrencyHttpService,
  IOrganizationHttpService,
  IStorageFileHttpService
} from '../../../core/http';
import {
  CLAIM_ASSIGNEE_STRATEGY_TYPES,
  Currency,
  EntityTemplate,
  Guid,
  Organization,
  PERMISSIONS,
  StorageFile,
  User
} from '../../../core/models';
import { IAlertService, ILogService, ISecurityService } from '../../../core/services';
import { EntityTemplateResource } from '../../../data/resources/entityTemplate';
import { IUserStore } from '../../../data/stores/user';
import { IFileService } from '../../files/file.service';
import { StateService } from 'angular-ui-router';
import 'rxjs/add/operator/switchMap';

export class OrganizationController {
  static $inject = [
    '$scope',
    '$state',
    'LogService',
    'AlertService',
    'SecurityService',
    'CurrencyHttpService',
    'OrganizationHttpService',
    'StorageFileHttpService',
    'EntityTemplateResource',
    'UserStore',
    'FileService'
  ];

  form: any;
  file: File;
  logoFile: StorageFile;
  organization: Organization;
  currencies: Currency[] = [];
  users: User[] = [];
  entityTypes: EntityTemplate[] = [];
  private _accountsPayable: string[] = [];
  private _accountsReceivable: string[] = [];

  constructor(
    private scope: any,
    private stateService: StateService,
    private logService: ILogService,
    private alertService: IAlertService,
    private securityService: ISecurityService,
    private currencyHttpService: ICurrencyHttpService,
    private organizationHttpService: IOrganizationHttpService,
    private storageFileHttpService: IStorageFileHttpService,
    private entityTemplateResource: EntityTemplateResource,
    private userStore: IUserStore,
    private fileService: IFileService
  ) {}

  $onInit() {
    if (!this.securityService.hasPermission(PERMISSIONS.Administrator)) {
      this.stateService.go('home');
      return;
    }
    this.loadOrganization();
    this.loadCurrencies();
    this.loadUsers();
    this.loadEntityTypes();
  }

  get accountsPayable(): string[] {
    return this._accountsPayable;
  }

  set accountsPayable(accounts: string[]) {
    if (!this.organization) {
      return;
    }
    this.organization.accountsPayable = accounts.map(x => new Guid(x));
    this.setAccountsPayable();
  }

  get accountsReceivable(): string[] {
    return this._accountsReceivable;
  }

  set accountsReceivable(accounts: string[]) {
    if (!this.organization) {
      return;
    }
    this.organization.accountsReceivable = accounts.map(x => new Guid(x));
    this.setAccountsReceivable();
  }

  get claimAssigneeDefaultUser(): string {
    if (!this.organization.claimAssigneeDefaultUser) {
      return undefined;
    }
    return this.organization.claimAssigneeDefaultUser.toString();
  }

  set claimAssigneeDefaultUser(userId: string) {
    if (!userId) {
      this.organization.claimAssigneeDefaultUser = undefined;
    } else {
      this.organization.claimAssigneeDefaultUser = new Guid(userId);
    }
  }

  fileChanged(file: File) {
    this.file = file;
  }

  claimAssigneeStrategyChanged() {
    if (
      this.organization.claimAssigneeStrategy !==
      CLAIM_ASSIGNEE_STRATEGY_TYPES.SpecificUser
    ) {
      this.organization.claimAssigneeDefaultUser = undefined;
    }
  }

  modifyOrganization() {
    this.form.active = true;
    this.organizationHttpService
      .modifyOrganization(this.organization)
      .safeApply(
        this.scope,
        organization => {
          this.setOrganization(organization);
          this.alertService.success('Organization updated');
        },
        err => this.handleModifyFailure(err)
      )
      .subscribe();
  }

  uploadLogo() {
    if (!this.file) {
      this.alertService.warn('You need to select a file first');
      return;
    }
    this.organizationHttpService
      .uploadLogo(this.organization, this.file)
      .switchMap(_ => this.organizationHttpService.getOrganization())
      .safeApply(
        this.scope,
        organization => {
          this.file = undefined;
          this.setOrganization(organization);
          this.alertService.success('Logo updated');
        },
        err => this.handleLoadFail(err)
      )
      .subscribe();
  }

  removeLogo() {
    if (!this.organization.logoFileId) {
      return;
    }
    this.organizationHttpService
      .removeLogo(this.organization)
      .switchMap(_ => this.organizationHttpService.getOrganization())
      .safeApply(
        this.scope,
        organization => {
          this.file = undefined;
          this.setOrganization(organization);
          this.alertService.success('Logo removed');
        },
        err => this.handleLoadFail(err)
      )
      .subscribe();
  }

  getLogoThumbnail() {
    return this.fileService.getThumbnailUrl(this.logoFile, 128, 128);
  }

  private setAccountsPayable() {
    this._accountsPayable = this.organization.accountsPayable.map(x => x.toString());
  }

  private setAccountsReceivable() {
    this._accountsReceivable = this.organization.accountsReceivable.map(x =>
      x.toString()
    );
  }

  private loadUsers() {
    this.userStore
      .query()
      .then(users => (this.users = users.items))
      .catch(err => this.handleLoadFail(err));
  }

  private loadEntityTypes() {
    this.entityTemplateResource
      .query()
      .$promise.then((result: any) => (this.entityTypes = result.items))
      .catch(err => this.handleLoadFail(err));
  }

  private loadCurrencies() {
    this.currencyHttpService
      .getAll()
      .safeApply(
        this.scope,
        currencies => (this.currencies = currencies),
        err => this.handleLoadFail(err)
      )
      .subscribe();
  }

  private loadOrganization() {
    this.organizationHttpService
      .getOrganization()
      .safeApply(
        this.scope,
        organization => this.setOrganization(organization),
        err => this.handleLoadFail(err)
      )
      .subscribe();
  }

  private setOrganization(organization: Organization) {
    this.organization = organization;
    this.form.active = false;
    this.form.$setPristine();
    this.setAccountsPayable();
    this.setAccountsReceivable();
    this.loadLogoFile();
  }

  private loadLogoFile() {
    if (!this.organization.logoFileId || Guid.isEmpty(this.organization.logoFileId)) {
      this.logoFile = undefined;
      return;
    }
    if (this.logoFile && this.logoFile.id.equals(this.organization.logoFileId)) {
      return;
    }
    this.storageFileHttpService
      .getFile(this.organization.logoFileId)
      .safeApply(
        this.scope,
        file => (this.logoFile = file),
        err => this.logService.error('Could not load logo', err.toString())
      )
      .subscribe();
  }

  private handleLoadFail(err) {
    this.logService.error('Load organization failed', err.toString());
    this.alertService.error('Could not load organization.');
    window.location.href = '/v2/settings';
  }

  private handleModifyFailure(err) {
    this.logService.error('Modify organization failed', err.toString());
    this.alertService.error('Could not modify organization, please try again.');
  }
}
