import './claim-template-service';
import { IAlertService } from '../../../core/services/alert';
import { ClaimTemplateResource } from '../../../data/resources/claimTemplate';
import { IGroupStore } from '../../../data/stores/group';
import reject from 'lodash/reject';

/* @ngInject */
export default function ClaimTypeEditController(
  $scope: any,
  Cache,
  ClaimTemplateManagerService,
  // tslint:disable-next-line:no-shadowed-variable
  ClaimTemplateResource: ClaimTemplateResource,
  GroupStore: IGroupStore,
  AlertService: IAlertService
): void {
  const fields = ClaimTemplateManagerService.fields();

  const vm = this;
  vm.model = ClaimTemplateManagerService.template();
  vm.section = null;
  vm.field = null;
  vm.formActive = false;
  vm.addSection = addSection;
  vm.removeSection = removeSection;
  vm.editSection = editSection;
  vm.addField = addField;
  vm.saveModel = saveModel;
  vm.editField = editField;
  vm.removeField = removeField;

  init();

  function init() {
    GroupStore.query().then(function(result) {
      vm.groups = result.items;
    });

    vm.model.sections = vm.model.sections || [];
    vm.model.sections.forEach(section => {
      const foundFields = [];
      section.fields = section.fields || [];
      section.fields.forEach(fd => {
        const field = fields.find(x => x.id === fd.fieldId);
        if (field) {
          fd.name = field.name;
          fd.label = field.label;
          fd.type = field.type;

          foundFields.push(fd);
        }
      });

      section.fields = foundFields;
    });
  }

  function addSection() {
    vm.model.sections.push({ name: 'New Section', fields: [] });
  }

  function removeSection(section) {
    vm.model.sections = reject(vm.model.sections, { name: section.name });
    vm.section = null;
    vm.field = null;
    $scope.$apply();
  }

  function editSection(section) {
    vm.section = section;
    vm.field = null;
  }

  function addField(section) {
    ClaimTemplateManagerService.showModal().result.then(function(selectedField) {
      const hasField = section.fields.find(x => x.fieldId === selectedField.id);
      if (hasField) {
        return;
      }

      if (typeof section.fields === 'undefined') {
        section.fields = [];
      }

      section.fields.push({
        fieldId: selectedField.id,
        isRequired: selectedField.isRequired,
        name: selectedField.name,
        label: selectedField.label,
        type: selectedField.type
      });
    });
  }

  function editField(section, field) {
    vm.section = section;
    vm.field = field;
  }

  function removeField(section, fieldId) {
    section.fields = reject(section.fields, { fieldId: fieldId });
    vm.section = null;
    vm.field = null;

    $scope.$apply();
  }

  function saveModel() {
    vm.formActive = true;

    ClaimTemplateResource.update(vm.model)
      .$promise.then(function(updated) {
        AlertService.success('Claim template ' + updated.name + ' has been updated.');
        vm.formActive = false;

        // invalidates cache
        Cache.claimTemplates(true);
        $scope.newForm.$setPristine();
      })
      .catch(function(error) {
        AlertService.error('Could not update claim template, please try again.');
        vm.formActive = false;
      });
  }
}
