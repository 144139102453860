import { HttpService } from './http';
import { FieldDefinition } from '../models';
import { logError } from '../utils/rx';
import { Observable } from 'rxjs/Observable';

export interface IFieldHttpService {
  all(): Observable<FieldDefinition[]>;
}

export class FieldHttpService implements IFieldHttpService {
  static $inject = ['HttpService'];

  private readonly _url: string = '/api/v1/fields';

  constructor(private httpService: HttpService) {}

  all(): Observable<FieldDefinition[]> {
    return this.httpService
      .get(`${this._url}/all`)
      .map(res => res.json())
      .catch(logError);
  }
}
