import { IAlertService, ITrackingService } from '../../core/services';
import { IClaimsWindow } from '../../core/utils/claimsWindow';
import { IClaimStore } from '../../data/stores/claim';
import { StateService } from 'angular-ui-router';

/* @ngInject */
export default function NewClaimController(
  $state: StateService,
  $stateParams: any,
  $window: IClaimsWindow,
  Cache: any,
  ClaimStore: IClaimStore,
  AlertService: IAlertService,
  TrackingService: ITrackingService
) {
  const vm = this;

  vm.templateId = $stateParams.templateId;
  vm.model = {
    templateId: vm.templateId
  };

  Cache.claimTemplate(vm.templateId)
    .then(function(template) {
      vm.template = template;
    })
    .catch(function(error) {
      AlertService.error('Could not load claim, please try again.');
      $state.go('app.search.grid');
    });

  vm.isTemplateValid = false;

  vm.saveShortcut = function($event) {
    $event.preventDefault();
    vm.save();
  };

  vm.save = function() {
    if (!vm.formNewClaim.$valid) {
      AlertService.error('Please complete all required fields.');
      return;
    }

    return ClaimStore.save(vm.model)
      .then(function(saved) {
        logClaimCreated();
        AlertService.success('Claim successfully saved.');
        vm.formNewClaim.$setPristine();
        return $state.go('app.claim.edit', {
          claimId: saved.id
        });
      })
      .catch(function(error) {
        AlertService.error('Could not save claim, please try again.');
      });
  };

  function logClaimCreated() {
    try {
      TrackingService.increment('Claims Created');
      TrackingService.track('Claim Create', {
        'Template Name': vm.template.name,
        'Template ID': vm.template.id
      });
    } catch {}
  }
}
