import { PaymentResource } from '../../data/resources/payment';

/* @ngInject */
export default function ClaimPaymentsService(
  $q: ng.IQService,
  // tslint:disable-next-line:no-shadowed-variable
  PaymentResource: PaymentResource
): any {
  const payments = [];

  const service = {
    payments: function() {
      return payments;
    },

    activate: activate,
    refresh: refresh,
    getInvoice: getInvoice,
    update: update,
    remove: remove,
    save: save
  };

  return service;

  function activate(claimId) {
    return refresh(claimId);
  }

  function refresh(claimId) {
    const defer = $q.defer();

    PaymentResource.query({ claimId: claimId }).$promise.then(function(data: any) {
      payments.length = 0;
      payments.push.apply(payments, data.items);

      defer.resolve(payments);
    });

    return defer.promise;
  }

  function getInvoice(paymentId) {
    return PaymentResource.get({ id: paymentId }).$promise;
  }

  function update(payment) {
    return PaymentResource.update(payment).$promise;
  }

  function remove(payment) {
    return PaymentResource.remove({ id: payment.id }).$promise;
  }

  function save(payment) {
    return PaymentResource.save(payment).$promise;
  }
}
