import { WorkflowActionCreateDocumentController } from './workflowActionCreateDocument.controller';
import { ComponentBase } from '../../../../core/utils/componentBase';

export class WorkflowActionCreateDocumentComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = { data: '=' };
    this.controller = WorkflowActionCreateDocumentController;
    this.template = require('./workflowActionCreateDocument.html');
  }
}
