/**
 * A service responsible for providing helpers for redirecting
 * the user to other pages.
 */
export interface IRedirectService {
  redirectToLogin(): void;
  redirectToError(error: string): void;
}

/* @ngInject */
export function RedirectService($window: ng.IWindowService): void {
  /**
   * Hard redirects the user to the login page.
   */
  this.redirectToLogin = function(): void {
    $window.location.assign('/login');
  };

  /**
   * Hard redirects the user to the error page.
   * @param {string} error The reason for the error.
   */
  this.redirectToError = function(error: string): void {
    $window.location.assign('/error?reason=' + (error || 'unknown').toString());
  };
}
