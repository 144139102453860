import { ITemplateItem } from './documentRequirement.interfaces';
import {
  EntityTemplate,
  Guid,
  TemplateRequirement,
  TemplateRequirementTypes
} from '../../../core/models';
import { ICacheService } from '../../services/cache';
import * as ng from 'angular';

export class DocumentRequirementNewModalController {
  static $inject = ['Cache', '$uibModalInstance'];

  selectedTemplate: string;
  requirement: TemplateRequirement;
  templates: ITemplateItem[] = [];
  isBusy: boolean = false;

  constructor(
    private cacheService: ICacheService,
    private modalInstanceService: ng.ui.bootstrap.IModalInstanceService
  ) {
    this.requirement = new TemplateRequirement();
    this.requirement.id = Guid.newGuid();
    this.requirement.type = TemplateRequirementTypes.Entity;
    this.requirement.isRequired = true;
  }

  get isValid(): boolean {
    return !!this.selectedTemplate;
  }

  $onInit() {
    this.loadTemplates();
  }

  save() {
    if (!this.selectedTemplate) {
      return;
    }
    this.isBusy = true;
    this.requirement.templateId = new Guid(this.selectedTemplate);
    this.modalInstanceService.close(this.requirement);
    this.isBusy = false;
  }

  cancel() {
    this.modalInstanceService.dismiss('cancel');
  }

  private loadTemplates() {
    this.cacheService
      .entityTemplates()
      .then((entityTemplates: EntityTemplate[]) => {
        this.templates = entityTemplates.map(t => {
          return { id: t.id, name: t.name };
        });
      })
      .catch(err => console.log(err));
  }
}
