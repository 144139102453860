import { WorkflowActionAddCommentComponent } from './actions/workflowActionAddComment.component';
import { WorkflowActionCreateDocumentComponent } from './actions/workflowActionCreateDocument.component';
import { WorkflowActionCreateTaskComponent } from './actions/workflowActionCreateTask.component';
import { WorkflowActionsComponent } from './actions/workflowActions.component';
import { WorkflowActionSendEmailComponent } from './actions/workflowActionSendEmail.component';
import { WorkflowActionUpdateFieldsComponent } from './actions/workflowActionUpdateFields.component';
import { WorkflowActionUpdateTaskComponent } from './actions/workflowActionUpdateTask.component';
import { WorkflowFilterComponent } from './filters/workflowFilter.component';
import { WorkflowFilterAddressComponent } from './filters/workflowFilterAddress.component';
import { WorkflowFilterBooleanComponent } from './filters/workflowFilterBoolean.component';
import { WorkflowFilterClaimTypeComponent } from './filters/workflowFilterClaimType.component';
import { WorkflowFilterDateComponent } from './filters/workflowFilterDate.component';
import { WorkflowFilterDatetimeComponent } from './filters/workflowFilterDatetime.component';
import { WorkflowFilterDropdownComponent } from './filters/workflowFilterDropdown.component';
import { WorkflowFilterEntityComponent } from './filters/workflowFilterEntity.component';
import { WorkflowFilterEntityContactComponent } from './filters/workflowFilterEntityContact.component';
import { WorkflowFilterGroupsComponent } from './filters/workflowFilterGroups.component';
import { WorkflowFilterNumberComponent } from './filters/workflowFilterNumber.component';
import { WorkflowFiltersComponent } from './filters/workflowFilters.component';
import { WorkflowFilterStatusComponent } from './filters/workflowFilterStatus.component';
import { WorkflowFilterTextComponent } from './filters/workflowFilterText.component';
import { WorkflowFilterUserComponent } from './filters/workflowFilterUser.component';
import { WorkflowFilterTagsComponent } from './filters/workflowFilterTags.component';
import { WorkflowEditComponent } from './workflowEdit.component';
import { WorkflowEditorComponent } from './workflowEditor.component';
import { WorkflowListComponent } from './workflowList.component';
import { WorkflowNewComponent } from './workflowNew.component';
import { WorkflowRoutes } from './workflows.routes';
import { CoreModule } from '../../../core/module';
import { DataModule } from '../../../data/module';
import { UIModule } from '../../../ui/module';
import * as angular from 'angular';

export const WorkflowSettingsModule = angular
  .module('app.instance.settings.workflow', [
    DataModule.name,
    CoreModule.name,
    UIModule.name
  ])
  // Routes
  .config(WorkflowRoutes)

  // Components
  .component('cioWorkflowList', new WorkflowListComponent())
  .component('cioWorkflowNew', new WorkflowNewComponent())
  .component('cioWorkflowEdit', new WorkflowEditComponent())
  .component('cioWorkflowEditor', new WorkflowEditorComponent())

  .component('cioWorkflowActions', new WorkflowActionsComponent())
  .component('cioWorkflowActionAddComment', new WorkflowActionAddCommentComponent())
  .component(
    'cioWorkflowActionCreateDocument',
    new WorkflowActionCreateDocumentComponent()
  )
  .component('cioWorkflowActionCreateTask', new WorkflowActionCreateTaskComponent())
  .component('cioWorkflowActionSendEmail', new WorkflowActionSendEmailComponent())
  .component('cioWorkflowActionUpdateFields', new WorkflowActionUpdateFieldsComponent())
  .component('cioWorkflowActionUpdateTask', new WorkflowActionUpdateTaskComponent())
  .component('cioWorkflowFilters', new WorkflowFiltersComponent())
  .component('cioWorkflowFilter', new WorkflowFilterComponent())
  .component('cioWorkflowFilterAddress', new WorkflowFilterAddressComponent())
  .component('cioWorkflowFilterBoolean', new WorkflowFilterBooleanComponent())
  .component('cioWorkflowFilterClaimType', new WorkflowFilterClaimTypeComponent())
  .component('cioWorkflowFilterDate', new WorkflowFilterDateComponent())
  .component('cioWorkflowFilterDatetime', new WorkflowFilterDatetimeComponent())
  .component('cioWorkflowFilterDropdown', new WorkflowFilterDropdownComponent())
  .component('cioWorkflowFilterEntity', new WorkflowFilterEntityComponent())
  .component('cioWorkflowFilterEntityContact', new WorkflowFilterEntityContactComponent())
  .component('cioWorkflowFilterGroups', new WorkflowFilterGroupsComponent())
  .component('cioWorkflowFilterNumber', new WorkflowFilterNumberComponent())
  .component('cioWorkflowFilterStatus', new WorkflowFilterStatusComponent())
  .component('cioWorkflowFilterText', new WorkflowFilterTextComponent())
  .component('cioWorkflowFilterUser', new WorkflowFilterUserComponent())
  .component('cioWorkflowFilterTags', new WorkflowFilterTagsComponent());
