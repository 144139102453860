/**
 * All possible report field types based in report type.
 *
 * @export
 * @class ReportFieldTypes
 */
export class ReportFieldTypes {
  /**
   * A claim report.
   *
   * @readonly
   * @static
   * @type {string}
   * @memberOf ReportTypes
   */
  public static get Claim(): string {
    return 'Claim';
  }

  /**
   * A Timesheet report.
   *
   * @readonly
   * @static
   * @type {string}
   * @memberOf ReportTypes
   */
  public static get Timesheet(): string {
    return 'Timesheet';
  }

  /**
   * A Invoice Summary report.
   *
   * @readonly
   * @static
   * @type {string}
   * @memberOf ReportTypes
   */
  public static get InvoiceSummary(): string {
    return 'Invoice';
  }

  /**
   * A Invoice Detail report.
   *
   * @readonly
   * @static
   * @type {string}
   * @memberOf ReportTypes
   */
  public static get InvoiceDetail(): string {
    return 'Invoice Item';
  }

  /**
   * A Payment Summary report.
   *
   * @readonly
   * @static
   * @type {string}
   * @memberOf ReportTypes
   */
  public static get PaymentSummary(): string {
    return 'Payment';
  }

  /**
   * A Payment Detail report.
   *
   * @readonly
   * @static
   * @type {string}
   * @memberOf ReportTypes
   */
  public static get PaymentDetail(): string {
    return 'Payment Item';
  }
}
