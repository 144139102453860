import '../../../core/services/alert';
import '../../../data/resources/emailTemplate';

/* @ngInject */
export default function EmailTemplateNewController(
  $scope,
  $state,
  EmailTemplateResource,
  AlertService
) {
  $scope.model = {};
  $scope.newForm = {};
  $scope.newForm.active = false;

  $scope.saveModel = function(model) {
    $scope.newForm.active = true;

    EmailTemplateResource.save(model)
      .$promise.then(function(updated) {
        AlertService.success('New email ' + updated.name + ' has been created.');
        $scope.newForm.active = false;
        $scope.newForm.$setPristine();
        $state.go('app.emailTemplate.edit', { id: updated.id });
      })
      .catch(function(error) {
        AlertService.error('Could not create email, please try again.');
        $scope.newForm.active = false;
      });
  };
}
