import { IAlertService } from '../../../core/services';
import { ClaimTemplateResource } from '../../../data/resources/claimTemplate';
import { IGroupStore } from '../../../data/stores/group';
import { StateService } from 'angular-ui-router';

/* @ngInject */
export default function ClaimTypeNewController(
  $scope: any,
  $state: StateService,
  Cache,
  // tslint:disable-next-line:no-shadowed-variable
  ClaimTemplateResource: ClaimTemplateResource,
  GroupStore: IGroupStore,
  AlertService: IAlertService
): void {
  $scope.model = {};
  $scope.newForm = {};
  $scope.newForm.active = false;
  $scope.saveModel = saveModel;

  init();

  function init() {
    GroupStore.query().then(function(result) {
      $scope.groups = result.items;
    });
  }

  function saveModel(model) {
    model.titleTemplate = model.titleTemplate || '{{reference}}';

    ClaimTemplateResource.save(model)
      .$promise.then(function(updated) {
        AlertService.success('New claim template ' + updated.name + ' has been created.');
        $scope.newForm.active = false;

        // invalidates cache
        Cache.claimTemplates(true);
        $scope.newForm.$setPristine();
        $state.go('app.claimType.edit', { id: updated.id });
      })
      .catch(function(error) {
        AlertService.error('Could not create claim template, please try again.');
        $scope.newForm.active = false;
      });
  }
}
