import {
  Workflow,
  WORKFLOW_ACTIONS,
  WORKFLOW_TRIGGERS,
  IWorkflowActionType,
  IWorkflowTriggerType
} from '../../../core/models';

export class WorkflowEditorController {
  static $inject = [];

  workflow: Workflow;
  actions: IWorkflowActionType[] = WORKFLOW_ACTIONS;
  triggers: IWorkflowTriggerType[] = WORKFLOW_TRIGGERS;
}
