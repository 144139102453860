import './modals/invoice-notes.service';
import './modals/invoice-payment.service';
import { IDocumentHttpService, IOrganizationHttpService } from '../../../core/http';
import { DocumentCreatedMessage } from '../../../core/messages';
import {
  CostCategory,
  Entity,
  Guid,
  ITemplateRequirementValue,
  TemplateRequirementTypes,
  Timesheet,
  TaxCode
} from '../../../core/models';
import {
  IAlertService,
  ILogService,
  IMessagingService,
  ISecurityService
} from '../../../core/services';
import { CostCategoryResource } from '../../../data/resources/costCategory';
import { InvoiceResource } from '../../../data/resources/invoice';
import { TaxCodeResource } from '../../../data/resources/tax-code';
import { TimesheetResource } from '../../../data/resources/timesheet';
import { IDocumentService } from '../../documents/document.service';
import { IClaimManagerService } from '../claim-manager-service';
import { StateService } from 'angular-ui-router';
import { DateTime } from 'luxon';
import find from 'lodash/find';
import filter from 'lodash/filter';

/* @ngInject */
export default function ClaimNewInvoiceController(
  $scope: any,
  $stateParams: any,
  $state: StateService,
  LogService: ILogService,
  ClaimManagerService: IClaimManagerService,
  // tslint:disable-next-line:no-shadowed-variable
  TaxCodeResource: TaxCodeResource,
  // tslint:disable-next-line:no-shadowed-variable
  InvoiceResource: InvoiceResource,
  AlertService: IAlertService,
  // tslint:disable-next-line:no-shadowed-variable
  TimesheetResource: TimesheetResource,
  // tslint:disable-next-line:no-shadowed-variable
  CostCategoryResource: CostCategoryResource,
  DocumentService: IDocumentService,
  InvoicePaymentService,
  InvoiceNotesService,
  SecurityService: ISecurityService,
  OrganizationHttpService: IOrganizationHttpService,
  DocumentHttpService: IDocumentHttpService,
  MessagingService: IMessagingService
): void {
  const vm = this;

  const claimId = $stateParams.claimId;
  const invoiceId = $stateParams.invoiceId;
  const currentUser = SecurityService.getCurrentUser();

  vm.claim = ClaimManagerService.claim();
  vm.availableStatus = ['Pending', 'Approved', 'Sent', 'Partial Payment', 'Finalised'];
  vm.taxCodes = [];
  vm.invoiceId = invoiceId;
  vm.invoice = null;
  vm.payment = {};
  vm.note = {};
  vm.organization = null;
  vm.categoryObj = {};
  vm.numberPlaceholder = 'Specify an invoice number';
  vm.numberEditable = true;

  vm.newInvoice = newInvoice;
  vm.newFromFees = newFromFees;
  vm.addItem = addItem;
  vm.getItemTotal = getItemTotal;
  vm.getItemSubTotal = getItemSubTotal;
  vm.calculateTax = calculateTax;
  vm.removeItem = removeItem;
  vm.removePayment = removePayment;
  vm.saveInvoice = saveInvoice;
  vm.transformFeeIntoInvoice = transformFeeIntoInvoice;
  vm.createDocument = createDocument;
  vm.updateItemTaxCode = updateItemTaxCode;
  vm.setEntity = setEntity;

  vm.getSubTotal = getSubTotal;
  vm.getTaxTotal = getTaxTotal;
  vm.getTotal = getTotal;

  vm.addPayment = addPayment;
  vm.addNotes = addNotes;

  activate();

  function activate() {
    if (invoiceId) {
      InvoiceResource.get({
        id: invoiceId
      }).$promise.then(function(data) {
        if (data.items) {
          data.items.forEach(function(i) {
            if (Guid.isEmpty(i.categoryId)) {
              i.categoryId = null;
            }
          });
        }
        vm.invoice = data;
      });
    }

    OrganizationHttpService.getOrganization()
      .safeApply(
        $scope,
        organization => {
          vm.organization = organization;
          if (organization.invoiceNumberStrategy !== 'Blank') {
            vm.numberPlaceholder = 'Auto generated on save';
            vm.numberEditable = false;
          }
        },
        err => LogService.error('Failed to load organization', err)
      )
      .subscribe();

    TaxCodeResource.query().$promise.then(function(result: any) {
      vm.taxCodes = result.items;
    });

    TimesheetResource.query({
      claimId: claimId
    }).$promise.then(function(data: any) {
      vm.fees = filter(data.items, function(f) {
        return !f.invoiced && f.billable;
      });

      vm.fees.forEach(function(f) {
        f.checked = true;
      });
    });

    CostCategoryResource.query().$promise.then(function(result: any) {
      vm.feeCategories = result.items;

      result.items.forEach(function(c) {
        vm.categoryObj[c.id] = c.name;
      });
    });
  }

  function newInvoice() {
    vm.invoice = {
      number: '',
      status: 'Pending',
      reference: '',
      items: [],
      payments: [],
      notes: [],
      claimId: claimId,
      invoiceDate: new Date().toISOString(),
      dueDate: DateTime.local()
        .plus({ days: 30 })
        .toISO()
    };

    addItem();
  }

  function newFromFees() {
    vm.showFees = true;
  }

  function addItem() {
    vm.invoice.items.push({
      quantity: 1,
      unitCost: 0,
      taxCode: vm.taxCodes.length > 0 ? vm.taxCodes[0].id : null,
      taxTotal: 0
    });
  }

  function getItemSubTotal(item) {
    return item.quantity * item.unitCost;
  }

  function getItemTotal(item) {
    calculateTax(item);
    const subTotal = item.quantity * item.unitCost;
    const taxTotal = item.quantity * item.taxTotal;

    return subTotal + taxTotal;
  }

  function getSubTotal() {
    let total = 0;
    vm.invoice.items.forEach(function(i) {
      total += i.quantity * i.unitCost;
    });

    return total;
  }

  function getTaxTotal() {
    let total = 0;
    vm.invoice.items.forEach(function(i) {
      total += i.quantity * i.taxTotal;
    });

    return total;
  }

  function getTotal() {
    let total = 0;
    vm.invoice.items.forEach(function(i) {
      const subTotal = i.quantity * i.unitCost;
      const taxTotal = i.quantity * i.taxTotal;

      total += subTotal + taxTotal;
    });

    vm.invoice.payments.forEach(function(p) {
      total -= p.amount || 0;
    });

    return total;
  }

  function calculateTax(item) {
    const taxCode = find(<any[]>vm.taxCodes, {
      id: item.taxCode
    });
    if (taxCode) {
      item.taxTotal = item.unitCost * (taxCode.percentage / 100);
    }
  }

  function removeItem(index) {
    vm.invoice.items.splice(index, 1);
    $scope.$apply();
  }

  function removePayment(index) {
    vm.invoice.payments.splice(index, 1);
    $scope.$apply();
  }

  function addPayment() {
    InvoicePaymentService.managePayments(vm.invoice, vm.claim)
      .then(() => {
        saveInvoice(null);
      })
      .catch(() => {});
  }

  function setEntity(entity: Entity) {
    if (!entity) {
      vm.invoice.entityId = undefined;
      return;
    }
    vm.invoice.entityId = entity.id;
  }

  function addNotes() {
    InvoiceNotesService.manageNotes(vm.invoice, vm.claim, currentUser)
      .then(() => {
        saveInvoice(null);
      })
      .catch(() => {});
  }

  function saveInvoice(form) {
    if (!vm.invoice.entityId || Guid.isEmpty(vm.invoice.entityId)) {
      AlertService.error('Please specify who the invoice is to.');
      return;
    }

    const operationMessage = vm.invoice.id ? 'update' : 'created';
    const request = vm.invoice.id
      ? InvoiceResource.update(vm.invoice)
      : InvoiceResource.save(vm.invoice);

    request.$promise.then(function(saved) {
      activate();
      AlertService.success('Invoice ' + operationMessage + ' successfully.');

      if (form) {
        form.$setPristine();
      }

      $state.go('app.claim.invoices.edit', {
        claimId: claimId,
        invoiceId: saved.id
      });
    });
  }

  function transformFeeIntoInvoice() {
    const fees = (vm.fees as (Timesheet & { checked: boolean })[]).filter(x => x.checked);

    vm.showFees = false;

    newInvoice();

    vm.invoice.items = [];

    fees.forEach(function(f) {
      const category = (vm.feeCategories as CostCategory[]).find(
        x => x.id === f.categoryId
      );
      let taxCode = null;
      if (category) {
        taxCode = find(<TaxCode[]>vm.taxCodes, x => x.id === category.taxCode);
      }

      vm.invoice.items.push({
        description: f.description,
        quantity: f.duration,
        unitCost: category.rate,
        feeId: f.id,
        categoryId: f.categoryId,
        taxCode: taxCode ? taxCode.id : null,
        taxTotal: taxCode ? category.rate * (taxCode.percentage / 100) : 0
      });
    });

    if (!vm.invoice.items.length) {
      addItem();
    }
  }

  function updateItemTaxCode(item) {
    if (item.categoryId) {
      const category = find(<any[]>vm.feeCategories, {
        id: item.categoryId
      });

      if (category && category.rate) {
        item.unitCost = category.rate;
      }

      if (category && category.taxCode) {
        item.taxCode = category.taxCode;

        calculateTax(item);
      }
    }
  }

  function createDocument() {
    const invoice = vm.invoice;
    const requirements: ITemplateRequirementValue[] = [
      { type: TemplateRequirementTypes.Claim, field: '', recordId: new Guid(claimId) },
      {
        type: TemplateRequirementTypes.Invoice,
        recordId: new Guid(invoice.id),
        field: 'invoice'
      }
    ];
    DocumentService.select(requirements)
      .then(selection => {
        if (!selection.document) {
          return;
        }
        DocumentHttpService.createDocument(
          vm.claim.folderId,
          selection.document.id,
          selection.values
        )
          .safeApply($scope, file => {
            if (!file) {
              return;
            }
            AlertService.success('Document created.');
            this.stateService.go('app.claim.files', { claimId: claimId });
            MessagingService.publish(new DocumentCreatedMessage(file));
          })
          .subscribe();
      })
      .catch(() => {});
  }
}
