import { Guid } from './guid';
import { RecordAssociation } from './recordAssociation';
import { Clock, DateTimeHelper } from '../utils';
import cloneDeep from 'lodash/cloneDeep';
import { DateTime } from 'luxon';
import { Tag } from './tag';

const HOURS_DAY = 24;
const HOURS_WEEK = HOURS_DAY * 7;

export enum TaskRepeatOptions {
  Hours = 1,
  Days = 2,
  Weeks = 3,
  Months = 4,
  Years = 5
}

export const TaskRepeatOptionHelper = {
  asDateTimePlus(value: number, repeatOption: TaskRepeatOptions): any {
    switch (repeatOption) {
      case TaskRepeatOptions.Hours:
        return { hours: value };
      case TaskRepeatOptions.Days:
        return { days: value };
      case TaskRepeatOptions.Weeks:
        return { weeks: value };
      case TaskRepeatOptions.Months:
        return { months: value };
      case TaskRepeatOptions.Years:
        return { years: value };
      default:
        return { days: value };
    }
  }
};

export class Task {
  id: Guid;
  text: string = '';
  assignee: Guid;
  complete: boolean = false;
  completedBy?: Guid;
  completed?: DateTime;
  dueDate: DateTime;
  hasTime: boolean = false;
  tags: Tag[] = [];
  tagIds: Guid[] = [];
  userId: Guid; // Obsolete
  created: DateTime;
  createdBy: Guid;
  lastModified: DateTime;
  lastModifiedBy: Guid;
  subscribers: Guid[] = [];
  associations: RecordAssociation[] = [];
  repeat: boolean;
  repeatEvery: number;
  repeatOption: TaskRepeatOptions;

  get hasAssignee(): boolean {
    return !!this.assignee;
  }

  get hasDueDate(): boolean {
    return !!this.dueDate;
  }

  get isOverdue(): boolean {
    if (this.complete || !this.dueDate) {
      return false;
    }
    if (this.hasTime) {
      return Clock.utc().valueOf() > Clock.utc(this.dueDate).valueOf();
    }
    const endOfToday = Clock.utc(this.dueDate).endOfToday();
    const start = Clock.local().startOfToday();
    return endOfToday.valueOf() < start.valueOf();
  }

  toggleCompleted(userId: Guid) {
    const complete = !this.complete;
    this.complete = complete;
    this.completed = complete ? Clock.utc().now() : undefined;
    this.completedBy = complete ? userId : undefined;
  }

  clone(userId: Guid): Task {
    const now = Clock.utc().now();
    const defaults = {
      id: undefined,
      created: now,
      createdBy: userId,
      lastModified: now,
      lastModifiedBy: userId,
      complete: false,
      completed: undefined,
      completedBy: undefined,
      dueDate: this.dueDate ? this.dueDate : undefined
    };
    return Object.assign(new Task(), cloneDeep(this), defaults);
  }

  dueString(): string {
    if (!this.hasDueDate || !this.dueDate.isValid) {
      return '';
    }
    const localNow = Clock.local().now();
    const dueDate = this.hasTime
      ? this.dueDate.toLocal()
      : this.dueDate.toUTC().startOf('day');
    const isPast = this.hasTime
      ? localNow.valueOf() > Clock.local(dueDate).valueOf()
      : localNow.startOf('day').valueOf() > dueDate.valueOf();
    return isPast
      ? this.complete
        ? this.pastCompleteString(localNow, dueDate)
        : this.pastOverdueString(localNow, dueDate)
      : `${this.complete ? '' : 'due '}${this.futureDateString(localNow, dueDate)}`;
  }

  private pastOverdueString(localNow: DateTime, dueDate: DateTime): string {
    const years = Math.abs(dueDate.diff(localNow, 'years').years);
    if (years > 1) {
      return overdueString(years, 'year');
    }
    const months = Math.abs(dueDate.diff(localNow, 'months').months);
    if (months > 1) {
      return overdueString(months, 'month');
    }
    const hours = Math.abs(dueDate.diff(localNow, 'hours').hours);
    if (hours >= HOURS_WEEK) {
      return overdueString(hours / HOURS_WEEK, 'week');
    }
    if (hours >= HOURS_DAY) {
      const days = hours / HOURS_DAY;
      if (days >= 2) {
        return overdueString(days, 'day');
      }
    }
    if (DateTimeHelper.isDateYesterday(dueDate)) {
      return 'due Yesterday';
    }
    if (!this.hasTime && DateTimeHelper.isDateToday(dueDate)) {
      return 'due Today';
    }
    if (hours >= 1) {
      return overdueString(hours, 'hour');
    }
    return 'now';

    function overdueString(value, unit) {
      const valueWhole = Math.floor(value);
      return `${valueWhole} ${unit}${valueWhole > 1 ? 's' : ''} overdue`;
    }
  }

  private pastCompleteString(localNow: DateTime, dueDate: DateTime): string {
    const days = Math.abs(dueDate.diff(localNow, 'days').days);
    const daysWhole = Math.floor(days);
    if (daysWhole > 1) {
      return `${
        daysWhole > 364 ? dueDate.toFormat('MMM d, yyyy') : dueDate.toFormat('MMM d')
      }`;
    }
    if (DateTimeHelper.isDateYesterday(dueDate)) {
      return 'Yesterday';
    }
    if (!this.hasTime && DateTimeHelper.isDateToday(dueDate)) {
      return 'Today';
    }
    const hoursDiff = Math.abs(dueDate.diff(localNow, 'hours').hours);
    if (hoursDiff >= 1) {
      const hours = Math.floor(hoursDiff);
      return `${hours} hour${hours > 1 ? 's' : ''} ago`;
    }
    return 'now';
  }

  private futureDateString(localNow: DateTime, dueDate: DateTime): string {
    const days = Math.abs(dueDate.diff(localNow, 'days').days);
    const daysWhole = Math.floor(days);
    if (daysWhole >= 6) {
      return `${
        daysWhole >= 364 ? dueDate.toFormat('MMM d, yyyy') : dueDate.toFormat('MMM d')
      }`;
    }
    if (daysWhole > 2) {
      return `${dueDate.toFormat('EEEE')}`;
    }
    const hoursDiff = Math.abs(dueDate.diff(localNow, 'hours').hours);
    if (!this.hasTime && DateTimeHelper.isDateTomorrow(dueDate)) {
      return 'Tomorrow';
    }
    if (!this.hasTime && DateTimeHelper.isDateToday(dueDate)) {
      return 'Today';
    }
    if (hoursDiff >= 1) {
      const hours = Math.floor(hoursDiff);
      return `in ${hours} hour${hours > 1 ? 's' : ''}`;
    }
    return 'now';
  }

  static fromJson(json: any): Task {
    const task = Object.assign(new Task(), json) as Task;
    task.id = new Guid(json.id);
    task.created = DateTimeHelper.parseUtcDateTime(json.created);
    task.createdBy = new Guid(json.createdBy);
    task.lastModified = DateTimeHelper.parseUtcDateTime(json.lastModified);
    task.lastModifiedBy = new Guid(json.lastModifiedBy);
    task.userId = json.userId && new Guid(json.userId);
    task.dueDate =
      json.dueDate && task.hasTime
        ? DateTimeHelper.parseUtcDateTime(json.dueDate)
        : DateTimeHelper.parseUtcDate(json.dueDate);
    task.associations = (json.associations || []).map(a => RecordAssociation.fromJson(a));
    task.completed = task.complete
      ? json.completed && DateTimeHelper.parseUtcDateTime(json.completed)
      : undefined;
    task.completedBy = task.complete
      ? json.completedBy && new Guid(json.completedBy)
      : undefined;
    task.tagIds = (json.tagIds || []).map((x: string) => new Guid(x));
    return task;
  }
}
