/* @ngInject */
export default function CioLogin(): any {
  return {
    restrict: 'E',
    controller: 'cioLoginController',
    controllerAs: 'login',
    bindToController: true,
    template: require('./cio-login.html')
  };
}
