import { Guid } from './guid';
import { DateTime } from 'luxon';
import { DateTimeHelper } from '../utils';

export class Activity {
  id: Guid;
  claimId: Guid;
  type: string;
  data: any;
  created: DateTime;
  createdBy: Guid;
  lastModified: DateTime;
  lastModifiedBy: Guid;
  isStarred: boolean = false;

  static fromJson(json: any): Activity {
    const activity = Object.assign(new Activity(), json) as Activity;
    activity.id = new Guid(json.id);
    activity.claimId = new Guid(json.claimId);
    activity.created = DateTimeHelper.parseUtcDateTime(json.created);
    activity.createdBy = new Guid(json.createdBy);
    activity.lastModified = DateTimeHelper.parseUtcDateTime(json.lastModified);
    activity.lastModifiedBy = new Guid(json.lastModifiedBy);
    return activity;
  }
}
