export interface ITimeValue {
  hour: number;
  minute: number;
}

enum TimeMeridiems {
  AM = 'AM',
  PM = 'PM'
}

export class TimeEditController {
  static $inject = [];

  noMinutes: boolean = false;
  timeHours: string = undefined;
  timeMinutes: string = undefined;
  timeMeridiem: TimeMeridiems = TimeMeridiems.AM;

  set time(value: ITimeValue) {
    if (value) {
      this.set12Hour(value.hour);
      this.timeMinutes = padNumber(value.minute);
      return;
    }
    this.resetValues();
  }

  get hourNumber(): number {
    return box12Hour(parseNumber(this.timeHours));
  }

  get minuteNumber(): number {
    return boxMinute(parseNumber(this.timeMinutes));
  }

  updateTime() {
    this.timeHours = this.hourNumber.toFixed(0);
    this.timeMinutes = padNumber(this.minuteNumber);
    this.setTime();
  }

  toggleTimeMeridiem() {
    this.timeMeridiem =
      this.timeMeridiem === TimeMeridiems.AM ? TimeMeridiems.PM : TimeMeridiems.AM;
    this.setTime();
  }

  private setTime() {
    this.change({
      hour: get24Hour(this.hourNumber, this.timeMeridiem),
      minute: this.minuteNumber
    });
  }

  private change(value: ITimeValue) {
    (this as any).onChange({ time: value });
  }

  private resetValues() {
    this.timeHours = '';
    this.timeMinutes = '';
    this.timeMeridiem = TimeMeridiems.AM;
  }

  private set12Hour(hour: number) {
    hour = box24Hour(hour);
    if (hour === 0) {
      this.timeMeridiem = TimeMeridiems.AM;
      this.timeHours = '12';
      return;
    }
    if (hour > 12) {
      this.timeMeridiem = TimeMeridiems.PM;
      this.timeHours = `${(hour - 12).toFixed(0)}`;
      return;
    }
    if (hour === 12) {
      this.timeMeridiem = TimeMeridiems.PM;
      this.timeHours = hour.toFixed(0);
      return;
    }
    this.timeMeridiem = TimeMeridiems.AM;
    this.timeHours = hour.toFixed(0);
  }
}

function get24Hour(hour: number, timeMeridiem: TimeMeridiems): number {
  if (timeMeridiem === TimeMeridiems.AM) {
    if (hour === 12) {
      // 12am is 0 in 24 hours
      hour = 0;
    }
    return hour;
  }
  if (hour < 12) {
    // 12pm is 12 in 24 hours
    hour += 12;
  }
  return hour;
}

function parseNumber(value: string): number {
  return parseInt((value || '0').replace(/[^0-9]/g, ''), 10);
}

function boxMinute(minute: number): number {
  return boxNumber(minute, 0, 59);
}

function box12Hour(hour: number): number {
  return boxNumber(hour, 1, 12);
}

function box24Hour(hour: number): number {
  return boxNumber(hour, 0, 23);
}

function boxNumber(value: number, min: number, max: number): number {
  if (isNaN(value)) {
    return min;
  }
  return Math.max(min, Math.min(max, Math.abs(value || 0)));
}

function padNumber(value: number): string {
  if (isNaN(value)) {
    return '00';
  }
  if (value < 10) {
    return `0${value.toFixed(0)}`;
  }
  return value.toFixed(0);
}
