import { Guid } from './guid';

export interface ITemplateRequirement {
  id: Guid;
  field: string;
  type: TemplateRequirementTypes;
  templateId?: Guid;
  recordId?: Guid;
  isRequired: boolean;
  toValue(): ITemplateRequirementValue;
}

export interface ITemplateRequirementValue {
  field: string;
  type: TemplateRequirementTypes;
  recordId: Guid;
}

export enum TemplateRequirementTypes {
  Claim = 'claim',
  Entity = 'entity',
  Contact = 'contact',
  Invoice = 'invoice',
  Payment = 'payment'
}

export class TemplateRequirement implements ITemplateRequirement {
  id: Guid;
  field: string;
  type: TemplateRequirementTypes;
  templateId?: Guid;
  recordId?: Guid;
  isRequired: boolean = false;

  toValue(): ITemplateRequirementValue {
    return {
      field: this.field,
      type: this.type,
      recordId: this.recordId
    };
  }

  static clone(original: TemplateRequirement) {
    return TemplateRequirement.fromJson(JSON.parse(JSON.stringify(original)));
  }

  static fromJson(json: any) {
    const requirement = Object.assign(
      new TemplateRequirement(),
      json
    ) as TemplateRequirement;
    requirement.id = new Guid(json.id);
    requirement.templateId = json.templateId && new Guid(json.templateId);
    requirement.recordId = json.recordId && new Guid(json.recordId);
    return requirement;
  }
}
