import { HttpService } from './http';
import { Guid, Note } from '../models';
import { logError } from '../utils/rx';
import { Observable } from 'rxjs/Observable';

export interface INoteHttpService {
  getNote(noteId: Guid): Observable<Note>;
  createNote(note: Note): Observable<Note>;
  modifyNote(note: Note): Observable<Note>;
  deleteNote(noteId: Guid): Observable<boolean>;
}

export class NoteHttpService implements INoteHttpService {
  static $inject = ['HttpService'];

  private readonly _url: string = '/api/v1/notes';

  constructor(private httpService: HttpService) {}

  getNote(noteId: Guid): Observable<Note> {
    const url = `${this._url}/${noteId.toString()}`;
    return this.httpService
      .get(url)
      .map(res => Note.fromJson(res.json()))
      .catch(logError);
  }

  createNote(note: Note): Observable<Note> {
    const body = JSON.stringify(note);
    return this.httpService
      .post(this._url, body)
      .map(res => Note.fromJson(res.json()))
      .catch(logError);
  }

  modifyNote(note: Note): Observable<Note> {
    const url = `${this._url}/${note.id.toString()}`;
    const body = JSON.stringify(note);
    return this.httpService
      .put(url, body)
      .map(res => Note.fromJson(res.json()))
      .catch(logError);
  }

  deleteNote(noteId: Guid): Observable<boolean> {
    const url = `${this._url}/${noteId.toString()}`;
    return this.httpService
      .delete(url)
      .map(_ => true)
      .catch(logError);
  }
}
