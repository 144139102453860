import { ITaskHttpService } from '../../core/http';
import { RecordAssociation, Task, TaskRepeatOptions, User } from '../../core/models';
import { Clock } from '../../core/utils';
import { IUserStore } from '../../data/stores/user';
import ng from 'angular';
import { StateService } from 'angular-ui-router';
import { DateTime } from 'luxon';
import { ISecurityService, AlertService } from '../../core/services';

const RepeatPeriods = [
  { id: 1, name: 'Hours' },
  { id: 2, name: 'Days' },
  { id: 3, name: 'Weeks' },
  { id: 4, name: 'Months' },
  { id: 5, name: 'Years' }
];

export class TaskModalController {
  static $inject = [
    '$scope',
    '$state',
    '$uibModalInstance',
    'TaskHttpService',
    'UserStore',
    'SecurityService',
    'task',
    'associations',
    'AlertService',
    'complete'
  ];

  isLoading: boolean = false;
  isNew: boolean;
  changes: Task;
  users: User[] = [];
  showTags: boolean = false;

  constructor(
    private scope: any,
    private stateService: StateService,
    private modalInstance: ng.ui.bootstrap.IModalServiceInstance,
    private taskHttpService: ITaskHttpService,
    private userStore: IUserStore,
    private securityService: ISecurityService,
    private task: Task,
    associations: RecordAssociation[],
    private alertService: AlertService,
    private complete?: boolean
  ) {
    this.isNew = !this.task;
    this.changes = Object.assign(new Task(), this.task || { associations });
    if (this.isNew) {
      this.changes.assignee = securityService.getCurrentUserId();
      this.changes.dueDate = Clock.local().startOfTomorrow();
    }
  }

  $onInit() {
    this.loadUsers();
    if (this.shouldUpdateTaskComplete()) {
      this.updateTaskComplete(this.complete);
    }
  }

  get repeatOptions() {
    return RepeatPeriods;
  }

  shouldUpdateTaskComplete() {
    return this.complete !== undefined && this.complete !== null;
  }

  updateTaskComplete(complete: boolean) {
    if (this.task.complete === complete) {
      this.task.complete
        ? this.alertService.warn('Task already complete')
        : this.alertService.warn('Task already open');
      return;
    }

    this.task.complete = complete;
    return this.taskHttpService
      .modifyTask(this.task, !!this.task.complete)
      .safeApply(this.scope, updated => {
        this.task = updated;
        this.task.complete
          ? this.alertService.success('Task complete')
          : this.alertService.success('Task incomplete');
      })
      .subscribe();
  }

  hasTags() {
    return this.changes.tagIds && this.changes.tagIds.length;
  }

  toggleShowTags() {
    this.showTags = !this.showTags;
  }

  toggleComplete() {
    this.changes.toggleCompleted(this.securityService.getCurrentUserId());
    this.save();
  }

  save(): void {
    this.isLoading = true;
    this.isNew ? this.create() : this.modify();
  }

  showAssociation(association: RecordAssociation) {
    this.modalInstance.dismiss('cancel');
    if (association.type.toLowerCase() === 'claim') {
      this.stateService.go('app.claim.overview', {
        claimId: association.id
      });
    } else if (association.type.toLowerCase() === 'entity') {
      this.stateService.go('app.entity.edit', { entityId: association.id });
    }
  }

  create(): void {
    this.taskHttpService
      .createTask(this.changes)
      .safeApply(this.scope, task => {
        this.alertService.success('Task created');
        this.modalInstance.close(task);
        this.isLoading = false;
      })
      .subscribe();
  }

  modify(): void {
    const isCompleted = this.changes.complete !== this.task.complete;
    this.taskHttpService
      .modifyTask(this.changes, isCompleted)
      .safeApply(this.scope, task => {
        this.alertService.success(isCompleted ? 'Task complete' : 'Task updated');
        this.modalInstance.close(task);
        this.isLoading = false;
      })
      .subscribe();
  }

  delete(): void {
    this.taskHttpService
      .deleteTask(this.changes.id)
      .safeApply(this.scope, () => this.modalInstance.close(undefined))
      .subscribe();
  }

  cancel(): void {
    this.modalInstance.dismiss('cancel');
  }

  toggleRepeat(): void {
    if (!this.changes.repeat) {
      this.changes.repeatEvery = undefined;
      this.changes.repeatOption = undefined;
    } else {
      this.changes.repeatEvery = Math.max(1, this.changes.repeatEvery);
      if (!this.changes.repeatEvery) {
        this.changes.repeatEvery = 1;
      }
      if (!this.changes.repeatOption) {
        this.changes.repeatOption = TaskRepeatOptions.Days;
      }
    }
  }

  removeRepeat() {
    this.changes.repeat = false;
    this.toggleRepeat();
  }

  dueDateChanged(dueDate: DateTime | undefined, hasTime: boolean) {
    this.changes.dueDate = dueDate ? dueDate.toUTC() : undefined;
    this.changes.hasTime = hasTime;
  }

  private loadUsers(): void {
    this.userStore
      .query()
      .then(users => (this.users = users.items))
      .catch(err => console.error(err));
  }
}
