import ReportController from './report.controller';

export default class Reportomponent implements ng.IComponentOptions {
  public controller: any;
  public template: string;
  public bindings: any;

  constructor() {
    this.bindings = {};
    this.controller = ReportController;
    this.template = require('./report.html');
  }
}
