import angular from 'angular';
import { CoreModule } from './app/core/module';
import { DataModule } from './app/data/module';
import { InstanceModule } from './app/instance/module';
import './styles/app.less';

/**
 * Bootstraps the application.
 */
const bootstrap = (): Promise<void> => {
  return new Promise<void>(function(resolve, reject) {
    try {
      debug('App : Bootstrap Starting');

      // Angular module
      angular.module('claims', [DataModule.name, CoreModule.name, InstanceModule.name]);

      // Connect to DOM
      angular.bootstrap(document, ['claims'], { strictDi: true });
      debug('App : Bootstrap Done');

      resolve();
    } catch (err) {
      reject(err);
    }
  });
};

/**
 * Bootstrap when document is ready.
 */
angular.element(document).ready(() => {
  bootstrap().catch(err => console.error(err));
});

/**
 * Writes debug log messages.
 */
function debug(message: string | Object) {
  console.log(message);
}
