import { StateService, IHookRegistry } from 'angular-ui-router';

/* @ngInject */
export default function CioAddButton(
  $rootScope,
  $state: StateService,
  $stateParams: any,
  $transitions: IHookRegistry
) {
  return {
    restrict: 'E',
    link: link,
    template: require('./cio-add-button.html')
  };

  function link(scope, element, attr) {
    // Hook up add option handlers

    scope.newInvoice = newInvoice;

    // On load we want to set the current claim, if
    // one is selected currently

    setCurrentClaim(scope);

    const unbindStateChange = $transitions.onSuccess({ to: '*' }, () => {
      setCurrentClaim(scope);
    });

    // Stop listening to the root scope, otherwise this
    // event handler will remain until the rootScope is
    // destroyed

    scope.$on('$destroy', () => {
      unbindStateChange();
    });
  }

  /**
   * Sets the current clalim (if any) or null.
   * @param {Object} scope The directive scope.
   */
  function setCurrentClaim(scope) {
    // We need to ignore the new claim state as we dont
    // have a claimId at this point

    if (!$state.includes('app.claim.**') || $state.is('app.claim.new')) {
      scope.claimId = null;
      return;
    }

    // This is a future proof check to make sure if
    // we introduce any other routes that dont contain
    // a claimId param, that it is easily identified

    if (!$stateParams.claimId) {
      console.error(
        'I expected to see a "claimId" param on the state "' +
          $state.current.name +
          '", but couldnt find it.'
      );

      scope.claimId = null;
      return;
    }

    scope.claimId = $stateParams.claimId;
  }

  function newInvoice() {
    const claimId = $stateParams.claimId;
    if (claimId) {
      $state.go('app.claim.invoices.new', { claimId: $stateParams.claimId });
    }
  }
}
