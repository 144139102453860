/* @ngInject */
export default function CioApplication(): any {
  return {
    restrict: 'E',
    controller: 'cioApplicationController',
    controllerAs: 'app',
    bindToController: true,
    template: require('./cio-application.html')
  };
}
