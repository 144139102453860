import { ILogService } from '../../core/services';

export class PdfViewerController {
  static $inject = ['$sce', 'LogService'];

  // bindings
  url: string;
  safeUrl: any;

  constructor(private sce: ng.ISCEService, private logService: ILogService) {}

  $onInit(): void {
    this.showPreview();
  }

  $onChanges(changes: any): void {
    if (changes.url) {
      this.showPreview();
    }
  }

  showPreview() {
    this.safeUrl = this.sce.trustAsResourceUrl(this.url);
  }

  handleResize(event) {
    this.logService.debug(event);
  }
}
