import { IResourceStore, IResourceStoreFactory } from './store';
import { Claim } from '../../core/models';

/* tslint:disable-next-line:no-empty-interface */
export interface IClaimStore extends IResourceStore<Claim> {}

/* @ngInject */
export default function ClaimStore(
  StoreFactory: IResourceStoreFactory<Claim>
): IClaimStore {
  return <IClaimStore>StoreFactory('/api/v1/claims/:id', { id: '@id' });
}
