import { IResourceStore, IResourceStoreFactory } from './store';
import { SavedSearch } from '../../core/models';

/* tslint:disable-next-line:no-empty-interface */
export interface ISavedSearchStore extends IResourceStore<SavedSearch> {}

/* @ngInject */
export default function SavedSearchStore(
  StoreFactory: IResourceStoreFactory<SavedSearch>
): ISavedSearchStore {
  return <ISavedSearchStore>StoreFactory('/api/v1/views/:id', { id: '@id' });
}
