import * as d3 from 'd3';
import { IClaimsWindow } from '../../core/utils';

/* @ngInject */
export default function CioPie($window: IClaimsWindow): any {
  return {
    restrict: 'E',
    scope: {
      data: '='
    },
    link: link
  };

  function link(scope, element, attrs) {
    let width = 480,
      height = 280;
    const radius = height / 2;

    if ($window.innerWidth <= 425) {
      width = 280;
      height = 480;
    }

    const svg = d3
      .select(element[0])
      .append('svg')
      .style('width', '100%');

    // Browser onresize event
    $window.onresize = function() {
      scope.updateWidthAndHeight();
      scope.$apply();
    };

    // Watch for resize event
    scope.$watch(
      function() {
        return $window.innerWidth;
      },
      function() {
        scope.render(scope.data);
      }
    );

    // watch for data changes and re-render
    scope.$watch(
      'data',
      function(newVals, oldVals) {
        return scope.render(newVals);
      },
      true
    );

    scope.updateWidthAndHeight = function() {
      if ($window.innerWidth <= 425) {
        width = 280;
        height = 480;
      } else {
        width = 480;
        height = 280;
      }
    };

    scope.render = function(data) {
      // remove all previous items before render
      svg.selectAll('*').remove();

      // If we don't pass any data, return out of the element
      if (!data) return;

      const color = d3.scale.category20();

      const vis = svg
        .data([data])
        .attr('width', width)
        .attr('height', height)
        .attr('style', 'margin-left:auto; margin-right: auto;')
        .append('g')
        .attr('transform', 'translate(' + radius + ',' + radius + ')');

      const pie = d3.layout.pie().value(function(d: any) {
        return d.value;
      });

      // declare an arc generator function
      const arc = d3.svg.arc().outerRadius(radius);

      // select paths, use arc generator to draw
      const arcs = vis
        .selectAll('g.slice')
        .data(pie)
        .enter()
        .append('g')
        .attr('class', 'slice');

      arcs
        .append('path')
        .attr('fill', function(d, i) {
          return color(<any>i);
        })
        .attr('d', function(d) {
          return arc(<any>d);
        });

      // set text to chart
      const legend = svg
        .append('g')
        .attr('class', 'legend')
        .attr('float', 'left')
        .attr('height', 200)
        .attr('width', 200)
        .attr('transform', 'translate(-20,50)');

      legend
        .selectAll('rect')
        .data(data)
        .enter()
        .append('rect')
        .attr('x', width - 165)
        .attr('y', function(d, i) {
          return i * 25;
        })
        .attr('width', 10)
        .attr('height', 10)
        .style('fill', function(d, i) {
          return color(<any>i);
        });

      legend
        .selectAll('text')
        .data(data)
        .enter()
        .append('text')
        .attr('x', width - 150)
        .attr('y', function(d, i) {
          return i * 25 + 8;
        })
        .text(function(d, i) {
          return data[i].key + ' (' + data[i].value + ')';
        });

      if ($window.innerWidth <= 425) {
        legend
          .selectAll('rect')
          .attr('x', width - 225)
          .attr('y', function(d, i) {
            return i * 25 + 260;
          });

        legend
          .selectAll('text')
          .attr('x', width - 210)
          .attr('y', function(d, i) {
            return i * 25 + 268;
          });
      }
    };
  }
}
