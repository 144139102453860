import { IDownloadService } from '../../../core/services';
import { IClaimManagerService } from '../claim-manager-service';
import '../claim-payments-service';
import { StateService } from 'angular-ui-router';
import sumBy from 'lodash/sumBy';
import { DateTime } from 'luxon';

/* @ngInject */
export default function ClaimPaymentsController(
  $scope: any,
  $state: StateService,
  ClaimManagerService: IClaimManagerService,
  ClaimPaymentsService,
  DownloadService: IDownloadService
): void {
  const claimId = $state.params.claimId;

  const vm = this;
  vm.payments = ClaimPaymentsService.payments();
  vm.claim = ClaimManagerService.claim();
  vm.newPayment = newPayment;
  vm.removePayment = removePayment;
  vm.isOverdue = isOverdue;
  vm.total = total;
  vm.exportExcel = exportExcel;
  vm.exportPdf = exportPdf;
  vm.disableSumInsuredPaymentTracking = false;

  activate();

  function newPayment() {
    if (!vm.claim.isClosed) {
      $state.go('app.claim.payments.new', { claimId: claimId });
    }
  }

  function activate() {
    ClaimPaymentsService.activate(claimId);
  }

  function removePayment(payment) {
    if (payment.id) {
      ClaimPaymentsService.remove(payment);
    }
  }

  function total() {
    return sumBy(vm.payments || [], 'total');
  }

  // CHECK: Date math
  function isOverdue(payment) {
    return (
      DateTime.utc()
        .endOf('day')
        .diff(DateTime.fromISO(payment.dueDate).toUTC(), 'days')
        .as('days') > 1
    );
  }

  function exportExcel() {
    exportTimesheets('xlsx', 'payments.xlsx');
  }

  function exportPdf() {
    exportTimesheets('pdf', 'payments.pdf');
  }

  function exportTimesheets(exportType: string, filename: string): void {
    DownloadService.downloadPost(
      '/api/v1/payments/export',
      { Claim: claimId, ExportType: exportType },
      filename
    );
  }
}
