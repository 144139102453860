import { WorkflowFiltersController } from './workflowFilters.controller';
import { ComponentBase } from '../../../../core/utils/componentBase';

export class WorkflowFiltersComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = { filters: '=' };
    this.controller = WorkflowFiltersController;
    this.template = require('./workflowFilters.html');
  }
}
