import { IDownloadService } from '../../../core/services';
import { IClaimInvoicesService } from '../claim-invoices-service';
import { IClaimManagerService } from '../claim-manager-service';
import { StateService } from 'angular-ui-router';
import sumBy from 'lodash/sumBy';
import { DateTime } from 'luxon';

/* @ngInject */
export default function ClaimInvoicesController(
  $state: StateService,
  $stateParams: any,
  ClaimManagerService: IClaimManagerService,
  ClaimInvoicesService: IClaimInvoicesService,
  DownloadService: IDownloadService
): void {
  const vm = this;
  const claimId = $stateParams.claimId;

  vm.invoices = ClaimInvoicesService.invoices();
  vm.claim = ClaimManagerService.claim();
  vm.newInvoice = newInvoice;
  vm.removeInvoice = removeInvoice;
  vm.isOverdue = isOverdue;
  vm.total = total;
  vm.exportExcel = exportExcel;
  vm.exportPdf = exportPdf;

  activate();

  function newInvoice() {
    if (!vm.claim.isClosed) {
      $state.go('app.claim.invoices.new', { claimId: claimId });
    }
  }

  function activate() {
    ClaimInvoicesService.activate(claimId);
  }

  function removeInvoice(invoice) {
    if (invoice.id) {
      ClaimInvoicesService.remove(invoice);
    }
  }

  function total() {
    return sumBy(vm.invoices || [], 'total');
  }

  // CHECK: Date math
  function isOverdue(invoice) {
    return (
      DateTime.utc()
        .endOf('day')
        .diff(DateTime.fromISO(invoice.dueDate).toUTC(), 'days')
        .as('days') > 1
    );
  }

  function exportExcel() {
    exportTimesheets('xlsx', 'invoices.xlsx');
  }

  function exportPdf() {
    exportTimesheets('pdf', 'invoices.pdf');
  }

  function exportTimesheets(exportType: string, filename: string): void {
    DownloadService.downloadPost(
      '/api/v1/invoices/export',
      { Claim: claimId, ExportType: exportType },
      filename
    );
  }
}
