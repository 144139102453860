import { SavedSearch } from '../../core/models';
import { ICacheService } from '../services/cache';
import * as angular from 'angular';

export class SearchChangeModalController {
  static $inject = ['Cache', '$uibModalInstance'];

  searches: SavedSearch[] = [];

  constructor(
    private cacheService: ICacheService,
    private modalInstance: ng.ui.bootstrap.IModalServiceInstance
  ) {}

  $onInit() {
    this.cacheService.savedSearches().then(searches => {
      this.searches = searches;
    });
  }

  select(search: SavedSearch) {
    this.modalInstance.close(search);
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }
}
