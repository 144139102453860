import { WorkflowFilterTagsController } from './workflowFilterTags.controller';
import { ComponentBase } from '../../../../core/utils/componentBase';

export class WorkflowFilterTagsComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      filter: '='
    };
    this.controller = WorkflowFilterTagsController;
    this.template = require('./workflowFilterTags.html');
  }
}
