import { WorkflowService } from '../workflow.service';
import { IFieldHttpService } from '../../../../core/http';
import { FieldDefinition, Guid, FieldDefinitionTypes } from '../../../../core/models';

interface IWorkflowActionUpdateFields {
  fields: FieldUpdate[];
}

enum RuleTypes {
  Copy = 'copy',
  Assign = 'assign'
}

class FieldUpdate {
  definition?: FieldDefinition;
  fieldId: Guid = Guid.empty();
  fieldName: string = '';
  value: string = '';
  rule: string = RuleTypes.Copy;

  constructor(props: any = {}) {
    Object.assign(this, props);
    this.fieldId = props.fieldId ? new Guid(props.fieldId) : Guid.empty();
  }

  reset() {
    this.fieldId = this.definition.id ? new Guid(this.definition.id) : Guid.empty();
    this.fieldName = this.definition.name;
    this.value = '';
    // At the moment we dont support direct assignment for entity fields
    this.rule =
      this.definition.type === FieldDefinitionTypes.Entity
        ? RuleTypes.Copy
        : RuleTypes.Assign;
  }

  toJSON(): any {
    return {
      fieldId: this.fieldId,
      fieldName: this.fieldName,
      value: this.value,
      rule: this.rule
    };
  }
}

export class WorkflowActionUpdateFieldsController {
  static $inject = ['$scope', 'FieldHttpService'];

  private _data: IWorkflowActionUpdateFields = { fields: [] };
  fields: FieldDefinition[] = [];
  rules: { label: string; value: RuleTypes }[] = [
    { label: 'Copy from another field', value: RuleTypes.Copy },
    { label: 'Using the following value', value: RuleTypes.Assign }
  ];

  constructor(private scope: any, private fieldHttpService: IFieldHttpService) {}

  $onInit() {
    this.loadFields();
  }

  get data(): IWorkflowActionUpdateFields {
    return this._data;
  }

  set data(value: IWorkflowActionUpdateFields) {
    this._data = value;
    this.data.fields = (this.data.fields || []).map(f => new FieldUpdate({ ...f }));
    this.mapExistingFields();
  }

  loadFields() {
    this.fieldHttpService
      .all()
      .safeApply(this.scope, fields => {
        this.fields = WorkflowService.getClaimFields(fields).filter(this.filterField);
        this.mapExistingFields();
      })
      .subscribe();
  }

  addField() {
    this.data.fields.push(new FieldUpdate());
  }

  removeField(index: number) {
    this.data.fields.splice(index, 1);
  }

  resetField(update: FieldUpdate) {
    update.reset();
  }

  private mapExistingFields() {
    if (!this.fields.length) {
      return;
    }

    for (const field of this.data.fields) {
      if (!field.definition && field.fieldName) {
        field.definition = this.fields.find(
          x => x.name === field.fieldName || (field.fieldId && field.fieldId.equals(x.id))
        );
      }
    }
  }

  private filterField(field: FieldDefinition): boolean {
    if (field.isReadOnly) {
      return false;
    }

    switch (field.name) {
      case 'tags':
      case 'assignees':
      case 'status':
      case 'reference':
        return false;
      default:
        break;
    }

    switch (field.type) {
      case FieldDefinitionTypes.Text:
      case FieldDefinitionTypes.Email:
      case FieldDefinitionTypes.Entity:
        return true;
      default:
        return false;
    }
  }
}
