import ng from 'angular';

export interface ITemplateService {
  createData(model, type): any;
}

interface ISection {
  fields: { fieldId: string }[];
  name: string;
}

/* @ngInject */
export function TemplateService($q: ng.IQService, Cache: any): ITemplateService {
  return {
    createData: createData
  };

  function createData(model, type) {
    const deferred = $q.defer();

    $q.all([Cache.fields(), selectTemplate(model, type)])
      .then(function(results) {
        const fields = results[0];
        const template = results[1];
        const mergedSections = [];

        // Ensure the model fields are available to set
        model.fields = model.fields || [];

        // Merge the field with the template field properties
        (template.sections || []).forEach((section: ISection) => {
          const mergedSection = {
            name: section.name,
            fields: []
          };

          (section.fields || []).forEach(field => {
            // Merge the field definition and template field values
            const definition = <any[]>fields.find(f => {
              return f.id === field.fieldId;
            });
            // If we dont find the definition, just ignore on screen
            if (definition === undefined) {
              return;
            }

            mergedSection.fields.push(ng.extend({}, field, definition));

            // If the model does not contain the field, add it
            const modelField = <any[]>model.fields.find(f => {
              return f.id === field.fieldId;
            });
            if (modelField === undefined) {
              model.fields.push({
                id: field.fieldId
              });
            }
          });

          mergedSections.push(mergedSection);
        });

        const data = {
          model: model,
          template: template,
          sections: mergedSections
        };

        deferred.resolve(data);
      })
      .catch(function(error) {
        deferred.reject(error);
      });

    return deferred.promise;
  }

  function selectTemplate(model, type) {
    if (type === 'claim') {
      return Cache.claimTemplate(model.templateId.toString());
    }
    if (type === 'entity') {
      return Cache.entityTemplate(model.templateId.toString());
    }

    throw new Error('Unknown template type ' + type);
  }
}
