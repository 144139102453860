export type PermissionTypes =
  | 'Administrator'
  | 'ManageSecurity'
  | 'ManageConfiguration'
  | 'ClaimView'
  | 'ClaimCreate'
  | 'ClaimModify'
  | 'TaskView'
  | 'TaskCreate'
  | 'TaskModify'
  | 'TaskDelete'
  | 'InvoiceView'
  | 'InvoiceCreate'
  | 'InvoiceModify'
  | 'InvoiceDelete'
  | 'NoteView'
  | 'NoteCreate'
  | 'NoteModify'
  | 'NoteDelete'
  | 'EntityView'
  | 'EntityCreate'
  | 'EntityDelete'
  | 'TimesheetView'
  | 'TimesheetModify'
  | 'SumInsuredView'
  | 'SumInsuredModify'
  | 'PaymentView'
  | 'PaymentCreate'
  | 'PaymentDelete'
  | 'FinancialView';

export const PERMISSIONS = {
  Administrator: 'Administrator' as PermissionTypes,
  ManageSecurity: 'ManageSecurity' as PermissionTypes,
  ManageConfiguration: 'ManageConfiguration' as PermissionTypes,
  ClaimView: 'ClaimView' as PermissionTypes,
  ClaimCreate: 'ClaimCreate' as PermissionTypes,
  ClaimModify: 'ClaimModify' as PermissionTypes,
  TaskView: 'TaskView' as PermissionTypes,
  TaskCreate: 'TaskCreate' as PermissionTypes,
  TaskModify: 'TaskModify' as PermissionTypes,
  TaskDelete: 'TaskDelete' as PermissionTypes,
  InvoiceView: 'InvoiceView' as PermissionTypes,
  InvoiceCreate: 'InvoiceCreate' as PermissionTypes,
  InvoiceModify: 'InvoiceModify' as PermissionTypes,
  InvoiceDelete: 'InvoiceDelete' as PermissionTypes,
  NoteView: 'NoteView' as PermissionTypes,
  NoteCreate: 'NoteCreate' as PermissionTypes,
  NoteModify: 'NoteModify' as PermissionTypes,
  NoteDelete: 'NoteDelete' as PermissionTypes,
  EntityView: 'EntityView' as PermissionTypes,
  EntityCreate: 'EntityCreate' as PermissionTypes,
  EntityModify: 'EntityModify' as PermissionTypes,
  EntityDelete: 'EntityDelete' as PermissionTypes,
  TimesheetView: 'TimesheetView' as PermissionTypes,
  TimesheetModify: 'TimesheetModify' as PermissionTypes,
  SumInsuredView: 'SumInsuredView' as PermissionTypes,
  SumInsuredModify: 'SumInsuredModify' as PermissionTypes,
  PaymentView: 'PaymentView' as PermissionTypes,
  PaymentCreate: 'PaymentCreate' as PermissionTypes,
  PaymentModify: 'PaymentModify' as PermissionTypes,
  PaymentDelete: 'PaymentDelete' as PermissionTypes,
  FinancialView: 'FinancialView' as PermissionTypes
};
