import { ClaimTemplateResource } from '../../../data/resources/claimTemplate';
import { IFieldStore } from '../../../data/stores/field';
import reject from 'lodash/reject';
import { FieldDefinitionScopes } from 'app/core/models';

/* @ngInject */
export default function ClaimTemplateManagerService(
  $q: ng.IQService,
  $uibModal: ng.ui.bootstrap.IModalService,
  // tslint:disable-next-line:no-shadowed-variable
  ClaimTemplateResource: ClaimTemplateResource,
  FieldStore: IFieldStore
): any {
  let theClaimTemplate = null,
    theFields = null;

  const service = {
    template: function() {
      return theClaimTemplate;
    },
    fields: function() {
      return theFields;
    },

    activate: activate,
    showModal: showModal
  };

  return service;

  function activate(claimTypeId) {
    const defer = $q.defer();

    const templatePromise = ClaimTemplateResource.get({ id: claimTypeId }).$promise,
      fieldsPromise = FieldStore.allFieldsPromise();

    $q.all([templatePromise, fieldsPromise]).then(function(values) {
      theClaimTemplate = values[0];
      theFields = reject(values[1], { scope: FieldDefinitionScopes.Entity });

      defer.resolve({});
    });

    return defer.promise;
  }

  function showModal(theConfig) {
    return $uibModal.open({
      template: require('./add-field.html'),
      controller: 'FieldAddModalController',
      size: 'lg',
      resolve: {
        fields: function() {
          return theFields;
        },
        config: function() {
          return theConfig || {};
        }
      }
    });
  }
}
