import { IAlertService } from '../../core/services/alert';
import { IEntityContactStore } from '../../data/stores/entityContact';
import EntityContactDetailController from './entity-contact-details';

/* @ngInject */
export default function EntityContactsController(
  EntityContactStore: IEntityContactStore,
  $stateParams: any,
  $uibModal: ng.ui.bootstrap.IModalService,
  AlertService: IAlertService
): void {
  const entityId = $stateParams.entityId;
  const vm = this;
  vm.contacts = [];
  vm.newContact = newContact;
  vm.edit = edit;
  vm.remove = remove;

  init();

  function init() {
    listContacts();
  }

  function listContacts() {
    EntityContactStore.query({}, { EntityId: entityId, Sort: 'Name' }).then(function(
      data
    ) {
      vm.contacts = data.items;
    });
  }

  function newContact() {
    const c = { entityId: entityId };
    showModal(c).result.then(function(contact) {
      EntityContactStore.save(contact).then(function(saved) {
        listContacts();
        AlertService.success('Contact successfully saved.');
      });
    });
  }

  function edit(c) {
    showModal(c).result.then(function(contact) {
      EntityContactStore.update(contact).then(function(updated) {
        listContacts();
        AlertService.success('Contact successfully updated.');
      });
    });
  }

  function remove(c) {
    EntityContactStore.delete(c).then(function(deleted) {
      listContacts();
      AlertService.success('Contact successfully deleted.');
    });
  }

  function showModal(contact) {
    return $uibModal.open({
      template: require('./entity-contact-details.html'),
      size: 'lg',
      controller: EntityContactDetailController,
      resolve: {
        contact: function() {
          return contact;
        }
      }
    });
  }
}
