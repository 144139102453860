import { DocumentService } from './document.service';
import { CoreModule } from '../../core/module';
import { UIModule } from '../../ui/module';
import * as angular from 'angular';

export const DocumentModule = angular
  .module('app.instance.documents', [CoreModule.name, UIModule.name])

  // Services
  .service('DocumentService', DocumentService);
