export const DEFAULT_PAGE_SIZE: number = 50;

export class Page<T> {
  items: T[];
  totalCount: number;
  take: number;
  skip: number;

  constructor(take?: number, skip?: number, items?: T[], totalCount?: number) {
    this.take = take || DEFAULT_PAGE_SIZE;
    this.skip = skip || 0;
    this.items = items || [];
    this.totalCount = totalCount || this.take || 0;
  }

  get page(): number {
    return this.skip > 0 ? this.skip / this.take + 1 : 1;
  }

  get count(): number {
    return this.items.length;
  }

  get hasNextPage(): boolean {
    return this.page * this.take < this.totalCount;
  }

  get hasPreviousPage(): boolean {
    return this.page > 1;
  }

  get totalPages(): number {
    return Math.ceil(this.totalCount / this.take);
  }

  static fromJson<TItem>(json: any, itemMap: (item) => TItem): Page<TItem> {
    const { items, totalCount, take, skip } = json;
    const page = Object.assign(new Page<TItem>(), {
      items,
      totalCount,
      take,
      skip
    }) as Page<TItem>;
    page.items = (json.items || []).map(itemMap);
    return page;
  }

  static empty(): Page<any> {
    return new Page<any>();
  }
}
