import { NotificationsWidgetController } from './notificationsWidget.controller';

export class NotificationsWidgetComponent implements ng.IComponentOptions {
  public controller: any;
  public template: string;
  public bindings: any;

  constructor() {
    this.bindings = {
      config: '<'
    };
    this.controller = NotificationsWidgetController;
    this.template = require('./notificationsWidget.html');
  }
}
