import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/empty';

export const logError = (error: any): Observable<any> => {
  const errorMessage = error.hasOwnProperty('statusCode')
    ? `Http Error\n` +
      `Method  : ${error.method}\n` +
      `URL     : ${error.url}\n` +
      `Status  : ${error.statusCode}\n` +
      `Reason  : ${error.statusText}\n` +
      `Response: ${error.text}`
    : error;

  console.error(errorMessage);

  return Observable.throw(error);
};

export const continueWith = (
  func: (error?: any) => any
): ((error: any) => Observable<any>) => {
  return (error: any) => {
    func(error);
    return Observable.empty();
  };
};
