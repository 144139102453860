import { ICacheService } from '../../services/cache';
import { Guid, FieldDefinition } from '../../../core/models';

const DEFAULT_COLOR = '#777777';

interface IStatusSetting {
  name: string;
  description: string;
  color: string;
}

export class StatusBadgeController {
  static $inject = ['Cache'];

  text: string = '';
  description: string = '';
  style = { 'background-color': DEFAULT_COLOR };
  private _status: string;
  private _templateId: Guid;

  constructor(private cacheService: ICacheService) {}

  get status(): string {
    return this._status;
  }

  set status(value: string) {
    this._status = value;
    this.text = this._status;
  }

  get templateId(): Guid {
    return this._templateId;
  }

  set templateId(value: Guid) {
    if (value) {
      this._templateId = new Guid(value);
      this.loadField();
    }
  }

  private loadField() {
    this.cacheService
      .claimTemplate(this._templateId.toString())
      .then(template => {
        const systemField = (template.systemFields || []).find(x => x.name === 'status');
        if (!systemField) {
          return;
        }
        return this.cacheService
          .field(systemField.fieldId)
          .then(field => this.setStyle(field));
      })
      .catch(error => console.log(error));
  }

  private setStyle(field: FieldDefinition) {
    if (!this._status) {
      return;
    }
    const statuses: IStatusSetting[] = field.settings.status || [];
    const status = statuses.find(
      x => x.name.toLowerCase() === this._status.toLowerCase()
    );
    if (status) {
      this.description = status.description;
      this.style['background-color'] = status.color || DEFAULT_COLOR;
    }
  }
}
