import { Permission } from '../../core/models';
import * as ng from 'angular';

/* tslint:disable-next-line:no-empty-interface */
export interface PermissionResource
  extends ng.resource.IResourceClass<ng.resource.IResource<Permission>> {}

/* @ngInject */
export default function PermissionResource(
  $resource: ng.resource.IResourceService
): PermissionResource {
  return <PermissionResource>$resource<ng.resource.IResource<Permission>>(
    '/api/v1/permissions/:id',
    { id: '@id' },
    {
      update: {
        method: 'PUT',
        params: {
          id: '@id'
        }
      }
    }
  );
}
