import './claim-template-service';

/* @ngInject */
export default function SystemFieldController($scope, ClaimTemplateManagerService): void {
  const vm = this;

  vm.systemField = $scope.sf;
  vm.field = {};
  vm.currentStatusSet = {};
  vm.setField = setField;
  vm.getTmpl = getTmpl;
  vm.changeStatus = changeStatus;

  refreshVmField();

  function refreshVmField() {
    vm.field = ClaimTemplateManagerService.fields().find(
      x => x.id === vm.systemField.fieldId
    );

    if (vm.field) {
      switch (vm.field.type) {
        case 'StatusSet':
          if (vm.systemField.defaultValue) {
            const status = vm.field.settings.status.find(
              x => x.name === vm.systemField.defaultValue
            );
            if (status) {
              vm.currentStatusSet.color = status.color;
              vm.currentStatusSet.name = status.name;
              vm.currentStatusSet.description = status.description;
            }
          }
          break;

        default:
          break;
      }
    }
  }

  function setField() {
    ClaimTemplateManagerService.showModal({
      fieldType: vm.systemField.fieldType
    }).result.then(function(selectedField) {
      vm.systemField.fieldId = selectedField.id;
      if (vm.field.type === 'StatusSet') {
        vm.currentStatusSet = vm.currentStatusSet || {
          color: '',
          name: '',
          description: ''
        };
      }
      refreshVmField();
    });
  }

  function getTmpl() {
    switch (vm.systemField.fieldType) {
      case 'StatusSet':
        return '/app/instance/settings/claimTypes/partials/status-set.html';
      default:
        return '/app/instance/settings/claimTypes/partials/text.html';
    }
  }

  function changeStatus(status) {
    vm.currentStatusSet.color = status.color;
    vm.currentStatusSet.name = status.name;
    vm.currentStatusSet.description = status.description;
    vm.systemField.defaultValue = status.name;
  }
}
