const md5 = require('md5');

/**
 * Provides hashing algorithms.
 */
export interface IHashService {
  /**
   * Hashs the specified string using MD5.
   * @params {String} value The value to hash.
   */
  MD5(value: string): string;
}

/* @ngInject */
export function HashService(): IHashService {
  return {
    MD5: md5
  };
}
