import '../../../core/services/alert';
import '../../../data/resources/tax-code';

/* @ngInject */
export default function TaxCodeNewController(
  $scope,
  $state,
  TaxCodeResource,
  AlertService
) {
  $scope.model = {};
  $scope.newForm = {};
  $scope.newForm.active = false;

  $scope.saveModel = function(model) {
    $scope.newForm.active = true;

    TaxCodeResource.save(model)
      .$promise.then(function(updated) {
        AlertService.success('New tax code ' + updated.code + ' has been created.');
        $scope.newForm.active = false;
        $scope.newForm.$setPristine();

        $state.go('app.taxcode.edit', { id: updated.id });
      })
      .catch(function(error) {
        AlertService.error('error', 'Could not create tax code, please try again.');
        $scope.newForm.active = false;
      });
  };
}
