import {
  TemplateField,
  EntityTemplate,
  EntityReferenceStrategies
} from '../../../core/models';
import ng from 'angular';
import uniqBy from 'lodash/uniqBy';

export default class EntityFieldsModalController {
  static $inject = ['template', 'fields', 'tableColumns', '$uibModalInstance'];

  public allFields: TemplateField[];
  public selectedFields: any[];
  public selected: any;
  public editor = {};

  constructor(
    public template: EntityTemplate,
    public fields: any[],
    public tableColumns: any[],
    private $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance
  ) {}

  $onInit() {
    this.allFields = [];
    this.selected = {};
    this.selectedFields = uniqBy(ng.copy(this.tableColumns || []), 'name');
    this.loadFields();
  }

  loadFields() {
    const that = this;
    if (this.template.entityReferenceStrategy !== EntityReferenceStrategies.Blank) {
      that.allFields.push(<any>{
        name: 'reference',
        label: 'Reference',
        type: 'reference'
      });
    }

    this.template.sections.forEach(section => {
      section.fields.forEach(field => {
        that.allFields.push(that.fields.find(x => x.id === field.fieldId));
      });
    });
  }

  select(field: any): void {
    if (!field) {
      return;
    }

    this.selected = null;

    // Ensure we do not add the same field to the
    // report twice

    let found = false;
    for (const i of this.selectedFields) {
      if (i.name === field.name) {
        found = true;
        break;
      }
    }

    if (!found) {
      field.tableDisplay = true;
      this.selectedFields.push(field);
    }
  }

  removeField(field: any, $index: number) {
    field.tableDisplay = false;
    this.selectedFields.splice($index, 1);
  }

  save(): void {
    this.$uibModalInstance.close(this.selectedFields);
  }

  cancel(): void {
    this.$uibModalInstance.dismiss('cancel');
  }
}
