export default class DropdownFieldComponentController {
  static $inject = [];

  public field: any;
  public sections: any[];
  public templateFields: any[];
  public definition: any;
  private fieldOptions = {};

  $onInit() {
    if (this.definition.settings.ddlDefault && !this.field.value) {
      this.field.value = this.definition.settings.ddlDefault;
    }
  }

  getSelectOptions(fieldId: string, options: any): any {
    if (this.fieldOptions[fieldId]) {
      return this.fieldOptions[fieldId];
    }

    this.fieldOptions[fieldId] = [];
    const results = [];

    options = options || '';
    (options.split('$$') || []).forEach(item => {
      item = item.substr(1);
      item = item.substr(0, item.length - 1);

      const parts = item.split('|');
      results.push(parts[1]);
    });

    this.fieldOptions[fieldId].length = 0;
    this.fieldOptions[fieldId].push.apply(this.fieldOptions[fieldId], results);

    return this.fieldOptions[fieldId];
  }
}
