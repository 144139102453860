import '../../core/module';

/* @ngInject */
export function ApplicationConfig(
  $httpProvider,
  $locationProvider,
  $analyticsProvider
): void {
  // Enable HTML5 urls
  $locationProvider.html5Mode(true).hashPrefix('!');

  $httpProvider.interceptors.push('ApiInterceptor');

  $analyticsProvider.developerMode(window.location.hostname === 'localhost');
  $analyticsProvider.firstPageview(true);
}
