import ng from 'angular';
import { IAlertService } from '../../core/services';
import debounce from 'lodash/debounce';
import { ISmsTarget } from './sms.service';
import { ISmsTemplate } from '../../core/models/smsTemplate';

const REGEX_ALLOWED = /([^+\d])/gi;
const REGEX_NOT_NUMBER = /[^0-9]/gi;

enum NumberStatus {
  Empty = 'empty',
  Valid = 'valid',
  Invalid = 'invalid'
}

export class SmsModalController {
  static $inject = ['target', '$scope', '$uibModalInstance', '$http', 'AlertService'];

  numberStatus: NumberStatus = NumberStatus.Empty;
  isValidating: boolean = false;
  isSending: boolean = false;
  text: string;
  countryCode: string = '+61';
  smsTemplates: ISmsTemplate[];
  currentSmsTemplates: ISmsTemplate[];
  isTemplatePopupOpen: boolean;
  searchTemplate: string;
  private _phoneNumber: string;

  throttleValidateNumber = debounce(this.validateNumber, 300);

  constructor(
    public target: ISmsTarget,
    private scope: any,
    private modalInstance: ng.ui.bootstrap.IModalInstanceService,
    private httpService: ng.IHttpService,
    private alertService: IAlertService
  ) {}

  $onInit() {
    this.getAllSmsTemplates();
    this.isTemplatePopupOpen = false;
    this.searchTemplate = '';
    if ((this.target.to || '').length > 0) {
      this.phoneNumber = this.target.to;
    }
  }

  get canSend(): boolean {
    return (
      !this.isSending &&
      !this.isValidating &&
      this.numberStatus === NumberStatus.Valid &&
      !!this.text
    );
  }

  get canEdit(): boolean {
    return !this.isValidating && !this.isSending;
  }

  get phoneNumber(): string {
    return this._phoneNumber;
  }

  set phoneNumber(value: string) {
    let local = (value || '').trim().replace(REGEX_ALLOWED, '');
    local =
      local.indexOf('+') === 0
        ? '+' + local.replace(REGEX_NOT_NUMBER, '')
        : local.replace(REGEX_NOT_NUMBER, '');

    this._phoneNumber = local;
  }

  cancel() {
    this.modalInstance.dismiss('cancel');
  }

  send() {
    if (!this.canSend) {
      return;
    }
    this.isSending = true;

    const message = {
      body: this.text,
      to: `+61 ${this._phoneNumber}`,
      countryCode: this.countryCode,
      recordId: this.target.recordId,
      recordType: this.target.recordType
    };

    this.httpService
      .post('/api/v1/sms/send', message)
      .then(_ => {
        this.alertService.success('SMS successfully sent');
        this.isSending = false;
        this.modalInstance.close({ success: true });
      })
      .catch(error => {
        this.alertService.error('Failed to send SMS');
        console.error(`Failed to send SMS ${error.toString()}`);
        this.isSending = false;
      });
  }

  onTemplateSelect(template) {
    this.closeSmsPopup();
    this.parseSmsBody(this.target.recordId, template.id).then(body => {
      this.text = body.replace(/<br ?\/?>/g, '\n');
      this.scope.$apply();
    });
  }

  parseSmsBody(claimId, templateId): Promise<string> {
    const requestBody = {
      claimId,
      templateId
    };

    return new Promise(resolve => {
      return this.httpService
        .post<any>('/api/v1/sms/parse', requestBody)
        .then(response => {
          return resolve(response.data.body);
        });
    });
  }

  validateNumber() {
    if (!this._phoneNumber) {
      this.numberStatus = NumberStatus.Empty;
      return;
    }
    this.isValidating = true;

    this.httpService
      .get<any>(`/api/v1/sms/validate?number=${encodeURIComponent(this._phoneNumber)}`)
      .then(response => {
        this.isValidating = false;
        if (!response.data.isValid) {
          this.numberStatus = NumberStatus.Invalid;
          return;
        }

        this.numberStatus = NumberStatus.Valid;
        this.countryCode = response.data.countryCode;
        this._phoneNumber = response.data.nationalNumber;
      })
      .catch(error => {
        this.numberStatus = NumberStatus.Invalid;
        console.error(`Failed to validate mobile number ${error.toString()}`);
        this.isValidating = false;
      });
  }

  getAllSmsTemplates() {
    this.httpService
      .get<ISmsTemplate[]>('/api/v1/sms-templates/all')
      .then(response => {
        this.smsTemplates = response.data;
        this.currentSmsTemplates = response.data;
      })
      .catch(error => {
        console.error(`Failed to get SMS templates ${error.toString()}`);
      });
  }

  toogleSmsPopup() {
    this.isTemplatePopupOpen = !this.isTemplatePopupOpen;
  }

  closeSmsPopup() {
    this.isTemplatePopupOpen = false;
  }

  onSmsTemplateSearch() {
    if (this.searchTemplate && this.searchTemplate !== ' ') {
      this.currentSmsTemplates = this.smsTemplates.filter(
        template =>
          template.name.toLowerCase().indexOf(this.searchTemplate.toLowerCase()) !== -1
      );
      return;
    }

    this.currentSmsTemplates = this.smsTemplates;
  }
}
