import { ComponentBase } from '../../core/utils';
import { SearchHeaderController } from './searchHeader.controller';

export class SearchHeaderComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      column: '<',
      sort: '<',
      sortDescending: '<',
      onSort: '&'
    };
    this.controller = SearchHeaderController;
    this.template = require('./searchHeader.html');
  }
}
