import { ComponentBase } from '../../../core/utils/componentBase';
import { EntityFilesController } from './entityFiles.controller';

export class EntityFilesComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      entity: '<'
    };
    this.controller = EntityFilesController;
    this.template = require('./entityFiles.html');
  }
}
