const LOADER_NAME = 'maps_loader';
const GOOGLE_API_KEY = 'AIzaSyCNsm_facrTCa0NjFa2AqdvUKlBwVb3-dU';

export interface IMapService {
  load(): ng.IPromise<any>;
}

/* @ngInject */
export function MapService($q: ng.IQService): any {
  const deferred = $q.defer();
  let isLoading = false;

  return {
    load: load
  };

  function load(): ng.IPromise<any> {
    if (isLoading) {
      return deferred.promise;
    }
    isLoading = true;

    // Callback once setup
    (window as any).cioMapsInit = () => {
      deferred.resolve();
    };

    const script = document.createElement('script');
    script.id = LOADER_NAME;
    script.type = 'text/javascript';
    script.async = true;
    script.src =
      'https://maps.googleapis.com/maps/api/js?key=' +
      GOOGLE_API_KEY +
      '&libraries=places&callback=cioMapsInit';
    document.body.appendChild(script);

    return deferred.promise;
  }
}
