import { ICurrencyHttpService, IOrganizationHttpService } from '../../../core/http';
import { Currency, Organization } from '../../../core/models';

export class CurrencyEditController {
  static $inject = ['$scope', 'CurrencyHttpService', 'OrganizationHttpService'];

  selected: Currency;
  currencies: Currency[] = [];
  private _currencyCode: string;
  private _organization: Organization;

  constructor(
    private scope: any,
    private currencyHttpService: ICurrencyHttpService,
    private organizationHttpService: IOrganizationHttpService
  ) {}

  $onInit() {
    this.loadOrganization();
  }

  get currency(): string {
    return this._currencyCode;
  }

  set currency(value: string) {
    this._currencyCode = value;
    this.selectFromCode();
  }

  currencyChanged(newCurrency: Currency) {
    this.selected = newCurrency;
    this._currencyCode = newCurrency ? newCurrency.code : undefined;
    (this as any).onChange({ currency: this._currencyCode });
  }

  private loadOrganization() {
    this.organizationHttpService
      .getOrganization()
      .safeApply(this.scope, organization => {
        this._organization = organization;
        this._organization.currencies = this._organization.currencies || [];
        this.loadCurrencies();
      })
      .subscribe();
  }

  private loadCurrencies() {
    this.currencyHttpService
      .getAll()
      .safeApply(this.scope, currencies => {
        this.currencies = currencies.filter(x =>
          this._organization.currencies.some(oc => oc === x.code)
        );
        this.selectFromCode();
      })
      .subscribe();
  }

  private selectFromCode() {
    if (!this._currencyCode) {
      this.selected = undefined;
      return;
    }
    if (!this.currencies || !this._organization) {
      return;
    }
    const currency = this.currencies.find(
      x => x.code.toUpperCase() === this._currencyCode.toUpperCase()
    );
    this.selected = currency ? currency : undefined;
  }
}
