import * as angular from 'angular';
import { IHookRegistry } from 'angular-ui-router';
import { ISessionService, ILogService } from '../../core/services';
import { hideLoader } from '../utils/loader';

/* @ngInject */
export function AuthenticationHandler(
  $rootScope: any,
  $transitions: IHookRegistry,
  LogService: ILogService
): void {
  const requiresAuthCriteria = {
    to: state => state.data && state.data.authenticate
  };

  const redirectToLogin = transition => {
    const SessionService: ISessionService = transition.injector().get('SessionService');
    const $state = transition.router.stateService;

    if (!SessionService.isActive()) {
      // Store desired route, so we can redirect after login
      $rootScope.nextState = transition.to.name;
      $rootScope.nextStateParams = angular.copy(transition.to.params);

      LogService.debug('Auth :: No session, redirecting to login');

      hideLoader();

      return $state.target('auth.login', undefined, { location: false });
    }
  };

  $transitions.onBefore(requiresAuthCriteria, redirectToLogin, {
    priority: 10
  });
}
