import { GroupEntitySelectController } from './groupEntitySelect.controller';

export interface IGroupEntitySelectService {
  open(title?: string, roleParticipation?: any): Promise<any>;
}

/* @ngInject */
export function GroupEntitySelectService($uibModal): IGroupEntitySelectService {
  const self = this;

  /**
   * Opens a modal and allows the user to select roles.
   * @param {string} [title=Select entities] - the modal title.
   * @param {object[]} [roleParticipation] - the role participation configuration.
   * @returns {promise}
   */
  self.open = function(title?: string, roleParticipation?: any) {
    title = title || 'Select entities';
    roleParticipation = roleParticipation || {};

    const modalInstance = $uibModal.open({
      template: require('./groupEntitySelect.html'),
      controller: GroupEntitySelectController,
      resolve: {
        title: function() {
          return title;
        },
        roleParticipation: function() {
          return roleParticipation;
        }
      }
    });

    return modalInstance.result;
  };
  return self;
}
