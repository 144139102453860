import { HttpService } from './http';
import { Guid, Page, Workflow, WorkflowSearch } from '../models';
import { logError } from '../utils/rx';
import { Observable } from 'rxjs/Observable';

export interface IWorkflowHttpService {
  getWorkflow(workflowId: Guid): Observable<Workflow>;
  createWorkflow(workflow: Workflow): Observable<Workflow>;
  modifyWorkflow(workflow: Workflow): Observable<Workflow>;
  deleteWorkflow(workflowId: Guid): Observable<boolean>;
  findWorkflows(search: WorkflowSearch): Observable<Page<Workflow>>;
}

export class WorkflowHttpService implements IWorkflowHttpService {
  static $inject = ['HttpService'];

  private readonly _url: string = '/api/v1/workflows';

  constructor(private httpService: HttpService) {}

  getWorkflow(workflowId: Guid): Observable<Workflow> {
    const url = `${this._url}/${workflowId.toString()}`;
    return this.httpService
      .get(url)
      .map(res => Workflow.fromJson(res.json()))
      .catch(logError);
  }

  createWorkflow(workflow: Workflow): Observable<Workflow> {
    const body = JSON.stringify(workflow);
    return this.httpService
      .post(this._url, body)
      .map(res => Workflow.fromJson(res.json()))
      .catch(logError);
  }

  modifyWorkflow(workflow: Workflow): Observable<Workflow> {
    const url = `${this._url}/${workflow.id.toString()}`;
    const body = JSON.stringify(workflow);
    return this.httpService
      .put(url, body)
      .map(res => Workflow.fromJson(res.json()))
      .catch(logError);
  }

  deleteWorkflow(workflowId: Guid): Observable<boolean> {
    const url = `${this._url}/${workflowId.toString()}`;
    return this.httpService
      .delete(url)
      .map(_ => true)
      .catch(logError);
  }

  findWorkflows(search: WorkflowSearch): Observable<Page<Workflow>> {
    const url = `${this._url}?${search.toParams()}`;
    return this.httpService
      .get(url)
      .map(res => Page.fromJson(res.json(), item => Workflow.fromJson(item)))
      .catch(logError);
  }
}
