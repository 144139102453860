import { Tag, Guid } from '../../../core/models';
import { ITagHttpService } from 'app/core/http';

interface ISelectableTag {
  tag: Tag;
  selected: boolean;
}

export class TagSelectController {
  static $inject = ['$scope', 'TagHttpService'];

  selected: ISelectableTag[] = [];
  tags: ISelectableTag[] = [];
  searchQuery: string = '';

  constructor(private scope: any, private tagHttpService: ITagHttpService) {}

  $onInit() {
    this.tagHttpService
      .getAllTags()
      .safeApply(this.scope, items => {
        this.tags = (items || []).map(t => {
          return {
            tag: t,
            selected: !!(this.selected || []).find(x => x.tag.id.equals(t.id))
          };
        });
      })
      .subscribe();
  }

  changed() {
    const onChange = (this as any).onChange;
    if (!onChange) {
      return;
    }
    onChange({
      tags: this.tags || []
    });
  }
}
