import '../../data/resources/sum-insured-category';

/* @ngInject */
export default function CioSumInsuredCategoryDisplay(SumInsuredCategoryResource): any {
  return {
    restrict: 'AE',
    link: link,
    scope: {
      categoryId: '='
    },
    template: '<span>{{text}}</span>'
  };

  function link(scope) {
    if (!scope.categoryId) {
      scope.text = '';
      return;
    }

    SumInsuredCategoryResource.get({ id: scope.categoryId }).$promise.then(function(
      category
    ) {
      scope.text = category.name;
    });
  }
}
