import { StateProvider } from 'angular-ui-router';

/* @ngInject */
export function NotificationRoutes($stateProvider: StateProvider) {
  $stateProvider
    .state('app.notifications', <any>{
      title: 'Notifications',
      url: '/notifications',
      template: '<cio-notification-list></cio-notification-list>'
    })
    .state('app.notification', <any>{
      title: 'View Notification',
      url: '/notification/{id}',
      template: '<cio-notification-view></cio-notification-view>'
    });
}
