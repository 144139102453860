import { ReportTypes } from '../../core/models';

export interface IReportService {
  /**
   * Returns the friendly name for the report type.
   *
   * @param {string} type The report type.
   * @returns {string} The text version of the report type.
   */
  getTypeText(type: string): string;
}

export default class ReportService implements IReportService {
  getTypeText(type: string): string {
    switch (type) {
      case ReportTypes.Claim:
        return 'Claim';
      case ReportTypes.InvoiceSummary:
        return 'Invoice Summary';
      case ReportTypes.InvoiceDetail:
        return 'Invoice Detail';
      case ReportTypes.PaymentSummary:
        return 'Payment Summary';
      case ReportTypes.PaymentDetail:
        return 'Payment Detail';
      case ReportTypes.Timesheet:
        return 'Timesheet';
      default:
        return 'Unknown';
    }
  }
}
