import { IResourceStore, IResourceStoreFactory } from './store';
import { Role } from '../../core/models';

/* tslint:disable-next-line:no-empty-interface */
export interface IRoleStore extends IResourceStore<Role> {}

/* @ngInject */
export default function RoleStore(StoreFactory: IResourceStoreFactory<Role>): IRoleStore {
  return <IRoleStore>StoreFactory('/api/v1/roles/:id', { id: '@id' });
}
