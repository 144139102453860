import { TimeEditController } from './timeEdit.controller';
import { ComponentBase } from '../../../core/utils/componentBase';

export class TimeEditComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      time: '<',
      onChange: '&',
      noMinutes: '<'
    };
    this.controller = TimeEditController;
    this.template = require('./timeEdit.html');
  }
}
