/* @ngInject */
export default function UserSelectController(
  $scope: any,
  $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
  title: string,
  config: any,
  users: any
) {
  $scope.title = title;
  $scope.users = users.data
    ? users.data.items
    : Array.isArray(users)
    ? users
    : users.items;
  $scope.query = '';
  $scope.config = config;

  $scope.selectUser = function(user) {
    $uibModalInstance.close(user);
  };

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };
}
