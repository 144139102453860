import { IAlertService } from '../../../core/services';
import { IRoleStore } from '../../../data/stores/role';
import { StateService } from 'angular-ui-router';

/* @ngInject */
export default function RoleListController(
  $scope: any,
  $state: StateService,
  RoleStore: IRoleStore,
  AlertService: IAlertService
): void {
  $scope.groups = [];

  RoleStore.query()
    .then(function(result) {
      $scope.groups = result.items;
    })
    .catch(function(error) {
      AlertService.error('Could not load roles, please try again.');
    });

  $scope.viewRole = function(role) {
    $state.go('app.role.edit', { id: role.id });
  };

  $scope.newRole = function() {
    $state.go('app.role.new');
  };
}
