import { HtmlEditorController } from './htmlEditor.controller';
import { ComponentBase } from '../../../core/utils/componentBase';

export class HtmlEditorComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      content: '=',
      required: '<'
    };
    this.controller = HtmlEditorController;
    this.template = require('./htmlEditor.html');
  }
}
