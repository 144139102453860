import WidgetsModalController from './widgets-modal-controller';
import '../../data/resources/dashboard';
import './widgets-service';

/* @ngInject */
export default function DashboardController(
  DashboardResource,
  userConfig,
  WidgetsService,
  $uibModal
): void {
  const vm = this;
  vm.addWidget = addWidget;
  vm.removeWidget = removeWidget;
  vm.save = save;
  vm.widgets = userConfig.widgets || [];
  vm.isEditMode = false;
  vm.toggleEditMode = toggleEditMode;

  function toggleEditMode() {
    vm.isEditMode = !vm.isEditMode;
  }

  function addWidget() {
    const modalInstance = $uibModal.open({
      template: require('./widgets-modal.html'),
      controller: WidgetsModalController,
      resolve: {
        widgets: function() {
          return WidgetsService.widgets;
        }
      }
    });

    modalInstance.result.then(function(widget) {
      vm.widgets.push(widget);
    });
  }

  function removeWidget(array, index, widget) {
    array.splice(index, 1);
  }

  function save() {
    DashboardResource.update({ widgets: vm.widgets }).$promise.then(function(data) {
      toggleEditMode();
    });
  }
}
