import { StateService } from 'angular-ui-router';
import { ILogService } from '../../core/services/log';
import { IAlertService } from '../../core/services/alert';
import '../../data/resources/claimTemplate';

/* @ngInject */
export default function ClaimTypeModalController(
  $scope: any,
  $uibModal: ng.ui.bootstrap.IModalService,
  $state: StateService,
  LogService: ILogService,
  AlertService: IAlertService,
  ClaimTemplateResource
): void {
  $scope.select = function() {
    ClaimTemplateResource.query()
      .$promise.then(function(types) {
        // Exclude disabled items
        const enabled = types.items.filter(t => !t.disabled);
        selectSignleOrShowAll(enabled);
      })
      .catch(function(err) {
        LogService.error(err, { action: 'Get claim templates' });
        AlertService.error('Could not load claim templates, please try again');
      });
  };

  /**
   * Selects the claim template if there is only one, otherwise asks
   * the user to select which one to use.
   * @param {Object[]} types The claim template to select from.
   */
  function selectSignleOrShowAll(types) {
    if (types.length === 0) {
      AlertService.warn('No claim templates have been configured yet');
      return;
    }

    // If there is only one type, dont ask the user to pick
    // which one they would like - make the mind up for them

    if (types.length === 1) {
      newClaim(types[0]);
      return;
    }

    $uibModal.open({
      template: require('./claim-type-modal.html'),
      controller: 'ClaimTypeSelectModalController',
      controllerAs: 'claimType',
      bindToController: true,
      resolve: {
        types: function() {
          return types;
        }
      }
    });
  }

  /**
   * Redirects the user to a new claim form.
   */
  function newClaim(type) {
    $state.go('app.claim.new', { templateId: type.id });
  }
}
