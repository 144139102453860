import EntityFieldsModalController from './entity-field-modal.controller';
import { TemplateField } from '../../../core/models';

/**
 * A service for selecting and configuring a report's fields.
 */
export interface IEntityFieldService {
  selectFields(
    template: any,
    fields: any[],
    tableColumns: any[]
  ): ng.IPromise<TemplateField[]>;
}

export default class EntityFieldService implements IEntityFieldService {
  static $inject = ['$uibModal'];

  constructor(private $uibModal: ng.ui.bootstrap.IModalService) {}

  selectFields(
    template: any,
    fields: any[],
    tableColumns: any[]
  ): ng.IPromise<TemplateField[]> {
    const modalInstance = this.$uibModal.open({
      template: require('./entity-field-modal.html'),
      controller: EntityFieldsModalController,
      controllerAs: '$ctrl',
      resolve: {
        template: function() {
          return template;
        },
        fields: function() {
          return fields;
        },
        tableColumns: function() {
          return tableColumns;
        }
      }
    });

    return <ng.IPromise<TemplateField[]>>modalInstance.result;
  }
}
