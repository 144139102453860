import { StorageFileRevision } from '../../core/models';

export class FileRevisionListController {
  // bindings
  revisions: StorageFileRevision[];
  selectedRevision: StorageFileRevision;

  select(revision: StorageFileRevision): void {
    (<any>this).onSelect({ revision });
  }

  close(): void {
    (<any>this).onClose();
  }
}
