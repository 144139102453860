import { ConfirmationModalConfig } from './confirmation-modal-config';
import * as ng from 'angular';

/* @ngInject */
export default function ConfirmationModalController(
  $scope: any,
  $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
  config: ConfirmationModalConfig
) {
  $scope.title = config.title;
  $scope.text = config.text;
  $scope.confirmText = config.confirmText;
  $scope.model = {};

  $scope.ok = function() {
    $uibModalInstance.close(true);
  };

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };

  $scope.isDisabled = function() {
    return $scope.confirmText !== $scope.model.text;
  };
}
