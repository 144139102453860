import { NotificationTableController } from './notificationTable.controller';
import { ComponentBase } from '../../core/utils/componentBase';

export class NotificationTableComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = { onSelect: '&', pageSize: '<', status: '<' };
    this.controller = NotificationTableController;
    this.template = require('./notificationTable.html');
  }
}
