import AddressCopyModalController from './address-copy-modal-controller';
import AddressModalController from './address-modal-controller';
import reject from 'lodash/reject';

export class AddressFieldController {
  static $inject = ['$uibModal'];

  public field: any;
  public sections: any[];
  public templateFields: any[];
  public definition: any;

  constructor(public $uibModal: ng.ui.bootstrap.IModalService) {}

  $onInit() {
    if (this.definition.settings.country && !this.field.value) {
      this.field.value = {
        country: this.definition.settings.country
      };
    }
  }

  openAddressModal() {
    const currentField = this.field;

    const modalInstance = this.$uibModal.open({
      template: require('./address-modal.html'),
      controller: AddressModalController,
      resolve: {
        address: function() {
          return currentField.value;
        }
      }
    });

    modalInstance.result
      .then(function(theAddress) {
        currentField.value = theAddress;
      })
      .catch(() => {});
  }

  public openAddressCopyModal() {
    const currentField = this.field;
    const templateFields = this.templateFields;
    const allFields = [];

    (this.sections || []).forEach(function(s) {
      allFields.push.apply(allFields, s.fields);
    });

    let addressFields = allFields.filter(x => x.type === 'Address');

    // Remove the current field from the list
    addressFields = reject(addressFields, { id: currentField.id });

    const modalInstance = this.$uibModal.open({
      template: require('./address-copy-modal.html'),
      controller: AddressCopyModalController,
      resolve: {
        addressDefinitions: function() {
          return addressFields;
        },
        address: function() {
          return currentField.value;
        },
        fields: function() {
          return templateFields;
        }
      }
    });

    modalInstance.result
      .then(function(theAddress) {
        currentField.value = theAddress;
      })
      .catch(() => {});
  }

  getGoogleMapsLink() {
    if (!this.field.value) {
      return '';
    }
    const address = `${this.field.value.street}, ${this.field.value.city}, ${
      this.field.value.postcode
    }, ${this.field.value.state}`;
    return `https://maps.google.com/maps?q=${encodeURIComponent(address)}`;
  }
}
