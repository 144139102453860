import { DefaultMimeType, MimeTypeByExtension } from '../utils/mime';
import fileSaver from 'file-saver';

const ExtensionRegex = /\.(.+)$/i;

/**
 * A service for downloading files.
 *
 * @export
 * @interface IDownloadService
 */
export interface IDownloadService {
  /**
   * Download the specified file using HTTP GET.
   *
   * @param {string} path The path of the file to download; if relative it will be mapped to the API.
   * @param {string} extension The extension of the file to download.
   * @returns {ng.IPromise<void>}
   */
  downloadGet(path: string, type: string): ng.IPromise<void>;

  /**
   * Download the specified file using HTTP POST with data.
   *
   * @param {string} path The path of the file to download; if relative it will be mapped to the API.
   * @param {string} extension The extension of the file to download.
   * @returns {ng.IPromise<void>}
   */
  downloadPost(path: string, data: any, filename: string): ng.IPromise<void>;
}

export class DownloadService implements IDownloadService {
  static $inject = ['$http'];

  constructor(private httpService: ng.IHttpService) {}

  downloadGet(url: string, filename: string): ng.IPromise<void> {
    const type = this.getMimeType(filename);
    return this.httpService.get(url, { responseType: 'blob' }).then(response => {
      const blobFile = new Blob([response.data as BlobPart], { type: type });
      fileSaver.saveAs(blobFile, filename);
    });
  }

  downloadPost(url: string, data: any, filename: string): ng.IPromise<void> {
    const type = this.getMimeType(filename);
    return this.httpService.post(url, data, { responseType: 'blob' }).then(response => {
      const blobFile = new Blob([response.data as BlobPart], { type: type });
      fileSaver.saveAs(blobFile, filename);
    });
  }

  private getMimeType(filename: string): string {
    return ExtensionRegex.test(filename)
      ? MimeTypeByExtension.get(ExtensionRegex.exec(filename)![1].toLowerCase()) ||
          DefaultMimeType
      : DefaultMimeType;
  }
}
