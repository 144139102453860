import { IResourceStore, IResourceStoreFactory } from './store';
import { UserDelegation } from '../../core/models';

/* tslint:disable-next-line:no-empty-interface */
export interface IUserDelegationStore extends IResourceStore<UserDelegation> {}

/* @ngInject */
export default function UserDelegationStore(
  StoreFactory: IResourceStoreFactory<UserDelegation>
): IUserDelegationStore {
  return <IUserDelegationStore>StoreFactory('/api/v1/delegations/:id', { id: '@id' });
}
