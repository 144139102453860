import { FileRenameController } from './fileRename.controller';
import { StorageFile } from '../../core/models';

export interface IFileRenameService {
  show(file: StorageFile): ng.ui.bootstrap.IModalServiceInstance;
}

export class FileRenameService implements IFileRenameService {
  static $inject = ['$uibModal'];

  constructor(private uibModal: ng.ui.bootstrap.IModalService) {}

  show(file: StorageFile): ng.ui.bootstrap.IModalServiceInstance {
    return this.uibModal.open({
      template: require('./fileRename.html'),
      controllerAs: '$ctrl',
      controller: FileRenameController,
      size: 'sm',
      resolve: {
        file: () => file
      }
    });
  }
}
