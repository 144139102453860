import clone from 'lodash/clone';
import ng from 'angular';

export interface IResourceStoreFactory<T> {
  (url: string, defaultParams?: any, config?: any): IResourceStore<T>;
}

export interface IResouceStoreFunc {
  (...args: any[]): any;
}

export interface IResourceStore<T> {
  extensions: any[];
  config: any;
  $http: ng.IHttpService;
  $q: ng.IQService;
  url: string;
  defaultParams: any[];
  getUrl(requestParams?: any): string;
  getResourceId(resource: IResource<T>): string;
  getResourceUrl(resource: IResource<T>, params?: any): string;
  withExtension(name: string, fn: IResouceStoreFunc): IResourceStore<T>;
  withStoreExtension(name: string, fn: IResouceStoreFunc): IResourceStore<T>;
  get(id: string): ng.IPromise<IResource<T>>;
  query(params?: any, query?: any): ng.IPromise<IResourceQueryResult<T>>;
  save(data: any): ng.IPromise<IResource<T>>;
  update(data: any): ng.IPromise<IResource<T>>;
  delete(data: any): ng.IPromise<any>;
}

/**
 * @description Hack to make an interface inherit from a type and itself,
 * like:
 *
 *   interface IResource<T> : T { }
 *
 * @see https://github.com/Microsoft/TypeScript/issues/2225
 *
 */
export type IResource<TInstance extends {}> = TInstance & {
  id: string;
  getId(): string;
  update(): ng.IPromise<IResource<TInstance>>;
  delete(): ng.IPromise<any>;
};

export interface IResourceQueryResult<T> {
  items: IResource<T>[];
  totalCount: number;
  take: number;
  skip: number;
  count: number;
}

// ---------------
// URI Encoders
// ---------------

/**
 * Encodes a URI segment.
 * @param {string} val - the value to encode.
 * @returns {string}
 */
function encodeUriSegment(val: string): string {
  return encodeUriQuery(val, true)
    .replace(/%26/gi, '&')
    .replace(/%3D/gi, '=')
    .replace(/%2B/gi, '+');
}

/**
 * Encodes a URI query string value.
 * @param {string} val - the value to encode.
 * @param {boolean} pctEncodeSpaces - indicates if %20 spaces should be encoded as + characters.
 * @returns {string}
 */
function encodeUriQuery(val: string, pctEncodeSpaces: boolean): string {
  return encodeURIComponent(val)
    .replace(/%40/gi, '@')
    .replace(/%3A/gi, ':')
    .replace(/%24/g, '$')
    .replace(/%2C/gi, ',')
    .replace(/%20/g, pctEncodeSpaces ? '%20' : '+');
}

// ---------------
// Url Helper
// ---------------

/**
 * A helper for parsing a RFC6570 url template.
 * http://tools.ietf.org/html/rfc6570
 * @param {string} url - the url template.
 * @param {object[]} defaultParamValues - the default parameters.
 * @constructor
 */
export function UrlHelper(extUrl: string, extDefaultParamValues: any[]): any {
  const url: string = extUrl;
  const defaultParamValues: any[] = extDefaultParamValues || [];
  const urlParams: any = {};

  ng.forEach(url.split(/\W/), function(param) {
    if (
      !new RegExp('^\\d+$').test(param) &&
      param &&
      new RegExp('(^|[^\\\\]):' + param + '(\\W|$)').test(url)
    ) {
      urlParams[param] = true;
    }
  });

  return {
    getUrl
  };

  /**
   * Returns the url based on the original url template and specified parameter values.
   * @param {object} resource - the resource object to derive parameters from, if specified with the @ symbol.
   * @param {object[]} requestParams - the request parameter values.
   * @returns {string}
   */
  function getUrl(resource: any, requestParams: any[]): string {
    const params = ng.extend({}, defaultParamValues, requestParams);

    ng.forEach(params, function(value, key) {
      if (ng.isFunction(value)) {
        params[key] = value(resource);
      } else if (ng.isString(value) && value.charAt(0) === '@') {
        params[key] = resource ? resource[value.slice(1)] : null;
      }
    });

    let currentUrl = clone(url);
    let val: any;
    let encodedVal: any;

    ng.forEach(urlParams, function(x, urlParam) {
      val = params[urlParam];

      if (ng.isDefined(val) && val !== null) {
        encodedVal = encodeUriSegment(val);
        currentUrl = currentUrl.replace(
          new RegExp(':' + urlParam + '(\\W|$)', 'g'),
          function(match, p1) {
            return encodedVal + p1;
          }
        );
      } else {
        currentUrl = currentUrl.replace(
          new RegExp('(/?):' + urlParam + '(\\W|$)', 'g'),
          function(match, leadingSlashes, tail) {
            if (tail.charAt(0) === '/') {
              return tail;
            } else {
              return leadingSlashes + tail;
            }
          }
        );
      }
    });

    if (currentUrl.length > 1 && currentUrl.substr(currentUrl.length - 1) === '/') {
      currentUrl = currentUrl.substr(0, currentUrl.length - 1);
    }

    return currentUrl;
  }
}

// ---------------
// Resource
// ---------------

/**
 * A resource that has been read from the API.
 * @param {ResourceStore} store - the resource store.
 * @param {object} data - the object to mixin with the resource.
 * @constructor
 */
export function Resource<T>(store: IResourceStore<T>, data: any): IResource<T> {
  const resource = {
    id: undefined,
    getId,
    update,
    delete: del
  };

  ng.extend(resource, data || { id: 'id' });
  ng.forEach(store.extensions, function(item) {
    resource[item.name] = item.fn;
  });

  return <IResource<T>>resource;

  /**
   * Returns the ID of the resource based on the store config.
   * @returns {*}
   */
  function getId(): string {
    return store.getResourceId(<IResource<T>>resource);
  }

  /**
   * Updates the resource with the API.
   * @returns {HttpPromise} - the promise.
   */
  function update(): ng.IPromise<IResource<T>> {
    if (store.config.readonly) {
      throw new Error('You cannot update a readonly resource.');
    }

    return store.$http
      .put(store.getResourceUrl(<IResource<T>>resource), JSON.stringify(resource))
      .then(function(response) {
        return Resource(store, response.data);
      });
  }

  /**
   * Deletes the resource with the API.
   * @returns {HttpPromise} - the promise.
   */
  function del(): ng.IPromise<any> {
    if (store.config.readonly) {
      throw new Error('You cannot delete a readonly resource.');
    }

    const params: any = {};
    params.id = resource.id;

    return store.$http.delete(store.getResourceUrl(<IResource<T>>resource, params));
  }
}

// ---------------
// Query Result
// ---------------

/**
 * The results of a query operation.
 * @param {Resource[]} items - the query result items.
 * @param {number} totalCount - the total number of items matching the query.
 * @param {number} take - the number of items that were requested.
 * @param {number} skip - the number of items that were skipped.
 * @param {number} count - the number of items in the result set.
 * @constructor
 */
function ResourceQueryResult<T>(
  items: IResource<T>[],
  totalCount: number,
  take: number,
  skip: number,
  count: number
): void {
  this.items = items;
  this.totalCount = totalCount;
  this.take = take;
  this.skip = skip;
  this.count = count;
  this.page = skip <= 1 ? 1 : take / skip;
}

// ---------------
// Resource Store
// ---------------

/**
 * A store for interacting with a specific type of resource.
 * @param {$http} $http - the HTTP service.
 * @param {$q} $q - the promise service.
 * @param {string} url - the URL template.
 * @param {*[]} defaultParams - the default template parameters.
 * @param {object} config - the store configuration.
 * @constructor
 */
/* @ngInject */
function ResourceStore<T>(
  $http: ng.IHttpService,
  $q: ng.IQService,
  url: string,
  defaultParams: any[],
  config: any
): IResourceStore<T> {
  const extendedConfig = ng.extend({ id: 'id', readonly: false }, config || {});
  const urlHelper = UrlHelper(url, defaultParams);
  const extensions = [];
  const store = {
    config: extendedConfig,
    extensions: extensions,
    $http: $http,
    $q: $q,
    url: url,
    defaultParams: defaultParams,
    getUrl: getUrl,
    getResourceUrl: getResourceUrl,
    getResourceId: getResourceId,
    withExtension: withExtension,
    withStoreExtension: withStoreExtension,
    get: get,
    query: query,
    save: save,
    update: update,
    delete: del
  };

  return store;

  /**
   * Returns the URL with specified parameters.
   * @param requestParams
   * @returns {string|*}
   */
  function getUrl(requestParams?: any): string {
    return urlHelper.getUrl({}, requestParams);
  }

  /**
   * Returns the URL for the specified resource.
   * @param {Resource} resource - the resource to use for parameter values, if required.
   * @returns {string} - the resource url.
   */
  function getResourceUrl(resource: IResource<T>, requestParams: any): string {
    return urlHelper.getUrl(resource, requestParams || {});
  }

  /**
   * Returns the ID of the specified resource.
   * @param {Resource} resource - the resource to return the ID from.
   * @returns {*} - the resource ID.
   */
  function getResourceId(resource: any): string {
    return resource[extendedConfig.id];
  }

  /**
   * Adds an extension method that will be added to all resources created by the store.
   * @param {string} name - the name of the method that will be added to the resource.
   * @param {function} fn - the function that will execute when the method is called on the resource.
   * @returns {ResourceStore}
   */
  function withExtension(name: string, fn: IResouceStoreFunc): IResourceStore<T> {
    extensions.push({ name: name, fn: fn });
    return store;
  }

  /**
   * Adds an extension method to the store.
   * @param {string} name - the name of the method that will be added.
   * @param {function} fn - the function that will execute when the method is called.
   * @returns {ResourceStore}
   */
  function withStoreExtension(name: string, fn: IResouceStoreFunc): IResourceStore<T> {
    store[name] = fn;
    return store;
  }

  /**
   * Returns the resource matching the specified ID.
   * @param {object} id - the ID of the resource.
   * @returns {HttpPromise} - the promise wrapping the operation.
   */
  function get(id: string): ng.IPromise<IResource<T>> {
    const params: any = {};
    params[extendedConfig.id] = id;

    return $http.get(getUrl(params)).then(function(response: any) {
      return Resource(store, response.data);
    });
  }

  /**
   * Returns a list of resources matching the query.
   * @param {*[]} [params] - a list of parameter values.
   * @param {object[]} [q] - a list of query string values.
   * @returns {HttpPromise} - the promise wrapping the operation.
   */
  function query(params?: any, q?: any): ng.IPromise<IResourceQueryResult<T>> {
    const deferred = $q.defer();
    $http
      .get(getUrl(params || {}), { params: q || {} })
      .then((res: any) => {
        let result = res.data;
        if (result.items) {
          const wrapped = result.items.map(item => {
            return Resource(store, item);
          });
          result = new ResourceQueryResult(
            wrapped,
            result.totalCount,
            result.take,
            result.skip,
            result.count
          );
        }
        deferred.resolve(result);
      })
      .catch(error => {
        deferred.reject(error);
      });

    return deferred.promise as ng.IPromise<IResourceQueryResult<T>>;
  }

  /**
   * Saves the specified resource.
   * @param {object} data - the resource to save.
   * @returns {HttpPromise} - the  promise wrapping the operation.
   */
  function save(data: any): ng.IPromise<IResource<T>> {
    if (extendedConfig.readonly) {
      throw new Error('You cannot save a readonly resource.');
    }

    return $http.post(getUrl(data), JSON.stringify(data)).then(function(response) {
      return Resource(store, response.data);
    });
  }

  /**
   * Updates the specified resource
   * @param {object} data - the resource to update.
   * @returns {HttpPromise} - the promise wrapping the operation.
   */
  function update(data: any): ng.IPromise<IResource<T>> {
    if (store.config.readonly) {
      throw new Error('You cannot update a readonly resource.');
    }

    return store.$http
      .put(store.getResourceUrl(<IResource<T>>data, {}), JSON.stringify(data))
      .then(function(response) {
        return Resource(store, response.data);
      });
  }

  /**
   * Deletes the specified resource with the API.
   * @param {object} data - the resource to delete.
   * @returns {HttpPromise} - the promise wrapping the operation.
   */
  function del(data: any): ng.IPromise<any> {
    return Resource(store, data).delete();
  }
}

/**
 * The factory for creating resource stores.
 * @param {$http} $http - the HTTP service.
 * @param {$q} $q - the promise service.
 * @returns {Function} - the store factory.
 * @constructor
 */
/* @ngInject */
export default function StoreFactory<T>(
  $http: ng.IHttpService,
  $q: ng.IQService
): Function {
  return function(url: string, defaultParams: any, config: any): IResourceStore<T> {
    return ResourceStore<T>($http, $q, url, defaultParams, config);
  };
}
