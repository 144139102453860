import { DateFieldController } from './dateField.controller';

export class DateFieldComponent implements ng.IComponentOptions {
  public controller: any;
  public template: string;
  public bindings: any;

  constructor() {
    this.bindings = {
      definition: '<',
      field: '=',
      templateDefinition: '<',
      templateFields: '<',
      formDisabled: '<',
      sections: '<'
    };

    this.controller = DateFieldController;
    this.template = require('./dateField.html');
  }
}
