/* @ngInject */
export function UrlChangeHandler($rootScope): void {
  $rootScope.$on('$locationChangeSuccess', function() {
    if (window.appInsights) {
      window.appInsights.trackPageView();
    }
  });
  $rootScope.$on('$locationChangeStart', (event: any, newUrl: string) => {
    if ((newUrl || '').indexOf('/v2/') > -1) {
      event.preventDefault();
      window.location.href = newUrl;
      return;
    }
  });
}
