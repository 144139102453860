import { Notification } from '../../core/models';
import { StateService } from 'angular-ui-router';
import * as ng from 'angular';

export class NotificationModalController {
  static $inject = ['$state', '$uibModalInstance'];

  constructor(
    private stateService: StateService,
    private modalInstance: ng.ui.bootstrap.IModalServiceInstance
  ) {}

  close() {
    this.modalInstance.dismiss('cancel');
  }

  goToNotification(notification: Notification) {
    this.close();
    this.stateService.go('app.notification', {
      id: notification.id.toString()
    });
  }

  goToAllNotifications() {
    this.close();
    this.stateService.go('app.notifications');
  }
}
