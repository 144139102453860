import { ReferenceLinkController } from './referenceLink.controller';
import { ComponentBase } from '../../../core/utils/componentBase';

export class ReferenceLinkComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      reference: '<',
      recordId: '<',
      recordType: '<'
    };
    this.controller = ReferenceLinkController;
    this.template = require('./referenceLink.html');
  }
}
