import { IFileService } from './file.service';
import { IFileRenameService } from './fileRename.service';
import { StorageFile, StorageFileRevision } from '../../core/models';
import { IFolderNavigator } from '../folders/folder.navigator';
import { element } from 'angular';
import debounce from 'lodash/debounce';

export class FileModalController {
  static $inject = [
    '$uibModalInstance',
    'FileService',
    'FileRenameService',
    'file',
    'showRevisions',
    'navigator',
    'revision'
  ];

  currentRevision: StorageFileRevision;
  throttledKeyPressHandler = debounce(this.keyPressHandler.bind(this), 500);

  constructor(
    private modalInstance: ng.ui.bootstrap.IModalServiceInstance,
    private fileService: IFileService,
    private fileRenameService: IFileRenameService,
    private file: StorageFile,
    private showRevisions: boolean,
    private navigator: IFolderNavigator | undefined,
    revision: StorageFileRevision
  ) {
    this.currentRevision = revision || file.headRevision;
  }

  $onInit() {
    const $doc = element(document);
    $doc.on('keydown', this.throttledKeyPressHandler);
  }

  $onDestroy() {
    const $doc = element(document);
    $doc.off('keydown', this.throttledKeyPressHandler);
  }

  get hasNavigator(): boolean {
    return !!this.navigator;
  }

  get hasNextFile(): boolean {
    return this.navigator && this.navigator.hasNext;
  }

  get hasPreviousFile(): boolean {
    return this.navigator && this.navigator.hasPrevious;
  }

  keyPressHandler($event: KeyboardEvent) {
    switch ($event.which) {
      case 37:
        this.previousFile($event);
        break;
      case 39:
        this.nextFile($event);
        break;
      default:
        break;
    }
  }

  nextFile($event: Event): void {
    $event.stopImmediatePropagation();
    if (!this.navigator || !this.navigator.hasNext) {
      return;
    }
    this.file = this.navigator.next();
    this.currentRevision = this.file.headRevision;
  }

  previousFile($event: Event): void {
    $event.stopImmediatePropagation();
    if (!this.navigator || !this.navigator.hasPrevious) {
      return;
    }
    this.file = this.navigator.previous();
    this.currentRevision = this.file.headRevision;
  }

  backgroundClick() {
    this.modalInstance.dismiss('cancel');
  }

  download(): void {
    this.fileService.downloadFile(this.file, this.currentRevision);
  }

  changeRevision(revision: StorageFileRevision): void {
    this.currentRevision = revision;
  }

  toggleRevisions(): void {
    this.showRevisions = !this.showRevisions;
  }

  rename(): void {
    this.fileRenameService
      .show(this.file)
      .result.then(result => result.success && this.updateFile(result.file));
  }

  updateFile(file: StorageFile): void {
    this.file = file;
  }

  cancel(): void {
    this.modalInstance.dismiss('cancel');
  }
}
