import { ReferenceViewController } from './referenceView.controller';
import { ComponentBase } from '../../../core/utils/componentBase';

export class ReferenceViewComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      reference: '<',
      recordId: '<',
      recordType: '<',
      canEdit: '<',
      onChange: '&'
    };
    this.controller = ReferenceViewController;
    this.template = require('./referenceView.html');
  }
}
