import { IAlertService } from '../../../core/services';
import * as ng from 'angular';

export class ReferenceEditModalController {
  static $inject = ['reference', 'AlertService', '$uibModalInstance'];

  updated: string;

  constructor(
    private reference: string,
    private alertService: IAlertService,
    private modalInstanceService: ng.ui.bootstrap.IModalInstanceService
  ) {
    this.updated = reference;
  }

  update() {
    if (!this.hasReference) {
      this.alertService.warn("Reference can't be blank");
      return;
    }
    if (!this.hasChanged) {
      this.modalInstanceService.close();
    }
    this.modalInstanceService.close(this.updated);
  }

  cancel() {
    this.modalInstanceService.dismiss('cancel');
  }

  private get hasChanged(): boolean {
    return (this.updated || '').trim() !== (this.reference || '').trim();
  }

  private get hasReference(): boolean {
    return (this.updated || '').trim().length > 0;
  }
}
