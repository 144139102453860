import { TypeHelper } from '../utils/typeHelper';
import ng from 'angular';
import 'rxjs/add/observable/fromPromise';
import 'rxjs/add/observable/throw';
import 'rxjs/add/operator/catch';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';

interface IAngularHttpResponse {
  data: string | Object;
  status: number;
  headers: any;
  config: any;
  statusText: string;
}

export interface IHttpHeaders {
  (): { [name: string]: string };
  (headerName: string): string;
}

export class HttpResponse {
  private _response: IAngularHttpResponse;

  constructor(response: any) {
    this._response = response;
  }

  get headers(): IHttpHeaders {
    return this._response.headers;
  }

  get statusCode() {
    return this._response.status;
  }

  get statusText() {
    return this._response.statusText;
  }

  get isSuccess(): boolean {
    return this._response.status >= 200 && this._response.status <= 299;
  }

  get method(): string {
    return this._response.config.method;
  }

  get url(): string {
    return this._response.config.url;
  }

  json<T>(): T {
    return TypeHelper.isObject(this._response.data)
      ? this._response.data
      : JSON.parse(String(this._response.data));
  }

  text(): string {
    return TypeHelper.isObject(this._response.data)
      ? JSON.stringify(this._response.data)
      : String(this._response.data);
  }
}

export interface IHttpService {
  get(url: string): Observable<HttpResponse>;
  post(url: string, body: any): Observable<HttpResponse>;
  postForm(url: string, form: FormData): Observable<HttpResponse>;
  put(url: string, body: any): Observable<HttpResponse>;
  putForm(url: string, form: FormData): Observable<HttpResponse>;
  patch(url: string, body: any): Observable<HttpResponse>;
  delete(url: string): Observable<HttpResponse>;
}

export class HttpService implements IHttpService {
  static $inject = ['$http', 'rx'];

  private static getFormsConfig(config: any): any {
    return Object.assign({}, config, {
      // will ensure the correct boundary is set
      headers: { 'Content-Type': undefined },
      // prevents serializing payload
      transformRequest: ng.identity
    });
  }

  constructor(private $http: ng.IHttpService, private rx: any) {}

  get(url: string): Observable<HttpResponse> {
    return this.wrapRequest(this.$http.get(url));
  }

  head(url: string): Observable<HttpResponse> {
    return this.wrapRequest(this.$http.head(url));
  }

  post(url: string, body: any): Observable<HttpResponse> {
    return this.wrapRequest(this.$http.post(url, body));
  }

  postForm(url: string, form: FormData, config: any = {}): Observable<HttpResponse> {
    return this.wrapRequest(
      this.$http.post(url, form, HttpService.getFormsConfig(config))
    );
  }

  put(url: string, body: any): Observable<HttpResponse> {
    return this.wrapRequest(this.$http.put(url, body));
  }

  putForm(url: string, form: FormData, config: any = {}): Observable<HttpResponse> {
    return this.wrapRequest(
      this.$http.put(url, form, HttpService.getFormsConfig(config))
    );
  }

  patch(url: string, body: any): Observable<HttpResponse> {
    return this.wrapRequest(this.$http.patch(url, body));
  }

  delete(url: string): Observable<HttpResponse> {
    return this.wrapRequest(this.$http.delete(url));
  }

  private wrapRequest(request: ng.IHttpPromise<any>): Observable<HttpResponse> {
    return this.rx.Observable.fromPromise(<any>request)
      .map(response => new HttpResponse(response))
      .catch(error => Observable.throw(new HttpResponse(error)));
  }
}
