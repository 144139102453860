import * as angular from 'angular';
import './cio-dropdown-service';

/* @ngInject */
export default function CioDropDownController(
  $scope,
  $attrs,
  $parse,
  CioDropDownService,
  $animate
): void {
  const self = this;
  const scope = $scope.$new(); // create a child scope so we are not polluting original one
  const openClass = 'open';
  const toggleInvoker = $attrs.onToggle ? $parse($attrs.onToggle) : angular.noop;
  let getIsOpen;
  let setIsOpen = angular.noop;

  this.init = function(element) {
    self.$element = element;

    if ($attrs.isOpen) {
      getIsOpen = $parse($attrs.isOpen);
      setIsOpen = getIsOpen.assign;

      $scope.$watch(getIsOpen, function(value) {
        scope.isOpen = !!value;
      });
    }
  };

  this.toggle = function(open) {
    return (scope.isOpen = arguments.length ? !!open : !scope.isOpen);
  };

  // Allow other directives to watch status
  this.isOpen = function() {
    return scope.isOpen;
  };

  scope.getToggleElement = function() {
    return self.toggleElement;
  };

  scope.focusToggleElement = function() {
    if (self.toggleElement) {
      self.toggleElement[0].focus();
    }
  };

  scope.$watch('isOpen', function(isOpen, wasOpen) {
    $animate[isOpen ? 'addClass' : 'removeClass'](self.$element, openClass);

    if (isOpen) {
      scope.focusToggleElement();
      CioDropDownService.open(scope);
    } else {
      CioDropDownService.close(scope);
    }

    setIsOpen($scope, isOpen);
    if (angular.isDefined(isOpen) && isOpen !== wasOpen) {
      toggleInvoker($scope, { open: !!isOpen });
    }
  });

  $scope.$on('$locationChangeSuccess', function() {
    scope.isOpen = false;
  });

  $scope.$on('$destroy', function() {
    scope.$destroy();
  });
}
