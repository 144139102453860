import { OrganizationController } from './organization.controller';
import { ComponentBase } from '../../../core/utils/componentBase';

export class OrganizationComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {};
    this.controller = OrganizationController;
    this.template = require('./organization.html');
  }
}
