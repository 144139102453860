import { WorkflowFilterGroup, Workflow } from '../../../../core/models';

const FILTER_GROUP_MATCHES = [
  { text: 'All', value: 'All' },
  { text: 'Any', value: 'Any' },
  { text: 'None', value: 'None' }
];

export class WorkflowFilterGroupsController {
  static $inject = [];

  workflow: Workflow;
  filterGroups: WorkflowFilterGroup[] = [];
  matches = FILTER_GROUP_MATCHES;

  addGroup(): void {
    this.workflow.filterGroups.push(WorkflowFilterGroup.create(1));
  }

  removeGroup(group: WorkflowFilterGroup) {
    const index = this.workflow.filterGroups.indexOf(group);
    if (index > -1) {
      this.workflow.filterGroups.splice(index, 1);
    }
  }
}
