import { HttpService } from './http';
import { ITrackingService } from '../services';
import { Guid, StorageFile } from '../models';
import { logError } from '../utils';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';

export interface IStorageFileHttpService {
  getFile(fileId: Guid): Observable<StorageFile>;
  uploadFile(
    file: StorageFile,
    data: Blob,
    progress: (progress: number) => {}
  ): Observable<StorageFile>;
  uploadFileRevision(
    file: StorageFile,
    data: Blob,
    progress: (progress: number) => {}
  ): Observable<StorageFile>;
  addStar(fileId: Guid): Observable<StorageFile>;
  removeStar(fileId: Guid): Observable<StorageFile>;
  modifyFile(file: StorageFile): Observable<StorageFile>;
  deleteFile(fileId: Guid): Observable<boolean>;
}

export class StorageFileHttpService implements IStorageFileHttpService {
  static $inject = ['$window', 'HttpService', 'TrackingService'];

  private readonly _url: string = '/api/v1/files';

  constructor(
    private window: any,
    private httpService: HttpService,
    private trackingService: ITrackingService
  ) {}

  getFile(fileId: Guid): Observable<StorageFile> {
    const url = `${this._url}/${fileId.toString()}`;
    return this.httpService
      .get(url)
      .map(res => StorageFile.fromJson(res.json()))
      .catch(logError);
  }

  uploadFile(
    file: StorageFile,
    data: Blob,
    progress: (value: number) => {}
  ): Observable<StorageFile> {
    return this.upload(file, data, progress, false);
  }

  uploadFileRevision(
    file: StorageFile,
    data: Blob,
    progress: (value: number) => {}
  ): Observable<StorageFile> {
    return this.upload(file, data, progress, true);
  }

  addStar(fileId: Guid): Observable<StorageFile> {
    const url = `${this._url}/${fileId.toString()}/add-star`;
    return this.httpService
      .put(url, JSON.stringify({}))
      .map(res => StorageFile.fromJson(res.json()))
      .do(f => this.trackFileStarred(f, true))
      .catch(logError);
  }

  removeStar(fileId: Guid): Observable<StorageFile> {
    const url = `${this._url}/${fileId.toString()}/remove-star`;
    return this.httpService
      .put(url, JSON.stringify({}))
      .map(res => StorageFile.fromJson(res.json()))
      .do(f => this.trackFileStarred(f, false))
      .catch(logError);
  }

  modifyFile(file: StorageFile): Observable<StorageFile> {
    const url = `${this._url}/${file.id.toString()}`;
    const body = JSON.stringify(file);
    return this.httpService
      .put(url, body)
      .map(res => StorageFile.fromJson(res.json()))
      .do(f => this.trackFileModified(f))
      .catch(logError);
  }

  deleteFile(fileId: Guid): Observable<boolean> {
    const url = `${this._url}/${fileId.toString()}`;
    return this.httpService
      .delete(url)
      .map(_ => true)
      .do(() => this.trackFileDeleted(fileId))
      .catch(logError);
  }

  private upload(
    file: StorageFile,
    data: Blob,
    progress: (value: number) => {},
    isRevision: boolean = false
  ): Observable<StorageFile> {
    const url = isRevision
      ? `${this._url}/${file.id.toString()}/revisions/upload`
      : `${this._url}/upload`;

    const form = new FormData();
    form.append('data', JSON.stringify(file));
    form.append('file', data, file.name);

    const config = {
      uploadEventHandlers: {
        progress: function(e) {
          if (e.lengthComputable && progress) {
            progress((e.loaded * 100) / e.total);
          }
        }
      }
    };

    return this.httpService
      .postForm(url, form, config)
      .map(res => StorageFile.fromJson(res.json()))
      .do(f => this.trackFileCreated(f))
      .catch(logError);
  }

  private trackFileCreated(file: StorageFile): StorageFile {
    this.trackingService.increment('Files Created');
    this.trackingService.track(
      'File Created',
      StorageFileHttpService.getTrackingFields(file)
    );
    return file;
  }

  private trackFileModified(file: StorageFile, isCompleted?: boolean): StorageFile {
    const fields = StorageFileHttpService.getTrackingFields(file);
    this.trackingService.increment('Files Updated');
    this.trackingService.track('File Updated', fields);
    if (isCompleted) {
      this.trackingService.track('File Completed', fields);
    }
    return file;
  }

  private trackFileStarred(file: StorageFile, starred: boolean): StorageFile {
    try {
      const fields = Object.assign(
        { Starred: starred },
        StorageFileHttpService.getTrackingFields(file)
      );
      this.trackingService.track('File Starred', fields);
      return file;
    } catch {}
    return file;
  }

  private trackFileDeleted(fileId: Guid): void {
    this.trackingService.increment('Files Deleted');
    this.trackingService.track('File Deleted', { 'File ID': fileId });
  }

  private static getTrackingFields(file: StorageFile): any {
    return {
      'File ID': file.id.toString(),
      Revisions: file.revisions,
      Size: file.size,
      Name: file.name,
      Extension: file.extension
    };
  }
}
