import { SumInsured } from '../../core/models';
import ng from 'angular';

/* tslint:disable-next-line:no-empty-interface */
export interface SumInsuredResource
  extends ng.resource.IResourceClass<ng.resource.IResource<SumInsured>> {
  update(sumInsured: SumInsured): ng.resource.IResource<SumInsured>;
}

/* @ngInject */
export default function SumInsuredResource(
  $resource: ng.resource.IResourceService
): SumInsuredResource {
  return <SumInsuredResource>$resource<ng.resource.IResource<SumInsured>>(
    '/api/v1/sums-insured/:id',
    { id: '@id' },
    {
      update: {
        method: 'PUT',
        params: {
          id: '@id'
        }
      },
      query: { method: 'GET' }
    }
  );
}
