import { HttpService } from './http';
import { PageHelper } from './pageHelper';
import {
  DocumentTemplate,
  Guid,
  Page,
  StorageFile,
  StorageFolder,
  ITemplateRequirementValue
} from '../models';
import { ITrackingService } from '../services';
import { Check } from '../utils';
import { logError } from '../utils/rx';
import { Observable } from 'rxjs/Observable';

export interface IDocumentCreationResult {
  success: boolean;
  documentTemplateId: Guid;
  requirements: ITemplateRequirementValue[];
  file: StorageFile;
}

export interface IDocumentHttpService {
  createDocument(
    folderId: Guid,
    documentTemplateId: Guid,
    requirements: ITemplateRequirementValue[]
  ): Observable<StorageFile>;
  getTemplateFiles(take: number, skip: number): Observable<Page<DocumentTemplate>>;
  geTemplateFolder(): Observable<StorageFolder>;
}

export class DocumentHttpService implements IDocumentHttpService {
  static $inject = ['$window', 'HttpService', 'TrackingService'];

  private readonly _url: string = '/api/v1/documents';

  constructor(
    private window: any,
    private httpService: HttpService,
    private trackingService: ITrackingService
  ) {}

  getTemplateFiles(
    take: number = 50,
    skip: number = 0
  ): Observable<Page<DocumentTemplate>> {
    const url = PageHelper.query(
      '/api/v1/folders/special/template/files/list',
      take,
      skip
    );
    return this.httpService
      .get(url)
      .map(res => Page.fromJson(res.json(), DocumentTemplate.fromJson))
      .catch(logError);
  }

  geTemplateFolder(): Observable<StorageFolder> {
    const url = '/api/v1/folders/special/template';
    return this.httpService
      .get(url)
      .map(res => StorageFolder.fromJson(res.json()))
      .catch(logError);
  }

  createDocument(
    folderId: Guid,
    documentTemplateId: Guid,
    requirements: ITemplateRequirementValue[] = []
  ): Observable<StorageFile> {
    Check.assert(documentTemplateId, 'Document Template ID not specified.');
    const body = {
      folderId,
      documentTemplateId: documentTemplateId.toString(),
      requirements: requirements
    };
    return this.httpService
      .post(this._url, body)
      .map(res => StorageFile.fromJson(res.json()))
      .do(file => this.trackDocumentCreated(file, documentTemplateId, requirements))
      .map(file => {
        return {
          success: true,
          file,
          documentTemplateId,
          requirements
        } as IDocumentCreationResult;
      })
      .catch(logError);
  }

  private trackDocumentCreated(
    file: StorageFile,
    documentTemplateId: Guid,
    requirements: ITemplateRequirementValue[] = []
  ): StorageFile {
    this.trackingService.increment('Files Created');
    this.trackingService.increment('Documents Created');
    this.trackingService.track(
      'File Created',
      DocumentHttpService.getDocumentTrackingFields(
        file,
        documentTemplateId,
        requirements
      )
    );
    return file;
  }

  private static getDocumentTrackingFields(
    file: StorageFile,
    documentTemplateId: Guid,
    requirements: ITemplateRequirementValue[] = []
  ): any {
    return {
      'File ID': file.id.toString(),
      'Template ID': documentTemplateId.toString(),
      Requirements: requirements
        .map(x => `${x.type}/${x.field}/${x.recordId}`)
        .join(', '),
      Revisions: file.revisions,
      Size: file.size,
      Name: file.name,
      Extension: file.extension
    };
  }
}
