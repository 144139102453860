import { Invoice, Claim } from '../../../../core/models';
import { IAlertService } from '../../../../core/services';
import * as ng from 'angular';

/* @ngInject */
export default function InvoicePaymentService($uibModal): any {
  return {
    managePayments: managePayments
  };

  function managePayments(invoice, claim) {
    const modalInstance = $uibModal.open({
      template: require('./invoice-payment-modal.html'),
      controller: InvoicePaymentModalController,
      controllerAs: '$ctrl',
      resolve: {
        invoice: function() {
          return invoice;
        },
        claim: function() {
          return claim;
        }
      }
    });

    return modalInstance.result;
  }
}

export function InvoicePaymentModalController(
  $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
  invoice: Invoice,
  claim: Claim,
  AlertService: IAlertService
): void {
  const vm = this;
  vm.payment = {};
  vm.invoiceId = invoice.id;
  vm.claim = claim;
  vm.invoice = invoice;

  vm.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };

  vm.savePayment = function() {
    if (!vm.payment.amount) {
      AlertService.error('Please specify the payment amount.');
      return;
    }

    vm.invoice.payments.push(vm.payment);
    $uibModalInstance.close(vm.invoice);
  };
}
