import { ClaimsModule } from './claims/claims.module';
import { ComponentsModule } from './components/components.module';
import {
  ApplicationConfig,
  ErrorHandlerConfig,
  RouteConfig,
  StateConfig
} from './config';
import { DashboardModule } from './dashboard/dashboard.module';
import { DirectivesModule } from './directives/directives.module';
import { DocumentModule } from './documents/document.module';
import { SmsModule } from './sms/sms.module';
import { EntitiesModule } from './entities/entities.module';
import { FilesModule } from './files/files.module';
import { FoldersModule } from './folders/folders.module';
import { HomeModule } from './home/home.module';
import { NotificationModule } from './notifications/notification.module';
import { ReportsModule } from './reports/reports.module';
import {
  AuthenticationHandler,
  LoggingHandler,
  StateErrorHandler,
  TitleHandler,
  UrlChangeHandler,
  AsyncHandler
} from './run';
import { SearchModule } from './search/search.module';
import { ServicesModule } from './services/services.module';
import { SettingsModule } from './settings/settings.module';
import { TasksModule } from './tasks/tasks.module';
import { TemplatesModule } from './templates/templates.module';
import { UsersModule } from './users/users.module';
import { CoreModule } from '../core/module';
import { DataModule } from '../data/module';
import { UIModule } from '../ui/module';
import angular from 'angular';

export const InstanceModule = angular
  .module('app.instance', [
    ClaimsModule.name,
    ComponentsModule.name,
    CoreModule.name,
    DashboardModule.name,
    DataModule.name,
    DirectivesModule.name,
    DocumentModule.name,
    SmsModule.name,
    EntitiesModule.name,
    FilesModule.name,
    FoldersModule.name,
    HomeModule.name,
    NotificationModule.name,
    ReportsModule.name,
    SearchModule.name,
    ServicesModule.name,
    SettingsModule.name,
    TasksModule.name,
    TemplatesModule.name,
    UIModule.name,
    UsersModule.name
  ])

  // Config
  .config(ApplicationConfig)
  .config(ErrorHandlerConfig)
  .config(RouteConfig)
  .config(StateConfig)

  // Run
  .run(AuthenticationHandler)
  .run(LoggingHandler)
  .run(StateErrorHandler)
  .run(UrlChangeHandler)
  .run(TitleHandler)
  .run(AsyncHandler);
