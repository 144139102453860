import { StateProvider } from 'angular-ui-router';

/* @ngInject */
export default function ReportsRoutes($stateProvider: StateProvider): void {
  $stateProvider

    .state('app.reports', {
      abstract: true,
      url: '/reports',
      template: '<ui-view/>'
    })

    .state('app.reports.list', {
      url: '/list',
      template: '<report-list-component></report-list-component>'
    })

    .state('app.reports.new', {
      url: '/run',
      template: '<report-component></report-component>'
    })

    .state('app.reports.run', {
      url: '/run/:id',
      template: '<report-component></report-component>'
    });
}
