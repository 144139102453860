import { IAlertService } from '../../../core/services';
import { ISecurityService } from '../../../core/services';
import { INoteHttpService } from '../../../core/http';
import { Guid, Note } from '../../../core/models';

/* @ngInject */
export default function CommentModalController(
  $scope: any,
  $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
  claimId: Guid,
  NoteHttpService: INoteHttpService,
  SecurityService: ISecurityService,
  AlertService: IAlertService
): void {
  const vm = this;
  vm.commentModel = {};
  vm.currentUserId = SecurityService.getCurrentUser().id;
  vm.cancel = cancel;
  vm.save = save;
  vm.saving = false;

  function cancel() {
    $uibModalInstance.dismiss('cancel');
  }

  function save() {
    if (vm.saving) {
      return;
    }
    vm.saving = true;
    const note = Object.assign(new Note(), {
      claimId: claimId,
      content: vm.commentModel.content
    });
    NoteHttpService.createNote(note)
      .safeApply($scope, applyNoteSaved)
      .subscribe();
  }

  function applyNoteSaved(note: Note): void {
    AlertService.success('Comment has been saved.');
    $uibModalInstance.close(note);
    vm.saving = false;
  }
}
