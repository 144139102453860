import { IFileService } from './file.service';
import { StorageFile, StorageFileRevision } from '../../core/models';

export class FilePreviewerController {
  static $inject = ['FileService'];

  // bindings
  file: StorageFile;
  revision: StorageFileRevision;
  loadingPercent: number = 0;

  url: string = null;
  isImage: boolean = false;
  isPdf: boolean = false;
  isOther: boolean = true;

  constructor(private fileService: IFileService) {}

  $onInit(): void {
    this.showPreview();
  }

  $onChanges(changes: any): void {
    if (changes.file || changes.revision) {
      this.showPreview();
    }
  }

  showPreview(): void {
    this.url = this.fileService.getPreviewUrl(this.file, this.revision);
    switch (this.file.extension.toLocaleLowerCase()) {
      case '.jpg':
      case '.jpeg':
      case '.gif':
      case '.tif':
      case '.tiff':
      case '.bmp':
      case '.png':
      case '.webp':
        this.isImage = true;
        this.isPdf = false;
        this.isOther = false;
        break;
      case '.pdf':
      case '.doc':
      case '.docx':
      case '.odt':
      case '.dot':
      case '.dotx':
      case '.ott':
      case '.xls':
      case '.xlsx':
      case '.ods':
      case '.ots':
      case '.csv':
      case '.eml':
      case '.msg':
        this.isImage = false;
        this.isPdf = true;
        this.isOther = false;
        break;
      default:
        this.isImage = false;
        this.isPdf = false;
        this.isOther = true;
        break;
    }
  }

  download(): void {
    (<any>this).onDownload();
  }

  stopClick($event: Event) {
    $event.stopImmediatePropagation();
  }
}
