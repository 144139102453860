import { RecentClaimsWidgetController } from './recentClaimsWidget.controller';

export class RecentClaimsWidgetComponent implements ng.IComponentOptions {
  public controller: any;
  public template: string;
  public bindings: any;

  constructor() {
    this.bindings = {
      config: '<'
    };
    this.controller = RecentClaimsWidgetController;
    this.template = require('./recentClaimsWidget.html');
  }
}
