import { ProfileController } from './profile.controller';
import { UserClaimsController } from './user-claims.controller';
import { UserRoutes } from './user.routes';
import { CoreModule } from '../../core/module';
import { DataModule } from '../../data/module';
import { UIModule } from '../../ui/module';
import * as angular from 'angular';

export const UsersModule = angular
  .module('app.instance.users', [DataModule.name, CoreModule.name, UIModule.name])

  // Users
  .config(UserRoutes)
  .controller('ProfileController', ProfileController)
  .controller('UserClaimsController', UserClaimsController);
