import { WorkflowFilterDateController } from './workflowFilterDate.controller';
import { ComponentBase } from '../../../../core/utils/componentBase';

export class WorkflowFilterDateComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      filter: '='
    };
    this.controller = WorkflowFilterDateController;
    this.template = require('./workflowFilterDate.html');
  }
}
