import { Instance } from '../../core/models';
import * as ng from 'angular';

/* tslint:disable-next-line:no-empty-interface */
export interface InstanceResource
  extends ng.resource.IResourceClass<ng.resource.IResource<Instance>> {}

/* @ngInject */
export default function InstanceResource($resource: ng.resource.IResourceService) {
  return <InstanceResource>(
    $resource<ng.resource.IResource<Instance>>('/api/v1/tenants/:id', { id: '@id' })
  );
}
