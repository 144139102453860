import { UserSummary } from '../../core/models';
import ng from 'angular';

/* tslint:disable-next-line:no-empty-interface */
export interface UserSummaryResource
  extends ng.resource.IResourceClass<ng.resource.IResource<UserSummary>> {}

/* @ngInject */
export default function UserSummaryResource(
  $resource: ng.resource.IResourceService
): UserSummaryResource {
  return <UserSummaryResource>$resource<ng.resource.IResource<UserSummary>>(
    '/api/v1/user-summary/:id',
    { id: '@id' },
    {
      get: { method: 'GET' },
      query: { method: 'GET', url: '/api/v1/user-summary' }
    }
  );
}
