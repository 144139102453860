import { HttpService } from './http';
import { Guid, Page, ClaimWatcher } from '../models';
import { logError } from '../utils/rx';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';

export interface ISubscriptionHttpService {
  getSubscription(subscriptionId: Guid): Observable<ClaimWatcher>;
  getUserSubscriptions(skip: number, take: number): Observable<Page<ClaimWatcher>>;
  unsubscribe(subscriptionId: Guid): Observable<void>;
  unsubscribeFromAll(): Observable<void>;
}

export class SubscriptionHttpService implements ISubscriptionHttpService {
  static $inject = ['$window', 'HttpService'];

  private readonly _url: string = '/api/v1/subscriptions';

  constructor(private httpService: HttpService) {}

  getSubscription(subscriptionId: Guid): Observable<ClaimWatcher> {
    return this.httpService
      .get(`${this._url}/${subscriptionId.toString()}`)
      .map(res => res.json() as ClaimWatcher)
      .catch(logError);
  }

  getUserSubscriptions(
    skip: number = 0,
    take: number = 100
  ): Observable<Page<ClaimWatcher>> {
    return this.httpService
      .get(`${this._url}?skip=${skip}&take=${take}`)
      .map(res => Page.fromJson(res.json(), x => x as ClaimWatcher))
      .catch(logError);
  }

  unsubscribe(subscriptionId: Guid): Observable<void> {
    return this.httpService
      .delete(`${this._url}/${subscriptionId.toString()}`)
      .map(_ => {})
      .catch(logError);
  }

  unsubscribeFromAll(): Observable<void> {
    return this.httpService
      .delete(`${this._url}/all`)
      .map(_ => {})
      .catch(logError);
  }
}
