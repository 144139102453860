import { ClaimFilesController } from './claimFiles.controller';
import { ComponentBase } from '../../../core/utils';

export class ClaimFilesComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      claim: '<'
    };
    this.controller = ClaimFilesController;
    this.template = require('./claimFiles.html');
  }
}
