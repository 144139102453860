declare var google: any;

/* @ngInject */
export default function googleplace(): any {
  return {
    restrict: 'EA',
    scope: {
      address: '='
    },
    link: link
  };

  function link(scope, element, attrs) {
    const options = {
      types: ['geocode'],
      componentRestrictions: {}
    };

    const gPlace = new google.maps.places.Autocomplete(element[0], options),
      typeMap = {
        route: 'street',
        street_number: 'street',
        locality: 'city',
        administrative_area_level_1: 'state',
        postal_code: 'postcode',
        country: 'country'
      };

    // Bias the autocomplete object to the user's geographical location,
    // as supplied by the browser's 'navigator.geolocation' object.
    element.on('focus', geoLocate);

    google.maps.event.addListener(gPlace, 'place_changed', function() {
      const place = gPlace.getPlace();

      if (place) {
        scope.address = {};

        place.address_components.forEach(component => {
          const type = component.types[0];

          if (type === 'neighborhood') {
            scope.address.street += ' - ' + component.long_name;
          } else {
            const prop = typeMap[type];
            if (!scope.address[prop]) {
              scope.address[prop] = '';
            }

            if (scope.address[prop].length) {
              scope.address[prop] += ' ' + component.long_name;
            } else {
              scope.address[prop] += component.long_name;
            }
          }
        });
      }

      scope.$apply();
    });

    function geoLocate() {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function(position) {
          const geolocation = new google.maps.LatLng(
            position.coords.latitude,
            position.coords.longitude
          );

          const circle = new google.maps.Circle({
            center: geolocation,
            radius: position.coords.accuracy
          });

          gPlace.setBounds(circle.getBounds());
        });
      }
    }
  }
}
