import { FieldDefinition, IWorkflowFilter } from '../../../../core/models';
import { IFieldHttpService } from '../../../../core/http';
import { WorkflowService } from '../workflow.service';

export class WorkflowFiltersController {
  static $inject = ['$scope', 'FieldHttpService'];

  filters: IWorkflowFilter[] = [];
  fields: FieldDefinition[] = [];

  constructor(private scope: any, private fieldHttpService: IFieldHttpService) {}

  $onInit() {
    // If no filter so far, add a new empty one
    if (!this.filters.length) {
      this.addFilter();
    }

    this.loadFields();
  }

  loadFields() {
    this.fieldHttpService
      .all()
      .safeApply(this.scope, fields => {
        this.fields = WorkflowService.getClaimFields(fields);
      })
      .subscribe();
  }

  removeFilter(index: number) {
    this.filters.splice(index, 1);
  }

  addFilter(): void {
    this.filters.push({} as IWorkflowFilter);
  }
}
