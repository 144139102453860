import { NotificationListController } from './notificationList.controller';
import { ComponentBase } from '../../core/utils/componentBase';

export class NotificationListComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {};
    this.controller = NotificationListController;
    this.template = require('./notificationList.html');
  }
}
