import { FIELD_DEFAULT_COLOR, StatusSetFieldDefinition } from '../../core/models';
import { ILogService } from '../../core/services';
import { ICacheService } from '../services/cache';

/* @ngInject */
export default function CioClaimStatus(Cache: ICacheService, LogService: ILogService) {
  return {
    restrict: 'E',
    link: link,
    scope: {
      claim: '=',
      status: '=',
      mode: '@'
    },
    template:
      '<span class="badge badge-status" ng-style="style" title="{{description}}">{{text}}</span>'
  };

  function link(scope, element, attr) {
    if (scope.mode && scope.mode === 'watch') {
      const watchFn = scope.$watch('claim', setStatus);
      scope.$on('$destroy', watchFn);
    } else {
      setStatus();
    }

    async function setStatus() {
      try {
        const template = await Cache.claimTemplate(scope.claim.templateId);

        const statusField = template.systemFields.find(x => x.name === 'status');
        if (!statusField) {
          LogService.error(
            new Error(`Could not find status field '${scope.claim.templateId}'`)
          );
          return;
        }

        const fields = await Cache.fields();
        const field = (fields.find(
          f => f.id === statusField.fieldId
        ) as any) as StatusSetFieldDefinition;
        if (!field) {
          LogService.error(`Could not find field ${statusField.fieldId}`);
          return;
        }

        const statusToLook = scope.status || scope.claim.status;
        const status = (field.settings.status || []).find(x => x.name === statusToLook);
        if (!status) {
          LogService.debug(`Could not find status '${statusToLook}'`);
          scope.style = { 'background-color': FIELD_DEFAULT_COLOR };
          scope.color = FIELD_DEFAULT_COLOR;
          scope.description = '';
          scope.text = statusToLook || 'No set';
        } else {
          scope.style = { 'background-color': status.color };
          scope.color = status.color;
          scope.description = status.description;
          scope.text = status.name;
        }
      } catch (err) {
        LogService.error(`Could not set status '${err}'.`);
      }
    }
  }
}
