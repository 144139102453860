import { CountryFieldController } from './countryField.controller';

export class CountryFieldComponent implements ng.IComponentOptions {
  public controller: any;
  public template: string;
  public bindings: any;

  constructor() {
    this.bindings = {
      definition: '<',
      field: '=',
      templateDefinition: '<',
      templateFields: '<',
      formDisabled: '<',
      sections: '<'
    };
    this.controller = CountryFieldController;
    this.template = require('./countryField.html');
  }
}
