import { ISecurityService } from './security';
import { DateTime, Interval } from 'luxon';

/**
 * Provides formatting support based on the user's date time preferences.
 */
export interface IDateService {
  /**
   * Returns the relative date time format.
   * @param {DateTime} instant The time to format.
   * @returns {String}
   */
  relative(instant: DateTime): string;

  /**
   * Formats the specified date as UTC.
   * @param {DateTime} instant The time to format.
   * @returns {String}
   */
  formatUtcDate(instant: DateTime): string;

  /**
   * Formats the specified date time as UTC.
   * @param {DateTime} instant The time to format.
   * @returns {String}
   */
  formatUtcDateTime(instant: DateTime): string;

  /**
   * Formats the specified date in the local timezone.
   * @param {DateTime} instant The time to format.
   * @returns {String}
   */
  formatLocalDate(instant: DateTime): string;

  /**
   * Formats the specified date time as a time in the local timezone.
   * @param {DateTime} instant The time to format.
   * @returns {String}
   */
  formatLocalTime(instant: DateTime): string;

  /**
   * Formats the specified date time in the local timezone.
   * @param {DateTime} instant The time to format.
   * @param {Boolean} withTimezone Indicates if the local timezone should be shown.
   * @returns {String}
   */
  formatLocalDateTime(instant: DateTime, withTimezone?: boolean): string;
}

export class DateService implements IDateService {
  static $inject = ['SecurityService'];

  constructor(private securityService: ISecurityService) {}

  relative(instant: DateTime): string {
    if (!instant) {
      return '';
    }
    const now = DateTime.local();
    const inPast = now.valueOf() > instant.valueOf();
    const interval = inPast
      ? Interval.fromDateTimes(instant, now)
      : Interval.fromDateTimes(now, instant);
    const prefix = inPast ? '' : 'in ';
    const suffix = inPast ? ' ago' : '';
    const yearsDiff = Math.abs(interval.toDuration('years').years);
    if (yearsDiff >= 1) {
      return compile(period(yearsDiff, 'year', true));
    }
    const monthsDiff = Math.abs(interval.toDuration('months').months);
    if (monthsDiff >= 1) {
      return compile(period(monthsDiff, 'month', true));
    }
    const secondsDiff = Math.abs(interval.toDuration('seconds').seconds);
    if (secondsDiff >= 60 * 60 * 24) {
      return compile(period(secondsDiff / (60 * 60 * 24), 'day', true));
    }
    if (secondsDiff >= 60 * 60) {
      return compile(period(secondsDiff / (60 * 60), 'hour', true));
    }
    if (secondsDiff >= 60) {
      return compile(period(secondsDiff / 60, 'minute', true));
    }
    if (secondsDiff >= 1) {
      return compile(period(secondsDiff, 'second', true));
    }
    return 'now';

    function compile(text) {
      return `${prefix}${text}${suffix}`;
    }

    function period(value, name, hasPlural) {
      const rounded = Math.floor(value);
      if (rounded <= 0) {
        // should never occur
        return `almost 1 ${name}`;
      }
      return rounded === 1 || !hasPlural ? `${rounded} ${name}` : `${rounded} ${name}s`;
    }
  }

  formatUtcDate(instant: DateTime): string {
    if (!instant) {
      return '';
    }
    return instant.toUTC().toFormat(this.securityService.getDateFormat());
  }

  formatUtcDateTime(instant: DateTime): string {
    if (!instant) {
      return '';
    }
    return instant.toUTC().toFormat(this.securityService.getDateTimeFormat());
  }

  formatLocalDate(instant: DateTime): string {
    if (!instant) {
      return '';
    }
    return instant.toLocal().toFormat(this.securityService.getDateFormat());
  }

  formatLocalTime(instant: DateTime, withTimezone: boolean = false): string {
    if (!instant) {
      return '';
    }
    return instant
      .toLocal()
      .toFormat(this.securityService.getTimeFormat() + withTimezone ? ' ZZZZ' : '');
  }

  formatLocalDateTime(instant: DateTime, withTimezone: boolean = false): string {
    if (!instant) {
      return '';
    }
    return instant
      .toLocal()
      .toFormat(
        `${this.securityService.getDateTimeFormat()}${withTimezone ? ' ZZZZ' : ''}`
      );
  }
}
