import { ICountryService } from '../../../core/services';
import ng from 'angular';

/* @ngInject */
export default function AddressCopyModalController(
  $scope: any,
  $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
  CountryService: ICountryService,
  addressDefinitions,
  fields: any[],
  address: any
) {
  $scope.selectedAddress = null;
  $scope.address = address;
  $scope.fields = addressDefinitions;
  $scope.countries = CountryService.getCountries();

  $scope.ok = function() {
    $uibModalInstance.close($scope.address);
  };

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };

  $scope.addressChange = function() {
    if ($scope.selectedAddress && $scope.selectedAddress !== null) {
      const theField = fields.find(x => x.id === $scope.selectedAddress.fieldId);
      if (theField) {
        $scope.address = ng.copy(theField.value);
      }
    } else {
      $scope.address = address;
    }
  };
}
