import { OrganizationComponent } from './organization.component';
import { OrganizationRoutes } from './organization.routes';
import { CoreModule } from '../../../core/module';
import { DataModule } from '../../../data/module';
import { UIModule } from '../../../ui/module';
import * as angular from 'angular';

export const OrganizationModule = angular
  .module('app.instance.settings.organization', [
    DataModule.name,
    CoreModule.name,
    UIModule.name
  ])
  // Routes
  .config(OrganizationRoutes)

  // Components
  .component('organizationComponent', new OrganizationComponent());
