import { HttpService } from './http';
import {
  Activity,
  ClaimSearchResults,
  Guid,
  IClaimSearchQuery,
  Page,
  StorageFile,
  ClaimWatcher
} from '../models';
import { ITrackingService } from '../services';
import { Check } from '../utils';
import { logError } from '../utils/rx';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';

export interface IClaimHttpService {
  search(search: IClaimSearchQuery): Observable<ClaimSearchResults>;
  getActvities(claimId: Guid, take?: number, skip?: number): Observable<Page<Activity>>;
  createDocument(
    claimId: Guid,
    documentId: Guid,
    invoiceId?: Guid
  ): Observable<StorageFile>;
  searchBySubscriber(): Observable<any>;
  isSubscriber(claimId: Guid): Observable<boolean>;
  subscribe(claimId: Guid): Observable<ClaimWatcher>;
  unsubscribe(claimId: Guid): Observable<void>;
}

export class ClaimHttpService implements IClaimHttpService {
  static $inject = ['$window', 'HttpService', 'TrackingService'];

  private readonly _url: string = '/api/v1/claims';

  constructor(
    private window: any,
    private httpService: HttpService,
    private trackingService: ITrackingService
  ) {}

  search(search: IClaimSearchQuery): Observable<ClaimSearchResults> {
    return this.httpService
      .post('/api/v1/claims/search', search)
      .map(res => ClaimSearchResults.fromJson(res.json()))
      .catch(logError);
  }

  searchBySubscriber(): Observable<any> {
    return this.httpService
      .get('/api/v1/claims/search/subscriber')
      .map(res => res.json())
      .catch(logError);
  }

  getActvities(
    claimId: Guid,
    take: number = 50,
    skip: number = 0
  ): Observable<Page<Activity>> {
    const url = `${
      this._url
    }/activities?claim=${claimId.toString()}take=${take}&skip=${skip}`;
    return this.httpService
      .get(url)
      .map(res => Page.fromJson(res.json(), item => Activity.fromJson(item)))
      .catch(logError);
  }

  createDocument(
    claimId: Guid,
    documentId: Guid,
    invoiceId?: Guid
  ): Observable<StorageFile> {
    Check.assert(claimId, 'Claim ID not specified.');
    Check.assert(documentId, 'Document ID not specified.');
    const url = `${this._url}/${claimId.toString()}/documents/create`;
    const body = {
      claimId: claimId.toString(),
      documentId: documentId.toString(),
      invoiceId: invoiceId && invoiceId.toString()
    };
    return this.httpService
      .post(url, body)
      .map(res => StorageFile.fromJson(res.json()))
      .do(f => this.trackDocumentCreated(f, claimId, documentId, invoiceId))
      .catch(logError);
  }

  isSubscriber(claimId: Guid): Observable<boolean> {
    return this.httpService
      .head(`${this._url}/${claimId.toString()}/subscriber`)
      .map(res => res.isSuccess)
      .catch(logError);
  }

  subscribe(claimId: Guid): Observable<ClaimWatcher> {
    return this.httpService
      .post(`${this._url}/${claimId.toString()}/subscribe`, {})
      .map(res => res.json() as ClaimWatcher)
      .catch(logError);
  }

  unsubscribe(claimId: Guid): Observable<void> {
    return this.httpService
      .post(`${this._url}/${claimId.toString()}/unsubscribe`, {})
      .map(res => {})
      .catch(logError);
  }

  private trackDocumentCreated(
    file: StorageFile,
    claimId: Guid,
    documentId: Guid,
    invoiceId?: Guid
  ): StorageFile {
    this.trackingService.increment('Files Created');
    this.trackingService.increment('Documents Created');
    this.trackingService.track(
      'File Created',
      ClaimHttpService.getDocumentTrackingFields(file, claimId, documentId, invoiceId)
    );
    return file;
  }

  private static getDocumentTrackingFields(
    file: StorageFile,
    claimId: Guid,
    documentId: Guid,
    invoiceId?: Guid
  ): any {
    return {
      'File ID': file.id.toString(),
      Revisions: file.revisions,
      Size: file.size,
      Name: file.name,
      Extension: file.extension,
      'Claim ID': claimId.toString(),
      'Document ID': documentId.toString(),
      'For Invoice': !!invoiceId
    };
  }
}
