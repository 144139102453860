/* @ngInject */
export default function WidgetsModalController(
  $scope: any,
  $uibModalInstance: ng.ui.bootstrap.IModalServiceInstance,
  widgets: any[]
) {
  $scope.widgets = widgets;

  $scope.selectWidget = function(widget) {
    $uibModalInstance.close(widget);
  };

  $scope.cancel = function() {
    $uibModalInstance.dismiss('cancel');
  };
}
