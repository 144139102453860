import { ComponentBase } from '../../core/utils/componentBase';
import { FilePreviewerController } from './filePreviewer.controller';

export class FilePreviewerComponent extends ComponentBase {
  constructor() {
    super();
    this.bindings = {
      file: '<',
      revision: '<',
      onDownload: '&'
    };
    this.controller = FilePreviewerController;
    this.template = require('./filePreviewer.html');
  }
}
