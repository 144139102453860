import { IWorkflowHttpService } from '../../../core/http';
import { Workflow, Guid, WorkflowFilterGroup } from '../../../core/models';
import { IAlertService } from '../../../core/services';
import ng from 'angular';
import { StateService } from 'angular-ui-router';

interface IWorkflowEditState {
  id: string;
}

export class WorkflowEditController {
  static $inject = [
    '$scope',
    '$state',
    '$stateParams',
    'WorkflowHttpService',
    'AlertService'
  ];

  editForm: ng.IFormController = {} as any;
  busy: boolean = false;
  workflow: Workflow;

  constructor(
    private scope: any,
    private stateService: StateService,
    private stateParams: IWorkflowEditState,
    private workflowHttpService: IWorkflowHttpService,
    private alertService: IAlertService
  ) {}

  $onInit() {
    this.load();
  }

  load() {
    this.busy = true;
    this.workflowHttpService
      .getWorkflow(new Guid(this.stateParams.id))
      .safeApply(
        this.scope,
        item => {
          this.workflow = item;
          if (!this.workflow.filterGroups || this.workflow.filterGroups.length === 0) {
            this.workflow.filterGroups = [new WorkflowFilterGroup()];
          }
          this.busy = false;
        },
        error => {
          this.alertService.error('Could not load workflow, please try again.');
          this.busy = false;
        }
      )
      .subscribe();
  }

  save() {
    if (this.editForm.$invalid || this.busy) {
      return;
    }

    this.busy = true;
    this.workflowHttpService
      .modifyWorkflow(this.workflow)
      .safeApply(
        this.scope,
        updated => {
          this.alertService.success('Workflow updated');
          this.busy = false;
          this.workflow = updated;
        },
        _ => {
          this.alertService.error('Could not update workflow, please try again.');
          this.busy = false;
        }
      )
      .subscribe();
  }

  delete() {
    if (this.busy) {
      return;
    }
    this.workflowHttpService
      .deleteWorkflow(this.workflow.id)
      .safeApply(
        this.scope,
        _ => {
          this.alertService.success('Workflow deleted');
          this.busy = false;
          this.stateService.go('app.workflows');
        },
        _ => {
          this.alertService.error('Could not delete workflow, please try again.');
          this.busy = false;
        }
      )
      .subscribe();
  }
}
