import { Entity, Guid } from '../../../core/models';
import { EntityResource } from '../../../data/resources/entity';
import { IEntityService } from '../../entities/entity.service';

interface IOnEntitySelect {
  (changes: { entity: Entity }): any;
}

export class FinancialEntitySelectController {
  static $inject = ['EntityService', 'EntityResource'];

  entity: Entity;
  onSelect: IOnEntitySelect;
  private _entityId?: Guid;
  private _validTypes: Guid[] = [];

  constructor(
    private entityService: IEntityService,
    private entityResource: EntityResource
  ) {}

  get entityId(): Guid {
    return this._entityId;
  }

  set entityId(value: Guid) {
    if (this._entityId && this._entityId.equals(value)) {
      return;
    }
    this._entityId = value ? new Guid(value) : undefined;
  }

  get validTypes(): Guid[] {
    return this._validTypes;
  }

  set validTypes(values: Guid[]) {
    // values could be strings, so we need to map
    this._validTypes = (values || []).map(x => new Guid(x));
  }

  $onChanges() {
    this.loadEntity();
  }

  select() {
    this.entityService
      .selectEntity(this.validTypes || [], true)
      .then(result => {
        if (!result) {
          return;
        }
        this._entityId = result;
        this.loadEntity()
          .then(() => {
            this.onSelect({ entity: this.entity });
          })
          .catch(() => {});
      })
      .catch(() => {});
  }

  private loadEntity(): Promise<void> {
    this.entity = undefined;
    if (!this.entityId || Guid.isEmpty(this.entityId)) {
      return;
    }
    return this.entityResource
      .simple({ id: this.entityId.toString() })
      .$promise.then(entity => (this.entity = entity)) as any;
  }
}
