interface IWorkflowActionSendEmail {
  from: string;
  to: string;
  cc: string;
  bcc: string;
  subject: string;
  body: string;
}

export class WorkflowActionSendEmailController {
  static $inject = [];
  data: IWorkflowActionSendEmail;
}
