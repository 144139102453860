import { HttpService } from './http';
import { Guid } from '../models';
import { logError } from '../utils';
import { Observable } from 'rxjs/Observable';
import 'rxjs/add/operator/do';
import 'rxjs/add/operator/map';
import { UserSetting, OrganizationSetting, EffectiveSetting } from '../models';

export interface ISettingHttpService {
  /**
   * Returns the effective setting for the specified user.
   *   1. user scoped (if available),
   *   2. else organization (if available),
   *   3. else undefined
   * @param userId The ID of the user to return the setting for.
   * @param name The name of the setting.
   */
  getEffectiveSetting(
    userId: Guid,
    name: string
  ): Observable<EffectiveSetting | undefined>;

  /**
   * Returns all effective settings for the specified user.
   *   1. user scoped (if available),
   *   2. else organization (if available),
   *   3. else undefined
   * @param userId The ID of the user to return the settings for.
   */
  getEffectiveSettings(userId: Guid): Observable<EffectiveSetting[]>;

  /**
   * Returns the user scoped setting, or undefined if not found.
   * @param userId The ID of the user to return the setting for.
   * @param name The name of the setting.
   */
  getUserSetting(userId: Guid, name: string): Observable<UserSetting | undefined>;

  /**
   * Returns all user scoped settings for the specified user.
   * @param userId The ID of the user to return the settings for.
   */
  getUserSettings(userId: Guid): Observable<UserSetting[]>;

  /**
   * Returns all organization scoped settings.
   */
  getOrganizationSettings(): Observable<OrganizationSetting[]>;

  /**
   * Modifies the specified user scoped setting.
   * @param userId The ID of the user to modify the setting for.
   * @param name The name of the setting.
   * @param value The value of the setting.
   */
  modifyUserSetting(userId: Guid, name: string, value: string): Observable<UserSetting>;

  /**
   * Modifies the specified organization scoped setting.
   * @param userId The ID of the user to modify the setting for.
   * @param name The name of the setting.
   * @param value The value of the setting.
   */
  modifyOrganizationSetting(name: string, value: string): Observable<OrganizationSetting>;

  /**
   * Deletes the specified user scoped setting.
   * @param userId The ID of the user to delete the setting for.
   * @param name The name of the setting.
   */
  deleteUserSetting(userId: Guid, name: string): Observable<void>;
}

export class SettingHttpService implements ISettingHttpService {
  static $inject = ['HttpService'];

  private readonly _url: string = '/api/v1/settings';

  constructor(private httpService: HttpService) {}

  getEffectiveSetting(
    userId: Guid,
    name: string
  ): Observable<EffectiveSetting | undefined> {
    name = (name || '').trim().toLowerCase();
    return this.httpService
      .get(`${this._url}/effective/${userId.toString()}/${name}`)
      .map(res => {
        if (res.statusCode === 404) {
          return undefined;
        }

        return new EffectiveSetting(res.json());
      })
      .catch(logError);
  }

  getEffectiveSettings(userId: Guid): Observable<EffectiveSetting[]> {
    return this.httpService
      .get(`${this._url}/effective/${userId.toString()}`)
      .map(res => (res.json<any[]>() || []).map(x => new EffectiveSetting(x)))
      .catch(logError);
  }

  getUserSetting(userId: Guid, name: string): Observable<UserSetting | undefined> {
    name = (name || '').trim().toLowerCase();
    return this.httpService
      .get(`${this._url}/user/${userId.toString()}/${name}`)
      .map(res => {
        if (res.statusCode === 404) {
          return undefined;
        }

        return new EffectiveSetting(res.json());
      })
      .catch(logError);
  }

  getUserSettings(userId: Guid): Observable<UserSetting[]> {
    return this.httpService
      .get(`${this._url}/user/${userId.toString()}`)
      .map(res => (res.json<any[]>() || []).map(x => new EffectiveSetting(x)))
      .catch(logError);
  }

  getOrganizationSettings(): Observable<OrganizationSetting[]> {
    return this.httpService
      .get(`${this._url}/organization`)
      .map(res => (res.json<any[]>() || []).map(x => new EffectiveSetting(x)))
      .catch(logError);
  }

  modifyUserSetting(userId: Guid, name: string, value: string): Observable<UserSetting> {
    name = (name || '').trim().toLowerCase();
    const setting = new UserSetting({
      name,
      userId,
      value
    });
    return this.httpService
      .put(`${this._url}/user/${userId.toString()}/${name}`, setting)
      .map(res => new UserSetting(res.json()))
      .catch(logError);
  }

  modifyOrganizationSetting(
    name: string,
    value: string
  ): Observable<OrganizationSetting> {
    name = (name || '').trim().toLowerCase();
    const setting = new OrganizationSetting({
      name,
      value
    });
    return this.httpService
      .put(`${this._url}/organization/${name}`, setting)
      .map(res => new OrganizationSetting(res.json()))
      .catch(logError);
  }

  deleteUserSetting(userId: Guid, name: string): Observable<void> {
    name = (name || '').trim().toLowerCase();
    return this.httpService
      .delete(`${this._url}/user/${userId.toString()}/${name}`)
      .map(res => new UserSetting(res.json()))
      .catch(logError);
  }
}
