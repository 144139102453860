export interface ITimeOption {
  label: string;
  hour: number;
  minute: number;
}

export enum TimeFrequencies {
  Hour = 'hour',
  Thirty = 'thirty',
  Fifteen = 'fifteen'
}

export const TimeFactory = {
  /**
   * Returns the hours list based on the frequency specified.
   * @param {TimeFrequency} frequency The frequency of the time.
   * @returns {ITimeOption[]} The hours.
   */
  getTimes(frequency: TimeFrequencies): ITimeOption[] {
    const times: ITimeOption[] = [];
    for (let hour = 0; hour <= 23; hour++) {
      let shortHour = hour > 12 ? hour - 12 : hour;
      if (hour === 0) {
        shortHour = 12;
      }
      const paddedHour = `${shortHour < 10 ? '0' : ''}${shortHour}`;
      const meridiem = hour < 12 ? 'AM' : 'PM';
      addTime(`${paddedHour}:00 ${meridiem}`, hour, 0);
      if (frequency === TimeFrequencies.Hour) {
        continue;
      }
      if (frequency === TimeFrequencies.Fifteen) {
        addTime(`${paddedHour}:15 ${meridiem}`, hour, 15);
      }
      addTime(`${paddedHour}:30 ${meridiem}`, hour, 30);
      if (frequency === TimeFrequencies.Fifteen) {
        addTime(`${paddedHour}:45 ${meridiem}`, hour, 45);
      }
    }
    return times;
    function addTime(label, hour, minute) {
      times.push({ label, hour, minute });
    }
  }
};
